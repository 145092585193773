// AOF Norge Admin department ID
export const ADMIN_DEPARTMENT_ID = 1;
// Studieforbundet AOF Norge department ID
export const DEFAULT_DEPARTMENT_ID = 1042;

export enum AuthRole {
  // Roles from auth token
  Everyone = "everyone",
  ApproveCurriculum = "approvecurriculum",
  ModifySchoolRoute = "modifyschoolroute",
  ModifyExtendedCourse = "modifyextendedcourse", // Kursadministrator utvidet
  ModifyCost = "modifycost",
  ModifyCourseArea = "modifycoursearea",
  ModifyRole = "modifyrole",
  ImpersonateDepartment = "impersonatedepartment",
  ApproveMemberOrgApplication = "approvememberorgapplication",
  ModifyMemberOrganization = "modifymemberorganization",
  CreateMemberOrganization = "creatememberorganization",
  ModifyClosedCourse = "modifyclosedcourse",
  CreateClientAccountRates = "createclientaccountrates",
  ModifyClientAccountRate = "modifyclientaccountrate",
  ModifyClientAccount = "modifyclientaccount",
}
