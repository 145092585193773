export enum AreaActions {
  Reset = "reset",
  FetchAreas = "fetchAreas",
  FetchArea = "fetchArea",
  AddArea = "addArea",
  EditArea = "editArea",
  FetchLocations = "fetchLocations",
  FetchLocation = "fetchLocation",
  RefreshRooms = "refreshRooms",
  AddLocation = "addLocation",
  EditLocation = "editLocation",
  FetchRoom = "fetchRoom",
  AddRoom = "addRoom",
  PostRoom = "postRoom",
  EditRoom = "editRoom",
  AddRoomsFromQueue = "addRoomsFromQueue",
  DelegateRoom = "delegateRoom",
}
