
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { FilterActions } from "@/store/modules/filter/filter.actions.enum";
import { StoreModules } from "@/store/store-modules.enum";
import { defineComponent } from "@vue/composition-api";
import { VAutocomplete } from "vuetify/lib";
import { mapGetters } from "vuex";

interface ComponentData {
  unsubscribe: (() => void) | null;
}

export default defineComponent({
  name: "TableFilter",
  data: (): ComponentData => ({
    unsubscribe: null,
  }),
  computed: {
    ...mapGetters("filter", ["filter", "hasFilter", "model"]),
    filters(): any {
      return this.$store.state.filter.filter.map((filter: any) => ({
        ...filter,
        filterComponent: filter.component !== undefined ? filter.component : VAutocomplete,
        props: {
          ...filter.attrs,
          value: (this as any).model[filter.value],
          "input-value": (this as any).model[filter.value],
        },
        handlers: {
          input: (event: Event) => {
            this.apply(filter.value, event);
            filter.on?.input?.(event);
          },
          change: (event: Event) => {
            this.apply(filter.value, event);
            filter.on?.change?.(event);
          },
        },
      }));
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type, payload }) => {
      if (type == "filter/APPLY") {
        const query = new URLSearchParams(window.location.search);
        if (!query.has(payload.key) || query.get(payload.key) != payload.value) {
          if (payload.value || typeof payload.value === "boolean") {
            query.set(payload.key, payload.value);
          } else {
            query.delete(payload.key);
          }

          this.$router.push({ query: Object.fromEntries(query) }, () => {
            (document.activeElement as HTMLElement)?.focus();
          });
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  methods: {
    apply(key: string, value: Event) {
      runStoreAction(this.$store, StoreModules.Filter, FilterActions.Apply, { key, value });
    },
    remove() {
      runStoreAction(this.$store, StoreModules.Filter, FilterActions.Remove);
    },
  },
});
