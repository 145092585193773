
// Components
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseList from "@/components/shared/list/BaseList.vue";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "ShellMenu",
  components: { BaseList, BaseSheet },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
