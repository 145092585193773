import { httpClient } from "./httpClient";

export const getDefaultOptions = () => httpClient.get("/curriculum/defaultoptions");

export const getStudyplan = (params: number) => httpClient.get(`/curriculum/${params}`);

export const getCoursesByStudyplanId = (params: number) => httpClient.get(`curriculum/${params}/course`);

export const putStudyplan = (studyplan: any) => httpClient.put(`/curriculum/${studyplan.id}`, studyplan);

export const getDrafts = () => httpClient.get("/curriculum/draft");

export const getDraft = (id: number) => httpClient.get(`/curriculum/draft/${id}`);

export const postDraft = (body: any) => httpClient.post(`/curriculum/draft`, body);

export const putDraft = (body: any) => httpClient.put(`/curriculum/draft/${body.draftId}`, body);

export const removeDraft = (id: number) => httpClient.delete(`/curriculum/draft/${id}`);

export const postPlan = (studyplan: any) => httpClient.post(`/curriculum`, studyplan);
