import { httpClient } from "./httpClient";

export const getSchedules = () => httpClient.get(`/holiday`);

export const getSchedule = (id: number) => httpClient.get(`/holiday/${id}`);

export const putSchedule = (holidayBody: any) => httpClient.put(`/holiday/${holidayBody.id}`, holidayBody);

export const postSchedule = (holidayBody: any) => httpClient.post(`/holiday`, holidayBody);

export const deleteSchedule = (id: number) => httpClient.delete(`/holiday/${id}`);

export const validateSchedule = (body: any) => httpClient.post("/holiday/validateschedule", body);
