import { api } from "@/api/api";
import {
  ApiFacilitationCostsDto,
  ApiGetCourseDto,
  ApiGetCourseParticipantDto,
  ApiGetCurriculumDto,
  ApiGetCustomerDto,
  ApiGetCustomerPersonDto,
  ApiGetExecutionPlanDto,
} from "@/api/generated/Api";
import { useRestrictedAccessApi } from "./accessLevelApiAdapter";
import { authService } from "@/auth/authService";

export const getCourseName = (vm: any, id: string) => {
  const data = vm.$store.state.courses;
  if (data.courses) {
    return data.courses.find((current: any) => {
      if (current.id == id) {
        return current;
      }
    });
  }
};

export const calculateTotalCourseHours = (courseValues: any) => {
  const { hoursWithInstructor, hoursWithoutInstructor, hoursOnline } = courseValues;
  const sum = [hoursWithInstructor, hoursWithoutInstructor, hoursOnline].reduce((sum, value) => {
    if (value === undefined || value === null) {
      return sum;
    }
    if (sum === undefined || sum === null) {
      return 0;
    }
    return +sum + +value;
  }, 0);
  return sum;
};

export const getCourseAreaNameById = async (id?: number) => {
  if (!id) {
    return;
  }
  return (await api.coursearea.getCourseAreaAsync(id)).data.name;
};

export const loadAllCourseData = async (courseId: number) => {
  const restrictedAccessApi = useRestrictedAccessApi();

  const data: {
    course: ApiGetCourseDto | undefined;
    courseParticipantStudents: ApiGetCourseParticipantDto[];
    executionPlan: ApiGetExecutionPlanDto | undefined;
    studyplan: ApiGetCurriculumDto | undefined;
  } = {
    course: undefined,
    courseParticipantStudents: [],
    executionPlan: undefined,
    studyplan: undefined,
  };

  const [course, courseParticipants] = await Promise.all([
    restrictedAccessApi.getCourse(courseId),
    restrictedAccessApi.getCourseParticipants(courseId, { IsActive: true, RoleNames: ["Student"] }),
  ]);
  data.course = course.data;
  data.courseParticipantStudents = courseParticipants.data;

  if (!data.course?.curriculumId) {
    throw new Error("CurriculumId is missing");
  }
  const [curriculum, executionPlan] = await Promise.all([
    restrictedAccessApi.getCurriculum(data.course.curriculumId),
    restrictedAccessApi.getExecutionPlan(data.course.curriculumId),
  ]);
  data.studyplan = curriculum.data;
  data.executionPlan = executionPlan.data;

  return data;
};

export const getDayText = (day: number) => {
  const dayFound = allDays.find((d) => d.value === day);
  return dayFound?.text ?? "Ukjent";
};

export const allDays = [
  { value: 1, text: "Mandag" },
  { value: 2, text: "Tirsdag" },
  { value: 3, text: "Onsdag" },
  { value: 4, text: "Torsdag" },
  { value: 5, text: "Fredag" },
  { value: 6, text: "Lørdag" },
  { value: 0, text: "Søndag" },
];

export type FacilitationCostType = keyof ApiFacilitationCostsDto;

export const sumCostType = (costType: FacilitationCostType, budgetLines: ApiFacilitationCostsDto[]) =>
  budgetLines.reduce((sum, row) => sum + (Number(row[costType]) || 0), 0) || 0;
export const enableGrantApplicationCourseGrantIds = [10, 20] as const;
export const enabledGrantIdsForClientAccountInvoiceBasis = [20, 25] as const;

export const isGrantApplicationEnabled = (
  grantId?: Nullable<number>
): grantId is (typeof enableGrantApplicationCourseGrantIds)[number] =>
  !!(grantId && enableGrantApplicationCourseGrantIds.some((id) => id === grantId));

export const canCreateInvoiceBasis = (
  grantId?: Nullable<number>
): grantId is (typeof enabledGrantIdsForClientAccountInvoiceBasis)[number] =>
  !!(grantId && enabledGrantIdsForClientAccountInvoiceBasis.some((id) => id === grantId));

export const calculateAmountByGrantId = (
  grantId: number,
  hoursWithInstructor: number,
  teachingGrantAmount: number,
  extraProfessionalCourseAmount: number
) => {
  if (!isGrantApplicationEnabled(grantId)) {
    return 0;
  }

  const calculateAmount: Record<(typeof enableGrantApplicationCourseGrantIds)[number], () => number> = {
    10: () => hoursWithInstructor * teachingGrantAmount,
    20: () => hoursWithInstructor * teachingGrantAmount + extraProfessionalCourseAmount,
  } as const;

  return calculateAmount[grantId]();
};

export const hasModifyClosedCourseAccess =
  authService.canImpersonateDepartment() || authService.canModifyClosedCourse();

// Guest endpoint has a different DTO for customer (ApiGetCustomerPersonDto) than universum (ApiGetCustomerDto)
// This method returns customer data for the participant regardless of the returned data from the Api.
export const getCustomerDtoRef = (ref: ApiGetCustomerDto | ApiGetCustomerPersonDto) => {
  const CUSTOMER_PROPERTY = "customer";
  if (CUSTOMER_PROPERTY in ref) {
    return (ref as ApiGetCustomerDto).customer;
  }
  return ref as ApiGetCustomerPersonDto;
};
