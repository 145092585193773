import { api } from "@/api/api";
import { getMemberDepartments } from "@/api/memberOrganizations.api";
import { authService } from "@/auth/authService";
import { MemberOrganizationsActions } from "@/store/modules/memberOrganizations.store/memberOrganizations.actions.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { MemberOrganizationsMutations } from "./memberOrganizations.mutations.enum";
import { MemberOrganizationsStoreState } from "./memberOrganizations.store.interface";

const initialState: () => MemberOrganizationsStoreState = () => ({
  organizations: [],
  departments: [],
});

const state = initialState();

const getters = <GetterTree<MemberOrganizationsStoreState, RootStoreState>>{
  getField,
  getOrganizations(state) {
    return state.organizations;
  },
  getDepartments(state) {
    return state.departments;
  },
};

const actions = <ActionTree<MemberOrganizationsStoreState, RootStoreState>>{
  [MemberOrganizationsActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [MemberOrganizationsActions.FetchMemberOrganizations]({ commit }) {
    const response = !authService.fromMemberOrganization()
      ? await api.organization.getDepartmentsAsync()
      : await api.guestside.getGuestUserDepartmentsAsync();
    commit("SET_ORGANIZATIONS", response.data);
  },
  async [MemberOrganizationsActions.FetchMemberDepartment]({ commit }) {
    const response = await getMemberDepartments();
    commit("SET_DEPARTMENTS", response.data);
  },
};

const mutations = <MutationTree<MemberOrganizationsStoreState>>{
  updateField,
  [MemberOrganizationsMutations.SET_ORGANIZATIONS](state, data) {
    state.organizations = data;
  },
  [MemberOrganizationsMutations.SET_DEPARTMENTS](state, data) {
    state.departments = data;
  },
};

export const MemberOrganizationsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
