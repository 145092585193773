// is this really better than normal case? Have to-remap in components for TS
export enum TeachingMaterialsMutations {
  RESET = "RESET",
  SET_TEACHINGMATERIAL = "SET_TEACHINGMATERIAL",
  SET_TEACHINGMATERIALS = "SET_TEACHINGMATERIALS",

  SET_CATEGORY = "SET_CATEGORY",
  SET_TEACHINGMATERIAL_CATEGORY = "SET_TEACHINGMATERIAL_CATEGORY",
  SET_TEACHINGMATERIAL_CATEGORIES = "SET_TEACHINGMATERIAL_CATEGORIES",

  SET_SUBJECTS = "SET_SUBJECTS",
  ADD_TM = "ADD_TM",
  ADD_TMC = "ADD_TMC",
  RESET_TM = "RESET_TM",
}
