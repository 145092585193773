import { httpClient } from "./httpClient";
import axios from "axios";

export const getCourse = (params: number) => httpClient.get(`/course/${params}`);

export const getCourseDefaultOptions = () => httpClient.get(`/course/defaultoptions`);

export const getCourses = (params: any) =>
  httpClient.get(`/course`, {
    params: {
      insertedBy: params.insertedBy,
      updatedBy: params.updatedBy,
      includes: params.includes,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
    },
  });

export const postCourse = (course: any, curriculumId: number) =>
  httpClient.post(`/course`, {
    curriculumId,
    ...course,
  });

export const putCourse = (courseId: number, course: any) => httpClient.put(`course/${courseId}`, course);

export const getCourseCategories = () => axios.get("https://www.aof.no/wp-json/tada/get/coursecategories");

export const getWebDescription = (courseId: number) => httpClient.get(`course/${courseId}/webdescription`);

export const postWebDescription = (courseId: number, webDescription: any) =>
  httpClient.post(`course/${courseId}/webdescription`, webDescription);

export const putWebdescription = (courseId: number, webDescription: any) =>
  httpClient.put(`course/${courseId}/webdescription`, webDescription);

export const getAppForm = (courseId: number) => httpClient.get(`course/${courseId}/appform`);

export const postAppForm = (courseId: number, appForm: any) => httpClient.post(`course/${courseId}/appform`, appForm);

export const getWebApplicationById = (courseId: number, userId: number) =>
  httpClient.get(`/course/${courseId}/participant/${userId}/application`);
