import { SearchActions } from "@/store/modules/search/search.actions.enum";

// TODO : NEEDED ?
import Vue from "vue";

import { SearchMutations } from "./search.mutations.enum";
import { SearchStoreState } from "./search.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";

const initialState: () => SearchStoreState = () => ({
  results: {},
  value: "",
});

const state = initialState();

const getters = <GetterTree<SearchStoreState, RootStoreState>>{
  value(state) {
    return state.value;
  },
  results(state) {
    return Object.values(state.results).reduce((m: any, v: any) => [...m, ...v], []);
  },
};

const actions = <ActionTree<SearchStoreState, RootStoreState>>{
  [SearchActions.SetValue]({ commit }, payload) {
    commit("SET_VALUE", payload);
  },
  [SearchActions.Reset]({ commit }) {
    commit("RESET");
  },
  [SearchActions.SetResults]({ commit }, payload) {
    commit("SET_RESULTS", payload);
  },
};

const mutations = <MutationTree<SearchStoreState>>{
  [SearchMutations.RESET](state) {
    state.results = {};
    state.value = "";
  },
  [SearchMutations.SET_VALUE](state, payload) {
    state.value = payload;
  },
  [SearchMutations.SET_RESULTS](state, { results, key }) {
    if (key in state.results) {
      state.results[key] = results;
    } else {
      Vue.set(state.results, key, results);
    }
  },
};

export const SearchModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
