
// Components
import BaseListItem from "@/components/shared/list/BaseListItem.vue";
import BaseVListGroup from "@/components/shared/BaseVListGroup.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseListGroup",
  components: {
    BaseListItem,
    BaseVListGroup,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({ model: null }),
  computed: {
    group(): any {
      return this.genGroup(this.item.items);
    },
    icon(): any {
      if (!this.item.icon) {
        return undefined;
      }
      const [off, on] = this.item.icon.split(":");
      return this.model ? on || off : off;
    },
  },
  methods: {
    genGroup(items: any) {
      return items
        .reduce((acc: any, cur: any) => {
          acc.push(cur.items ? this.genGroup(cur.items) : cur.to);
          return acc;
        }, [])
        .join("|");
    },
  },
});
