
import { authService } from "@/auth/authService";
import TheDepartmentSelector from "@/components/shell/TheDepartmentSelector.vue";
import TheGlobalSearch from "@/components/shell/TheGlobalSearch.vue";
import TheProfileMenu from "@/components/shell/TheProfileMenu.vue";
import { AuthRole } from "@/auth/auth.constants";
// @ts-ignore
import SearchableSection from "@/mixins/SearchableSection";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { adminItems, contactItems, courseItems, dashboardItems, studyplanItems } from "@/shared/helpers/shellHelpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "TheAppBarItems",
  components: { TheGlobalSearch, TheDepartmentSelector, TheProfileMenu },
  mixins: [SearchableSection],
  setup() {
    const router = useRouter();
    const store = useStore<StoreState>();

    const links = [
      {
        title: "Dashboard",
        link: "/home",
        isEnabledForMemberOrganizations: true,
      },
      {
        title: "Studieplaner",
        link: "/studieplaner",
      },
      {
        title: "Oversikt",
        link: "/kurs",
        isEnabledForMemberOrganizations: true,
      },
      {
        title: "Klientkonto kurs",
        link: "/client-account-courses",
        hasEconomyPermissions: authService.hasRoles(AuthRole.ModifyClientAccount),
      },
      {
        title: "Adressebok",
        link: "/adressebok",
      },
      {
        title: "Administrasjon",
        link: "/admin/teachingmaterial",
      },
    ].filter((link) => {
      if ("hasEconomyPermissions" in link) {
        return link.hasEconomyPermissions;
      }

      // If you add featureflags to routes you need to add them here as well
      // if ("featureFlag" in link && "hasEconomyPermissions" in link) {
      //   return link.featureFlag && link.hasEconomyPermissions;
      // }

      // if ("featureFlag" in link) {
      //  return link.featureFlag;
      // }

      if ("hasEconomyPermissions" in link) {
        return link.hasEconomyPermissions;
      }

      if (!hasMemberOrgAccess) {
        return true;
      }

      return link.isEnabledForMemberOrganizations;
    });
    const logout = () => authService.signOut();

    const getSearchResults = async (value: string) => {
      const searchValue = value.toLowerCase();
      const filterByTitle = ({ title, link }: { title: string; link: string }) =>
        title.toLowerCase().includes(searchValue) && router.currentRoute.path != link;

      const results = [
        {
          label: "Dashboard",
          results: dashboardItems(router).filter((item) => filterByTitle(item) || "dashboard".includes(searchValue)),
        },
        {
          label: "Studieplaner",
          results: studyplanItems(router).filter((item) => filterByTitle(item) || "studieplaner".includes(searchValue)),
        },
        {
          label: "Kurs",
          results: await courseItems(store, router.currentRoute.path, +router.currentRoute.params.id, router).then(
            (items) => {
              if (!items) {
                return [];
              }
              return items.filter((item) => filterByTitle(item) || "kurs".includes(searchValue));
            }
          ),
        },
        {
          label: "Adressebok",
          results: contactItems(router).filter(
            (item) => filterByTitle(item) || "adressebok".includes(searchValue) || "kontakter".includes(searchValue)
          ),
        },
        {
          label: "Administrasjon",
          results: adminItems(router).filter((item) => filterByTitle(item) || "administrasjon".includes(searchValue)),
        },
      ].filter(({ results }) => results.length > 0);
      return results.reduce(
        (model: any[], section) => [
          ...model,
          {
            header: section.label,
          },
          ...section.results.map(({ title, link }) => ({
            text: title,
            value: title,
            onSelected: () => router.push({ path: link }),
          })),
        ],
        []
      );
    };

    return {
      links,
      logout,
      getSearchResults,
      canImpersonateDepartment: computed(() => authService.canImpersonateDepartment()),
    };
  },
});
