import { getField, updateField } from "vuex-map-fields";
import { getHour, getHours, postHour, putHour } from "@/api/hours.api";
import { HoursStoreState } from "./hours.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { HoursMutations } from "./hours.mutations.enum";
import { HoursActions } from "@/store/modules/hours/hours.actions.enum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

const initialState: () => HoursStoreState = () => ({
  hours: [],
  hourSingle: {},
  selected: [],
});

const state = initialState();

const getters = <GetterTree<HoursStoreState, RootStoreState>>{
  getField,
  getHours(state) {
    return state.hours;
  },
  getSelected: (state) => {
    state.selected;
  },
  getHour(state) {
    return state.hourSingle;
  },
};

const actions = <ActionTree<HoursStoreState, RootStoreState>>{
  [HoursActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [HoursActions.FetchHours]({ commit }) {
    const response = await getHours();
    if (response) {
      await commit("SET_HOURS", response);
    }
  },
  async [HoursActions.FetchHour]({ commit }, employeeId) {
    const response = await getHour(employeeId);
    if (response) {
      await commit("SET_HOUR", response);
    }
  },
  async [HoursActions.AddHour]({ commit }) {
    const response = await postHour(state.hourSingle);
    if (response.status === 200) {
      commit("ADD", "9812");
      openNotification(this as any, NotificationItemType.Success, "Opprettelse av test type vellykket");
    }
  },
  [HoursActions.ApproveHours]({ commit }) {
    state.selected.forEach((item) => {
      const index = state.hourSingle.children.findIndex((a: any) => item.date == a.date);
      commit("APPROVE_HOUR", index);
      commit("SET_SELECTED", []);
    });
  },
  async [HoursActions.EditHour]() {
    const response = await putHour(state.hourSingle);
    if (response.status === 204) {
      openNotification(this as any, NotificationItemType.Success, "Redigering av test type vellykket");
    }
  },
};

const mutations = <MutationTree<HoursStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the gloabal reset.
  [HoursMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof HoursStoreState] = newState[key as keyof HoursStoreState];
    });
  },
  [HoursMutations.SET_SELECTED](state, data) {
    state.selected = data;
  },
  [HoursMutations.SET_HOUR](state, data) {
    state.hourSingle = data;
  },
  [HoursMutations.SET_HOURS](state, data) {
    state.hours = data;
  },
  [HoursMutations.APPROVE_HOUR](state, i) {
    state.hourSingle.children[i].approved = true;
  },
  [HoursMutations.ADD](state, data) {
    state.hourSingle.id = data;
    state.hours.push(state.hourSingle);
  },
};

export const HoursModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
