export enum HocActions {
  Reset = "reset",
  LoadingSkeleton = "loadingSkeleton",
  RemoveLoadingSkeleton = "removeLoadingSkeleton",
  RemoveNotificationItem = "removeNotificationItem",
  OpenNotification = "openNotification",
  ChangeBlockingRequests = "changeBlockingRequests",
  ChangeNonBlockingRequests = "ChangeNonBlockingRequests",
  ChangeTableSkeletonRequests = "ChangeTableSkeletonRequests",
  SetDepartment = "SetDepartment",
}
