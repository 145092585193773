// Config object to be passed to Msal on creation.
// For a full list of msal.js configuration parameters,

import { isStringArray } from "@/shared/helpers/typeHelpers";
import { AuthRole } from "./auth.constants";

export const loginTenantId = "AOFTID";

export enum TenantId {
  aofNorge = "c2454593-0a23-4e32-b3ce-ce0d03b07d43",
  aofHaugland = "b53ae35e-78bc-47ff-b1d7-5b60c55b99dd",
}

// visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
export const msalConfig = {
  auth: {
    clientId: "df59ac0f-3310-4c96-8062-bc6c0c95dc19",
    authority: `https://login.microsoftonline.com/${localStorage.getItem(loginTenantId)}/`,
    redirectUri: `${location.origin}/login-callback`, // localhost:8000 is whitelisted in azure
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here the scopes to request when obtaining an access token for MS Graph API
// for more, visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/scopes.md
export const loginRequest = {
  scopes: ["api://df59ac0f-3310-4c96-8062-bc6c0c95dc19/Fields.Read"],
};

// Add here scopes for access token to be used at MS Graph API endpoints.
export const tokenRequest = {
  scopes: ["api://df59ac0f-3310-4c96-8062-bc6c0c95dc19/Fields.Read"],
};

/**
 * Reduce user authorizations from a generic string array provided in auth token to a filtered array of typed authorizations.
 *
 * Convenience function that allows us to work with typed auth groups/roles.
 * Looks up token values in a provided enum and returns a typed array.
 */
export function reduceUserAuthorizations<AuthEnumType extends AuthRole>(
  userAuthorizations: string | string[],
  authEnum: { [s: string]: AuthEnumType }
) {
  // Auth tokens are typed as string, but in reality provided as string[], so
  // this is a workaround to ensure we're working with the correct type
  if (!isStringArray(userAuthorizations)) {
    return [];
  }

  // Reduces an array of user authorizations from auth token (e.g. ['everyone',
  // 'invalid role']) to an array of typed authorizations the user has based on
  // enum values (e.g. [AuthRole.Everyone])
  return userAuthorizations.reduce<AuthEnumType[]>((filteredAuthorizations, userAuthorization) => {
    // Check if a token role/group (e.g. role 'everyone') is present in the
    // enum (e.g. AuthRole)
    const lookupAuthorization = Object.values(authEnum).find((authEnumValue) => authEnumValue === userAuthorization);
    if (!lookupAuthorization) {
      // Lookup fails if the token role/group isn't present in the enum
      return [...filteredAuthorizations];
    }
    // Return a collection of only valid, typed authorizations - including the
    // typed authorization we just looked up in the auth enum
    return [...filteredAuthorizations, lookupAuthorization];
  }, []);
}
