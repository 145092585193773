import { httpClient } from "./httpClient";

export const fetchCurriculum = (curriculumId: number) => httpClient.get(`/curriculum/${curriculumId}`);

export const getExecutionPlan = (id: number) => httpClient.get(`/curriculum/${id}/executionplan`);

export const postExecutionPlan = (executionPlan: any, id: number) =>
  httpClient.post(`/curriculum/${id}/executionplan`, {
    info: {
      reasonForStatusChangeRequired: executionPlan.info.reasonForStatusChangeRequired,
      cancellationDeadlineRequired: executionPlan.info.cancellationDeadlineRequired,
      hoursWithoutInstructorRequired: executionPlan.info.hoursWithoutInstructorRequired,
      hoursSelfStudyRequired: executionPlan.info.hoursSelfStudyRequired,
      enrollmentDeadlineRequired: executionPlan.info.enrollmentDeadlineRequired,
      unenrollmentDeadlineRequired: executionPlan.info.unenrollmentDeadlineRequired,
      minimumParticipantsRequired: executionPlan.info.minimumParticipantsRequired,
      maximumParticipantsRequired: executionPlan.info.maximumParticipantsRequired,
      //  Not in the diagram
      availableParticipantsRequired: false,
      financiersRequired: executionPlan.info.financiersRequired,
      priceRequired: executionPlan.info.priceRequired,
      additionalFilesRequired: executionPlan.info.additionalFilesRequired,
      groupIdRequired: executionPlan.info.groupIdRequired,
    },
    plan: executionPlan.plan.plan,
    /* plan: {
          scheduleRequired: executionPlan.scheduleRequired
        } */
    economyRequired: executionPlan.economyRequired,
    certificatesRequired: executionPlan.certificatesRequired,
    certificate: {
      courseCertificateRequired: executionPlan.certificate.courseCertificateRequired,
      gradeCertificateRequired: executionPlan.certificate.gradeCertificateRequired,
      diplomaRequired: executionPlan.certificate.diplomaRequired,
    },
    teachingMaterialsRequired: executionPlan.teachingMaterialsRequired,
    testsRequired: executionPlan.testsRequired,
    testDescriptionRequired: executionPlan.descriptionRequired,
  });
