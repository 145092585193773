import { HocMutations } from "@/store/modules/hoc/hoc.mutations.enum";
import { UnionKeys } from "@/store/store.types";
import { AreaMutations } from "./modules/area/area.mutations.enum";
import { ContactsMutations } from "./modules/contacts/contacts.mutations.enum";
import { CostMutations } from "./modules/cost/cost.mutations.enum";
import { CourseParticipantsMutations } from "./modules/courseParticipants/courseParticipants.mutations.enum";
import { CoursesMutations } from "./modules/courses/courses.mutations.enum";
import { ExecutionMutations } from "./modules/execution/execution.mutations.enum";
import { FilesMutations } from "./modules/files/files.mutations.enum";
import { FilterMutations } from "./modules/filter/filter.mutations.enum";
import { HoursMutations } from "./modules/hours/hours.mutations.enum";
import { MemberOrganizationsMutations } from "./modules/memberOrganizations.store/memberOrganizations.mutations.enum";
import { OrganizationMutations } from "./modules/organization/organization.mutations.enum";
import { PartnerMutations } from "./modules/partner/partner.mutations.enum";
import { PlansMutations } from "./modules/plans/plans.mutations.enum";
import { RoleMutations } from "./modules/role/role.mutations.enum";
import { SearchMutations } from "./modules/search/search.mutations.enum";
import { SsbMutations } from "./modules/ssb/ssb.mutations.enum";
import { SubjectRegisterMutations } from "./modules/subjectRegister/subjectRegister.mutations.enum";
import { TeachingMaterialsMutations } from "./modules/teachingMaterials/teachingMaterials.mutations.enum";
import { TestTypesMutations } from "./modules/testTypes/testTypes.mutations.enum";
import { TrainingGrantsMutations } from "./modules/trainingGrants/trainingGrants.mutations.enum";
import { VacationScheduleMutations } from "./modules/vacationSchedule/vacationSchedule.mutations.enum";

export const StoreMutations = {
  HocMutations,
  AreaMutations,
  ContactsMutations,
  CostMutations,
  CourseParticipantsMutations,
  CoursesMutations,
  ExecutionMutations,
  FilesMutations,
  FilterMutations,
  HoursMutations,
  MemberOrganizationsMutations,
  OrganizationMutations,
  PartnerMutations,
  PlansMutations,
  RoleMutations,
  SearchMutations,
  SsbMutations,
  SubjectRegisterMutations,
  TeachingMaterialsMutations,
  TestTypesMutations,
  TrainingGrantsMutations,
  VacationScheduleMutations,
} as const;

export type StoreMutationsType = UnionKeys<(typeof StoreMutations)[keyof typeof StoreMutations]>;
