export enum TeachingMaterialsActions {
  Reset = "reset",
  FetchTeachingMaterialCategories = "fetchTeachingMaterialCategories",
  PostTeachingmaterial = "postTeachingmaterial",
  PutTeachingmaterial = "putTeachingmaterial",
  FetchTeachingmaterials = "fetchTeachingmaterials",
  FetchTeachingmaterialsById = "fetchTeachingmaterialsById",
  FetchTeachingmaterialsByGroup = "fetchTeachingmaterialsByGroup",
  ChangeCategory = "changeCategory",
}
