import { getYear } from "date-fns";
import { getField, updateField } from "vuex-map-fields";
import { TrainingGrantsActions } from "@/store/modules/trainingGrants/trainingGrants.actions.enum";

import {
  getTrainingGrant,
  getTrainingGrants,
  postTrainingGrant,
  putTrainingGrant,
} from "../../../api/trainingGrants.api";
import { TrainingGrantsMutations } from "./trainingGrants.mutations.enum";
import { TrainingGrantsStoreState } from "./trainingGrants.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

const initialState: () => TrainingGrantsStoreState = () => ({
  trainingGrants: [],
  trainingGrantSingle: {},
});

const state = initialState();

const getters = <GetterTree<TrainingGrantsStoreState, RootStoreState>>{
  getField,
  getTrainingGrants(state) {
    return state.trainingGrants;
  },
  getTrainingGrant(state) {
    return state.trainingGrantSingle;
  },
};

const actions = <ActionTree<TrainingGrantsStoreState, RootStoreState>>{
  [TrainingGrantsActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [TrainingGrantsActions.FetchTrainingGrants]({ commit }) {
    const response = await getTrainingGrants();
    if (response.length > 0) {
      await commit("SET_TRAINING_GRANTS", response);
    }
  },
  async [TrainingGrantsActions.FetchTrainingGrant]({ commit }, id) {
    const response = await getTrainingGrant(id);
    await commit("SET_TRAINING_GRANT", response);
  },
  async [TrainingGrantsActions.AddTrainingGrant]({ commit }) {
    const data = {
      year: getYear(Date.now()).toString(),
      trainingGrantsAmount: 0,
      additionalCoursesAmount: 0,
    };

    const modal = {
      type: "add",
      headline: "Legg til opplæringstilskudd",
    };

    await postTrainingGrant(data);
    commit("SET_TRAINING_GRANT", data);
    commit("hoc/SET_MODAL_TYPE", modal, { root: true });
    commit("hoc/OPEN_MODAL", "Admin/TrainingGrants.vue", { root: true });
  },
  async [TrainingGrantsActions.EditTrainingGrant]() {
    await putTrainingGrant(state.trainingGrantSingle);
    openNotification(this as any, NotificationItemType.Success, "Redigering av tilskudd vellykket");
  },
};

const mutations = <MutationTree<TrainingGrantsStoreState>>{
  updateField,

  //  Will always add a reset mutation so we can use the global reset.
  [TrainingGrantsMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof TrainingGrantsStoreState] = newState[key as keyof TrainingGrantsStoreState];
    });
  },
  [TrainingGrantsMutations.SET_TRAINING_GRANT](state, data) {
    state.trainingGrantSingle = data;
  },
  [TrainingGrantsMutations.SET_TRAINING_GRANTS](state, data) {
    state.trainingGrants = data;
  },
};

export const TrainingGrantsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
