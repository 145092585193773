
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "BaseTooltipButton",
  inheritAttrs: false,
  props: {
    icon: String,
    path: {
      type: String,
      required: true,
    },
  },
});
