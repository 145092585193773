import { getField, updateField } from "vuex-map-fields";
import { getType, getTypes, postType, putType } from "@/api/testTypes.api";
import { TestTypesMutations } from "./testTypes.mutations.enum";
import { TestTypesStoreState } from "./testTypes.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { TestTypesActions } from "@/store/modules/testTypes/testTypes.actions.enum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

const initialState: () => TestTypesStoreState = () => ({
  types: [],
  typeSingle: {},
});

const state = initialState();

const getters = <GetterTree<TestTypesStoreState, RootStoreState>>{
  getField,
  getTypes(state) {
    return state.types;
  },
  getType(state) {
    return state.typeSingle;
  },
};

const actions = <ActionTree<TestTypesStoreState, RootStoreState>>{
  [TestTypesActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [TestTypesActions.FetchTypes]({ commit }) {
    const response = await getTypes();
    if (response.status === 200) {
      await commit("SET_TYPES", response.data);
    }
  },
  async [TestTypesActions.FetchType]({ commit }, id) {
    const response = await getType(id);
    if (response.status === 200) {
      await commit("SET_TYPE", response.data);
    }
  },
  async [TestTypesActions.AddType]({ commit }) {
    const response = await postType(state.typeSingle);
    if (response.status === 200) {
      commit("ADD", response.data.id);
      openNotification(this as any, NotificationItemType.Success, "Opprettelse av test type vellykket.");
    }
  },
  async [TestTypesActions.EditType]() {
    const response = await putType(state.typeSingle);
    if (response.status === 204) {
      openNotification(this as any, NotificationItemType.Success, "Redigering av test type vellykket.");
    }
  },
};

const mutations = <MutationTree<TestTypesStoreState>>{
  updateField,

  //  Will always add a reset mutation so we can use the global reset.
  [TestTypesMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof TestTypesStoreState] = newState[key as keyof TestTypesStoreState];
    });
  },
  [TestTypesMutations.SET_TYPE](state, data) {
    state.typeSingle = data;
  },
  [TestTypesMutations.SET_TYPES](state, data) {
    state.types = data;
  },
  [TestTypesMutations.ADD](state, data) {
    state.typeSingle.id = data;
    state.types.push(state.typeSingle);
  },
};

export const TestTypesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
