
// TODO TS when possible, or remove file
// Extensions
import { defineComponent } from "@vue/composition-api";
import { VListGroup } from "vuetify/lib";
// Utilities
export default defineComponent({
  name: "BaseVListGroup",
  extends: VListGroup,
  methods: {
    onRouteChange(to: any) {
      // TODO : Move this to proper TS-type. (Should have been VueConstructor but doesn't accept it.)
      (VListGroup as any).options.methods.onRouteChange.call(this, to);
    },
  },
});
