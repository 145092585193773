var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', {
    staticClass: "mx-2 mx-md-4",
    class: _vm.isSearching ? 'rounded-b-0' : ' rounded-lg',
    staticStyle: {
      "max-width": "450px"
    },
    attrs: {
      "search-input": _vm.value,
      "background-color": !_vm.isFocused ? '#ECF0FF' : undefined,
      "flat": !_vm.isFocused && !_vm.isSearching,
      "placeholder": "Søk",
      "dense": "",
      "hide-details": "",
      "hide-no-data": "",
      "return-object": "",
      "items": _vm.results,
      "solo": "",
      "no-filter": ""
    },
    on: {
      "input": _vm.onItemSelected,
      "update:search-input": _vm.setValue,
      "focus": function focus($event) {
        _vm.isFocused = true;
      },
      "blur": function blur($event) {
        _vm.isFocused = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-1 mr-2",
          attrs: {
            "color": !_vm.isFocused ? '#6485FC' : undefined
          }
        }, [_vm._v(" mdi-magnify ")])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }