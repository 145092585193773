import { EnvironmentVariables } from "@/environment";
import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex";
import { AreaModule } from "./modules/area/area.store";
import { ContactsModule } from "./modules/contacts/contacts.store";
import { ContractsModule } from "./modules/contracts/contracts.store";
import { CostModule } from "./modules/cost/cost.store";
import { CourseParticipantsModule } from "./modules/courseParticipants/courseParticipants.store";
import { CoursesModule } from "./modules/courses/courses.store";
import { ExecutionModule } from "./modules/execution/execution.store";
import { FilesModule } from "./modules/files/files.store";
import { filterModule } from "./modules/filter/filter.store";
import { HocModule } from "./modules/hoc/hoc.store";
import { HoursModule } from "./modules/hours/hours.store";
import { MemberOrganizationsModule } from "./modules/memberOrganizations.store/memberOrganizations.store";
import { OrganizationModule } from "./modules/organization/organization.store";
import { PartnerModule } from "./modules/partner/partner.store";
import { PlansModule } from "./modules/plans/plans.store";
import { RoleModule } from "./modules/role/role.store";
import { SearchModule } from "./modules/search/search.store";
import { SsbModule } from "./modules/ssb/ssb.store";
import { SubjectRegisterModule } from "./modules/subjectRegister/subjectRegister.store";
import { teachingMaterialsModule } from "./modules/teachingMaterials/teachingMaterials.store";
import { TestTypesModule } from "./modules/testTypes/testTypes.store";
import { TrainingGrantsModule } from "./modules/trainingGrants/trainingGrants.store";
import { VacationScheduleModule } from "./modules/vacationSchedule/vacationSchedule.store";
import { RootMutations } from "./root-store.mutations";
import { RootStoreState } from "./root-store.state.interface";
import { StoreModules } from "./store-modules.enum";

Vue.use(Vuex);

const initialState: () => RootStoreState = () => ({
  isLoading: false,
});

const state = initialState();

const mutations = <MutationTree<RootStoreState>>{
  [RootMutations.SET_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
};

const getters = <GetterTree<RootStoreState, RootStoreState>>{};

const actions = <ActionTree<RootStoreState, RootStoreState>>{};

export default new Vuex.Store<RootStoreState>({
  strict: EnvironmentVariables.DEVELOPMENT,
  state: {
    ...state,
  },
  mutations,
  actions,
  getters,
  modules: {
    [StoreModules.Hoc]: HocModule,
    [StoreModules.Area]: AreaModule,
    [StoreModules.Contacts]: ContactsModule,
    [StoreModules.Contracts]: ContractsModule,
    [StoreModules.Cost]: CostModule,
    [StoreModules.CourseParticipants]: CourseParticipantsModule,
    [StoreModules.Courses]: CoursesModule,
    [StoreModules.Execution]: ExecutionModule,
    [StoreModules.Files]: FilesModule,
    [StoreModules.Filter]: filterModule,
    [StoreModules.Hours]: HoursModule,
    [StoreModules.MemberOrganizations]: MemberOrganizationsModule,
    [StoreModules.Organization]: OrganizationModule,
    [StoreModules.Partner]: PartnerModule,
    [StoreModules.Plans]: PlansModule,
    [StoreModules.Role]: RoleModule,
    [StoreModules.Search]: SearchModule,
    [StoreModules.Ssb]: SsbModule,
    [StoreModules.SubjectRegister]: SubjectRegisterModule,
    [StoreModules.TeachingMaterials]: teachingMaterialsModule,
    [StoreModules.TestTypes]: TestTypesModule,
    [StoreModules.TrainingGrants]: TrainingGrantsModule,
    [StoreModules.VacationSchedule]: VacationScheduleModule,
  },
});
