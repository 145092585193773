import { api } from "@/api/api";
import { createCustomer, createOrganization, getOrganization, getPerson, putOrganization } from "@/api/contacts.api";
import { authService } from "@/auth/authService";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { ContactsActions } from "@/store/modules/contacts/contacts.actions.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { ContactsMutations } from "./contacts.mutations.enum";
import { ContactsStoreState } from "./contacts.store.interface";

const initialState: () => ContactsStoreState = () => ({
  persons: [],
  organisations: [],
  contactsInCourse: [],
  contactSingle: {},
  person: {
    id: undefined,
    postAddress1: "",
    postAddress2: undefined,
    postAddress3: undefined,
    postCity: "",
    postZip: "",
    postCountry: "",
    invoiceAddress1: "",
    invoiceAddress2: undefined,
    invoiceAddress3: undefined,
    invoiceCity: "",
    invoiceZip: "",
    invoiceCountry: "",
    phoneNumber: "",
    mobileNumber: "",
    email: "",
    isActive: false,
    customer: {
      customerId: undefined,
      title: "",
      firstName: "",
      middleName: undefined,
      lastName: "",
      birthDate: "",
      ssno: undefined,
      gender: "",
      allowDm: true,
    },
  },
  organisation: {},
  searchedPerson: null,
});

const state = initialState();

const getters = <GetterTree<ContactsStoreState, RootStoreState>>{
  getField,
  getPersons(state) {
    return state.persons;
  },
  getPerson(state) {
    return state.person;
  },
  getOrganization(state) {
    return state.organisation;
  },
  getOrganizations(state) {
    return state.organisations;
  },
  getContact(state) {
    return state.contactSingle;
  },
  contactFormattedBirthdate(state) {
    const date = state.contactSingle.customer.birthDate;
    return date ? format(date, "PP", { locale: nb }) : "";
  },
  contactGender(state) {
    // const labels = {
    //   M: "Mann",
    //   F: "Kvinne",
    // };
    return state.contactSingle.customer.gender;
    // Todo fix
    // return labels[state.contactSingle.customer.gender] || "";
  },
  getSearchedPerson(state) {
    return state.searchedPerson;
  },
};

const actions = <ActionTree<ContactsStoreState, RootStoreState>>{
  [ContactsActions.Reset]({ commit }) {
    commit(ContactsMutations.RESET);
  },
  /* Fetches all contacts */
  async [ContactsActions.FetchContacts]({ commit }) {
    const response = !authService.fromMemberOrganization()
      ? await api.customer.getCustomerPersonsAsync()
      : await api.guestside.getGuestUserCustomerPersonsAsync();
    commit(ContactsMutations.SET_PERSONS, response.data);
  },
  async [ContactsActions.FetchOrganisations]({ commit }) {
    const response = await api.customer.getCustomerOrganizationsAsync();
    commit(ContactsMutations.SET_ORGANIZATIONS, response?.data);
  },
  async [ContactsActions.FetchPerson]({ commit }, id) {
    const response = await getPerson(id);
    commit(ContactsMutations.SET_PERSON, response.data);
  },
  async [ContactsActions.FetchOrganization]({ commit }, id) {
    const response = await getOrganization(id);
    commit(ContactsMutations.SET_ORGANIZATION, response.data);
  },
  async [ContactsActions.AddPerson]({ dispatch }) {
    const response = await createCustomer(state.contactSingle);
    if (response.status == 200) {
      openNotification(this as any, NotificationItemType.Success, "Registrering vellykket!");
      await dispatch("contacts/fetchContacts", "", {
        root: true,
      });
      dispatch("hoc/closeModal", "", { root: true });
    }
  },
  async [ContactsActions.EditOrganization]({ dispatch }) {
    const response = await putOrganization(state.contactSingle);
    if (response.status == 204) {
      openNotification(this as any, NotificationItemType.Success, "Redigering av organisasjon vellykket.");
      await dispatch("role/fetchOrganisations", null, { root: true });
    }
  },

  async [ContactsActions.EditPerson]() {
    throw new Error("Not implemented yet");
    // const response = await api.customer.updatePersonAsync(state.contactSingle.id, state.contactSingle);
    // if (response.status == 204) {
    //   openNotification(this as any, NotificationItemType.Success, "Redigering av person vellykket.");
    //   await dispatch("contacts/fetchContacts", null, { root: true });
    // }
  },

  async [ContactsActions.AddOrganization]({ commit }) {
    try {
      const org = {
        postAddress1: state.contactSingle.postAddress1,
        postAddress2: "",
        postAddress3: "",
        postCity: state.contactSingle.postCity,
        postZip: state.contactSingle.postZip,
        postCountry: state.contactSingle.postCountry,
        invoiceAddress1: state.contactSingle.postAddress1,
        invoiceAddress2: "",
        invoiceAddress3: "",
        invoiceCity: state.contactSingle.postCity,
        invoiceZip: state.contactSingle.postZip,
        invoiceCountry: state.contactSingle.postCountry,
        phoneNumber: state.contactSingle.phoneNumber,
        mobileNumber: state.contactSingle.mobileNumber,
        email: state.contactSingle.email,
        isActive: true,
        customer: {
          name: state.contactSingle.customer.name,
          orgNumber: state.contactSingle.customer.orgNumber,
          homePage: state.contactSingle.customer.homePage,
        },
      };
      const response = await createOrganization(org);
      if (response.status == 200) {
        openNotification(this as any, NotificationItemType.Success, "Registrering vellykket!");
        commit(ContactsMutations.ADD_ORG, response.data.id);
      }
    } catch (error) {
      if ((error as any).status == 400) {
        openNotification(this as any, NotificationItemType.Error, "Personen er allerede registrert.");
      } else {
        openNotification(
          this as any,
          NotificationItemType.Error,
          "Det oppstod et problem med registrering av personen. Prøv igjen."
        );
      }
    }
  },
  // TODO fix api is missing in api file remove try/catch (searchPerson should now use httpClient file to detect errors)
  // async [ContactsActions.SearchForPerson]({ commit }, data) {
  //   try {
  //     const response = await searchPerson(data);
  //     commit("SET_SEARCHED_PERSON", response);
  //   } catch (err) {
  //     console.warn("Error occured", err);
  //   }
  // },
  [ContactsActions.ChangeToSearchedForPerson]({ commit }, person) {
    commit(ContactsMutations.SET_TO_SEARCHED_FOR_PERSON, [person]);
  },
  [ContactsActions.SetPersons]({ commit }, data) {
    commit(ContactsMutations.SET_PERSONS, data);
  },
};

const mutations = <MutationTree<ContactsStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the gloabal reset.
  [ContactsMutations.RESET](state) {
    const newState = initialState();
    // TODO fix this
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // state = { ...newState };
    Object.keys(newState).forEach((key) => {
      // @ts-ignore TODO
      state[key as keyof ContactsStoreState] = newState[key as keyof ContactsStoreState];
    });
  },
  [ContactsMutations.ADD_ORG](state, data) {
    state.contactSingle.id = data;
    state.organisations.push(state.contactSingle);
  },
  [ContactsMutations.SET_PERSONS](state, response) {
    state.persons = response;
  },
  [ContactsMutations.SET_PERSON](state, response) {
    state.contactSingle = response;
  },
  [ContactsMutations.SET_ORGANIZATIONS](state, response) {
    state.organisations = response;
  },
  [ContactsMutations.SET_ORGANIZATION](state, response) {
    state.contactSingle = response;
  },
  [ContactsMutations.SET_SEARCHED_PERSON](state, data) {
    state.searchedPerson = data;
  },
  [ContactsMutations.SET_TO_SEARCHED_FOR_PERSON](state, data) {
    state.persons = data;
  },
};

export const ContactsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
