// import { httpClient } from "./httpClient";

export const getHours = () => appendingHours;

export const getHour = (id: number) => {
  const type = appendingHours.find((obj) => obj.employeeId === id);
  return type;
};

export const putHour = (body: any): any => {
  const index = appendingHours.findIndex((obj) => obj.id === body.id);
  appendingHours[index] = body;
  return appendingHours;
};

export const postHour = (body: any): any => {
  appendingHours.push(body);
  return appendingHours;
};

/* HELPERS */

const appendingHours = [
  {
    id: 1, // TODO fix, ID was not there before moving
    courseId: 34,
    totalHoursFromContract: 20,
    hoursRegistered: 4,
    employeeId: 1204,
    allApproved: false,
    children: [
      {
        date: "2021-01-10T12:57:50.1231234",
        pdId: 300,
        pdName: "Undervisningstimer",
        pdSubTypeId: 301,
        pdSubTypeName: "Undervisningstimer 1-2",
        title: "Forelesning",
        status: "Godkjent",
        registeredHours: 2,
        approved: true,
        approvedById: null,
        comment: "",
        lastUpdated: "2021-01-19T12:57:50.5436537",
      },
      {
        date: "2021-01-18T12:57:50.3453876",
        pdId: 300,
        pdName: "Undervisningstimer",
        pdSubTypeId: 301,
        pdSubTypeName: "Undervisningstimer 1-2",
        title: "Forelesning",
        status: "Til Godkjenning",
        registeredHours: 2,
        approved: false,
        approvedById: null,
        comment: "",
        lastUpdated: "2021-01-22T12:57:50.7713075",
      },
    ],
  },
  {
    courseId: 33,
    totalHoursFromContract: 20,
    hoursRegistered: 4,
    allApproved: false,
    employeeId: 451,
    children: [
      {
        date: "2021-01-10T12:57:50.2134543",
        pdId: 300,
        pdName: "Undervisningstimer",
        pdSubTypeId: 301,
        pdSubTypeName: "Undervisningstimer 1-2",
        title: "Forelesning",
        status: "Godkjent",
        registeredHours: 2,
        approved: true,
        approvedById: null,
        comment: "",
        lastUpdated: "2021-01-19T12:57:50.7713075",
      },
      {
        date: "2021-01-18T12:57:50.6754433",
        pdId: 300,
        pdName: "Undervisningstimer",
        pdSubTypeId: 301,
        pdSubTypeName: "Undervisningstimer 1-2",
        title: "Forelesning",
        status: "Til Godkjenning",
        registeredHours: 2,
        approved: false,
        approvedById: null,
        comment: "",
        lastUpdated: "2021-01-22T12:57:50.7713075",
      },
    ],
  },
];
