export enum ContactsMutations {
  ADD_ORG = "ADD_ORG",
  RESET = "RESET",
  SET_ORGANIZATION = "SET_ORGANIZATION",
  SET_ORGANIZATIONS = "SET_ORGANIZATIONS",
  SET_PERSON = "SET_PERSON",
  SET_PERSONS = "SET_PERSONS",
  SET_SEARCHED_PERSON = "SET_SEARCHED_PERSON",
  SET_TO_SEARCHED_FOR_PERSON = "SET_SETO_SEARCHED_FOR_PERSON",
}
