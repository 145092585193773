var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-1-1-auto d-flex align-center justify-end"
  }, [_vm.canImpersonateDepartment ? _c('TheDepartmentSelector') : _vm._e(), _c('TheGlobalSearch'), _vm.$vuetify.breakpoint.lgAndUp ? _vm._l(_vm.links, function (link, i) {
    return _c('v-btn', {
      key: i,
      class: 'link-' + i,
      attrs: {
        "to": "".concat(link.link),
        "text": "",
        "data-cy": "appBarItemsButton"
      }
    }, [_vm._v(" " + _vm._s(link.title) + " ")]);
  }) : _vm._e(), _c('TheProfileMenu')], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }