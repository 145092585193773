export enum PlansActions {
  Reset = "reset",
  FetchDefaultOptions = "fetchDefaultOptions",
  PostStudyplan = "postStudyplan",
  UpdateStudyplan = "updateStudyplan",
  FetchCurriculum = "fetchCurriculum",
  FetchDraft = "fetchDraft",
  SaveDraft = "saveDraft",
  UpdateDraft = "updateDraft",
}
