import { ClientAccountCourseRouteNames } from "@/shared/enums/RouteNames/clientAccountCourseRouteNames.enum";

export const ClientAccountCourseList = [
  {
    path: "client-account-courses",
    name: ClientAccountCourseRouteNames.ClientAccountCourseList,
    component: () =>
      import(
        /* webpackChunkName: "clientAccountCourseListPage" */ "@/pages/clientAccountCourse/ClientAccountCourseListPage.vue"
      ),
  },
];
