export enum StoreModules {
  Todo = "todo",
  Hoc = "hoc",
  Area = "area",
  Contracts = "contracts",
  Contacts = "contacts",
  Cost = "cost",
  CourseParticipants = "courseParticipants",
  Courses = "courses",
  Execution = "execution",
  Files = "files",
  Filter = "filter",
  Hours = "hours",
  MemberOrganizations = "memberOrganizations",
  Organization = "organization",
  Partner = "partner",
  Plans = "plans",
  Role = "role",
  Search = "search",
  Ssb = "ssb",
  SubjectRegister = "subjectRegister",
  TeachingMaterials = "teachingMaterials",
  TestTypes = "testTypes",
  TrainingGrants = "trainingGrants",
  VacationSchedule = "vacationSchedule",
}
