import { getField, updateField } from "vuex-map-fields";
import { SubjectRegisterActions } from "@/store/modules/subjectRegister/subjectRegister.actions.enum";

import {
  getSubjRegs,
  // getSubjReg,
  postSubjReg,
  putSubjReg,
} from "@/api/subjectRegister.api";
import { SubjectRegisterMutations } from "./subjectRegister.mutations.enum";
import { SubjectRegisterStoreState } from "./subjectRegister.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

const initialState: () => SubjectRegisterStoreState = () => ({
  subjectRegister: [],
  subjectRegisterEntry: {},
});

const state = initialState();

const getters = <GetterTree<SubjectRegisterStoreState, RootStoreState>>{
  getField,
  getSubjectRegister(state) {
    return state.subjectRegister;
  },
  getSubjectRegisterEntry(state) {
    return state.subjectRegisterEntry;
  },
};

const actions = <ActionTree<SubjectRegisterStoreState, RootStoreState>>{
  [SubjectRegisterActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [SubjectRegisterActions.FetchSubjectRegister]({ commit }) {
    const response = await getSubjRegs();
    if (response.status == 200) {
      // TODO : Remove unneccesary await ?
      await commit("SET_SUBJECTS", response.data);
    }
  },

  async [SubjectRegisterActions.AddsubjReg]({ dispatch }) {
    await postSubjReg(state.subjectRegisterEntry);
    openNotification(this as any, NotificationItemType.Success, "Opprettelse av test type vellykket.");
    dispatch("subjectRegister/fetchSubjectRegister", null, { root: true });
  },
  async [SubjectRegisterActions.EditsubjReg]() {
    await putSubjReg(state.subjectRegisterEntry);
    openNotification(this as any, NotificationItemType.Success, "Redigering av test type vellykket.");
  },
};

const mutations = <MutationTree<SubjectRegisterStoreState>>{
  updateField,

  //  Will always add a reset mutation so we can use the global reset.
  [SubjectRegisterMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof SubjectRegisterStoreState] = newState[key as keyof SubjectRegisterStoreState];
    });
  },
  [SubjectRegisterMutations.SET_SUBJECT](state, data) {
    state.subjectRegisterEntry = data;
  },
  [SubjectRegisterMutations.SET_SUBJECTS](state, data) {
    state.subjectRegister = data;
  },
};

export const SubjectRegisterModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
