export enum AreaMutations {
  RESET = "RESET",
  SET_COUNTRY_CODE = "SET_COUNTRY_CODE",
  SET_AREAS = "SET_AREAS",
  SET_AREA = "SET_AREA",
  SET_LOCATIONS = "SET_LOCATIONS",
  SET_LOCATION = "SET_LOCATION",
  SET_ROOMS = "SET_ROOMS",
  SET_ROOM_LIST = "SET_ROOM_LIST",
  SET_ROOM = "SET_ROOM",
  PUSH_ROOM_IN_QUEUE = "PUSH_ROOM_IN_QUEUE",
  REMOVE_INDEX_FROM_QUEUE = "REMOVE_INDEX_FROM_QUEUE",
}
