import { AddressBookRouteNames } from "@/shared/enums/RouteNames/addressBookRouteNames.enum";
export const AdressebokRouteList = [
  {
    path: "adressebok/firma",
    name: AddressBookRouteNames.CustomerOrganizations,
    component: () =>
      import(/* webpackChunkName: "ContactsOrganisationPage" */ "@/pages/adressebok/ContactsOrganisationPage.vue"),
  },
  {
    path: "adressebok",
    name: AddressBookRouteNames.CustomerPersons,
    component: () => import(/* webpackChunkName: "ContactsPeoplePage" */ "@/pages/adressebok/ContactsPeoplePage.vue"),
  },
  {
    path: "adressebok/person/:id",
    name: AddressBookRouteNames.CustomerPerson,
    component: () => import(/* webpackChunkName: "CustomerPersonPage" */ "@/pages/adressebok/CustomerPersonPage.vue"),
  },
  // {
  //   path: "adressebok/concepttable",
  //   name: "concepttable",
  //   component: () => import("@/components/Contacts/ConceptTable.vue"), // Not moving for now
  // },
];
