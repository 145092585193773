import { SsbActions } from "@/store/modules/ssb/ssb.actions.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { getSSBCountryList, getSSBSubject } from "../../../api/ssb.api";
import { SsbMutations } from "./ssb.mutations.enum";
import { SsbStoreState } from "./ssb.store.interface";

const initialState: () => SsbStoreState = () => ({
  ssbCodes: [],
  ssbCountries: [],
});

const state = initialState();

const getters = <GetterTree<SsbStoreState, RootStoreState>>{
  getField,
  getSSBCodes(state) {
    return state.ssbCodes;
  },
  getCountries(state) {
    return state.ssbCodes;
  },
};

const actions = <ActionTree<SsbStoreState, RootStoreState>>{
  [SsbActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [SsbActions.FetchSSBSubject]({ commit }) {
    const response = await getSSBSubject();
    if (response.status == 200) {
      // TODO : Not needed Await ?
      await commit("SET_SSB_CODES", response.data);
    }
  },
  async [SsbActions.fetchCountries]({ commit }) {
    const response = await getSSBCountryList();
    if (response.status == 200) {
      await commit("SET_COUNTRIES", response.data);
    }
  },
};

const mutations = <MutationTree<SsbStoreState>>{
  updateField,

  //  Will always add a reset mutation so we can use the global reset.
  [SsbMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof SsbStoreState] = newState[key as keyof SsbStoreState];
    });
  },
  [SsbMutations.SET_SSB_CODES](state, data) {
    state.ssbCodes = data;
  },
  [SsbMutations.SET_COUNTRIES](state, data) {
    state.ssbCountries = data;
  },
};

export const SsbModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
