import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";

export const KursetRouteList = [
  {
    path: "kurset/:id",
    name: SingleCourseRouteNames.CourseDashboard,
    component: () => import(/* webpackChunkName: "CourseDetailsPage" */ "@/pages/kurs/details/CourseDetailsPage.vue"),
    meta: { isEnabledForMemberOrganizations: true },
  },
  {
    path: "kurset/:id/participants",
    name: SingleCourseRouteNames.CourseParticipants,
    component: () =>
      import(/* webpackChunkName: "CourseParticipantsPage" */ "@/pages/kurs/details/CourseParticipantsPage.vue"),
    meta: { isEnabledForMemberOrganizations: true },
  },
  {
    path: "kurset/:id/participants/:participantId",
    name: SingleCourseRouteNames.CourseParticipantDetails,
    component: () =>
      import(
        /* webpackChunkName: "CourseParticipantDetailPage" */ "@/pages/kurs/details/CourseParticipantDetailPage.vue"
      ),
    meta: { isEnabledForMemberOrganizations: true },
  },
  {
    path: "kurset/:id/platform",
    name: SingleCourseRouteNames.CourseTeachingPlatform,
    component: () =>
      import(
        /* webpackChunkName: "CourseTeachingPlatoformPage" */ "@/pages/kurs/details/CourseTeachingPlatoformPage.vue"
      ),
  },
  {
    path: "kurset/:id/resources",
    name: SingleCourseRouteNames.CourseResources,
    component: () =>
      import(/* webpackChunkName: "CourseResoursesPage" */ "@/pages/kurs/details/CourseResoursesPage.vue"),
  },
  {
    path: "kurset/:id/resources/:resourceId",
    name: SingleCourseRouteNames.CourseResourceDetails,
    component: () => import(/* webpackChunkName: "ViewResource" */ "@/pages/kurs/resources/ViewResourcePage.vue"),
  },
  {
    path: "kurset/:id/companyPage",
    name: SingleCourseRouteNames.CourseCompanyPage,
    component: () => import(/* webpackChunkName: "CourseCompanyPage" */ "@/pages/kurs/details/CourseCompanyPage.vue"),
  },
  {
    path: "kurset/:id/history",
    name: SingleCourseRouteNames.CourseHistory,
    component: () => import(/* webpackChunkName: "CourseHistoryPage" */ "@/pages/kurs/details/CourseHistoryPage.vue"),
  },
  {
    path: "kurset/:id/attendance",
    name: SingleCourseRouteNames.CourseAttendance,
    component: () =>
      import(/* webpackChunkName: "CourseAttendancePage" */ "@/pages/kurs/details/CourseAttendancePage.vue"),
  },
  {
    path: "kurset/:id/attendance/:startDate",
    name: SingleCourseRouteNames.CourseAttendanceParticipant,
    component: () =>
      import(
        /* webpackChunkName: "CourseAttendanceParticipantPage" */ "@/pages/kurs/details/CourseAttendanceParticipantPage.vue"
      ),
  },
  {
    path: "kurset/:id/certificate",
    name: SingleCourseRouteNames.CourseCertificates,
    component: () =>
      import(/* webpackChunkName: "CourseCertificatesPage" */ "@/pages/kurs/details/CourseCertificatesPage.vue"),
  },
  {
    path: "kurset/:id/newOrderDraft",
    name: SingleCourseRouteNames.CourseEconomyNew,
    component: () => import(/* webpackChunkName: "CourseEconomyPage" */ "@/pages/kurs/details/OrderDraftPage.vue"),
  },
  {
    path: "kurset/:id/economy",
    name: SingleCourseRouteNames.CourseEconomy,
    component: () => import(/* webpackChunkName: "CourseEconomyPage" */ "@/pages/kurs/details/CourseEconomyPage.vue"),
  },
  {
    path: "kurset/:id/economy/:orderNo",
    name: SingleCourseRouteNames.CourseEconomyDetails,
    component: () =>
      import(/* webpackChunkName: "CourseEconomyDetailsPage" */ "@/pages/kurs/details/CourseEconomyDetailsPage.vue"),
  },
  {
    path: "kurset/:id/economy/:orderNo/contraOrder",
    name: SingleCourseRouteNames.CourseEconomyContra,
    component: () =>
      import(/* webpackChunkName: "CourseEconomyDetailsPage" */ "@/pages/kurs/details/CourseEconomyContraPage.vue"),
  },
  {
    path: "kurset/:id/expenses",
    name: SingleCourseRouteNames.CourseExpenses,
    component: () => import(/* webpackChunkName: "CourseExpensesPage" */ "@/pages/kurs/details/CourseExpensesPage.vue"),
  },
  {
    path: "kurset/:id/expenses/:expenseId",
    name: SingleCourseRouteNames.CourseExpenseDetails,
    component: () =>
      import(/* webpackChunkName: "CourseExpenseDetailsPage" */ "@/pages/kurs/details/CourseExpenseDetailsPage.vue"),
  },
  {
    path: "kurset/:id/files/:searchQuery?",
    name: SingleCourseRouteNames.CourseFiles,
    component: () => import(/* webpackChunkName: "CourseFileNotesPage" */ "@/pages/kurs/details/CourseFilePage.vue"),
  },
  {
    path: "kurset/:id/notes",
    name: SingleCourseRouteNames.CourseNotes,
    component: () => import(/* webpackChunkName: "CourseNotesPage" */ "@/pages/kurs/details/CourseNotesPage.vue"),
  },
  {
    path: "kurset/:id/invites",
    name: SingleCourseRouteNames.CourseInvites,
    component: () => import(/* webpackChunkName: "CourseInvitesPage" */ "@/pages/kurs/details/CourseInvitesPage.vue"),
  },
  {
    path: "kurset/:id/invites/new",
    name: SingleCourseRouteNames.CourseInvitesNew,
    component: () =>
      import(/* webpackChunkName: "CourseInvitesNewPage" */ "@/pages/kurs/details/CourseInvitesNewPage.vue"),
  },
  {
    path: "kurset/:id/evaluation",
    name: SingleCourseRouteNames.CourseEvaluation,
    component: () =>
      import(/* webpackChunkName: "CourseEvaluationPage" */ "@/pages/kurs/details/CourseEvaluationPage.vue"),
  },
  {
    path: "kurset/:id/messages",
    name: SingleCourseRouteNames.CourseMessages,
    component: () => import(/* webpackChunkName: "CourseMessagesPage" */ "@/pages/kurs/details/CourseMessagesPage.vue"),
  },
  {
    path: "kurset/:id/messages/:messageId",
    name: SingleCourseRouteNames.CourseSingleMessage,
    component: () => import(/* webpackChunkName: "CourseMessagesPage" */ "@/pages/kurs/details/CourseMessagePage.vue"),
  },
  {
    path: "kurset/:id/workingHours",
    name: SingleCourseRouteNames.CourseWorkingHours,
    component: () =>
      import(/* webpackChunkName: "CourseWorkingHoursPage" */ "@/pages/kurs/details/CourseWorkingHoursPage.vue"),
  },
  {
    path: "kurset/:id/workingHours/:hourEntryId",
    name: SingleCourseRouteNames.CourseWorkingHourDetails,
    component: () =>
      import(
        /* webpackChunkName: "CourseWorkingHoursDetailsPage" */ "@/pages/kurs/details/CourseWorkingHoursDetailsPage.vue"
      ),
  },
  {
    path: "kurset/:id/facilitationGrants",
    name: SingleCourseRouteNames.CourseFacilitationGrants,
    component: () =>
      import(
        /* webpackChunkName: "CourseFacilitationGrants" */ "@/pages/kurs/details/CourseFacilitationGrantsPage.vue"
      ),
  },
  {
    path: "kurset/:id/endCourse",
    name: SingleCourseRouteNames.EndCourse,
    component: () => import(/* webpackChunkName: "EndCoursePage" */ "@/pages/kurs/details/EndCoursePage.vue"),
  },
  {
    path: "kurset/:id/invoiceBasis",
    name: SingleCourseRouteNames.CourseInvoiceBasis,
    component: () =>
      import(/* webpackChunkName: "CourseInvoiceBasisPage" */ "@/pages/kurs/details/CourseInvoiceBasisPage.vue"),
  },
  {
    path: "kurset/:id/invoiceBasis/:invoiceBasisId",
    name: SingleCourseRouteNames.CourseInvoiceBasisDetails,
    component: () =>
      import(
        /* webpackChunkName: "CourseInvoiceBasisDetailsPage" */ "@/pages/kurs/details/CourseInvoiceBasisDetailsPage.vue"
      ),
  },
  {
    path: "kurset/:id/employer",
    name: SingleCourseRouteNames.Employer,
    component: () =>
      import(/* webpackChunkName: "CourseInvoiceBasisDetailsPage" */ "@/pages/kurs/details/EmployerPage.vue"),
  },
];
