import { httpClient } from "./httpClient";

export const getRole = (id: string) => httpClient.get(`/role/${id}`);

export const putRole = (roleBody: any) => {
  httpClient.put(`/role/${roleBody.id}`, roleBody);
};

export const postRole = (roleBody: any) => {
  httpClient.post(`/role`, roleBody);
};
