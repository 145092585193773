import { getFiles, openFile, postFile, deleteFile } from "@/api/file.api";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { FilesMutations } from "./files.mutations.enum";
import { FilesStoreState } from "./files.store.interface";
import { FilesActions } from "@/store/modules/files/files.actions.enum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

const initialState: () => FilesStoreState = () => ({
  fileList: [],
  file: null,
});

const state = initialState();

const getters = <GetterTree<FilesStoreState, RootStoreState>>{
  getFilelist(state) {
    return state.fileList;
  },
  getFile(state) {
    return state.file;
  },
};

const actions = <ActionTree<FilesStoreState, RootStoreState>>{
  [FilesActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [FilesActions.AddFile]({ dispatch }, data) {
    const response = await postFile(data.data, data.entity, data.curriculumId);
    if (response.status == 200) {
      openNotification(this as any, NotificationItemType.Success, "Fil lastet opp");
      await dispatch("files/fetchFiles", data, { root: true });
    }
  },
  async [FilesActions.FetchFiles]({ commit }, data) {
    const response = await getFiles(data.entity, data.curriculumId);
    if (response.status == 200) {
      commit("SET_FILELIST", response.data);
    }
  },
  async [FilesActions.DisplayFile]({ commit }, data) {
    try {
      const response = await openFile(data.id);
      if (response.status == 200) {
        openNotification(this as any, NotificationItemType.Success, "Fil lastet ned");
        const fileURL = URL.createObjectURL(response.data);
        const file = {
          Url: fileURL,
          Name: data.originalFileName,
        };
        commit("SET_FILE_URL", file);
      }
    } catch {
      openNotification(this as any, NotificationItemType.Error, "En feil oppsto ved nedlasting av filen");
    }
  },
  async [FilesActions.DeleteFile]({ commit }, data) {
    const response = await deleteFile(data.id);
    if (response.status == 204) {
      openNotification(this as any, NotificationItemType.Success, "Fil slettet");

      const newList = state.fileList;

      const i = state.fileList.findIndex((v) => v.id === data.id);
      newList.splice(i, 1);
      commit("SET_FILELIST", newList);
    }
  },
};

const mutations = <MutationTree<FilesStoreState>>{
  [FilesMutations.SET_FILELIST](state, data) {
    state.fileList = data;
  },
  [FilesMutations.SET_FILE_URL](state, data) {
    state.file = data;
  },
};

export const FilesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
