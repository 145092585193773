export function isStringArray(input: any): input is string[] {
  if (!Array.isArray(input)) {
    return false;
  }
  return input.every((element) => typeof element === "string");
}

export function isValidStringProperty<ObjectType extends Record<string, any>>(
  obj: ObjectType,
  property?: string | number | symbol
): property is keyof ObjectType {
  return typeof property !== "undefined" && property in obj;
}

export type ValidatableRef<T> = T & { validate: () => boolean };
export const isValidatableRef = (
  ref: Record<string, unknown> | Element | Vue | Element[] | Vue[]
): ref is ValidatableRef<Vue> =>
  ref !== undefined && !Array.isArray(ref) && "validate" in ref && typeof ref.validate === "function";
export const getValidatableRef = (ref: Record<string, unknown> | Element | Vue | Element[] | Vue[]) =>
  isValidatableRef(ref) ? ref : undefined;
