
import { authService } from "@/auth/authService";
import BaseTooltipButton from "@/components/shared/button/BaseTooltipButton.vue";
import ShellMenu from "@/components/shell/ShellMenu.vue";
import { EnvironmentVariables } from "@/environment";
import { runStoreAction, runStoreMutation } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreMutations } from "@/store/store.mutations";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "TheProfileMenu",
  components: { ShellMenu, BaseTooltipButton },
  setup() {
    const store = useStore<StoreState>();

    const menu = ref(false);
    const usePreFilledValues = computed(() => store.state.hoc.usePreFilledValues);
    const showPreFillSwitch = !EnvironmentVariables.IN_PRODUCTION_ENV;

    const signOut = () => {
      authService.signOut();
    };

    // switch to defaultoptions.departments?
    runStoreAction(
      store,
      StoreModules.MemberOrganizations,
      StoreActions.MemberOrganizationsActions.FetchMemberOrganizations
    );

    const updatePreFilledValue = (value: boolean | null) => {
      runStoreMutation(store, StoreModules.Hoc, StoreMutations.HocMutations.SET_PRE_FILLED_VALUE, value ?? false);
    };

    return {
      menu,
      maxWidth: computed(() => 320), // TODO fix:  (this as any).$vuetify.breakpoint.mobile ? 296 :
      userInitials: computed(
        () => authService.user.given_name.charAt(0).toUpperCase() + authService.user.family_name.charAt(0).toUpperCase()
      ),
      userEmail: computed(() => authService.user.preferred_username),
      menuItems: computed(() => [
        {
          action: signOut,
          title: "Logg ut",
          icon: "mdi-logout-variant",
        },
      ]),
      usePreFilledValues,
      showPreFillSwitch,
      updatePreFilledValue,
    };
  },
});
