import { mapGetters, mapActions } from "vuex";

// TODO can this be changed to composition instead of mixin?
export default {
  computed: {
    ...mapGetters("search", {
      _value: "value",
    }),
    _searchKey() {
      return this.searchKey || this.$options.name || "default";
    },
  },
  methods: {
    ...mapActions("search", {
      _reset: "reset",
      _setResults: "setResults",
    }),
  },
  watch: {
    _value(value) {
      if (!value) {
        return this._setResults({ results: [], key: this._searchKey });
      }
      (async () => {
        let results = await this?.getSearchResults(value);
        if (results) {
          if (this.searchSectionLabel && results.length > 0) {
            results = [
              {
                header: this.searchSectionLabel,
              },
            ].concat(results);
          }
          this._setResults({
            results,
            key: this._searchKey,
          });
        }
      })();
    },
  },
};
