import rootStore from "@/store/root.store";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { watch } from "@vue/composition-api";
import { Store } from "vuex";
import { LoadingType } from "../enums/loading-type.enum";
import { useRouteComputed } from "../useHelpers";
import { runStoreAction } from "./store.helpers";

interface WrapperType {
  blocking?: boolean;
  type?: LoadingType;
}

export const globalLoadingWrapper = async <ReturnType>(
  config: WrapperType = { blocking: false, type: LoadingType.Spinner },
  wrappedFunction: () => Promise<ReturnType>
) => {
  const getActionName = () => {
    if (config.type === LoadingType.SkeletonTable) {
      return StoreActions.HocActions.ChangeTableSkeletonRequests;
    }
    if (config.blocking) {
      return StoreActions.HocActions.ChangeBlockingRequests;
    }
    return StoreActions.HocActions.ChangeNonBlockingRequests;
  };
  // TODO improve this
  const store = rootStore as unknown as Store<StoreState>;
  runStoreAction(store, StoreModules.Hoc, getActionName(), 1);
  let returnVal: ReturnType;
  try {
    returnVal = await wrappedFunction();
  } finally {
    // Make sure we unblock the UI if any exceptions are thrown
    runStoreAction(store, StoreModules.Hoc, getActionName(), -1);
  }
  return returnVal;
};

export const onParamChange = (callback: () => Promise<any>) => {
  const route = useRouteComputed();
  watch(
    route,
    () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await callback();
      });
    },
    { immediate: true }
  );
};
