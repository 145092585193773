import {
  fetchTeachingmaterials,
  fetchTeachingmaterialsByGroup,
  getTeachingMaterialById,
  getTeachingMaterialCategories,
  postMaterial,
  putMaterial,
} from "@/api/teachingMaterials.api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { TeachingMaterialsActions } from "@/store/modules/teachingMaterials/teachingMaterials.actions.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { TeachingMaterialsMutations } from "./teachingMaterials.mutations.enum";
import { TeachingMaterialsStoreState } from "./teachingMaterials.store.interface";

const initialState: () => TeachingMaterialsStoreState = () => ({
  teachingMaterials: [],
  teachingMaterialCategories: [],
  teachingMaterial: {
    name: "",
    isActive: false,
    category: "",
  },
  currentCategory: {
    name: "",
  },
});

const state = initialState();

const getters = <GetterTree<TeachingMaterialsStoreState, RootStoreState>>{
  getField,
  getCategories: (state) => state.teachingMaterialCategories,
  getTeachingmaterial: (state) => state.teachingMaterial,
  getCurrentCategory: (state) => state.currentCategory,
  getTeachingmaterials: (state) => state.teachingMaterials,
};

const actions = <ActionTree<TeachingMaterialsStoreState, RootStoreState>>{
  [TeachingMaterialsActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [TeachingMaterialsActions.FetchTeachingMaterialCategories]({ commit }) {
    const response = await getTeachingMaterialCategories();
    if (response.status === 200) {
      commit("SET_TEACHINGMATERIAL_CATEGORIES", response.data);
    }
  },
  async [TeachingMaterialsActions.PostTeachingmaterial]({ commit }) {
    const response = await postMaterial(state.teachingMaterial);
    if (response.status === 200) {
      openNotification(this as any, NotificationItemType.Success, "Læremiddel lagt til");
      commit("ADD_TM", response.data.id);
    }
  },
  async [TeachingMaterialsActions.PutTeachingmaterial]() {
    const response = await putMaterial(state.teachingMaterial);
    if (response.status === 204) {
      openNotification(this as any, NotificationItemType.Success, "Læremiddel endret");
    }
  },
  async [TeachingMaterialsActions.FetchTeachingmaterials]({ commit }) {
    const response = await fetchTeachingmaterials();
    commit("SET_TEACHINGMATERIALS", response.data);
  },
  async [TeachingMaterialsActions.FetchTeachingmaterialsById]({ commit }, id) {
    const response = await getTeachingMaterialById(id);
    commit("SET_TEACHINGMATERIAL", response.data);
  },
  async [TeachingMaterialsActions.FetchTeachingmaterialsByGroup]({ commit }) {
    const response = await fetchTeachingmaterialsByGroup(state.teachingMaterial);
    commit("SET_TEACHINGMATERIALS", response.data.teachingMaterials);
  },
  [TeachingMaterialsActions.ChangeCategory]({ commit }) {
    commit("SET_CATEGORY", state.teachingMaterial.category);
  },
};

const mutations = <MutationTree<TeachingMaterialsStoreState>>{
  updateField,

  //  Will always add a reset mutation so we can use the global reset.
  [TeachingMaterialsMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof TeachingMaterialsStoreState] = newState[key as keyof TeachingMaterialsStoreState];
    });
  },
  [TeachingMaterialsMutations.SET_TEACHINGMATERIALS](state, data) {
    state.teachingMaterials = data;
  },
  [TeachingMaterialsMutations.ADD_TM](state, data) {
    state.teachingMaterial.id = data;
    state.teachingMaterials.push(state.teachingMaterial);
  },
  [TeachingMaterialsMutations.ADD_TMC](state) {
    state.teachingMaterialCategories.push(state.teachingMaterial);
  },
  [TeachingMaterialsMutations.RESET_TM](state) {
    state.teachingMaterial = initialState().teachingMaterial;
    state.currentCategory = {
      name: "",
    };
  },
  [TeachingMaterialsMutations.SET_TEACHINGMATERIAL](state, data) {
    state.currentCategory = { name: "" };
    const i = state.teachingMaterialCategories.findIndex((v: any) => v.name === data.category);
    state.currentCategory = state.teachingMaterialCategories[i];

    state.teachingMaterial = data;
  },
  [TeachingMaterialsMutations.SET_TEACHINGMATERIAL_CATEGORIES](state, data) {
    state.teachingMaterialCategories = data;
  },
  [TeachingMaterialsMutations.SET_TEACHINGMATERIAL_CATEGORY](state, data) {
    state.param = data;
  },
  [TeachingMaterialsMutations.SET_CATEGORY](state, data) {
    state.currentCategory = { name: "" };
    const i = state.teachingMaterialCategories.findIndex((v: any) => v.name === data);
    state.currentCategory = state.teachingMaterialCategories[i];
    // TODO : RAINCHECK : organizationId required or not ?
    state.teachingMaterial = { category: data, name: "", isActive: false };
  },
};

export const teachingMaterialsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
