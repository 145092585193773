var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', _vm._g(_vm._b({
    attrs: {
      "bottom": "",
      "close-delay": "100",
      "content-class": "rounded",
      "left": "",
      "max-height": "500",
      "offset-y": "",
      "open-delay": "60",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(props) {
        return [_vm._t("activator", null, null, Object.assign({}, props))];
      }
    }], null, true)
  }, 'v-menu', _vm.$attrs, false), _vm.$listeners), [_c('BaseSheet', {
    attrs: {
      "outlined": false
    }
  }, [_vm.$slots.default ? _vm._t("default") : _c('BaseList', {
    attrs: {
      "items": _vm.items
    },
    scopedSlots: _vm._u([_vm.$scopedSlots.item ? {
      key: "item",
      fn: function fn(props) {
        return [_vm._t("item", null, null, Object.assign({}, props))];
      }
    } : null], null, true)
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }