import { DEFAULT_DEPARTMENT_ID } from "@/auth/auth.constants";
import { EnvironmentVariables } from "@/environment";
import { debounceHelper } from "@/shared/debounce";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { NotificationItem } from "@/shared/interfaces/notificationItem.interface";
import { NotificationItemInternal } from "@/shared/interfaces/notificationItemInternal.interface";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { HocActions } from "./hoc.actions.enum";
import { HocMutations } from "./hoc.mutations.enum";
import { HocStoreState } from "./hoc.store.interface";

const preFillQueryInUrl = typeof new URLSearchParams(location.search).get("prefill") === "string";

const initialState: () => HocStoreState = () => ({
  language: localStorage.getItem("language"),
  skeletonLoader: {
    loading: false,
    type: "",
  },
  valid: false,
  notifications: [],
  showLoadingSpinner: false,
  ongoingBlockingRequests: 0,
  ongoingNonBlockingRequests: 0,
  ongoingTableSkeletonRequests: 0,
  department: DEFAULT_DEPARTMENT_ID.toString(),
  usePreFilledValues: preFillQueryInUrl,
});

const state = initialState();

const getters = <GetterTree<HocStoreState, RootStoreState>>{
  getField,
};

const actions = <ActionTree<HocStoreState, RootStoreState>>{
  [HocActions.Reset]({ commit }) {
    commit("RESET");
  },
  [HocActions.LoadingSkeleton]({ commit }, response) {
    commit("SET_SKELETON_LOADING", response);
  },
  [HocActions.RemoveLoadingSkeleton]({ commit }) {
    commit("REMOVE_SKELETON_LOADING");
  },
  [HocActions.RemoveNotificationItem]({ commit }, notificationItem: NotificationItemInternal) {
    commit(HocMutations.REMOVE_NOTIFICATION_ITEM, notificationItem);
  },
  [HocActions.OpenNotification]({ commit }, notificationItem: NotificationItem) {
    const notificationInternal: NotificationItemInternal = {
      guid: Symbol(),
      type: notificationItem.type,
      message: notificationItem.message,
    };
    commit(HocMutations.APPEND_NOTIFICATION_ITEM, notificationInternal);
    setTimeout(
      () => {
        commit(HocMutations.REMOVE_NOTIFICATION_ITEM, notificationInternal.guid);
      },
      notificationInternal.type === NotificationItemType.Error ? 8000 : 5000
    );
  },
  async [HocActions.ChangeBlockingRequests]({ commit, state }, change: number) {
    commit(HocMutations.CHANGE_BLOCKING_REQUESTS, change);
    await debounceHelper.debounce(HocActions.ChangeBlockingRequests, EnvironmentVariables.HTTP_TIMEOUT + 10);
    if (state.ongoingBlockingRequests !== 0) {
      console.error("Blocking requests are out of sync!");
    }
  },
  async [HocActions.ChangeNonBlockingRequests]({ commit, state }, change: number) {
    commit(HocMutations.CHANGE_NON_BLOCKING_REQUESTS, change);
    await debounceHelper.debounce(HocActions.ChangeNonBlockingRequests, EnvironmentVariables.HTTP_TIMEOUT + 10);
    if (state.ongoingNonBlockingRequests !== 0) {
      console.error("Non blocking requests are out of sync!");
    }
  },
  async [HocActions.ChangeTableSkeletonRequests]({ commit, state }, change: number) {
    commit(HocMutations.CHANGE_TABLE_SKELETON_REQUESTS, change);
    await debounceHelper.debounce(HocActions.ChangeTableSkeletonRequests, EnvironmentVariables.HTTP_TIMEOUT + 10);
    if (state.ongoingNonBlockingRequests !== 0) {
      // TODO this error sometimes triggers, either fix why or add fallback
      console.error("Table skeleton requests are out of sync!");
    }
  },
  [HocActions.SetDepartment]({ commit }, department: number) {
    commit(HocMutations.SET_DEPARTMENT, { department });
  },
};

const mutations = <MutationTree<HocStoreState>>{
  updateField,

  //  Will always add a reset mutation so we can use the global reset.
  RESET(state) {
    const newState = initialState();
    // TODO fix this
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // state = { ...newState };
    Object.keys(newState).forEach((key) => {
      // @ts-ignore TODO
      state[key as keyof HocStoreState] = newState[key as keyof HocStoreState];
    });
  },
  [HocMutations.SET_LANGUAGE](state) {
    localStorage.setItem("language", "no");
    state.language = "no";
  },
  [HocMutations.SET_SKELETON_LOADING](state, response) {
    state.skeletonLoader.loading = true;
    state.skeletonLoader.type = response;
  },
  [HocMutations.REMOVE_SKELETON_LOADING](state) {
    state.skeletonLoader.loading = null;
    state.skeletonLoader.type = null;
  },
  [HocMutations.APPEND_NOTIFICATION_ITEM](state, notification: NotificationItemInternal) {
    state.notifications.push(notification);
  },
  [HocMutations.REMOVE_NOTIFICATION_ITEM](state, notificationGuid: symbol) {
    state.notifications = state.notifications.filter((item) => item.guid !== notificationGuid);
  },
  [HocMutations.SET_SHOW_LOADING_SPINNER](state, showLoadingSpinner: boolean) {
    state.showLoadingSpinner = showLoadingSpinner;
  },
  [HocMutations.CHANGE_BLOCKING_REQUESTS](state, change: number) {
    if (change > 1 || change < -1 || change === 0) {
      throw new Error("Invalid change value");
    }
    state.ongoingBlockingRequests = state.ongoingBlockingRequests += change;
  },
  [HocMutations.CHANGE_NON_BLOCKING_REQUESTS](state, change: number) {
    if (change > 1 || change < -1 || change === 0) {
      throw new Error("Invalid change value");
    }
    state.ongoingNonBlockingRequests = state.ongoingNonBlockingRequests += change;
  },
  [HocMutations.CHANGE_TABLE_SKELETON_REQUESTS](state, change: number) {
    if (change > 1 || change < -1 || change === 0) {
      throw new Error("Invalid change value");
    }
    state.ongoingTableSkeletonRequests = state.ongoingTableSkeletonRequests += change;
  },
  [HocMutations.SET_DEPARTMENT](state, { department }) {
    state.department = department;
  },
  [HocMutations.SET_PRE_FILLED_VALUE](state, newValue: boolean) {
    state.usePreFilledValues = newValue;
  },
};

export const HocModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
