import { StoreModules } from "@/store/store-modules.enum";
import { StoreMutations, StoreMutationsType } from "@/store/store.mutations";
import { StoreActions, StoreActionsType } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { Store } from "vuex";
import { NotificationItemType } from "../enums/notificationItemEnum";
import { NotificationItem } from "../interfaces/notificationItem.interface";

export function runStoreAction(
  store: Store<StoreState>,
  module: StoreModules,
  element: StoreActionsType,
  payload?: any
): Promise<any> {
  return store.dispatch(`${getModulePath(module)}${element}`, payload);
}

export function runStoreMutation(
  store: Store<StoreState>,
  module: StoreModules,
  mutation: StoreMutationsType,
  value?: any
): void {
  store.commit(`${getModulePath(module)}${mutation}`, value);
}

export function openNotification(store: Store<StoreState>, type: NotificationItemType, message: string) {
  const notification: NotificationItem = {
    message,
    type,
  };
  runStoreAction(store, StoreModules.Hoc, StoreActions.HocActions.OpenNotification, notification);
}

export function setShowLoadingSpinner(store: Store<StoreState>, show: boolean) {
  runStoreMutation(store, StoreModules.Hoc, StoreMutations.HocMutations.SET_SHOW_LOADING_SPINNER, show);
}

export function setShowSkeletonLoadingTable(store: Store<StoreState>, show: boolean) {
  if (show) {
    runStoreMutation(store, StoreModules.Hoc, StoreMutations.HocMutations.SET_SKELETON_LOADING, "table");
  } else {
    runStoreMutation(store, StoreModules.Hoc, StoreMutations.HocMutations.REMOVE_SKELETON_LOADING, "table");
  }
}

function getModulePath(module: string | null) {
  if (!module || module.length === 0) {
    return "";
  }
  return `${module}/`;
}
