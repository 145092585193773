var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "v-app-tooltip-btn d-inline-block"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "v-app-tooltip-btn__content",
      "open-delay": "200"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "aria-label": _vm.path,
            "icon": ""
          }
        }, 'v-btn', _vm.$attrs, false), Object.assign({}, on, _vm.$listeners)), [_vm.$slots.icon ? _vm._t("icon") : _c('v-icon', {
          domProps: {
            "textContent": _vm._s(_vm.icon)
          }
        })], 2)];
      }
    }], null, true)
  }, [_vm._v(" " + _vm._s(_vm.path) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }