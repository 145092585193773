export enum PlansMutations {
  RESET = "RESET",
  SET_INFO = "SET_INFO",
  ADD_TEACHINGMATERIAL = "ADD_TEACHINGMATERIAL",
  REMOVE_TEACHINGMATERIAL = "REMOVE_TEACHINGMATERIAL",
  SET_SSB = "SET_SSB",
  SET_CURRICULUM_SINGLE = "SET_CURRICULUM_SINGLE",
  SET_COURSES = "SET_COURSES",
  SET_ID = "SET_ID",
  SET_DRAFT_ID = "SET_DRAFT_ID",
  SET_STATUS = "SET_STATUS",
  SET_DUPE_CHECK = "SET_DUPE_CHECK",
  SET_DEFAULT_OPTIONS = "SET_DEFAULT_OPTIONS",
  REMOVE_PARTNER = "REMOVE_PARTNER",
  SET_CHILD_ITEMS = "SET_CHILD_ITEMS",
  RESET_MAIN_SUB = "RESET_MAIN_SUB",
}
