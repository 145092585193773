export enum CurriculumRouteNames {
  CurriculumList = "curriculumList",
  CurriculumDraftList = "curriculumDraftList",
  CreateCurriculumPrerequisites = "createCurriculumPrerequisites",
  CreateExecutionPlanPrerequisites = "createExecutionPlanPrerequisites",
  CreateCurriculum = "createCurriculum",
  EditCurriculumDraft = "editCurriculumDraft",
  EditCurriculum = "editCurriculum",
  CurriculumDetails = "curriculumDetails",
}
