import { getSchedules } from "@/api/vacationSchedule.api";
import { VacationScheduleActions } from "@/store/modules/vacationSchedule/vacationSchedule.actions.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { VacationScheduleMutations } from "./vacationSchedule.mutations.enum";
import { VacationScheduleStoreState } from "./vacationSchedule.store.interface";

const initialState: () => VacationScheduleStoreState = () => ({
  vacationSchedules: [],
  vacationScheduleSingle: {
    vacationPeriods: [],
  },
});

const state = initialState();

const getters = <GetterTree<VacationScheduleStoreState, RootStoreState>>{
  getField,
  getSchedules: (state) => state.vacationSchedules,
  getSchedule: (state) => state.vacationScheduleSingle,
};

const actions = <ActionTree<VacationScheduleStoreState, RootStoreState>>{
  [VacationScheduleActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [VacationScheduleActions.FetchSchedules]({ commit }) {
    const response = await getSchedules();
    await commit("SET_SCHEDULES", response?.data);
  },
};

const mutations = <MutationTree<VacationScheduleStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the global reset.
  [VacationScheduleMutations.RESET](state) {
    const newState = initialState();
    // TODO fix this
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // state = { ...newState };
    Object.keys(newState).forEach((key) => {
      // @ts-ignore TODO
      state[key] = newState[key as keyof VacationScheduleStoreState];
    });
  },
  [VacationScheduleMutations.SET_SCHEDULES](state, data) {
    state.vacationSchedules = data;
  },
};

export const VacationScheduleModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
