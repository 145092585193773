import Vue from "vue";
import router from "@/router/router";
import { FilterStoreState } from "./filter.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { FilterMutations } from "./filter.mutations.enum";
import { FilterActions } from "@/store/modules/filter/filter.actions.enum";

const state: FilterStoreState = {
  filter: [],
  model: {},
};

const generateModel = (filter: any) =>
  filter.reduce((model: any, current: any) => {
    model[current.value] = router.currentRoute.query[current.value] ?? current.default ?? (current.multiple ? [] : "");
    return model;
  }, {});

const getters = <GetterTree<FilterStoreState, RootStoreState>>{
  filter: (state) => state.filter,
  model: (state) => state.model,
  hasFilter: ({ filter }) => filter.length > 0,
};

const actions = <ActionTree<FilterStoreState, RootStoreState>>{
  [FilterActions.Reset]({ commit }) {
    commit(FilterMutations.RESET);
  },
  [FilterActions.Remove]({ commit }) {
    commit(FilterMutations.REMOVE);
  },
  [FilterActions.Set]({ commit }, payload) {
    commit(FilterMutations.SET, payload);
  },
  [FilterActions.Apply]({ commit }, payload) {
    commit(FilterMutations.APPLY, payload);
  },
};

const mutations = <MutationTree<FilterStoreState>>{
  [FilterMutations.RESET]: (state) => {
    state.filter = [];
    state.model = {};
  },
  [FilterMutations.REMOVE]: (state) => {
    state.model = generateModel(state.filter);
  },
  [FilterMutations.SET]: (state, filters) => {
    state.filter = filters.map((filter: any) => ({
      ...filter,
      get component() {
        // This is important to prevent vuex errors for some components
        return filter.component;
      },
    }));
    const model = generateModel(filters);
    const updatedModelKeys = Object.keys(model).sort();
    const currentModelKeys = Object.keys(state.model).sort();
    if (updatedModelKeys.length === currentModelKeys.length) {
      const isSameModel = updatedModelKeys.every((key, index) => currentModelKeys[index] == key);
      if (isSameModel) {
        return;
      }
    }
    state.model = model;
  },
  [FilterMutations.APPLY]: (state, { key, value }) => {
    Vue.set(state.model, key, value);
  },
};

export const filterModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
