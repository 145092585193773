import { httpClient } from "./httpClient";

export const getTeachingMaterialCategories = () =>
  httpClient.get(`/teachingmaterialcategory`, {
    params: {},
  });

export const getTeachingMaterialById = (id: number) => httpClient.get(`/teachingmaterial/${id}`);

export const postMaterial = (tm: any) => httpClient.post(`/teachingmaterial`, tm);

export const putMaterial = (tm: any) => httpClient.put(`/teachingmaterial/${tm.id}`, tm);

export const putMaterialCategory = (tm: any) => httpClient.put(`/teachingmaterialcategory/${tm.name}`, tm);

export const postMaterialCategory = (tm: any) => httpClient.post(`/teachingmaterialcategory`, tm);

export const fetchTeachingmaterials = () => httpClient.get(`/teachingmaterial`);
export const fetchTeachingmaterialsByGroup = (params: any) =>
  httpClient.get(`/teachingmaterialgroup/${params.groupId}`);
