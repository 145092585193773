var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticClass: "v-bar--underline",
    attrs: {
      "app": "",
      "color": "white",
      "flat": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    staticClass: "hidden-lg-and-up",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$emit('toggleDrawer');
      }
    }
  }), _c('router-link', {
    attrs: {
      "to": "/home"
    }
  }, [_c('v-img', {
    staticClass: "mx-2 hidden-sm-and-down",
    attrs: {
      "src": "https://aofnorge.wpengine.com/wp-content/uploads/2020/10/aof-logo.svg",
      "contain": ""
    }
  })], 1), _c('v-spacer'), _c('TheAppBarItems')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }