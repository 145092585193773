var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseVListGroup', _vm._b({
    staticClass: "v-list-group--default",
    attrs: {
      "group": _vm.group,
      "prepend-icon": _vm.icon,
      "append-icon": "mdi-menu-down",
      "no-action": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn() {
        return [_c('v-list-item-content', [_vm.item.title ? _c('v-list-item-title', {
          domProps: {
            "textContent": _vm._s(_vm.item.title)
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, 'BaseVListGroup', _vm.$attrs, false), [_vm._l(_vm.item.items, function (child, i) {
    return [child.heading ? _c('v-subheader', {
      key: "heading-".concat(i),
      staticClass: "text--primary font-weight-black text-uppercase",
      attrs: {
        "inset": ""
      },
      domProps: {
        "textContent": _vm._s(child.title)
      }
    }) : child.divider ? _c('v-divider', {
      key: "divider-".concat(i),
      staticClass: "mt-3 mb-2 ml-16",
      attrs: {
        "inset": ""
      }
    }) : child.items ? _c('BaseListItem', {
      key: "sub-group-".concat(i),
      attrs: {
        "item": child,
        "sub-group": ""
      }
    }) : _c('BaseListItem', {
      key: "child-".concat(i),
      attrs: {
        "item": child
      }
    })];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }