// import { httpClient } from "./httpClient";

import { httpClient } from "./httpClient";

export const getSubjRegs = () => httpClient.get(`/coursearea`);

// TODO : String ?
export const getSubjRegById = (id: string) => httpClient.get(`/coursearea/${id}`);

export const putSubjReg = (body: any) => {
  httpClient.put(`coursearea/${body.id}`, body);
};

export const postSubjReg = (body: any) => {
  httpClient.post(`coursearea`, body);
};
