import { authService } from "@/auth/authService";
import { EnvironmentVariables } from "@/environment";
import { ApplicationRoutes } from "@/router/routes-list";
import store from "@/store/root.store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: EnvironmentVariables.BASE_URL,
  routes: ApplicationRoutes,
});

router.beforeEach((to, from, next) => {
  if (from.path !== to.path) {
    // Only reset filters when navigating away from page
    //
    // Avoids regenerating state e.g. when redirecting to same page with query
    // parameters when changing filters
    store.dispatch("filter/reset");
    store.dispatch("search/reset");
  }

  if (to.matched.some((record) => record.meta.redirect)) {
    next({ path: "/home" });
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authService.isAuthenticated) {
      if (!authService.fromMemberOrganization()) {
        next();
      } else {
        const matchedNoDashboard = to.matched.filter((record) => record.name !== "dashboard");
        if (matchedNoDashboard.length !== 1) {
          throw new Error("Fant ikke siden du leter etter");
        }
        const [newRoute] = matchedNoDashboard;
        if (newRoute.meta.isEnabledForMemberOrganizations) {
          next();
        } else {
          next({ path: "/unauthorized" });
        }
      }
    } else {
      sessionStorage.setItem("redirectPostLoginUrl", to.fullPath);
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (authService.isAuthenticated) {
      next({ path: "/home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
