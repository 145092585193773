export enum ContactsActions {
  Reset = "reset",
  FetchContacts = "fetchContacts",
  FetchOrganisations = "fetchOrganisations",
  FetchPerson = "fetchPerson",
  FetchOrganization = "fetchOrganization",
  AddPerson = "addPerson",
  EditOrganization = "editOrganization",
  EditPerson = "editPerson",
  AddOrganization = "addOrganization",
  SearchForPerson = "searchForPerson",
  ChangeToSearchedForPerson = "changeToSearchedForPerson",
  SetPersons = "setPersons",
}
