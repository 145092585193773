import { api } from "@/api/api";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { Store } from "vuex";
import { runStoreAction } from "./store.helpers";

export const vocationalSchoolId = 1022;

export const mainCourseIdLanguage = 1017;

export const isVocationalSchool = (mainCourseId?: number) => mainCourseId === vocationalSchoolId;

export const isMainCourseLanguage = (mainCourseId?: number) => mainCourseId === mainCourseIdLanguage;

export const getNusCodeNameByCode = async (code?: string | null) => {
  if (!code) {
    return;
  }
  return (await api.curriculum.getNusCodes()).data.find((x) => x.code === code)?.name;
};

export const getForApprovalStatus = async (store: Store<StoreState>) => {
  if (!store.state.plans.defaultOptions.courseStatuses) {
    await runStoreAction(store, StoreModules.Plans, StoreActions.PlansActions.FetchDefaultOptions);
  }
  return store.state.plans.defaultOptions.courseStatuses?.find((current) => current.description === "Til godkjenning");
};
