export const EnvironmentVariables = {
  VUE_APP_API_URL: process.env.VUE_APP_API_URL,
  VUE_APP_API_OCP_KEY: process.env.VUE_APP_API_OCP_KEY,
  VUE_APP_API_OCP_KEY_GUEST: process.env.VUE_APP_API_OCP_KEY_GUEST,
  VUE_APP_ENV_NAME: process.env.VUE_APP_ENV_NAME,
  NODE_ENV: process.env.NODE_ENV || "unknown",
  DEVELOPMENT: process.env.NODE_ENV === "development",
  PRODUCTION: process.env.NODE_ENV === "production",

  IN_PRODUCTION_ENV: process.env.VUE_APP_ENV_NAME === "Production",
  IN_DEV_ENV: process.env.VUE_APP_ENV_NAME === "Development",
  IN_DEV_OR_TEST_ENV: process.env.VUE_APP_ENV_NAME === "Development" || process.env.VUE_APP_ENV_NAME === "Test", // use for feature flagging
  NOT_IN_PROD_ENV: process.env.VUE_APP_ENV_NAME !== "Production", // use for feature flagging
  NOT_IN_PROD_OR_STAGING: process.env.VUE_APP_ENV_NAME !== "Production" && process.env.VUE_APP_ENV_NAME !== "Staging", // use for feature flagging

  BASE_URL: process.env.BASE_URL,
  APP_NAME: process.env.APP_NAME,
  APP_VERSION: process.env.APP_VERSION,
  APP_REV: process.env.APP_REV?.slice(0, 7),
  HTTP_TIMEOUT: 40000,
} as const;
