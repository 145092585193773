import { httpClient } from "./httpClient";

export const getMemberOrganizations = () => httpClient.get("/organization/department");

export const getMemberDepartments = () => httpClient.get("/organization/member");

export const getMemberOrganization = (id: any) => {
  const type = memberOrganizations.find((obj) => obj.id === id);
  return type;
};

export const putMemberOrganization = (body: any) => {
  const index = memberOrganizations.findIndex((obj) => obj.id === body.id);
  memberOrganizations[index] = body;
};

export const postMemberOrganization = (body: any) => {
  memberOrganizations.push(body);
};

/* HELPERS */

const memberOrganizations = [
  {
    id: 1,
    name: "Muntlig",
    description: "Muntlig test skal gjennomføres ...",
    isActive: true,
  },
  {
    id: 2,
    name: "Skriftlig",
    description: "Skriftlig test skal gjennomføres ...",
    isActive: true,
  },
  {
    id: 3,
    name: "Test type 3",
    description: "Test type 3 test skal gjennomføres ...",
    isActive: true,
  },
  {
    id: 4,
    name: "Test type 4",
    description: "Test type 4 test skal gjennomføres ...",
    isActive: true,
  },
  {
    id: 5,
    name: "Test type 5",
    description: "Test type 5 test skal gjennomføres ...",
    isActive: true,
  },
];
