<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({ name: "BaseSheet" });
</script>

<template>
  <v-sheet
    class="overflow-hidden"
    v-bind="{
      outlined: true,
      rounded: true,
      ...$attrs,
    }"
    v-on="$listeners"
  >
    <slot />
  </v-sheet>
</template>
