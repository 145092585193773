import { api } from "@/api/api";
import { getDraft, postDraft, postPlan, putDraft, putStudyplan } from "@/api/studieplan.api";
import { getSubjRegById } from "@/api/subjectRegister.api";
import { authService } from "@/auth/authService";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { PlansActions } from "@/store/modules/plans/plans.actions.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import Vue from "vue";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { PlansMutations } from "./plans.mutations.enum";
import { PlansStoreState } from "./plans.store.interface";

const initialState: () => PlansStoreState = () => ({
  validateForm: false,
  vocationalSchool: {},
  studyplan: {
    id: undefined,
    name: "",
    memberOrgId: undefined,
    subCourseId: undefined,
    teacherRequired: false,
    course: {
      teachingMaterials: [],
      content: "",
      method: "",
    },
    courseCertificateRequired: false,
    teachingMethod: "",
    statusId: undefined,
  },
  defaultOptions: {},
  courseAreaItems: {},
  dupCheck: [],
  dupCheckLoading: false,
  studieplanParams: {
    // For api
    afterId: 0,
    pageSize: 10000,
    orgId: 1,
    // For data table
    /* page: 1,
    rowsPerPage: 5,
    totalItems: 0,
    sortBy: "name",
    rowsPerPageItems: [5, 10, 15, 20] */
  },
  courses: [],
});

const state = initialState();

const getters = <GetterTree<PlansStoreState, RootStoreState>>{
  getField,
  getStudy(state) {
    return state.studyplan;
  },
  getTeachingmaterials(state) {
    return state.studyplan.course.teachingMaterials;
  },
  getCourses(state) {
    return state.courses;
  },
  getDefaultOpt(state) {
    return state.defaultOptions;
  },
};

const studyplanToApiCurriculum = (studyplan: any) => {
  const { course, ...rest } = studyplan;
  const teachingMaterials = course.teachingMaterials.map((e: any) => (e instanceof Object ? e.id : e));
  return {
    ...rest,
    course: {
      ...course,
      teachingMaterials,
    },
  };
};

const actions = <ActionTree<PlansStoreState, RootStoreState>>{
  [PlansActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [PlansActions.FetchDefaultOptions]({ commit }) {
    const response = await api.curriculum.getCurriculumDefaultoptionsAsync();
    commit("SET_DEFAULT_OPTIONS", response.data);
  },
  async [PlansActions.PostStudyplan]({ commit }) {
    const studyplan = studyplanToApiCurriculum(state.studyplan);
    if (isVocationalSchool(state.studyplan.mainCourseId)) {
      studyplan.vocationalSchool = state.vocationalSchool;
    }
    const response = await postPlan(studyplan);
    commit("SET_ID", response.data.id);
  },
  // this will fail hard if called from PostExecutionPlan with state.studyplan not set
  // (like refresh on gjennomforingsplaner/ny/) TODO
  async [PlansActions.UpdateStudyplan]() {
    const studyplan = studyplanToApiCurriculum(state.studyplan);
    delete studyplan.statusId;

    if (isVocationalSchool(state.studyplan.mainCourseId)) {
      studyplan.vocationalSchool = state.vocationalSchool;
    }
    if (studyplan.partners?.length > 0) {
      if (studyplan.partners[0].customerId) {
        studyplan.partners = studyplan.partners.map((current: any) => current.customerId);
      }
    }
    await putStudyplan(studyplan);
  },
  async [PlansActions.FetchCurriculum]({ commit, dispatch }, id) {
    const response = !authService.fromMemberOrganization()
      ? await api.curriculum.getCurriculumAsync(id)
      : await api.guestside.getGuestUserCurriculumAsync(id);
    if (response.data.courseCertificateRequired) {
      dispatch("execution/certificateRequired", null, { root: true });
    }
    commit("SET_CURRICULUM_SINGLE", response.data);
  },

  async [PlansActions.FetchDraft]({ commit }, id) {
    const response = await getDraft(id);
    if (response.status === 200) {
      const courseId = await getSubjRegById(response.data.subCourseId);
      response.data.mainCourseId = courseId.data.parentId;
      commit("SET_CURRICULUM_SINGLE", response.data);
    }
  },
  async [PlansActions.SaveDraft]({ commit }) {
    const studyplan = studyplanToApiCurriculum(state.studyplan);
    if (isVocationalSchool(state.studyplan.mainCourseId)) {
      studyplan.vocationalSchool = state.vocationalSchool;
    }
    const response = await postDraft(studyplan);
    if (response.status === 200) {
      openNotification(this as any, NotificationItemType.Success, "Utkast lagret");
      commit("SET_DRAFT_ID", response.data.id);
    }
  },
  async [PlansActions.UpdateDraft]() {
    const studyplan = studyplanToApiCurriculum(state.studyplan);

    if (isVocationalSchool(state.studyplan.mainCourseId)) {
      studyplan.vocationalSchool = state.vocationalSchool;
    }

    if (studyplan.partners.length > 0) {
      if (studyplan.partners[0].customerId) {
        const p = studyplan.partners.map((current: any) => current.customerId);
        studyplan.partners = p;
      }
    }
    await putDraft(studyplan);
    openNotification(this as any, NotificationItemType.Success, "Redigering vellykket");
  },
};

const mutations = <MutationTree<PlansStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the gloabal reset.
  [PlansMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof PlansStoreState] = newState[key as keyof PlansStoreState];
    });
  },
  [PlansMutations.SET_INFO](state, data) {
    Object.assign(state.studyplan, data);
  },
  [PlansMutations.ADD_TEACHINGMATERIAL](state, data) {
    if (state.studyplan.course.teachingMaterials.some((e) => e.id === data.id)) {
      return;
    }
    state.studyplan.course.teachingMaterials.push(data);
  },
  [PlansMutations.REMOVE_TEACHINGMATERIAL](state, data) {
    state.studyplan.course.teachingMaterials.splice(
      state.studyplan.course.teachingMaterials.findIndex((v) => v.id === data.id),
      1
    );
  },
  [PlansMutations.SET_SSB](state, data) {
    state.studyplan.ssbCode = data;
  },
  [PlansMutations.SET_CURRICULUM_SINGLE](state, data) {
    state.studyplan = data;
    if (isVocationalSchool(data.mainCourseId)) {
      state.vocationalSchool = data.vocationalSchool;
    }
  },
  [PlansMutations.SET_COURSES](state, data) {
    state.courses = data;
  },
  [PlansMutations.SET_ID](state, data) {
    // eslint-disable-next-line no-prototype-builtins
    if (state.studyplan.hasOwnProperty("id")) {
      state.studyplan.id = data;
    } else {
      Vue.set(state.studyplan, "id", data);
    }
    state.dupCheck.push(state.studyplan);
  },
  [PlansMutations.SET_DRAFT_ID](state, data) {
    // eslint-disable-next-line no-prototype-builtins
    if (state.studyplan.hasOwnProperty("draftId")) {
      state.studyplan.draftId = data;
    } else {
      Vue.set(state.studyplan, "draftId", data);
    }
    state.dupCheck.push(state.studyplan);
  },
  [PlansMutations.SET_STATUS](state, data) {
    state.studyplan.statusId = data;
  },
  [PlansMutations.SET_DUPE_CHECK](state, data) {
    state.dupCheck = data;
  },
  [PlansMutations.SET_DEFAULT_OPTIONS](state, data) {
    state.defaultOptions = data;
  },
  [PlansMutations.SET_CHILD_ITEMS](state) {
    if (!state.defaultOptions.courseAreas) {
      throw new Error("Course areas not set");
    }
    const area = state.defaultOptions.courseAreas.find((obj: any) => obj.id === state.studyplan.mainCourseId);
    state.courseAreaItems = area?.children;
  },
  [PlansMutations.REMOVE_PARTNER](state, data) {
    state.studyplan.partners.splice(data, 1);
  },
  [PlansMutations.RESET_MAIN_SUB](state) {
    state.studieplanParams.mainCourseId = "";
    state.studieplanParams.subCourseId = "";
  },

  /* [PlansMutations.SET_CUSTOMER](state, data) {
    state.customer = data;
  } */
};

export const PlansModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
