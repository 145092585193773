import { httpClient } from "./httpClient";

export const getStudents = (params: number) => httpClient.get(`/course/${params}/participant`, {});

export const getStudent = (params: number) => httpClient.get(`/course/participant/${params}`, {});

export const addParticipant = (body: any, courseId: number) => {
  httpClient.post(`/course/${courseId}/participant`, body);
};

export const getCoursesByParticipantId = (id: number) => {
  httpClient.get(`/course/participant/${id}`);
};
