var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    attrs: {
      "dense": "",
      "expand": "",
      "nav": ""
    }
  }, [_vm._l(_vm.items, function (item, i) {
    return [item.heading ? _c('v-subheader', {
      key: "heading-".concat(i),
      staticClass: "text--primary font-weight-black text-uppercase",
      domProps: {
        "textContent": _vm._s(item.heading)
      }
    }) : item.divider ? _c('v-divider', {
      key: "divider-".concat(i),
      staticClass: "mt-3 mb-2 ml-2 mr-n2"
    }) : item.items ? _c('BaseListGroup', {
      key: "group-".concat(i),
      attrs: {
        "item": item
      }
    }) : _vm.$scopedSlots.item ? _vm._t("item", null, {
      "index": i,
      "item": item
    }) : _c('BaseListItem', {
      key: "item-".concat(i),
      attrs: {
        "item": item
      }
    })];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }