import { getHttpClient } from "./httpClient";
import { Api } from "./generated/Api";
import { getDashboardHttpClient } from "@/shared/helpers/dashboardHelpers";

const api = new Api();
// Override autogenerated Axios instance with custom instance for API
api.instance = getHttpClient();

const binaryApi = new Api({ format: "blob" });
// Override autogenerated Axios instance with custom instance for API
binaryApi.instance = getHttpClient();

const dashboardApi = new Api();
dashboardApi.instance = getDashboardHttpClient();

export { api, binaryApi, dashboardApi };
