var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', _vm._g(_vm._b({
    staticClass: "overflow-hidden"
  }, 'v-sheet', Object.assign({
    outlined: true,
    rounded: true
  }, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }