var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-navigation-drawer', {
    attrs: {
      "absolute": "",
      "temporary": "",
      "app": ""
    },
    model: {
      value: _vm.drawerOpen,
      callback: function callback($$v) {
        _vm.drawerOpen = $$v;
      },
      expression: "drawerOpen"
    }
  }, [[_c('v-toolbar', {
    staticClass: "v-bar--underline",
    attrs: {
      "flat": ""
    }
  }, [_c('v-list-item', {
    staticClass: "flex-1-1-auto d-flex align-center justify-center"
  }, [_c('router-link', {
    attrs: {
      "to": "/home"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "https://aofnorge.wpengine.com/wp-content/uploads/2020/10/aof-logo.svg",
      "max-height": "40",
      "max-width": "80",
      "contain": ""
    }
  })], 1)], 1)], 1)], _c('v-list', {
    attrs: {
      "nav": ""
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-list-group', {
      key: item.title,
      staticClass: "primary-font-color",
      attrs: {
        "prepend-icon": item.icon,
        "no-action": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "text-button"
          }, [_vm._v(_vm._s(item.title))])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.active,
        callback: function callback($$v) {
          _vm.$set(item, "active", $$v);
        },
        expression: "item.active"
      }
    }, [_c('v-divider'), _vm._l(item.items, function (child) {
      return _c('v-list-item', _vm._b({
        key: child.title,
        staticClass: "pr-2 pl-2",
        attrs: {
          "link": "",
          "to": child.link,
          "exact": ""
        }
      }, 'v-list-item', child.extraProperties, false), [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(child.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
        staticClass: "text-overline",
        domProps: {
          "textContent": _vm._s(child.title)
        }
      })], 1)], 1);
    }), _c('TableFilter'), _c('v-divider')], 2);
  }), 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }