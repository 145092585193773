// import { httpClient } from "./httpClient";

export const getTrainingGrants = () => trainingGrants;

/* httpClient.get(`/TrainingGrants`); */

export const getTrainingGrant = (id: string) => {
  const type = trainingGrants.find((obj) => obj.year === id);
  return type;
};
/* httpClient.get(`/TrainingGrants/${id}`); */

export const putTrainingGrant = (body: any) => {
  const index = trainingGrants.findIndex((obj: any) => obj.id === body.id);
  trainingGrants[index] = body;
  /*   httpClient.put(
        `/TrainingGrants/${TrainingGrantBody.id}`,
        TrainingGrantBody
      );
    }; */
};

export const postTrainingGrant = (body: any) => {
  trainingGrants.push(body);
  /*   httpClient.post(`/TrainingGrants`, TrainingGrantBody);
   */
};

/* HELPERS */

const trainingGrants = [
  {
    year: "2022",
    trainingGrantsAmount: 160,
    additionalCoursesAmount: 0,
  },
  {
    year: "2021",
    trainingGrantsAmount: 160,
    additionalCoursesAmount: 0,
  },
  {
    year: "2015",
    trainingGrantsAmount: 40,
    additionalCoursesAmount: 100,
  },
  {
    year: "2019",
    trainingGrantsAmount: 50,
    additionalCoursesAmount: 100,
  },
  {
    year: "2018",
    trainingGrantsAmount: 50,
    additionalCoursesAmount: 90,
  },
  {
    year: "2017",
    trainingGrantsAmount: 50,
    additionalCoursesAmount: 90,
  },
  {
    year: "2016",
    trainingGrantsAmount: 50,
    additionalCoursesAmount: 90,
  },
];
