import { api } from "@/api/api";
import { ApiCourseResponsibleFilter } from "@/api/generated/Api";
import { getCourseCategories, putCourse } from "@/api/kurs.api";
import { authService } from "@/auth/authService";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { LoadingState } from "@/shared/models/enums/loadingStates.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { getCourseInitialState } from "./course.initial-state";
import { CoursesActions } from "./courses.actions.enum";
import { CoursesMutations } from "./courses.mutations.enum";
import { CoursesStoreState } from "./courses.store.interface";
import { CourseDto } from "./interfaces/Course.interface";

// TODO move!
interface ScheduleMeetingDataInterface {
  start: string;
  name: string;
  startTime: string;
  endTime: string;
}
function scheduleMeeting(data: ScheduleMeetingDataInterface) {
  const start = new Date(data.start);
  const end = new Date(data.start);
  const title = data.name;
  const [endHour, endMinute] = data.endTime.split(":");
  const [startHour, startMinute] = data.startTime.split(":");
  end.setHours(+endHour, +endMinute, 0);
  start.setHours(+startHour, +startMinute, 0);
  const meeting = {
    title,
    start: start.toISOString(),
    end: end.toISOString(),
  };
  return meeting;
}

const initialState: () => CoursesStoreState = () => ({
  courseLoadState: LoadingState.NotLoaded,
  courses: [],
  courseDefaultOptions: {
    courseStatuses: [],
    courseEnrollmentStatuses: [],
    participantStatuses: [],
    participantEnrollmentStatuses: [],
    participantLaterStatus: [],
  },
  curriculum: {
    id: 0,
    name: "",
    memberOrgId: undefined,
    subCourseId: undefined,
    teacherRequired: false,
    course: {
      teachingMaterials: [],
      content: "",
      method: "",
    },
    hoursMin: 0,
    hoursMax: 0,
    courseCertificateRequired: false,
    teachingMethod: "",
    statusId: undefined,
  },
  courseSerie: { courseSeries: [] },
  course: { ...getCourseInitialState(), id: -1 },
  webDescription: {
    templateId: "",
    title: "",
    webCategory: "",
    intro: "",
    targetGroup: "",
    foreknowledgeRequirements: "",
    documentationRequired: false,
    learningObjectives: "",
    courseContent: "",
    method: "",
    financing: "",
    exam: "",
    publishDate: "",
    enrollmentStatus: "",
    defaultEnrollmentStudentStatus: "",
  },
  courseCategories: [],
  schedules: {
    days: [],
  },
  schedule: {
    title: "",
    day: "",
    startTime: "",
    endTime: "",
    nTimes: "",
    each: "",
    startDate: undefined,
    endDate: undefined,
    startStopDates: [],
  },
  notes: [
    "<h2>Test note</h2><br/> <p>Dette er et test notat</p>",
    "<h2>Test 2 note</h2><br/> <p>Dette er et test notat</p>",
  ],
});

const state = initialState();

const getters = <GetterTree<CoursesStoreState, RootStoreState>>{
  getField,
  getTeachingmaterials: (state) => state.course.additionalTeachingMaterialIds,
  getCourses: (state) => state.courses,
  getCourse: (state) => state.course,
  getEconomy: (state) => state.course.economy,
  getSchedule: (state) => state.schedules.days,
  getDay: (state) => state.schedule,
  curriculum: (state) => state.curriculum,
  courseCategories: (state) => state.courseCategories,
  // getWebDescription: (state) => state.webDescription, Remove when popup is gone
};

const actions = <ActionTree<CoursesStoreState, RootStoreState>>{
  [CoursesActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [CoursesActions.FetchCourse]({ commit }, courseId) {
    const response = !authService.fromMemberOrganization()
      ? await api.course.getCourseByIdFilteredByDepartmentAsync(courseId)
      : await api.guestside.getGuestUserCourseByIdFilteredByOrganizationAsync(courseId);
    commit("SET_COURSE", { ...response.data, id: courseId });
  },
  async [CoursesActions.FetchCourseDefaultOptions]({ commit }) {
    commit("SET_LOADING_STATE", LoadingState.Loading);
    const defaultOptions = (await api.course.getCourseDefaultOptions()).data;

    commit("SET_COURSE_DEFAULT_OPTIONS", defaultOptions);
    commit("SET_LOADING_STATE", LoadingState.Loaded);
  },
  async [CoursesActions.FetchCourses]({ commit }) {
    const response = await api.course.getCourseList({
      CourseResponsibleFilter: ApiCourseResponsibleFilter.ALL,
    });
    commit("SET_COURSES", response.data);
  },
  async [CoursesActions.GetCoursesByStudId]({ commit }, id) {
    const response = await api.curriculum.getCoursesByCurriculumIdAsync(id);
    if (response.status == 200) {
      commit("SET_COURSES", response.data);
    }
  },
  async [CoursesActions.FetchCurriculum]({ commit }, id) {
    const response = await api.curriculum.getCurriculumAsync(id);
    commit("SET_CURRICULUM_SINGLE", response.data);
    // commit("SET_CHILD_ITEMS");
  },
  async [CoursesActions.FetchLocations]({ dispatch }) {
    dispatch("area/fetchLocations", state.course.courseLocation?.locationId, {
      root: true,
    });
  },
  async [CoursesActions.UpdateCourse](context, courseId) {
    await putCourse(courseId, state.course);
    openNotification(this as any, NotificationItemType.Success, "Kurs oppdatert.");
    // dispatch("updateWebDescription", courseId); TODO remove on edit course fixed
  },
  async [CoursesActions.FetchCourseCategories]({ commit }) {
    const res = await getCourseCategories().then((res) => res.data);
    commit("SET_COURSE_CATEGORIES", res);
  },
};

const mutations = <MutationTree<CoursesStoreState>>{
  [CoursesMutations.SET_WEB_DESCRIPTION](state, payload) {
    state.webDescription = payload;
  },
  [CoursesMutations.SET_COURSE_CATEGORIES](state, payload) {
    state.courseCategories = payload;
  },
  updateField,
  //  Will always add a reset mutation so we can use the gloabal reset.
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof CoursesStoreState] = newState[key as keyof CoursesStoreState];
    });
  },
  ADD_NOTE(state) {
    const data = {
      id: `${performance.now()}`.replace(".", ""),
      data: "",
    };
    state.notes.push(data);
  },
  [CoursesMutations.SET_CURRICULUM_SINGLE](state, data) {
    state.curriculum = data;
  },
  [CoursesMutations.SET_COURSES](state, data) {
    state.courses = data;
  },
  [CoursesMutations.SET_TRAINING_GRANT](state, data) {
    if (!state.course.economy) {
      return;
    }
    state.course.economy.grantId = data;
  },
  [CoursesMutations.SET_COURSE](state, data: CourseDto) {
    state.course = data;
  },
  [CoursesMutations.SET_COURSE_DEFAULT_OPTIONS](state, data) {
    state.courseDefaultOptions = data;
  },
  [CoursesMutations.SET_SCHEDULE](state, data) {
    Object.assign(state.schedule, data);
  },
  [CoursesMutations.SET_LOADING_STATE](state, data) {
    state.courseLoadState = data;
  },
  [CoursesMutations.EDIT_SCHEDULE_ENTRY](state, data) {
    if (!state.course.plan || !state.course.plan.schedules) {
      return;
    }
    const meeting = scheduleMeeting(data);
    state.course.plan.schedules.splice(data.index, 1, meeting);
  },
  [CoursesMutations.SET_COURSE_INFO](state, data) {
    Object.assign(state.course, data);
  },
};

export const CoursesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
