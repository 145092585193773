
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "BaseListItem",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
});
