var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', _vm._g(_vm._b({
    staticClass: "v-list-item--default",
    attrs: {
      "href": _vm.item.href,
      "rel": _vm.item.href ? 'nofollow' : undefined,
      "target": _vm.item.href ? '_blank' : undefined,
      "to": _vm.item.to,
      "color": "primary"
    }
  }, 'v-list-item', _vm.$attrs, false), _vm.$listeners), [_vm.item.icon ? _c('v-list-item-icon', [_c('v-icon', {
    domProps: {
      "textContent": _vm._s(_vm.item.icon)
    }
  })], 1) : _vm._e(), _vm.item.recent || _vm.item.fresh ? _c('v-avatar', {
    staticClass: "flex-0-1-auto mr-2 ml-n3",
    attrs: {
      "color": "#00C58E",
      "size": "4"
    }
  }) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', {
    domProps: {
      "textContent": _vm._s(_vm.item.title)
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }