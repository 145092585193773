export enum CoursesMutations {
  RESET = "RESET",
  SET_WEB_DESCRIPTION = "SET_WEB_DESCRIPTION",
  SET_COURSE_CATEGORIES = "SET_COURSE_CATEGORIES",
  SET_CURRICULUM_SINGLE = "SET_CURRICULUM_SINGLE",
  SET_COURSES = "SET_COURSES",
  SET_TRAINING_GRANT = "SET_TRAINING_GRANT",
  SET_COURSE = "SET_COURSE",
  SET_SCHEDULE = "SET_SCHEDULE",
  EDIT_SCHEDULE_ENTRY = "EDIT_SCHEDULE_ENTRY",
  SET_COURSE_INFO = "SET_COURSE_INFO",
  SET_COURSE_DEFAULT_OPTIONS = "SET_COURSE_DEFAULT_OPTIONS",
  SET_LOADING_STATE = "SET_LOADING_STATE",
}
