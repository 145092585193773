import { api } from "./api";
import { httpClient } from "./httpClient";

export const getEconomyDefaultOptions = () => httpClient.get("/economy/defaultoption");

export const getAllCost = () => api.economy.getCostsPerOrganizationAsync();

export const getCost = (id: number) => httpClient.get(`/economy/cost/${id}`);

export const putCost = (body: any) => httpClient.put(`/economy/cost/${body.id}`, body);
export const postCost = (body: any) => httpClient.post(`/economy/cost`, body);
