import { httpClient } from "./httpClient";

export const getFiles = (entity: string, id: number) => httpClient.get(`file/${entity}/${id}`);

export const openFile = (fileId: string) =>
  httpClient.get(`file/${fileId}`, {
    responseType: "blob",
  });

export const deleteFile = (fileId: string) => httpClient.delete(`file/${fileId}`);

export const getFileDefaultOptions = () => {
  httpClient.get(`/file/defaultoption`);
};

export const getFileListByEntity = (entity: string, id: string) => httpClient.get(`/file?entities[${entity}]=${id}`);

export const getCourseFilesById = (courseId: number) => httpClient.get(`/file/course/${courseId}`);

export const getWebapplicationFilesByUserId = (courseId: number, userId: number) =>
  httpClient.get(`/file/course/${courseId}/participant/${userId}`);

export const getCurriculumFilesById = (curriculumId: number) => httpClient.get(`/file/curriculum/${curriculumId}`);
//  Area = curriculum, course or user. ID and formData as usual
export const postFile = (area: string, id: number, formData: any) =>
  httpClient.post(`/file/${area}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

/* export const postFile = (formData) =>
  httpClient.post(`/file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }); */
