import { computed, getCurrentInstance } from "@vue/composition-api";
import VueRouter, { Route } from "vue-router";
import { Store } from "vuex";

// requires type to make vuex 3 -> 4 easier
export function useStore<StoreType>(): Store<StoreType> {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error("must be called in setup");
  }
  const { $store } = "proxy" in vm ? vm.proxy : vm;
  return $store;
}

// will not update on route change (known bug, use useRouteComputed)
export function useRoute(): Route {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error("must be called in setup");
  }

  return vm.proxy.$route;
}

export function useRouteComputed() {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error("must be called in setup");
  }

  return computed(() => vm.proxy.$route);
}

export function useRouter(): VueRouter {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error("must be called in setup");
  }

  return vm.proxy.$router;
}
