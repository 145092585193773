import { getField, updateField } from "vuex-map-fields";
import { getRole, putRole, postRole } from "@/api/role.api";
import { RoleMutations } from "./role.mutations.enum";
import { RoleStoreState } from "./role.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { RoleActions } from "@/store/modules/role/role.actions.enum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { api } from "@/api/api";

const initialState: () => RoleStoreState = () => ({
  roles: [],
  roleSingle: {},
});

const state = initialState();

const getters = <GetterTree<RoleStoreState, RootStoreState>>{
  getField,
  getRoles(state) {
    return state.roles;
  },
  getRole(state) {
    return state.roleSingle;
  },
};

const actions = <ActionTree<RoleStoreState, RootStoreState>>{
  [RoleActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [RoleActions.FetchRoles]({ commit }) {
    const response: any = await api.role.getRolesAsync();
    if (response.status == 200) {
      await commit("SET_ROLES", response.data);
    }
  },
  async [RoleActions.FetchRole]({ commit }, id) {
    console.warn("Missing api for getting role by ID");
    const response: any = await getRole(id);
    if (response.status == 200) {
      await commit("SET_ROLE", response.data);
    }
  },
  async [RoleActions.AddRole]({ dispatch }) {
    const response: any = await postRole(state.roleSingle);
    if (response.status == 200) {
      openNotification(this as any, NotificationItemType.Success, "Opprettelse av rolle vellykket.");
      await dispatch("role/fetchRoles", null, { root: true });
    }
  },
  async [RoleActions.EditRole]({ dispatch }) {
    // TODO update
    const response: any = await putRole(state.roleSingle);
    if (response.status == 204) {
      openNotification(this as any, NotificationItemType.Success, "Redigering av rolle vellykket.");
      await dispatch("role/fetchRoles", null, { root: true });
    }
  },
};

const mutations = <MutationTree<RoleStoreState>>{
  updateField,

  //  Will always add a reset mutation so we can use the global reset.
  [RoleMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof RoleStoreState] = newState[key as keyof RoleStoreState];
    });
  },
  [RoleMutations.SET_ROLE](state, data) {
    state.roleSingle = data;
  },
  [RoleMutations.SET_ROLES](state, data) {
    state.roles = data;
  },
};

export const RoleModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
