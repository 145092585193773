var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "courseAdmin"
    }
  }, [_c('TheCollapsableDrawer', {
    ref: "drawer"
  }), _vm.$auth.isAuthenticated ? _c('TheAppBar', {
    on: {
      "toggleDrawer": function toggleDrawer($event) {
        _vm.$refs.drawer.drawerOpen = !_vm.$refs.drawer.drawerOpen;
      }
    }
  }) : _vm._e(), _c('v-main', {
    staticClass: "grey lighten-3"
  }, [_c('router-view'), _c('div', {
    staticClass: "notificationSection"
  }, [_c('TheNotificationDisplayer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }