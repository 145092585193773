import { HocActions } from "@/store/modules/hoc/hoc.actions.enum";
import { AreaActions } from "./modules/area/area.actions.enum";
import { ContactsActions } from "./modules/contacts/contacts.actions.enum";
import { ContractsActions } from "./modules/contracts/contracts.actions.enum";
import { CostActions } from "./modules/cost/cost.actions.enum";
import { CourseParticipantsActions } from "./modules/courseParticipants/courseParticipants.actions.enum";
import { CoursesActions } from "./modules/courses/courses.actions.enum";
import { ExecutionActions } from "./modules/execution/execution.actions.enum";
import { FilesActions } from "./modules/files/files.actions.enum";
import { FilterActions } from "./modules/filter/filter.actions.enum";
import { HoursActions } from "./modules/hours/hours.actions.enum";
import { MemberOrganizationsActions } from "./modules/memberOrganizations.store/memberOrganizations.actions.enum";
import { OrganizationActions } from "./modules/organization/organization.actions.enum";
import { PartnerActions } from "./modules/partner/partner.actions.enum";
import { PlansActions } from "./modules/plans/plans.actions.enum";
import { RoleActions } from "./modules/role/role.actions.enum";
import { SearchActions } from "./modules/search/search.actions.enum";
import { SsbActions } from "./modules/ssb/ssb.actions.enum";
import { SubjectRegisterActions } from "./modules/subjectRegister/subjectRegister.actions.enum";
import { TeachingMaterialsActions } from "./modules/teachingMaterials/teachingMaterials.actions.enum";
import { TestTypesActions } from "./modules/testTypes/testTypes.actions.enum";
import { TrainingGrantsActions } from "./modules/trainingGrants/trainingGrants.actions.enum";
import { VacationScheduleActions } from "./modules/vacationSchedule/vacationSchedule.actions.enum";

export const StoreActions = {
  HocActions,
  AreaActions,
  ContactsActions,
  ContractActions: ContractsActions,
  CostActions,
  CourseParticipantsActions,
  CoursesActions,
  ExecutionActions,
  FilesActions,
  FilterActions,
  HoursActions,
  MemberOrganizationsActions,
  OrganizationActions,
  PartnerActions,
  PlansActions,
  RoleActions,
  SearchActions,
  SsbActions,
  SubjectRegisterActions,
  TeachingMaterialsActions,
  TestTypesActions,
  TrainingGrantsActions,
  VacationScheduleActions,
} as const;

export type StoreActionsType =
  | HocActions
  | AreaActions
  | ContactsActions
  | ContractsActions
  | CostActions
  | CourseParticipantsActions
  | CoursesActions
  | ExecutionActions
  | FilesActions
  | FilterActions
  | HoursActions
  | MemberOrganizationsActions
  | OrganizationActions
  | PartnerActions
  | PlansActions
  | RoleActions
  | SearchActions
  | SsbActions
  | SubjectRegisterActions
  | TeachingMaterialsActions
  | TestTypesActions
  | TrainingGrantsActions
  | VacationScheduleActions;
