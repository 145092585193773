/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiAccountRatesDto {
  /** @format double */
  participantPrice?: number | null;
  /** @format double */
  hourlyPrice?: number | null;
  /** @format double */
  percentageOfTotalCostsPerCourse?: number | null;
  /** @format double */
  maximumAmount?: number | null;
}

export interface ApiAccountingDepartment {
  /** @format int32 */
  organizationId: number;
  /** @format int32 */
  erpClientId: number;
  /** @format int32 */
  erpDepartmentId: number;
  name?: string | null;
  organization?: ApiOrganization;
}

export interface ApiAddActualCostsToFacilitationGrantApplicationDto {
  commentToGrant?: string | null;
  actualCosts?: ApiFacilitationActualCostDto[] | null;
}

export interface ApiAddActualCostsToFacilitationGrantApplicationDtoValidationEntry {
  validatedItem?: ApiAddActualCostsToFacilitationGrantApplicationDto;
  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiAddActualCostsToFacilitationGrantApplicationDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiAddActualCostsToFacilitationGrantApplicationDtoValidationEntry[] | null;
}

export interface ApiAppFormResponse {
  /** @format int64 */
  id: number;
  /** @format uuid */
  templateId: string;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  isNorwegianCitizen?: boolean | null;
  citizenship?: string | null;
  personalnumber?: string | null;
  isNewsletter?: boolean | null;
  employerName?: string | null;
  employerAddress?: string | null;
  employerPostNumber?: string | null;
  employerLocation?: string | null;
  employerEmail?: string | null;
  organizationPurchaserBrRegOrganizationNumber?: string | null;
  organizationPurchaserOrganizationName?: string | null;
  organizationPurchaserAddress?: string | null;
  organizationPurchaserPostNumber?: string | null;
  organizationPurchaserPostLocation?: string | null;
  organizationPurchaserPostCountry?: string | null;
  organizationPurchaserMobileNumber?: string | null;
  organizationPurchaserEmail?: string | null;
  organizationPurchaserHomePage?: string | null;
  isPrivateCostBearer?: boolean | null;
  /** @format int32 */
  privateCostBearerInstallmentType?: number | null;
  /** @format int32 */
  privateCostBearerInstallmentInterval?: number | null;
  organizationCostBearerEmail?: string | null;
  organizationCostBearerBrRegOrganizationNumber?: string | null;
  organizationCostBearerOrganizationName?: string | null;
  organizationCostBearerAddress?: string | null;
  organizationCostBearerPostNumber?: string | null;
  organizationCostBearerPostLocation?: string | null;
  organizationCostBearerPostCountry?: string | null;
  organizationCostBearerMobileNumber?: string | null;
  organizationCostBearerOrderNumber?: string | null;
  organizationCostBearerReferenceNumber?: string | null;
  organizationCostBearerHomePage?: string | null;
  isNorwegianMothertongue?: boolean | null;
  norwegianSkillsLevel?: string | null;
  norwegianSkillsCerfLevel?: string | null;
  isFormalCompetence?: boolean | null;
  formalCompetenciesIsNorwegianHealthAndSocialSciences?: boolean | null;
  formalCompetenciesIsAuthorizationDiploma?: boolean | null;
  formalCompetenciesIsForeignHealthAndSocialSciences?: boolean | null;
  isLoMember?: boolean | null;
  /** @format int32 */
  memberOrganizationId?: number | null;
  courseRegulationsAccepted?: boolean | null;
  /** @format int64 */
  regulationFileId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  insertedBy: number;
  /** @format int32 */
  updatedBy: number;
  appFormResponseFile?: ApiAppFormResponseFile[] | null;
  memberOrganization?: ApiMemberOrganization;
  appFormResponseAdditionalQuestion?: ApiAppFormResponseAdditionalQuestion[] | null;
  courseParticipant?: ApiCourseParticipant;
  regulationFile?: ApiRegulationFile;
}

export interface ApiAppFormResponseAdditionalQuestion {
  /** @format int64 */
  id: number;
  /** @format int64 */
  appFormResponseId: number;
  /** @format int64 */
  fileId?: number | null;
  response?: string | null;
  /** @format uuid */
  appFormTemplateAdditionalQuestionId: string;
  appFormResponse?: ApiAppFormResponse;
  file?: ApiFile;
  appFormTemplateAdditionalQuestion?: ApiAppFormTemplateAdditionalQuestion;
}

export interface ApiAppFormResponseFile {
  /** @format int64 */
  appFormResponseId: number;
  /** @format int64 */
  fileId: number;
  appFormResponse?: ApiAppFormResponse;
  file?: ApiFile;
}

export interface ApiAppFormTemplate {
  /** @format uuid */
  id: string;
  name?: string | null;
  isActive: boolean;
  isVocationalSchool: boolean;
  isCitizenship: boolean;
  isPersonalNumber: boolean;
  isNorwegianMothertongue: boolean;
  isNorwegianCertificationLevel: boolean;
  isNorwegianSkills: boolean;
  isCompetence: boolean;
  isEmployer: boolean;
  isEmployerEmail?: boolean | null;
  isCostBearer: boolean;
  isInstallments: boolean;
  isMonthly?: boolean | null;
  isSemiAnnually?: boolean | null;
  isQuarterly?: boolean | null;
  isInvoiceReference?: boolean | null;
  isNewsletter: boolean;
  isUnionized: boolean;
  isMemberOrganization: boolean;
  isRegionalOffices?: boolean | null;
  /** @format int64 */
  regulationFileId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  insertedBy: number;
  /** @format int32 */
  updatedBy: number;
  regulationFile?: ApiRegulationFile;
  appFormTemplateAdditionalQuestion?: ApiAppFormTemplateAdditionalQuestion[] | null;
}

export interface ApiAppFormTemplateAdditionalQuestion {
  /** @format uuid */
  id: string;
  /** @format uuid */
  appFormTemplateId: string;
  type?: string | null;
  description?: string | null;
  /** @format uuid */
  parentQuestionId: string;
  /** @format int32 */
  questionNumber: number;
  required: boolean;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  insertedBy: number;
  /** @format int32 */
  updatedBy: number;
  appFormTemplate?: ApiAppFormTemplate;
  appFormResponseAdditionalQuestion?: ApiAppFormResponseAdditionalQuestion[] | null;
  appFormTemplateAdditionalQuestionAlternative?: ApiAppFormTemplateAdditionalQuestionAlternative[] | null;
}

export interface ApiAppFormTemplateAdditionalQuestionAlternative {
  /** @format uuid */
  appFormTemplateAdditionalQuestionId: string;
  alternative?: string | null;
  /** @format int32 */
  alternativeNumber: number;
  appFormTemplateAdditionalQuestion?: ApiAppFormTemplateAdditionalQuestion;
}

export interface ApiApplicationFormTemplateAdditionalQuestionAlternativeDto {
  /** @format int32 */
  alternativeNumber: number;
  description?: string | null;
}

export interface ApiApplicationFormTemplateAdditionalQuestionDto {
  /** @format uuid */
  id: string;
  type?: string | null;
  description?: string | null;
  questionAlternatives?: ApiApplicationFormTemplateAdditionalQuestionAlternativeDto[] | null;
  required: boolean;
  /** @format int32 */
  questionNumber: number;
  displayIfQuestionIdIsTrue?: string | null;
}

export interface ApiApproveFacilitationGrantApplicationCostsDto {
  commentToActualCosts?: string | null;
}

export interface ApiApproveFacilitationGrantApplicationCostsDtoValidationEntry {
  validatedItem?: ApiApproveFacilitationGrantApplicationCostsDto;
  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiApproveFacilitationGrantApplicationCostsDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiApproveFacilitationGrantApplicationCostsDtoValidationEntry[] | null;
}

export interface ApiApproveFacilitationGrantApplicationDto {
  commentToGrant?: string | null;
  costsGranted?: ApiFacilitationCostGrantedDto[] | null;
}

export interface ApiApproveFacilitationGrantApplicationDtoValidationEntry {
  validatedItem?: ApiApproveFacilitationGrantApplicationDto;
  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiApproveFacilitationGrantApplicationDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiApproveFacilitationGrantApplicationDtoValidationEntry[] | null;
}

export interface ApiArea {
  /** @format int32 */
  id: number;
  country?: string | null;
  countryCode?: string | null;
  county?: string | null;
  municipality?: string | null;
  place?: string | null;
  studylocationCode?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse[] | null;
  location?: ApiLocation[] | null;
}

export interface ApiArticleDto {
  articleNo?: string | null;
  /**
   * Optional amount override
   * @format double
   */
  amountOverride?: number | null;
}

export interface ApiArticleDtoType {
  id?: string | null;
  name?: string | null;
  /** @format double */
  vatPercent?: number | null;
  teachingMethod?: string | null;
}

export interface ApiAttachmentDto {
  /** @format int64 */
  fileId: number;
  fileName?: string | null;
}

export interface ApiBaseGetFileDto {
  /** @format int64 */
  id?: number | null;
  originalFileName?: string | null;
  description?: string | null;
  mimeType?: string | null;
  category?: string | null;
  isAdminRead?: boolean | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
}

export interface ApiBatchMergeCourseParticipantAttendancesDto {
  attendances?: ApiCourseParticipantAttendanceBaseDto[] | null;
}

export interface ApiBuildinfo {
  enabled: boolean;
  environment?: string | null;
  buildNumber?: string | null;
  sourceBranchName?: string | null;
  sourceVersion?: string | null;
}

export interface ApiCallbackRequest {
  callbackUrl?: string | null;
  callbackTag?: string | null;
  serviceBusMessageId?: string | null;
  isSuccessful: boolean;
  errorMessage?: string | null;
}

export enum ApiCertificateStatus {
  ApiSent = "Sent",
  ApiSentManually = "SentManually",
}

export interface ApiClientAccountInvoiceBasis {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  invoiceBasisNumber: number;
  status?: string | null;
  organizerIsAOFAccountingDepartment: boolean;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  insertedBy: number;
  /** @format int32 */
  updatedBy: number;
  /** @format double */
  coursePrice: number;
  /** @format int32 */
  productNo: number;
  courseName?: string | null;
  /** @format int32 */
  accountingDepartmentOrganizationId: number;
  /** @format date-time */
  courseStartDate: string;
  /** @format date-time */
  courseEndDate: string;
  /** @format int32 */
  courseOwnerOrganizationId: number;
  /** @format int32 */
  courseOrganizerOrganizationId: number;
  /** @format int32 */
  grantId?: number | null;
  grantName?: string | null;
  /** @format int32 */
  curriculumId: number;
  /** @format int32 */
  numberOfParticipants: number;
  isLocal: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /** @format double */
  costInAccountingReport: number;
  /** @format double */
  changeInCostInAccountingReport: number;
  /** @format double */
  totalCostInAccountingReport: number;
  /** @format double */
  contributionOuFondParticipants: number;
  /** @format double */
  contributionOuFondHourly: number;
  /** @format double */
  contributionOuFondPercentageOfCosts: number;
  /** @format double */
  totalCostAllocation: number;
  /** @format double */
  totalCourseFee: number;
  /** @format double */
  deductionGovernmentContribution: number;
  /** @format double */
  invoiceOuFond: number;
  /** @format double */
  shareAofNorge: number;
  /** @format double */
  contributionAdmOuFond: number;
  /** @format double */
  costReimbursementFromOuFond: number;
  /** @format double */
  creditAofDepartment: number;
  generationStatus?: string | null;
  message?: string | null;
  serviceBusMessageId?: string | null;
  clientAccountInvoicePartBasis?: ApiClientAccountInvoicePartBasis[] | null;
  course?: ApiCourse;
  file?: ApiClientAccountInvoiceBasisFile;
}

export interface ApiClientAccountInvoiceBasisFile {
  /** @format int32 */
  invoiceBasisNumber: number;
  /** @format int64 */
  fileId: number;
  clientAccountInvoiceBasis?: ApiClientAccountInvoiceBasis;
  file?: ApiFile;
}

export interface ApiClientAccountInvoiceBasisNumberDto {
  /** @format int32 */
  invoiceBasisNumber: number;
}

export interface ApiClientAccountInvoicePartBasis {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  invoiceBasisNumber: number;
  /** @format int32 */
  memberOrganizationId: number;
  /** @format int32 */
  numberOfParticipants: number;
  /** @format double */
  shareOfRegisteredCosts: number;
  /** @format double */
  change: number;
  /** @format double */
  shareOfCourseFee: number;
  /** @format double */
  shareOfTotalCosts: number;
  clientAccountInvoiceBasis?: ApiClientAccountInvoiceBasis;
}

export interface ApiClientAccountInvoicePartBasisDto {
  /**
   * Forbund
   * @format int32
   */
  memberOrganizationId: number;
  /**
   * Antall deltakere fra forbundet
   * @format int32
   */
  numberOfParticipants: number;
  /**
   * Forbundets andel av registrerte kostnader
   * @format double
   */
  shareOfRegisteredCosts: number;
  /**
   * Endring
   * @format double
   */
  change: number;
  /**
   * Forbundets andel av kursavgift
   * @format double
   */
  shareOfCourseFee: number;
  /**
   * Forbundets samlede kostnad
   * @format double
   */
  shareOfTotalCosts: number;
}

export interface ApiCloseCourseValidationCategoryDto {
  name?: string | null;
  errors?: ApiCloseCourseValidationMessageDto[] | null;
  warnings?: ApiCloseCourseValidationMessageDto[] | null;
  /** @format int32 */
  numberOfErrors: number;
  /** @format int32 */
  numberOfWarnings: number;
}

export interface ApiCloseCourseValidationDto {
  /**
   * Categories av feil ved avslutte kurs. Disse er:
   * "course"
   * "teachinggrant"
   * "participantAttendance"
   * "participantExpense"
   * "workingHour"
   * "economy"
   */
  categories?: ApiCloseCourseValidationCategoryDto[] | null;
  /**
   * antall feil i valideringen
   * @format int32
   */
  numberOfErrors: number;
  /**
   * antall varsler i valideringen
   * @format int32
   */
  numberOfWarnings: number;
  /**
   * type feilmeldinger som kan bypasses. Disse er:
   * "skipWorkingHours": kan hoppe over arbeidstimer sjekk
   * "skipTeachingHours": kan hoppe over "timer med lærer mindre enn 4 timer"
   * "skipAmountOfParticipantsOlderThan14": kan hoppe over "mindre enn 4 deltakere over 14 år"
   */
  availableBypasses?: string[] | null;
}

export interface ApiCloseCourseValidationMessageDto {
  message?: string | null;
  users?: ApiUsersIdNameDto[] | null;
  /** @format date-time */
  date?: string | null;
}

export interface ApiCommunicationTemplate {
  /** @format int32 */
  id: number;
  /** @format int32 */
  communicationTemplateGroupId: number;
  tag?: string | null;
  name?: string | null;
  subject?: string | null;
  content?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  communicationTemplateGroup?: ApiCommunicationTemplateGroup;
}

export interface ApiCommunicationTemplateGroup {
  /** @format int32 */
  id: number;
  /** @format int32 */
  organizationId?: number | null;
  name?: string | null;
  type?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  organization?: ApiOrganization;
  communicationTemplate?: ApiCommunicationTemplate[] | null;
}

export interface ApiContractRequest {
  /** @format int32 */
  id: number;
  /** @format int32 */
  contractId: number;
  type?: string | null;
  status?: string | null;
  /** @format date-time */
  sent: string;
  message?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  contract?: ApiCourseContract;
}

export interface ApiContractStatusDescriptionDto {
  contractStatus?: string | null;
  contractStatusDescription?: string | null;
}

export interface ApiCost {
  /** @format int32 */
  id: number;
  /** @format int32 */
  organizationId: number;
  /** @format int32 */
  costTypeId: number;
  name?: string | null;
  description?: string | null;
  /** @format double */
  price: number;
  /** @format int32 */
  payType?: number | null;
  articleNo?: string | null;
  /** @format int32 */
  deductionCode?: number | null;
  teachingMethod?: string | null;
  isActive: boolean;
  travelAndExpenseType?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  costType?: ApiCostType;
  organization?: ApiOrganization;
  courseContractLine?: ApiCourseContractLine[] | null;
  employeeContractLine?: ApiEmployeeContractLine[] | null;
}

export interface ApiCostBearerOrganizationDto {
  isPurchaser: boolean;
  orderNumber?: string | null;
  referenceNumber?: string | null;
  organizationNumber?: string | null;
  name?: string | null;
  homePage?: string | null;
  address?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  isBrregSubCompany?: boolean | null;
}

export interface ApiCostBearerPrivateDto {
  /**
   * Ønsket avdrag; mndlig(1), kvartalsvis(3), halvårlig(6)
   * @format int32
   */
  installmentInterval?: number | null;
  /** @format int32 */
  installmentType?: number | null;
}

export interface ApiCostType {
  /** @format int32 */
  id: number;
  costGroup?: string | null;
  name?: string | null;
  /** @format date-time */
  inserted: string;
  cost?: ApiCost[] | null;
}

export interface ApiCourse {
  /** @format int32 */
  id: number;
  /** @format int32 */
  curriculumId: number;
  courseName?: string | null;
  externalTitle?: string | null;
  status?: string | null;
  reasonForStatusChange?: string | null;
  useLearningPlatform: boolean;
  /** @format int32 */
  areaId?: number | null;
  /** @format int32 */
  locationId?: number | null;
  /** @format int32 */
  roomId?: number | null;
  /** @format int32 */
  studyLocationId?: number | null;
  /** @format date-time */
  cancellationDeadline?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  isTimeWebVisible: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /** @format int32 */
  hoursWithoutInstructor?: number | null;
  /** @format int32 */
  hoursOnline?: number | null;
  /** @format int32 */
  hoursSelfStudy?: number | null;
  /** @format int32 */
  courseSupervisorId: number;
  /** @format int32 */
  organizerOrganizationId: number;
  /** @format int32 */
  subOrganizerOrganizationId?: number | null;
  /** @format int32 */
  ownerOrganizationId: number;
  /** @format int32 */
  lecturerId: number;
  /** @format int32 */
  contactId: number;
  /** @format int32 */
  kasId?: number | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format date-time */
  unenrollmentDeadline?: string | null;
  /** @format int32 */
  minimumParticipants?: number | null;
  /** @format int32 */
  maxParticipants?: number | null;
  /** @format int32 */
  participantCapacity?: number | null;
  isOrderable?: boolean | null;
  registerHours: boolean;
  comments?: string | null;
  /** @format int32 */
  holidayId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format int32 */
  msgUserId?: number | null;
  /** @format int32 */
  erpProjectNo?: number | null;
  /** @format date-time */
  erpLastUpdated?: string | null;
  /** @format int32 */
  mainCourseId?: number | null;
  isCommissionedCourse: boolean;
  /** @format int32 */
  hoursOfDigitalEducation?: number | null;
  area?: ApiArea;
  curriculum?: ApiCurriculum;
  holiday?: ApiHoliday;
  location?: ApiLocation;
  room?: ApiRoom;
  studyLocation?: ApiStudyLocation;
  courseDescription?: ApiCourseDescription;
  courseEconomy?: ApiCourseEconomy;
  ownerOrganization?: ApiDepartment;
  courseArticle?: ApiCourseArticle[] | null;
  courseContract?: ApiCourseContract[] | null;
  courseFile?: ApiCourseFile[] | null;
  courseNote?: ApiCourseNote[] | null;
  courseParticipant?: ApiCourseParticipant[] | null;
  courseTeachingMaterial?: ApiCourseTeachingMaterial[] | null;
  courseTest?: ApiCourseTest[] | null;
  schedule?: ApiSchedule[] | null;
  courseFinancedOrganization?: ApiCourseFinancedOrganization[] | null;
  orders?: ApiOrder[] | null;
  courseInvitation?: ApiCourseInvitation[] | null;
  courseSupervisorEmployee?: ApiEmployee;
  courseContactEmployee?: ApiEmployee;
  courseLecturerEmployee?: ApiEmployee;
  courseParticipantTravelAndExpense?: ApiCourseParticipantTravelAndExpense[] | null;
  facilitationGrantApplication?: ApiFacilitationGrantApplication;
  mainCourse?: ApiCourse;
  grantAmount?: ApiTeachingGrantAmount;
  facilitationCourse?: ApiCourse[] | null;
  courseSms?: ApiCourseSms[] | null;
  clientAccountInvoiceBasis?: ApiClientAccountInvoiceBasis[] | null;
  organizerOrganization?: ApiOrganization;
  subOrganizerOrganization?: ApiOrganization;
  ownerOrganizationOrg?: ApiOrganization;
}

export interface ApiCourseArea {
  /** @format int32 */
  id: number;
  name?: string | null;
  code?: string | null;
  ssbCode?: string | null;
  /** @format int32 */
  parentId?: number | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format int32 */
  erpProductNo?: number | null;
  /** @format date-time */
  erpLastUpdated?: string | null;
  parent?: ApiCourseArea;
  curriculum?: ApiCurriculum[] | null;
  inverseParent?: ApiCourseArea[] | null;
  orderLines?: ApiOrderLine[] | null;
}

export interface ApiCourseArticle {
  /** @format int32 */
  courseId: number;
  articleNo?: string | null;
  course?: ApiCourse;
}

export interface ApiCourseCertificateBulkVariablesDto {
  courseTitle?: string | null;
  /** @format date-time */
  courseStartDate?: string | null;
  /** @format date-time */
  courseEndDate?: string | null;
  /** @format int32 */
  hoursWithInstructor?: number | null;
  practice?: string | null;
  location?: string | null;
  curriculumContent?: string | null;
  learningObjectives?: string | null;
  showCoursePractice?: boolean | null;
  showAttendance?: boolean | null;
}

export interface ApiCourseCertificateFile {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format int64 */
  fileId: number;
  courseParticipantCertificate?: ApiCourseParticipantCertificate;
  file?: ApiFile;
}

export interface ApiCourseCertificateParticipantVariablesDto {
  userFullName?: string | null;
  /** @format double */
  userAttendanceHours?: number | null;
  courseTitle?: string | null;
  /** @format date-time */
  courseStartDate?: string | null;
  /** @format date-time */
  courseEndDate?: string | null;
  /** @format int32 */
  hoursWithInstructor?: number | null;
  practice?: string | null;
  location?: string | null;
  curriculumContent?: string | null;
  learningObjectives?: string | null;
  showCoursePractice?: boolean | null;
  showAttendance?: boolean | null;
}

export interface ApiCourseContract {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  status?: string | null;
  accountNo?: string | null;
  isSelfEmployed: boolean;
  isMission: boolean;
  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer: boolean;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;
  isActive: boolean;
  roleName?: string | null;
  serviceBusMessageId?: string | null;
  /** @format date-time */
  deadline?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  courseParticipant?: ApiCourseParticipant;
  customerOrganization?: ApiCustomerOrganization;
  courseContractLine?: ApiCourseContractLine[] | null;
  role?: ApiRole;
  courseContractFile?: ApiCourseContractFile;
  contractRequests?: ApiContractRequest[] | null;
}

export interface ApiCourseContractFile {
  /** @format int32 */
  courseContractId: number;
  /** @format int64 */
  signedFileId?: number | null;
  /** @format int64 */
  unsignedFileId: number;
  /** @format int32 */
  signicatDocumentId?: number | null;
  signStatus?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseContract?: ApiCourseContract;
  signedFile?: ApiFile;
  unsignedFile?: ApiFile;
}

export interface ApiCourseContractLine {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseContractId: number;
  /** @format int32 */
  costId: number;
  unit?: string | null;
  /** @format double */
  amount: number;
  /** @format double */
  price?: number | null;
  description?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  cost?: ApiCost;
  courseContract?: ApiCourseContract;
  workingHour?: ApiWorkingHour[] | null;
}

export interface ApiCourseDefaultVariablesDto {
  courseTitle?: string | null;
  /** @format date-time */
  courseStartDate: string;
  /** @format date-time */
  courseEndDate: string;
  /** @format double */
  hoursWithInstructor?: number | null;
  practice?: string | null;
  location?: string | null;
  curriculumContent?: string | null;
  learningObjectives?: string | null;
  showCoursePractice?: boolean | null;
  showAttendance?: boolean | null;
}

export interface ApiCourseDescription {
  /** @format int32 */
  courseId: number;
  /** @format uuid */
  appFormTemplateId: string;
  webCategories?: string[] | null;
  intro?: string | null;
  targetGroup?: string | null;
  foreknowledgeRequirements?: string | null;
  documentationRequired: boolean;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  financing?: string | null;
  exam?: string | null;
  /** @format date-time */
  publishDate: string;
  /** @format date-time */
  unpublishDate?: string | null;
  enrollmentStatus?: string | null;
  defaultEnrollmentStudentStatus?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
}

export interface ApiCourseEconomy {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  accountingDepartment: number;
  /** @format double */
  price: number;
  /** @format double */
  cancellationFee: number;
  /** @format int32 */
  grantRateId?: number | null;
  /** @format int32 */
  grantId?: number | null;
  allowExpenses: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  grant?: ApiGrant;
  grantRate?: ApiGrantRate;
}

export interface ApiCourseEconomyBaseDataDto {
  economyDepartments?: ApiIdNameDtoType[] | null;
  articles?: ApiArticleDtoType[] | null;
  grants?: ApiGetGrantDto[] | null;
  constractStatusDescriptions?: ApiContractStatusDescriptionDto[] | null;
  grantRates?: ApiGetGrantRateDto[] | null;
}

export interface ApiCourseFile {
  /** @format int32 */
  courseId: number;
  /** @format int64 */
  fileId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
  file?: ApiFile;
}

export interface ApiCourseFinancedOrganization {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  customerOrganizationId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
  customerOrganization?: ApiCustomerOrganization;
}

export interface ApiCourseInfoDto {
  courseName?: string | null;
  externalTitle?: string | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format double */
  totalScheduledHours: number;
}

export interface ApiCourseInvitation {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  phoneNumber?: string | null;
  status?: string | null;
  /** @format int32 */
  memberOrganizationId?: number | null;
  createRestrictedUser: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
}

export interface ApiCourseInvitationValidationDto {
  errors?: ApiCourseInvitationValidationMessageDto[] | null;
  warnings?: ApiCourseInvitationValidationMessageDto[] | null;
  /** @format int32 */
  numberOfErrors: number;
  /** @format int32 */
  numberOfWarnings: number;
}

export interface ApiCourseInvitationValidationMessageDto {
  message?: string | null;
  invitations?: ApiInvitationDto[] | null;
}

export interface ApiCourseLocationDtoType {
  /** @format int32 */
  areaId?: number | null;
  /** @format int32 */
  locationId?: number | null;
  /** @format int32 */
  roomId?: number | null;
}

export interface ApiCourseNote {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  title?: string | null;
  text?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
}

export interface ApiCourseParticipant {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  status?: string | null;
  requiresElectronicInvoice: boolean;
  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format int32 */
  purchaserId?: number | null;
  /** @format int32 */
  invoiceRecipientId?: number | null;
  /** @format date-time */
  applicationDate?: string | null;
  /** @format int32 */
  rank?: number | null;
  isRead: boolean;
  course?: ApiCourse;
  memberOrganization?: ApiMemberOrganization;
  user?: ApiUser;
  courseParticipantPoliceCertificate?: ApiCourseParticipantPoliceCertificate;
  courseContract?: ApiCourseContract[] | null;
  courseParticipantAttendance?: ApiCourseParticipantAttendance[] | null;
  courseParticipantFile?: ApiCourseParticipantFile[] | null;
  courseParticipantTravelAndExpense?: ApiCourseParticipantTravelAndExpense[] | null;
  courseParticipantRole?: ApiCourseParticipantRole[] | null;
  courseParticipantCertificate?: ApiCourseParticipantCertificate;
  appFormResponse?: ApiAppFormResponse;
  purchaser?: ApiCustomerOrganization;
  invoiceRecipient?: ApiCustomerOrganization;
  courseOfferletter?: ApiCourseParticipantOfferletter;
  employeeContractLine?: ApiEmployeeContractLine[] | null;
  courseParticipantGuestUserChange?: ApiCourseParticipantGuestUserChange[] | null;
  courseParticipantComment?: ApiCourseParticipantComment[] | null;
  courseParticipantEmployment?: ApiCourseParticipantEmployment[] | null;
}

export interface ApiCourseParticipantAttendance {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format date-time */
  scheduleStart: string;
  /** @format double */
  noOfHours: number;
  comment?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseParticipant?: ApiCourseParticipant;
  schedule?: ApiSchedule;
}

export interface ApiCourseParticipantAttendanceBaseDto {
  /**
   * Startdatoen og tidspunktet til timeplanen som fremmøtet gjelder for
   * @format date-time
   */
  scheduleStart: string;
  /**
   * Id'en til brukeren som fremmøtet gjelder for
   * @format int32
   */
  userId: number;
  /**
   * Antall timer brukeren var tilstede
   * @format double
   */
  noOfHours: number;
  /** Kommentaren til fremmøtet */
  comment?: string | null;
}

export interface ApiCourseParticipantAttendanceBaseDtoValidationEntry {
  validatedItem?: ApiCourseParticipantAttendanceBaseDto;
  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiCourseParticipantAttendanceBaseDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiCourseParticipantAttendanceBaseDtoValidationEntry[] | null;
}

export interface ApiCourseParticipantCertificate {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  status?: string | null;
  message?: string | null;
  variables?: ApiCourseParticipantCertificateVariables;
  serviceBusMessageId?: string | null;
  file?: ApiCourseCertificateFile;
  courseParticipant?: ApiCourseParticipant;
}

export interface ApiCourseParticipantCertificateVariables {
  userFullName?: string | null;
  /** @format double */
  userAttendanceHours?: number | null;
  courseTitle?: string | null;
  /** @format date-time */
  courseStartDate?: string | null;
  /** @format date-time */
  courseEndDate?: string | null;
  /** @format int32 */
  hoursWithInstructor?: number | null;
  practice?: string | null;
  location?: string | null;
  /** @format date-time */
  date?: string | null;
  curriculumContent?: string | null;
  learningObjectives?: string | null;
  showCoursePractice: boolean;
  showAttendance: boolean;
}

export interface ApiCourseParticipantComment {
  /** @format int32 */
  id: number;
  /** @format int32 */
  userId: number;
  /** @format int32 */
  courseId: number;
  comment?: string | null;
  isGuestUserVisible: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  courseParticipant?: ApiCourseParticipant;
}

export interface ApiCourseParticipantDto {
  /** @format int32 */
  userId: number;
  /** @format int32 */
  courseId: number;
}

export interface ApiCourseParticipantEmployment {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  insertedBy: number;
  /** @format int32 */
  updatedBy: number;
  employment?: ApiEmploymentInformation;
  courseParticipant?: ApiCourseParticipant;
}

export interface ApiCourseParticipantFile {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format int64 */
  fileId: number;
  isAdminRead: boolean;
  /** @format int32 */
  signingFileId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  courseParticipant?: ApiCourseParticipant;
  file?: ApiFile;
  signingFile?: ApiSigningFile;
}

export interface ApiCourseParticipantGuestUserChange {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  status?: string | null;
  statusComment?: string | null;
  /** @format int32 */
  memberOrganizationId?: number | null;
  isRead: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseParticipant?: ApiCourseParticipant;
}

export interface ApiCourseParticipantOfferletter {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format int64 */
  fileId?: number | null;
  generationStatus?: string | null;
  variables?: ApiOfferletterCustomVariables;
  serviceBusMessageId?: string | null;
  message?: string | null;
  file?: ApiCourseParticipantOfferletterFile;
  courseParticipant?: ApiCourseParticipant;
}

export interface ApiCourseParticipantOfferletterFile {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format int64 */
  fileId: number;
  courseParticipantOfferletter?: ApiCourseParticipantOfferletter;
  file?: ApiFile;
}

export interface ApiCourseParticipantPoliceCertificate {
  /** @format int32 */
  userId: number;
  /** @format int32 */
  courseId: number;
  status?: string | null;
  message?: string | null;
  fileName?: string | null;
  /** @format date-time */
  fileInserted: string;
  policeCertificateFile?: ApiCourseParticipantPoliceCertificateFile;
  courseParticipant?: ApiCourseParticipant;
}

export interface ApiCourseParticipantPoliceCertificateFile {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format int64 */
  fileId: number;
  policeCertificate?: ApiCourseParticipantPoliceCertificate;
  file?: ApiFile;
}

export interface ApiCourseParticipantRole {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  roleName?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  courseParticipant?: ApiCourseParticipant;
  roleNameNavigation?: ApiRole;
}

export interface ApiCourseParticipantTravelAndExpense {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  status?: string | null;
  comment?: string | null;
  /** @format date-time */
  date: string;
  bankAccountNumber?: string | null;
  description?: string | null;
  /** @format double */
  totalAmount: number;
  /** @format int32 */
  orderId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  travelAndExpenseLines?: ApiCourseParticipantTravelAndExpenseLine[] | null;
  course?: ApiCourse;
  courseParticipant?: ApiCourseParticipant;
  order?: ApiOrder;
}

export interface ApiCourseParticipantTravelAndExpenseAddressDto {
  addressLine?: string | null;
  postalCode?: string | null;
  postalArea?: string | null;
  country?: string | null;
}

export interface ApiCourseParticipantTravelAndExpenseFile {
  /** @format int32 */
  userId: number;
  /** @format int64 */
  fileId: number;
  /** @format int32 */
  expenseId?: number | null;
  /** @format int32 */
  expenseLineId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  file?: ApiFile;
  expenseLine?: ApiCourseParticipantTravelAndExpenseLine;
}

export interface ApiCourseParticipantTravelAndExpenseLine {
  /** @format int32 */
  id: number;
  /** @format int32 */
  expenseId: number;
  /** @format int32 */
  costId: number;
  /** @format double */
  quantity: number;
  /** @format double */
  amount: number;
  description?: string | null;
  /** @format double */
  totalAmount: number;
  courseParticipantTravelAndExpense?: ApiCourseParticipantTravelAndExpense;
  files?: ApiCourseParticipantTravelAndExpenseFile[] | null;
}

export enum ApiCourseResponsibleFilter {
  ALL = "ALL",
  SUPERVISOR_ONLY = "SUPERVISOR_ONLY",
  LECTURER_ONLY = "LECTURER_ONLY",
  SUPERVISOR_AND_LECTURER = "SUPERVISOR_AND_LECTURER",
}

export interface ApiCourseSms {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  messageText?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  recipients?: ApiCourseSmsRecipient[] | null;
  course?: ApiCourse;
}

export interface ApiCourseSmsRecipient {
  /** @format int32 */
  courseSmsId: number;
  /** @format int32 */
  userId: number;
  messageId?: string | null;
  mobileNumber?: string | null;
  courseSms?: ApiCourseSms;
}

export interface ApiCourseTeachingMaterial {
  /** @format int32 */
  teachingMaterialId: number;
  /** @format int32 */
  courseId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  course?: ApiCourse;
  teachingMaterial?: ApiTeachingMaterial;
}

export interface ApiCourseTest {
  /** @format int32 */
  id: number;
  /** @format int32 */
  testTypeId: number;
  name?: string | null;
  description?: string | null;
  /** @format int32 */
  courseId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  testType?: ApiTestType;
}

export interface ApiCreateApplicationFormTemplateDto {
  name?: string | null;
  isVocationalSchool: boolean;
  isCitizenship: boolean;
  isPersonalNumber: boolean;
  isNorwegianMotherTongue: boolean;
  isNorwegianCertificationLevel: boolean;
  isNorwegianSkills: boolean;
  isCompetence: boolean;
  isEmployer: boolean;
  isEmployerEmail?: boolean | null;
  isCostBearer: boolean;
  isInstallments: boolean;
  isMonthly?: boolean | null;
  isSemiAnnually?: boolean | null;
  isQuarterly?: boolean | null;
  isInvoiceReference?: boolean | null;
  isNewsletter: boolean;
  isUnionized: boolean;
  isMemberOrganization: boolean;
  isRegionalOffices?: boolean | null;
  /** @format int32 */
  regulationFileId?: number | null;
  additionalQuestions?: ApiApplicationFormTemplateAdditionalQuestionDto[] | null;
}

export interface ApiCreateClientAccountInvoiceBasisDto {
  createClientAccountInvoicePartBasis?: ApiCreateClientAccountInvoicePartBasisDto[] | null;
}

export interface ApiCreateClientAccountInvoicePartBasisDto {
  /**
   * Forbund
   * @format int32
   */
  memberOrganizationId: number;
  /**
   * Forbundets andel av registrerte kostnader
   * @format double
   */
  shareOfRegisteredCosts?: number | null;
  /**
   * Forbundets andel av kursavgift
   * @format double
   */
  shareOfCourseFee?: number | null;
  /**
   * Antall deltakere på kurset. Verdien her kan være
   * annerledes enn den som er på kurset.
   * @format int32
   */
  numberOfParticipants?: number | null;
}

export interface ApiCreateCommunicationTemplateDto {
  /**
   * Id'en malgruppen denne kommunikasjonsmalen gjelder for
   * @format int32
   */
  communicationTemplateGroupId: number;
  /** Navnet til kommunikasjonsmalen */
  name?: string | null;
  /** Emnet til kommunikasjonsmalen */
  subject?: string | null;
  /** Innholdet til kommunikasjonsmalen */
  content?: string | null;
  /** Om kommunikasjonsmalen er aktiv eller ikke */
  isActive: boolean;
}

export interface ApiCreateContractDto {
  contractLines?: ApiCreateContractLineDto[] | null;
  accountNo?: string | null;
  isSelfEmployed?: boolean | null;
  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer?: boolean | null;
  isMission?: boolean | null;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;
  /** @format date-time */
  signatureDeadline?: string | null;
  /**
   * The role per contract can be different than the course participant role.
   * E.g. a resource can have a contract for a role that is different than his usual role.
   */
  roleName?: string | null;
  isActive?: boolean | null;
}

export interface ApiCreateContractLineDto {
  /** @format int32 */
  costId: number;
  unit?: string | null;
  /** @format double */
  amount: number;
  /**
   * If the SYSCO.Universum.Api.Dtos.CreateContractLineDto.CostId is a cost where the price is not null,
   * the cost price will be saved for the contract line instead of the value of this input field.
   * @format double
   */
  price?: number | null;
  description?: string | null;
}

export interface ApiCreateCostDto {
  /** @format int32 */
  costTypeId: number;
  /** Kostnadstype / Navn */
  name?: string | null;
  /** Beskrivelse (kun brukt for utgifter) */
  description?: string | null;
  /**
   * Sats
   * @format double
   */
  price?: number | null;
  /**
   * Lønnsart (kun brukt for lønnskostnader)
   * @format int32
   */
  payType?: number | null;
  /** Arikkelnummer (kun brukt for utgifter) */
  articleNo?: string | null;
  /**
   * Trekkode (kun brukt for lønnskostnader)
   * @format int32
   */
  deductionCode?: number | null;
  /** Undervisningsform */
  teachingMethod?: string | null;
  /** Status */
  isActive?: boolean | null;
  /**
   * If expense, specify expense type
   * Valid values:
   * generelle reiseutgifter
   * reise eget kjøretøy
   * reise eget kjøretøy med avtale om forhøyet sats
   * passasjergodtgjørelse
   * diettsats uten overnatting 6 til 12 timer
   * diettsats uten overnatting over 12 timer
   * diettsats overnatting
   * stipend
   */
  travelAndExpenseType?: string | null;
}

export interface ApiCreateCourseAreaDto {
  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  ssbCode?: string | null;
  code?: string | null;
  isActive?: boolean | null;
}

export interface ApiCreateCourseDto {
  /**
   * Id til studieplan
   * @format int32
   */
  curriculumId: number;
  courseName?: string | null;
  externalTitle?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;
  /** @format int32 */
  studyLocationId?: number | null;
  /** @format date-time */
  cancellationDeadline?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  isTimeWebVisible: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /** @format int32 */
  hoursWithoutInstructor?: number | null;
  /** @format int32 */
  hoursOnline?: number | null;
  /** @format int32 */
  hoursSelfStudy?: number | null;
  /**
   * Kursansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  courseSupervisorId: number;
  /** @format int32 */
  organizerOrganizationId: number;
  /**
   * Fagansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  lecturerId: number;
  /**
   * Kontaktperson - kursadministratorbruker, ansatt.
   * @format int32
   */
  contactId: number;
  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format date-time */
  unenrollmentDeadline?: string | null;
  /** @format int32 */
  minimumParticipants: number;
  /** @format int32 */
  maxParticipants: number;
  /** @format int32 */
  participantCapacity: number;
  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiUpsertPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;
  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  tests?: ApiUpsertTestDtoType[] | null;
  comments?: string | null;
  webDescription?: ApiUpsertCourseDescriptionDto;
  economy?: ApiUpsertCourseEconomyDtoType;
  /** @format int32 */
  subOrganizerOrganizationId?: number | null;
  /** @format int32 */
  hoursOfDigitalEducation?: number | null;
}

export interface ApiCreateCourseInvitationDto {
  /** Fornavnet */
  firstName?: string | null;
  /** Etternavnet */
  lastName?: string | null;
  /** Eposten */
  emailAddress?: string | null;
  /** Telefonnummeret */
  phoneNumber?: string | null;
  /** Adressen (gateadresse og nummer) */
  postAddress?: string | null;
  /** Postnummeret */
  postZip?: string | null;
  /** Poststedet */
  postCity?: string | null;
  /** Kjønnet - K (Kvinne), M (Mann), A (Anonym) */
  sex?: string | null;
  /**
   * Fødselsår. Bør sendes inn i UTC-tid hvor tiden er 00:00:00 (e.g. 2007-03-23T00:00:00)
   * @format int32
   */
  birthYear?: number | null;
  /** Om det skal opprettes en begrenset bruker for personen hvis eposten ikke allerede finnes i systemet */
  createRestrictedUser: boolean;
  /** Om det skal sendes epost til brukeren om at de har mottatt en kursinvitasjon (vil ikke ble sendt for begrenset bruker) */
  sendEmail: boolean;
  /** Om det skal sendes sms til brukeren om at de har mottatt en kursinvitasjon (vil ikke ble sendt for begrenset bruker) */
  sendSms: boolean;
  /** @format int32 */
  memberOrganizationId?: number | null;
}

export interface ApiCreateCourseParticipantCommentDto {
  comment?: string | null;
  isGuestUserVisible: boolean;
}

export interface ApiCreateCourseParticipantTravelAndExpenseDto {
  /**
   * Course Id
   * @format int32
   */
  courseId: number;
  /** General description */
  description?: string | null;
  /**
   * Date
   * @format date-time
   */
  date: string;
  /** Bank account number */
  bankAccountNo?: string | null;
  /** Expenses and diet for travel to course */
  expenseLinesTo?: ApiExpenseLineDto[] | null;
  /** Expenses and diet for travel from course */
  expenseLinesFrom?: ApiExpenseLineDto[] | null;
  stipend?: ApiStipendDto;
}

export interface ApiCreateDraftDto {
  orderLines?: ApiCreateOrderLineDto[] | null;
  /** @format int32 */
  courseId: number;
  participant?: ApiOrderParticipantTemplateDto;
  invoiceRecipient?: ApiOrderInvoiceRecipientTemplateDto;
  /** Avdragslinjer (en liste, bare ved type 2) */
  orderPaymentLines?: ApiOrderPaymentLineDto[] | null;
  /** Internfakturering */
  isInternal: boolean;
  /** Kundens bestillingsnummer – (manuelt felt, PH nummer, kan være tom) */
  customerOrderNo?: string | null;
  /** Reise/utleggsreferanse – (Refusjon kurs ____)(max 30 tegn) */
  expenseRef?: string | null;
  /** Deres referanse – (manuelt) */
  theirRef?: string | null;
  /**
   * Motbilag – (ved kreditering sende PK fra originale ordrenummer)
   * @format int32
   */
  contraEntryOrderId?: number | null;
  /**
   * Avdragstype – (type 0, 1 eller 2)
   * Type 0: Ingen avdrag
   * Type 1: Fast intervall
   *     Antall avdrag
   *     Intervall(eks.antall mnd mellom hvert avdrag)
   * Type 2: Liste med avdrag(må gå opp i 100%)
   *     Dato
   *     Prosent
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;
  /**
   * Antall avdrag – (bare fylt ut dersom type 1)
   * @format int32
   */
  noOfPayments?: number | null;
  /**
   * Avdragsintervall – (bare fylt ut dersom type 1)
   * @format int32
   */
  paymentInverval?: number | null;
  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  contraEntryOrdersId?: number[] | null;
}

export interface ApiCreateEmployeeWorkingHourDto {
  /**
   * Id til ansatt kontraktslinjer som det skal skrives timer for
   * @format int32
   */
  employeeContractLineId: number;
  /**
   * Antall timer som skal registreres. Kan bli satt til nærmeste halvtime (i.e. 2.5)
   * @format double
   */
  amount: number;
  /**
   * <br>
   *              Hvis kostnadstypen for ansattskontraktlinjen er 'Undervisningstimer' så må denne datoen/tidspunktet tilsvare en dato/tidspunkt i timeplanen.
   *
   *              hvis kostnadstypen for ansattskontraktlinjen er ikk 'Unervisningstimer' så må denne datoen tilsvar en dag hvor UTC tidspunktet er
   *              00:00:00 på den angitte dagen og være for idag eller en dag i fortiden
   *
   * @format date-time
   */
  date: string;
  /** kommentaren til de registrerte timene. Kan være opptil 500 karakterer lang */
  comment?: string | null;
  /** Indikerer om arbeidstimen er en sykemelding. Kan kun registreres av en kursadministrator */
  isSickLeave?: boolean | null;
  /** Indikerer om arbeidstimen er kanselert ifh til slettet timeplanoppføring fra tidligere. Kan kun registreres av en kursadministrator */
  isCancelled?: boolean | null;
}

export interface ApiCreateFacilitationGrantApplicationDto {
  applicationDescription?: string | null;
  descriptionOfAdditionalCost?: string | null;
  targetGroup?: string | null;
  location?: string | null;
  /** @format double */
  numberOfHours: number;
  /** @format int32 */
  numberOfParticipants: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  budgetLines?: ApiFacilitationCostsAppliedForDto[] | null;
}

export interface ApiCreateFacilitationGrantApplicationDtoValidationEntry {
  validatedItem?: ApiCreateFacilitationGrantApplicationDto;
  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiCreateFacilitationGrantApplicationDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiCreateFacilitationGrantApplicationDtoValidationEntry[] | null;
}

export interface ApiCreateHolidayDetailDto {
  periodName?: string | null;
  /** @format date-time */
  fromDate: string;
  /** @format date-time */
  toDate: string;
}

export interface ApiCreateHolidayDto {
  name?: string | null;
  isActive: boolean;
  details?: ApiCreateHolidayDetailDto[] | null;
}

export interface ApiCreateMemberOrganizationDto {
  /** @format int32 */
  parentId?: number | null;
  isActive?: boolean | null;
  name?: string | null;
  orgNo?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible?: boolean | null;
  /** @format int32 */
  clientAccountNumber?: number | null;
  inheritOrgNoFromParent?: boolean | null;
}

export interface ApiCreateMemberOrganizationFeatureSettingDto {
  /**
   * Setting to control if a member-organization can have more than the standard 2 levels.
   * When set Backend sets the maximum lvl to 3. If needed this can be changed to go deeper or be an option
   * that the user could set in the Frontend.
   */
  hasMultiLevelMemberOrganization: boolean;
  /**
   * Setting to control if a course can use sub-organizer. When using sub-organizer this will be the approving
   * member organization. If used in conjunction with pre-approval the participant first needs to be pre-approved
   * before being approved.
   */
  canUseSubOrganizer: boolean;
  /**
   * Setting to control if a member-organization's participants have to be pre-approved for all courses.
   * The pre-approval organization will always be the lowest one in the hierarchy, in Fellesforbundet
   * this is level 3 (avdelinger).
   */
  usePreApprovalForCourses: boolean;
  /** Frontend setting to see if member organization should skip the check for enrollment deadline. */
  bypassEnrollmentDeadlineInGuestPortal: boolean;
}

export interface ApiCreateNewMessageMsDto {
  /** @format int32 */
  courseId: number;
  subject?: string | null;
  text?: string | null;
  attachments?: File[] | null;
}

export interface ApiCreateNewSystemCommunicationTemplateMessageMsDto {
  /** @format int32 */
  courseId: number;
  communicationTemplateTag?: string | null;
  dynamicVariables?: ApiStringStringKeyValuePair[] | null;
}

export interface ApiCreateOrderLineDto {
  /** Beskrivelse. Hvis oppgitt, overstyrer artikkelnavn */
  description?: string | null;
  /** Kan foreslås hentes fra CourseArticle */
  articleNo?: string | null;
  /**
   * @format double
   * @min 0
   * @max 10000000000000000
   */
  amount: number;
  /**
   * Rabatt
   * @format double
   * @min 0
   * @max 100
   */
  discount: number;
  /**
   * Antall – (positivt ved debitering, negativt ved kreditering)
   * @format double
   */
  quantity: number;
  user?: ApiOrderLineUserDto;
}

export interface ApiCreateRoleDto {
  name?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  isResource?: boolean | null;
  isForRegularCourse?: boolean | null;
  isForVocationalSchool?: boolean | null;
  cannotBeCombined?: boolean | null;
  requiresCertification?: boolean | null;
}

export interface ApiCreateSigningFileDto {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format int64 */
  fileId: number;
  /** @format date-time */
  signatureDeadline?: string | null;
}

export interface ApiCreateTeachingMaterialCategoryDto {
  name?: string | null;
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
}

export interface ApiCreateWorkingHourDto {
  /**
   * The id'en til kurskontraktlinjen som det skal skrives timer for
   * @format int32
   */
  courseContractLineId: number;
  /**
   * Antall timer som skal registreres. Kan bli satt ned til nærmeste halvtime (e.g. 2.5)
   * @format double
   */
  amount: number;
  /**
   * <br>
   *             Hvis kostnadstypen for kontraktlinjen er 'Undervisningstimer' så må denne datoen/tidspunktet tilsvare en dato/tidspunkt i timeplanen.
   *
   *             Hvis kostnadstypen for kontraktlinjen ikke er 'Undervisningstimer' så må denne datoen tilsvare en dag hvor
   *             UTC tidspunktet er 00:00:00 på den angitte dagen og være for i dag eller en dag i fortiden.
   *
   * @format date-time
   */
  date: string;
  /** Kommentaren til de registrerte timene. Kan være opptil 500 karakterer lang. */
  comment?: string | null;
  /** Indikerer om arbeidstimen er en sykemelding. Kan kun registreres av en kursadministrator */
  isSickLeave?: boolean | null;
  /** Indikerer om arbeidstimen er kanselert ifh til slettet timeplanoppføring fra tidligere. Kan kun registreres av en kursadministrator */
  isCancelled?: boolean | null;
}

export interface ApiCurriculum {
  /** @format int32 */
  id: number;
  name?: string | null;
  /** @format int32 */
  organizationId: number;
  /** @format int32 */
  memberOrganizationId?: number | null;
  /** @format int32 */
  courseAreaId: number;
  teachingMethod?: string | null;
  cefrLevel?: string | null;
  /** @format double */
  hoursMin: number;
  /** @format double */
  hoursMax: number;
  /** @format double */
  hoursOnline?: number | null;
  /** @format int32 */
  levelDescriptionId: number;
  /** @format int32 */
  points?: number | null;
  /** @format int32 */
  evaluationDescriptionId: number;
  /** @format int32 */
  statusDescriptionId: number;
  ssbCode?: string | null;
  /** @format double */
  hoursStudyLoad?: number | null;
  /** @format int32 */
  subjectCount?: number | null;
  practice?: boolean | null;
  nusCode?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseArea?: ApiCourseArea;
  curriculumDetail?: ApiCurriculumDetail;
  virNusCode?: ApiNusCode;
  course?: ApiCourse[] | null;
  curriculumCustomerOrganization?: ApiCurriculumCustomerOrganization[] | null;
  curriculumFile?: ApiCurriculumFile[] | null;
  curriculumTeachingMaterial?: ApiCurriculumTeachingMaterial[] | null;
  curriculumSubjectCodes?: ApiCurriculumSubjectCode[] | null;
  curriculumSubjectCodeCurriculums?: ApiCurriculumSubjectCodeCurriculum[] | null;
}

export interface ApiCurriculumCustomerOrganization {
  /** @format int32 */
  curriculumId: number;
  /** @format int32 */
  customerOrganizationId: number;
  curriculum?: ApiCurriculum;
  customerOrganization?: ApiCustomerOrganization;
}

export interface ApiCurriculumDetail {
  /** @format int32 */
  curriculumId: number;
  teacherRequired: boolean;
  teacherRequirements?: string | null;
  foreknowledgeRequired: boolean;
  foreknowledgeRequirements?: string | null;
  externalCurriculumUrl?: string | null;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  courseCertificateRequired: boolean;
  webContent?: string | null;
  targetGroup?: string | null;
  curriculum?: ApiCurriculum;
}

export interface ApiCurriculumDto {
  /** @format int32 */
  id: number;
  name?: string | null;
}

export interface ApiCurriculumFile {
  /** @format int32 */
  curriculumId: number;
  /** @format int64 */
  fileId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  curriculum?: ApiCurriculum;
  file?: ApiFile;
}

export interface ApiCurriculumSubjectCode {
  /** @format int32 */
  id: number;
  /** @format int32 */
  ownerOrganizationId: number;
  educationalOfferCode?: string | null;
  /** @format int32 */
  number: number;
  code?: string | null;
  name?: string | null;
  /** @format int32 */
  credits: number;
  /** @format int32 */
  hours: number;
  isActive: boolean;
  department?: ApiDepartment;
  curricula?: ApiCurriculum[] | null;
}

export interface ApiCurriculumSubjectCodeCurriculum {
  /** @format int32 */
  curriculumId: number;
  /** @format int32 */
  curriculumSubjectCodeId: number;
  /** @format int32 */
  rank: number;
  curriculumSubjectCode?: ApiCurriculumSubjectCode;
}

export enum ApiCurriculumSubjectCodeStateFilter {
  ApiAll = "All",
  ApiActive = "Active",
  ApiInactive = "Inactive",
}

export interface ApiCurriculumTeachingMaterial {
  /** @format int32 */
  teachingMaterialId: number;
  /** @format int32 */
  curriculumId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  curriculum?: ApiCurriculum;
  teachingMaterial?: ApiTeachingMaterial;
}

export interface ApiCustomerOrgDto {
  /** @format int32 */
  customerId: number;
  name?: string | null;
  orgNumber?: string | null;
  homePage?: string | null;
  isActive: boolean;
}

export interface ApiCustomerOrganization {
  /** @format int32 */
  userId: number;
  orgNumber?: string | null;
  homePage?: string | null;
  postAddress1?: string | null;
  postAddress2?: string | null;
  postAddress3?: string | null;
  postCity?: string | null;
  postZip?: string | null;
  postCountry?: string | null;
  invoiceAddress1?: string | null;
  invoiceAddress2?: string | null;
  invoiceAddress3?: string | null;
  invoiceCity?: string | null;
  invoiceZip?: string | null;
  invoiceCountry?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  user?: ApiUser;
  courseContract?: ApiCourseContract[] | null;
  curriculumCustomerOrganization?: ApiCurriculumCustomerOrganization[] | null;
  courseFinancedOrganization?: ApiCourseFinancedOrganization[] | null;
  courseParticipantPurchaser?: ApiCourseParticipant[] | null;
  courseParticipantInvoiceRecipient?: ApiCourseParticipant[] | null;
}

export interface ApiCustomerPerson {
  /** @format int32 */
  userId: number;
  /** @format uuid */
  adUserId?: string | null;
  ssno?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  postAddress?: string | null;
  postZip?: string | null;
  postCity?: string | null;
  postCountry?: string | null;
  sex?: string | null;
  termsOfUsage?: string | null;
  /** @format date-time */
  birthDate?: string | null;
  type?: string | null;
  bankAccountNo?: string | null;
  /** @format date-time */
  lastVerifiedContactInfoDate: string;
  lastApprovedTermsOfUsage?: string | null;
  receiveNewsletter: boolean;
  /** @format int32 */
  birthYear?: number | null;
  user?: ApiUser;
}

export interface ApiDeleteCourseParticipantCertificateDto {
  userIds?: number[] | null;
}

export interface ApiDepartment {
  /** @format int32 */
  organizationId: number;
  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  email?: string | null;
  tag?: string | null;
  isWebVisible: boolean;
  /** @format int32 */
  erpClientId?: number | null;
  organization?: ApiOrganization;
  ownedCourse?: ApiCourse[] | null;
  employee?: ApiEmployee[] | null;
  regulationFile?: ApiRegulationFile[] | null;
  studyLocation?: ApiStudyLocation[] | null;
  nusCode?: ApiNusCode[] | null;
}

export interface ApiEmployee {
  /** @format int32 */
  userId: number;
  /** @format int32 */
  organizationId: number;
  email?: string | null;
  phoneNumber?: string | null;
  /** @format uuid */
  adUserId?: string | null;
  /** @format int32 */
  erpEmployeeId?: number | null;
  department?: ApiDepartment;
  user?: ApiUser;
  coursesContact?: ApiCourse[] | null;
  coursesSupervisor?: ApiCourse[] | null;
  coursesLecturer?: ApiCourse[] | null;
  orderLines?: ApiOrderLine[] | null;
  workingHoursApproved?: ApiWorkingHour[] | null;
  workingHoursDeclined?: ApiWorkingHour[] | null;
  facilitationGrantApplicationsAppliedFor?: ApiFacilitationGrantApplication[] | null;
}

export interface ApiEmployeeContractLine {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format double */
  amount: number;
  description?: string | null;
  unit?: string | null;
  /** @format int32 */
  costId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseParticipant?: ApiCourseParticipant;
  cost?: ApiCost;
  employeeWorkingHour?: ApiEmployeeWorkingHour[] | null;
}

export interface ApiEmployeeWorkingHour {
  /** @format int64 */
  id: number;
  /** @format int32 */
  employeeContractLineId: number;
  /** @format date-time */
  date: string;
  /** @format double */
  amount: number;
  status?: string | null;
  comment?: string | null;
  isSickLeave?: boolean | null;
  isCancelled?: boolean | null;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updated: string;
  employeeContractLine?: ApiEmployeeContractLine;
}

export interface ApiEmployerDto {
  name?: string | null;
  address?: string | null;
  email?: string | null;
  zip?: string | null;
  city?: string | null;
}

export interface ApiEmploymentInformation {
  name?: string | null;
  email?: string | null;
  mobilePhone?: string | null;
  address?: string | null;
  postalCode?: string | null;
  city?: string | null;
  /** @format double */
  employmeentFraction: number;
  isCurrentEmployment: boolean;
}

export interface ApiEmploymentInformationDto {
  name?: string | null;
  email?: string | null;
  mobilePhone?: string | null;
  address?: string | null;
  postalCode?: string | null;
  city?: string | null;
  /** @format double */
  employmentFraction: number;
  isCurrentEmployment: boolean;
}

export interface ApiErpEmployeeDtoType {
  /** @format int32 */
  employeeNo: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  ssn?: string | null;
}

export interface ApiErrorDtoWithPointers {
  fieldsErrors?: ApiFieldErrorWithPointerDtoType[] | null;
}

export interface ApiExecutionPlanCertificateDtoType {
  courseCertificateRequired: boolean;
  gradeCertificateRequired: boolean;
  diplomaRequired: boolean;
}

export interface ApiExecutionPlanDto {
  info: ApiExecutionPlanInfoDtoType;
  plan: boolean;
  economyRequired: boolean;
  certificatesRequired: boolean;
  certificate: ApiExecutionPlanCertificateDtoType;
  teachingMaterialsRequired: boolean;
  testsRequired: boolean;
  testDescriptionRequired: boolean;
}

export interface ApiExecutionPlanInfoDtoType {
  reasonForStatusChangeRequired: boolean;
  cancellationDeadlineRequired: boolean;
  hoursWithoutInstructorRequired: boolean;
  hoursSelfStudyRequired: boolean;
  enrollmentDeadlineRequired: boolean;
  unenrollmentDeadlineRequired: boolean;
  minimumParticipantsRequired: boolean;
  maximumParticipantsRequired: boolean;
  availableParticipantsRequired: boolean;
  financiersRequired: boolean;
  priceRequired: boolean;
  additionalFilesRequired: boolean;
  groupIdRequired: boolean;
}

export interface ApiExpenseLineDto {
  /**
   * CostId for Car, passenger, diet etc.
   * @format int32
   */
  costId: number;
  /**
   * Quantity is used on costs with a set price
   * @format double
   */
  quantity?: number | null;
  /**
   * Amount is used on costs with no set price
   * @format double
   */
  amount?: number | null;
  origin?: ApiCourseParticipantTravelAndExpenseAddressDto;
  destination?: ApiCourseParticipantTravelAndExpenseAddressDto;
  attachmentIds?: number[] | null;
  description?: string | null;
  /** @format int32 */
  providedBreakfastQuantity?: number | null;
  /** @format int32 */
  providedLunchQuantity?: number | null;
  /** @format int32 */
  providedDinnerQuantity?: number | null;
  /** @format date-time */
  departure?: string | null;
  /** @format date-time */
  arrival?: string | null;
}

export interface ApiFacilitationActualCostDto {
  budgetLineType: ApiFacilitationGrantBudgetLineType;
  /** @format double */
  actualCost: number;
}

export interface ApiFacilitationCostGrantedDto {
  budgetLineType: ApiFacilitationGrantBudgetLineType;
  /** @format double */
  amountGranted: number;
}

export interface ApiFacilitationCostsAppliedForDto {
  budgetLineType: ApiFacilitationGrantBudgetLineType;
  /** @format double */
  amountAppliedFor: number;
}

export interface ApiFacilitationCostsDto {
  budgetLineType: ApiFacilitationGrantBudgetLineType;
  /** @format double */
  amountAppliedFor: number;
  /** @format double */
  amountGranted: number;
  /** @format double */
  amountActualCost: number;
}

export interface ApiFacilitationGrantApplication {
  /** @format int32 */
  courseId: number;
  applicationStatus?: string | null;
  /** @format int32 */
  applicantUserId: number;
  applicationDescription?: string | null;
  descriptionOfAdditionalCost?: string | null;
  targetGroup?: string | null;
  location?: string | null;
  /** @format double */
  numberOfHours: number;
  /** @format int32 */
  numberOfParticipants: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  commentToGrant?: string | null;
  commentToActualCost?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  insertedBy: number;
  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  budgetLines?: ApiFacilitationGrantApplicationBudgetLine[] | null;
  attachments?: ApiFacilitationGrantApplicationFile[] | null;
  applicantEmployee?: ApiEmployee;
}

export interface ApiFacilitationGrantApplicationBudgetLine {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  grantApplicationBudgetLineTypeId: number;
  /** @format double */
  amountAppliedFor: number;
  /** @format double */
  amountGranted: number;
  /** @format double */
  amountActualCost: number;
  facilitationGrantApplication?: ApiFacilitationGrantApplication;
}

export interface ApiFacilitationGrantApplicationFile {
  /** @format int32 */
  courseId: number;
  /** @format int64 */
  fileId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  facilitationGrantApplication?: ApiFacilitationGrantApplication;
  file?: ApiFile;
}

export enum ApiFacilitationGrantBudgetLineType {
  ApiExtraPersonnel = "ExtraPersonnel",
  ApiFacilitationOfProgramAndMaterial = "FacilitationOfProgramAndMaterial",
  ApiExtraPlanning = "ExtraPlanning",
  ApiExtraEquipment = "ExtraEquipment",
  ApiExtraTransport = "ExtraTransport",
  ApiOtherCosts = "OtherCosts",
}

export interface ApiFieldErrorWithPointerDtoType {
  field?: string | null;
  error?: string | null;
  ids?: number[] | null;
}

export interface ApiFile {
  /** @format int64 */
  id: number;
  originalFileName?: string | null;
  mimeType?: string | null;
  description?: string | null;
  externalFileName?: string | null;
  externalContainer?: string | null;
  externalUrl?: string | null;
  category?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  courseFile?: ApiCourseFile[] | null;
  courseParticipantFile?: ApiCourseParticipantFile[] | null;
  curriculumFile?: ApiCurriculumFile[] | null;
  courseContractFileSigned?: ApiCourseContractFile;
  courseContractFileUnsigned?: ApiCourseContractFile;
  regulationFile?: ApiRegulationFile;
  workingHourFile?: ApiWorkingHourFile;
  appFormResponseFile?: ApiAppFormResponseFile;
  appFormResponseAdditionalQuestion?: ApiAppFormResponseAdditionalQuestion;
  facilitationGrantApplicationFile?: ApiFacilitationGrantApplicationFile[] | null;
  courseCertificateFile?: ApiCourseCertificateFile;
  courseOfferletterFile?: ApiCourseParticipantOfferletterFile;
  travelAndExpenseFile?: ApiCourseParticipantTravelAndExpenseFile;
  signingFileUnsigned?: ApiSigningFile;
  signingFileSigned?: ApiSigningFile;
  clientAccountInvoiceBasisFile?: ApiClientAccountInvoiceBasisFile;
  courseParticipantPoliceCertificateFile?: ApiCourseParticipantPoliceCertificateFile;
}

export interface ApiFileOwnerDto {
  ownerName?: string | null;
  /** @format int32 */
  ownerId: number;
  ownerType?: string | null;
}

export interface ApiFormalCompetenciesDto {
  isNorwegianHealthAndSocialSciences?: boolean | null;
  isAuthorizationDiploma?: boolean | null;
  isForeignHealthAndSocialSciences?: boolean | null;
}

export interface ApiGenerateCourseParticipantCertificateDto {
  userIds?: number[] | null;
  showCoursePractice?: boolean | null;
  showAttendance?: boolean | null;
}

export interface ApiGenerateEmailVerificationCodeDto {
  /** The new email address to send verification code to */
  emailAddress?: string | null;
}

export interface ApiGenerateOrganizationOrderTemplateDto {
  /** @format int32 */
  courseId: number;
  /**
   * Bestiller - enten deltager eller firma
   * @format int32
   */
  customerUserId: number;
  /**
   * Fakturamottaker - enten deltager eller firma
   * @format int32
   */
  invoiceRecipientUserId: number;
  articles?: ApiArticleDto[] | null;
}

export interface ApiGenerateParticipantOrderTemplateDto {
  /** Deltagere - enten registrerte med ID eller navn. */
  userIds?: number[] | null;
  /** @format int32 */
  courseId: number;
  /**
   * Bestiller - enten deltager eller firma
   * @format int32
   */
  customerUserId: number;
  /**
   * Fakturamottaker - enten deltager eller firma
   * @format int32
   */
  invoiceRecipientUserId: number;
  articles?: ApiArticleDto[] | null;
}

export interface ApiGenerateTemplateDto {
  articleNos?: string[] | null;
  /** Deltagere - enten registrerte med ID eller navn. */
  userIds?: number[] | null;
  /** @format int32 */
  courseId: number;
  /**
   * Bestiller - enten deltager eller firma
   * @format int32
   */
  customerUserId: number;
  /**
   * Fakturamottaker - enten deltager eller firma
   * @format int32
   */
  invoiceRecipientUserId: number;
  articles?: ApiArticleDto[] | null;
}

export interface ApiGetAppFormResponseAdditionalQuestionDto {
  file?: ApiBaseGetFileDto;
  response?: string | null;
  /** @format uuid */
  questionId: string;
}

export interface ApiGetApplicationFormResponseDto {
  files?: ApiBaseGetFileDto[] | null;
  additionalQuestions?: ApiGetAppFormResponseAdditionalQuestionDto[] | null;
  costBearerPrivate?: ApiCostBearerPrivateDto;
  formalCompetencies?: ApiFormalCompetenciesDto;
  /** @format date-time */
  inserted?: string | null;
  /** @format date-time */
  updated?: string | null;
  /** @format uuid */
  templateId: string;
  employer?: ApiEmployerDto;
  isCostBearerPrivate?: boolean | null;
  costBearerOrganization?: ApiCostBearerOrganizationDto;
  purchaser?: ApiPurchaserOrganizationDto;
  isFormalCompetence?: boolean | null;
  isNorwegianCitizen?: boolean | null;
  citizenship?: string | null;
  isNorwegianMotherTongue?: boolean | null;
  norwegianSkillsLevel?: string | null;
  norwegianSkillsCerfLevel?: string | null;
  personalNumber?: string | null;
  isNewsletter?: boolean | null;
  isUnionized?: boolean | null;
  /** @format int32 */
  memberOrganizationId?: number | null;
  courseRegulationsAccepted?: boolean | null;
  /** @format int32 */
  regulationsFileId?: number | null;
}

export interface ApiGetApplicationFormTemplateDto {
  /** @format uuid */
  id: string;
  isActive: boolean;
  appliedInCourses?: number[] | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  name?: string | null;
  isVocationalSchool: boolean;
  isCitizenship: boolean;
  isPersonalNumber: boolean;
  isNorwegianMotherTongue: boolean;
  isNorwegianCertificationLevel: boolean;
  isNorwegianSkills: boolean;
  isCompetence: boolean;
  isEmployer: boolean;
  isEmployerEmail?: boolean | null;
  isCostBearer: boolean;
  isInstallments: boolean;
  isMonthly?: boolean | null;
  isSemiAnnually?: boolean | null;
  isQuarterly?: boolean | null;
  isInvoiceReference?: boolean | null;
  isNewsletter: boolean;
  isUnionized: boolean;
  isMemberOrganization: boolean;
  isRegionalOffices?: boolean | null;
  /** @format int32 */
  regulationFileId?: number | null;
  additionalQuestions?: ApiApplicationFormTemplateAdditionalQuestionDto[] | null;
}

export interface ApiGetAreaDto {
  /** @format int32 */
  id: number;
  country?: string | null;
  countryCode?: string | null;
  county?: string | null;
  municipality?: string | null;
  place?: string | null;
  studyLocationCode?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetChangedCourseParticipantApplicationDto {
  /** @format int32 */
  changeId: number;
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  /** @format int32 */
  applicantUserId: number;
  applicantName?: string | null;
  /** @format int32 */
  courseSupervisorId: number;
  courseSupervisorName?: string | null;
  /** @format int32 */
  lecturerId: number;
  lecturerName?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format int32 */
  guestUserId: number;
  guestUserName?: string | null;
  applicationStatus?: string | null;
  applicationStatusComment?: string | null;
  /** @format int32 */
  memberOrganizationId?: number | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
}

export interface ApiGetClientAccountCourseDto {
  /** @format int32 */
  id: number;
  courseName?: string | null;
  externalTitle?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format int32 */
  erpProductCode: number;
  /** @format int32 */
  ownerOrganizationId: number;
  ownerOrganizationName?: string | null;
  /** @format int32 */
  organizerOrganizationId: number;
  organizerOrganizationName?: string | null;
  /** @format int32 */
  accountingDepartmentOrganizationId: number;
  accountingDepartmentName?: string | null;
  /** @format int32 */
  accountingDepartmentErpId: number;
  status?: string | null;
  /** @format int32 */
  grantId: number;
  /** @format double */
  invoiceTotal?: number | null;
  /** True if any invoice basis has been sent to ERP */
  isSentToErp: boolean;
}

export interface ApiGetClientAccountInvoiceBasisDto {
  /** @format int32 */
  invoiceBasisNumber: number;
  /** @format int32 */
  courseId: number;
  status?: string | null;
  /** @format double */
  coursePrice: number;
  /** @format int32 */
  productNo: number;
  courseName?: string | null;
  accountingDepartment?: ApiOrganizationDto;
  /** @format date-time */
  courseStartDate: string;
  /** @format date-time */
  courseEndDate: string;
  courseOwner?: ApiOrganizationDto;
  courseOrganizer?: ApiOrganizationDto;
  grant?: ApiGrantDto;
  curriculum?: ApiCurriculumDto;
  /** @format int32 */
  numberOfParticipants: number;
  isLocal: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /**
   * Kostnader i følge regnskapsrapport
   * @format double
   */
  costInAccountingReport: number;
  /**
   * Endring
   * @format double
   */
  changeInCostInAccountingReport: number;
  /**
   * Ny sum kostnader i følge regnskapsrapport
   * @format double
   */
  totalCostInAccountingReport: number;
  /**
   * Bidrag fra OU Fond Administrasjon deltakere
   * @format double
   */
  contributionOuFondParticipants: number;
  /**
   * Bidrag fra OU Fond Administrasjon time
   * @format double
   */
  contributionOuFondHourly: number;
  /**
   * Bidrag fra OU Fond % av kostnader ifølge regnskapsrapport
   * @format double
   */
  contributionOuFondPercentageOfCosts: number;
  /**
   * Sum kostnadsfordeling
   * @format double
   */
  totalCostAllocation: number;
  /**
   * Kursavgift
   * @format double
   */
  totalCourseFee: number;
  /**
   * Fradrag statsstøtte
   * @format double
   */
  deductionGovernmentContribution: number;
  /**
   * Fakturer OU Fond
   * @format double
   */
  invoiceOuFond: number;
  /**
   * Andel AOF-Norge
   * @format double
   */
  shareAofNorge: number;
  /**
   * Bidrag administrasjon OU Fond (Bokføres kto: 3420)
   * @format double
   */
  contributionAdmOuFond: number;
  /**
   * Kostnadsdekning fra OU Fond (Bokføres kto: 7690)
   * @format double
   */
  costReimbursementFromOuFond: number;
  /**
   * Godskriv AOF – avdeling
   * @format double
   */
  creditAofDepartment: number;
  /**
   * Id for pdf som blir generert
   * @format int64
   */
  fileId?: number | null;
  /** Status for pdf som blir generert */
  generationStatus?: string | null;
  clientAccountInvoicePartBasis?: ApiClientAccountInvoicePartBasisDto[] | null;
  /** @format date-time */
  inserted?: string | null;
  /** @format int32 */
  insertedBy?: number | null;
  insertedByName?: string | null;
  /** @format date-time */
  updated?: string | null;
  /** @format int32 */
  updatedBy?: number | null;
  updatedByName?: string | null;
}

export interface ApiGetClientAccountRateDto {
  /** @format int32 */
  year: number;
  /** @format int32 */
  memberOrganizationId: number;
  memberOrganizationName?: string | null;
  /** @format int32 */
  clientAccountNumber?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByName?: string | null;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  updatedByName?: string | null;
  centralCourseRates?: ApiAccountRatesDto;
  localCourseRates?: ApiAccountRatesDto;
  productContributions?: ApiProductContributionDto;
  /** @format double */
  governmentContribution?: number | null;
  /** @format double */
  grantedAmount?: number | null;
  /** @format double */
  balance?: number | null;
  isActive: boolean;
}

export interface ApiGetClientAccountRateOverviewDto {
  /** @format int32 */
  year: number;
  /** @format date-time */
  lastUpdated: string;
  isActive: boolean;
}

export interface ApiGetCommunicationTemplateDto {
  /**
   * Id'en til kommunikasjonsmalen
   * @format int32
   */
  id: number;
  /** Taggen til kommunikasjonsmalen. Kun brukt for system-maler som har ekstra logikk knyttet til seg. */
  tag?: string | null;
  /**
   * Id'en til organisasjonen for malgruppen denne kommunikasjonsmalen er under
   * @format int32
   */
  communicationTemplateGroupOrganizationId?: number | null;
  /** Gruppenavnet til malgruppen denne kommunikasjonsmalen er under */
  communicationTemplateGroupName?: string | null;
  /** Typen til malgruppen denne kommunikasjonsmalen er under */
  communicationTemplateGroupType?: string | null;
  /** @format date-time */
  inserted?: string | null;
  /** @format int32 */
  insertedBy?: number | null;
  insertedByUserFullName?: string | null;
  /** @format date-time */
  updated?: string | null;
  /** @format int32 */
  updatedBy?: number | null;
  updatedByUserFullName?: string | null;
  /**
   * Id'en malgruppen denne kommunikasjonsmalen gjelder for
   * @format int32
   */
  communicationTemplateGroupId: number;
  /** Navnet til kommunikasjonsmalen */
  name?: string | null;
  /** Emnet til kommunikasjonsmalen */
  subject?: string | null;
  /** Innholdet til kommunikasjonsmalen */
  content?: string | null;
  /** Om kommunikasjonsmalen er aktiv eller ikke */
  isActive: boolean;
}

export interface ApiGetCommunicationTemplateGroupDto {
  /**
   * Id'en til malgruppen
   * @format int32
   */
  id: number;
  /** @format date-time */
  inserted?: string | null;
  /** @format int32 */
  insertedBy?: number | null;
  /** @format date-time */
  updated?: string | null;
  /** @format int32 */
  updatedBy?: number | null;
  /** Navnet til malgruppen */
  name?: string | null;
  /** Type malgruppe (e.g. system, bruker) */
  type?: string | null;
  /**
   * Id'en til organisasjonen for malgruppen
   * @format int32
   */
  organizationId?: number | null;
}

export interface ApiGetContractFileDto {
  /** @format int32 */
  courseContractId: number;
  /** @format int64 */
  signedFileId?: number | null;
  /** @format int64 */
  unsignedFileId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetContractLineDtoType {
  /**
   * Id'en til kontraktlinjen
   * @format int32
   */
  id: number;
  /**
   * Id'en til kontrakten
   * @format int32
   */
  courseContractId: number;
  /**
   * Id'en til kostnaden for kontraktlinjen
   * @format int32
   */
  costId: number;
  /** Navnet på kostnadstypen (type timer) for kostnaden til kontraktlinjen */
  costTypeName?: string | null;
  /** Hvilken type antallet er (vanligvis bare timer) */
  unit?: string | null;
  /**
   * Maks antall (vanligvis bare timer) for denne kontraktlinjen
   * @format double
   */
  amount: number;
  /**
   * Dette er antall timer minus alle registrerte arbeidstimer for denne kontraktlinjen uansett status på timene
   * @format double
   */
  remainingAmount: number;
  /**
   * Dette er antall timer minus alle registrerte arbeidstimer for denne kontraktlinjen som har status godkjent eller til utbetaling
   * @format double
   */
  remainingApprovedAmount: number;
  /**
   * Dette er antall arbeidstimer for denne kontraktlinjen som har status godkjent eller til utbetaling
   * @format double
   */
  approvedWorkingHours: number;
  /**
   * Dette er antall arbeidstimer for denne kontraktlinjen uansett status på timene
   * @format double
   */
  workingHours: number;
  /**
   * Prisen
   * @format double
   */
  price?: number | null;
  /** Beskrivelsen på kontraktlinjen */
  description?: string | null;
  /** Sammendrag av registrerte arbeidstimer for hver timeplanoppføring, vises bare for kontraktrslinjer som er knyttet til undervisningtimer */
  scheduleWorkingHours?: ApiScheduleWorkingHourDto[] | null;
  /** @format date-time */
  inserted: string;
  insertedBy?: string | null;
  /** @format date-time */
  updated: string;
  updatedBy?: string | null;
}

export interface ApiGetContractNotificationDto {
  type?: string | null;
  status?: string | null;
  /** @format date-time */
  sent: string;
}

export interface ApiGetContractSimpleDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  status?: string | null;
  statusText?: string | null;
  accountNo?: string | null;
  isSelfEmployed: boolean;
  isMission: boolean;
  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer: boolean;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;
  /**
   * The role per contract can be different than the course participant role.
   * E.g. a resource can have a contract for a role that is different than his usual role.
   */
  roleName?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  contractRequests?: ApiGetContractNotificationDto[] | null;
  isSigned: boolean;
  isStatusChangeable: boolean;
  isDeletable: boolean;
  isResendable: boolean;
}

export interface ApiGetContractWithFileDto {
  contractFile?: ApiGetContractFileDto;
  contractLines?: ApiGetShortContractLineDtoType[] | null;
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  status?: string | null;
  statusText?: string | null;
  accountNo?: string | null;
  isSelfEmployed: boolean;
  isMission: boolean;
  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer: boolean;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;
  /**
   * The role per contract can be different than the course participant role.
   * E.g. a resource can have a contract for a role that is different than his usual role.
   */
  roleName?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  contractRequests?: ApiGetContractNotificationDto[] | null;
  isSigned: boolean;
  isStatusChangeable: boolean;
  isDeletable: boolean;
  isResendable: boolean;
}

export interface ApiGetCostDto {
  /**
   * Id'en til kostnaden
   * @format int32
   */
  id: number;
  /**
   * Id'en til organisasjonen/avdelingen denne kostnaden er knyttet til
   * @format int32
   */
  organizationId: number;
  /**
   * Id'en til kostnadstypen
   * @format int32
   */
  costTypeId: number;
  /** Gruppen/typen til kostnadstypen */
  costTypeCostGroup?: string | null;
  /** Navnet til kostnadstypen (kategori) */
  costTypeName?: string | null;
  /** Kostnadstype / Navn */
  name?: string | null;
  /** Beskrivelse (kun brukt for utgifter) */
  description?: string | null;
  /**
   * Sats
   * @format double
   */
  price: number;
  /**
   * Lønnsart
   * @format int32
   */
  payType?: number | null;
  /** Arikkelnummer */
  articleNo?: string | null;
  /**
   * Trekkode
   * @format int32
   */
  deductionCode?: number | null;
  /** Undervisningsform */
  teachingMethod?: string | null;
  /** Status */
  isActive: boolean;
  /** Expense type for expenses */
  travelAndExpenseType?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCostTypeDto {
  /** @format int32 */
  id: number;
  costGroup?: string | null;
  name?: string | null;
  /** @format date-time */
  inserted: string;
}

export interface ApiGetCourseAreaDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  ssbCode?: string | null;
  code?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCourseCertificateDto {
  /** @format int32 */
  courseId: number;
  courseDefaultVariables?: ApiCourseDefaultVariablesDto;
  courseCustomVariables?: ApiCourseDefaultVariablesDto;
  /** Participants */
  courseParticipants?: ApiGetCourseParticipantCertificateDto[] | null;
}

export interface ApiGetCourseDefaultOptionDto {
  courseStatuses?: string[] | null;
  courseEnrollmentStatuses?: string[] | null;
  participantStatuses?: string[] | null;
  vocationalSchoolParticipantStatuses?: string[] | null;
  memberOrganizationParticipantStatuses?: string[] | null;
  courseCloseStatuses?: string[] | null;
}

export interface ApiGetCourseDescriptionBaseDto {
  /** @format uuid */
  templateId: string;
  webCategories?: string[] | null;
  intro?: string | null;
  targetGroup?: string | null;
  foreknowledgeRequirements?: string | null;
  documentationRequired: boolean;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  financing?: string | null;
  exam?: string | null;
  /** @format date-time */
  publishDate: string;
  /** @format date-time */
  unpublishDate?: string | null;
  enrollmentStatus?: string | null;
  defaultEnrollmentStudentStatus?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCourseDescriptionDto {
  /** @format int32 */
  courseId: number;
  /** @format uuid */
  templateId: string;
  webCategories?: string[] | null;
  intro?: string | null;
  targetGroup?: string | null;
  foreknowledgeRequirements?: string | null;
  documentationRequired: boolean;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  financing?: string | null;
  exam?: string | null;
  /** @format date-time */
  publishDate: string;
  /** @format date-time */
  unpublishDate?: string | null;
  enrollmentStatus?: string | null;
  defaultEnrollmentStudentStatus?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCourseDraftDtoType {
  content?: string | null;
  method?: string | null;
  teachingMaterials?: ApiGetTeachingMaterialDto[] | null;
}

export interface ApiGetCourseDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  externalTitle?: string | null;
  reasonForStatusChange?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;
  /** @format int32 */
  studyLocationId?: number | null;
  /** @format date-time */
  cancellationDeadline?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  isTimeWebVisible: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /** @format int32 */
  hoursWithoutInstructor?: number | null;
  /** @format int32 */
  hoursOnline?: number | null;
  /** @format int32 */
  hoursOfDigitalEducation?: number | null;
  /** @format int32 */
  hoursSelfStudy?: number | null;
  /**
   * Antall (timeplan)timer gjennomført (timeplan-timer som er i fortiden)
   * @format double
   */
  completedScheduledHours: number;
  /**
   * Antall (timeplan)timer gjenværende
   * @format double
   */
  remainingScheduledHours: number;
  /**
   * Totalt antall (timeplan)timer
   * @format double
   */
  totalScheduledHours: number;
  /** @format int32 */
  courseSupervisorId: number;
  /** @format int32 */
  organizerOrganizationId: number;
  /** @format int32 */
  ownerOrganizationId: number;
  /** @format int32 */
  lecturerId: number;
  /** @format int32 */
  contactId: number;
  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format date-time */
  unenrollmentDeadline?: string | null;
  /** @format int32 */
  minimumParticipants: number;
  /** @format int32 */
  maxParticipants: number;
  /**
   * Vises kun hvis bruker-context er en ressurs
   * @format int32
   */
  participantSubscribedCount?: number | null;
  /** @format int32 */
  participantCapacity: number;
  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiGetPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;
  /** Id'ene til læremidlene for studieplanen for dette kurset */
  curriculumTeachingMaterialIds?: number[] | null;
  tests?: ApiGetTestDtoType[] | null;
  comments?: string | null;
  economy?: ApiGetCourseEconomyDtoType;
  webDescription?: ApiGetCourseDescriptionBaseDto;
  teachingMethod?: string | null;
  /** @format int32 */
  mainCourseId?: number | null;
  /** @format int32 */
  facilitationCourseId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format int32 */
  subOrganizerOrganizationId?: number | null;
}

export interface ApiGetCourseEconomyDtoType {
  /** @format int32 */
  accountingDepartment?: number | null;
  /** @format double */
  cancellationFee?: number | null;
  /** @format double */
  price?: number | null;
  /**
   * Id til opplæringstilskudd
   * @format int32
   */
  grantId?: number | null;
  /** @format int32 */
  grantRateYear?: number | null;
  /** @format int32 */
  grantRateVersion?: number | null;
  allowExpenses?: boolean | null;
  articleNos?: string[] | null;
}

export interface ApiGetCourseInvitationDto {
  /**
   * Id'en til invitasjonen
   * @format int32
   */
  id: number;
  /**
   * Id'en til kurset invitasjonen gjelder for
   * @format int32
   */
  courseId: number;
  /** Fornavnet */
  firstName?: string | null;
  /** Etternavnet */
  lastName?: string | null;
  /** Eposten */
  emailAddress?: string | null;
  /** Telefonnummeret */
  phoneNumber?: string | null;
  /** Statusen på invitasjonen - new, approved, declined */
  status?: string | null;
  /** @format int32 */
  memberOrganizationId?: number | null;
  createRestrictedUser: boolean;
  /** @format date-time */
  inserted: string;
  course?: ApiCourseInfoDto;
}

export interface ApiGetCourseListDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  curriculumId: number;
  courseName?: string | null;
  externalTitle?: string | null;
  areaName?: string | null;
  /** @format int32 */
  organizerOrganizationId: number;
  organizerOrganizationName?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  status?: string | null;
  /** @format int32 */
  enrolledStudentsCount: number;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  teachingMethod?: string | null;
  /** @format int32 */
  courseSupervisorId: number;
  courseSupervisorName?: string | null;
  /** @format int32 */
  lecturerId: number;
  lecturerName?: string | null;
  /** @format int32 */
  ownerOrganizationId: number;
  ownerOrganizationName?: string | null;
  /** @format int32 */
  erpProductNo?: number | null;
  /** @format int32 */
  subOrganizerOrganizationId?: number | null;
}

export interface ApiGetCourseListDtoPagedResult {
  items?: ApiGetCourseListDto[] | null;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface ApiGetCourseNoteDto {
  /** @format int32 */
  id: number;
  title?: string | null;
  text?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
}

export interface ApiGetCourseParticipantAttendanceDto {
  /**
   * Id'en til kurset som fremmøtet gjelder for
   * @format int32
   */
  courseId: number;
  /**
   * Sluttdatoen og tidspunktet til timeplanen som fremmøtet gjelder for
   * @format date-time
   */
  scheduleEnd: string;
  /**
   * Antall timer mellom start- og sluttdatoen til timeplanen som fremmøtet gjelder for
   * @format double
   */
  scheduleHours: number;
  /** Brukerens fulle navn */
  userFullName?: string | null;
  /** Om fremmøtet er registrert av en bruker */
  isRegistered: boolean;
  /** Om fremmøtet er registrert av en KursAdmin-bruker */
  isSetByKursAdmin: boolean;
  /** @format date-time */
  inserted?: string | null;
  /** @format int32 */
  insertedBy?: number | null;
  /** @format date-time */
  updated?: string | null;
  /** @format int32 */
  updatedBy?: number | null;
  updatedByUserFullName?: string | null;
  /**
   * Startdatoen og tidspunktet til timeplanen som fremmøtet gjelder for
   * @format date-time
   */
  scheduleStart: string;
  /**
   * Id'en til brukeren som fremmøtet gjelder for
   * @format int32
   */
  userId: number;
  /**
   * Antall timer brukeren var tilstede
   * @format double
   */
  noOfHours: number;
  /** Kommentaren til fremmøtet */
  comment?: string | null;
}

export interface ApiGetCourseParticipantCertificateDto {
  /** @format int32 */
  userId: number;
  userFullName?: string | null;
  participantStatus?: string | null;
  hasCertificate: boolean;
  /**
   * Generering startet,
   * Generering feilet,
   * Generert,
   * Sendt,
   * Sendt manuelt
   */
  certificateStatus?: string | null;
  certificateMessage?: string | null;
  /** @format int64 */
  certificateFileId?: number | null;
  /** @format double */
  attendancePercentage: number;
  userDefaultVariables?: ApiUserDefaultVariablesDto;
  customVariables?: ApiCourseCertificateParticipantVariablesDto;
}

export interface ApiGetCourseParticipantCommentDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  userId: number;
  /** @format int32 */
  courseId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
  comment?: string | null;
  isGuestUserVisible: boolean;
}

export interface ApiGetCourseParticipantDto {
  course?: ApiGetCourseDto;
  userFullName?: string | null;
  isActive: boolean;
  /**
   * Vises kun hvis bruker-context er en ressurs.
   * Hvis kontraktstatusen ikke er signert så vil den kjøre en statussjekk mot Signicat før denne verdien fylles.
   */
  isContractSigned?: boolean | null;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @deprecated */
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  requiresElectronicInvoice: boolean;
  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  applicationDate?: string | null;
}

export interface ApiGetCourseParticipantDtoType {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @deprecated */
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  requiresElectronicInvoice: boolean;
  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  applicationDate?: string | null;
}

export interface ApiGetCourseParticipantEmploymentAggregatedDataDto {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  fullName?: string | null;
  haveEmployeer: boolean;
  /** @format int32 */
  numberOfEmployeers: number;
  /** @format double */
  percentageOfEmployment: number;
}

export interface ApiGetCourseParticipantEmploymentDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  firstName?: string | null;
  lastName?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  insertedBy: number;
  /** @format int32 */
  updatedBy: number;
  employment?: ApiEmploymentInformationDto;
}

export interface ApiGetCourseParticipantKursAdminDto {
  userType?: string | null;
  userTypeType?: string | null;
  latestCourseParticipantComment?: string | null;
  purchaser?: ApiCustomerOrgDto;
  invoiceRecipient?: ApiCustomerOrgDto;
  /** @format int32 */
  rank?: number | null;
  /**
   * Attendance percentage for the scheduled hours including today
   * @format double
   */
  currentAttendancePercentage?: number | null;
  course?: ApiGetCourseDto;
  userFullName?: string | null;
  isActive: boolean;
  /**
   * Vises kun hvis bruker-context er en ressurs.
   * Hvis kontraktstatusen ikke er signert så vil den kjøre en statussjekk mot Signicat før denne verdien fylles.
   */
  isContractSigned?: boolean | null;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @deprecated */
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  requiresElectronicInvoice: boolean;
  /** @format int32 */
  memberOrganizationId?: number | null;
  appliedViaForm: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  applicationDate?: string | null;
}

export interface ApiGetCourseParticipantOfferletterDto {
  /** @format int64 */
  fileId?: number | null;
  /** @format int32 */
  userId: number;
  /** @format int32 */
  signingFileId?: number | null;
  userFullName?: string | null;
  generationStatus?: string | null;
  signatureStatus?: string | null;
  /** @format date-time */
  offerDeadLine?: string | null;
  missingBirthDate: boolean;
  decisionText?: string | null;
  ourReferance?: string | null;
  /** @format int32 */
  practicalHourAmount?: number | null;
  showPoliceCertificate?: boolean | null;
  showReference?: boolean | null;
  isSigned: boolean;
  isStatusChangeable: boolean;
  isDeletable: boolean;
  isResendable: boolean;
  isCancellable: boolean;
}

export interface ApiGetCourseParticipantStatusDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  courseStatus?: string | null;
  participantStatus?: string | null;
  roleName?: string | null;
}

export interface ApiGetCourseParticipantTravelAndExpenseDto {
  /**
   * Course Id
   * @format int32
   */
  courseId: number;
  /** Course external title */
  externalTitle?: string | null;
  /**
   * Id of the user
   * @format int32
   */
  userId: number;
  /** Full name of the user */
  userFullName?: string | null;
  /** General description */
  description?: string | null;
  /**
   * Date
   * @format date-time
   */
  date: string;
  /** Bank account number */
  bankAccountNo?: string | null;
  /** Expenses and diet for travel to course */
  expenseLinesTo?: ApiGetExpenseLineDto[] | null;
  /** Expenses and diet for travel from course */
  expenseLinesFrom?: ApiGetExpenseLineDto[] | null;
  stipend?: ApiGetStipendDto;
  /** Status */
  status?: string | null;
  /** Comment */
  comment?: string | null;
}

export interface ApiGetCourseParticipantTravelAndExpenseListDto {
  /**
   * Expense Id
   * @format int32
   */
  id: number;
  /**
   * Course Id
   * @format int32
   */
  courseId: number;
  /** Course external title */
  externalTitle?: string | null;
  /**
   * Id of the user
   * @format int32
   */
  userId: number;
  /** Full name of the user */
  userFullName?: string | null;
  /** General description */
  description?: string | null;
  /**
   * Date
   * @format date-time
   */
  date: string;
  /** Status */
  status?: string | null;
  /**
   * Total amount
   * @format double
   */
  totalAmount: number;
}

export interface ApiGetCourseRegulationFileDto {
  /** @format int32 */
  organizationId: number;
  /** @format int64 */
  id?: number | null;
  originalFileName?: string | null;
  description?: string | null;
  mimeType?: string | null;
  category?: string | null;
  isAdminRead?: boolean | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
}

export interface ApiGetCourseRelatedFileDto {
  fileOwners?: ApiFileOwnerDto[] | null;
  isSignable: boolean;
  /** @format int32 */
  singingFileId?: number | null;
  signingFileStatus?: string | null;
  /** @format int64 */
  id?: number | null;
  originalFileName?: string | null;
  description?: string | null;
  mimeType?: string | null;
  category?: string | null;
  isAdminRead?: boolean | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
}

export interface ApiGetCourseResourceDto {
  /** @format int32 */
  userId: number;
  name?: string | null;
  mobile?: string | null;
  email?: string | null;
  /** @deprecated */
  roleName?: string | null;
  roleNames?: string[] | null;
  /** @format double */
  noTeachingHours: number;
  /**
   * @deprecated
   * @format double
   */
  noRemainingTeachingHours: number;
  /** @format double */
  registeredWorkingHours: number;
  contractStatus?: Record<string, string | null>;
  /** C = customer, E = employee */
  userType?: string | null;
}

export interface ApiGetCourseScheduleDtoType {
  /** The title/name of the schedule */
  title?: string | null;
  /**
   * The start date/time of the schedule
   * @format date-time
   */
  start: string;
  /**
   * The end date/time of the schedule
   * @format date-time
   */
  end: string;
  /** Whether all non-resource course participants have their attendances registered for this schedule entry */
  isAllAttendancesRegistered: boolean;
  /**
   * Number of registered participants excluding resources
   * @format int32
   */
  expectedAttendantCount: number;
  /**
   * Hours of registered participant attendances
   * @format double
   */
  registeredAttendanceHours: number;
  /** Whether any working hours are referencing this schedule entry */
  hasAnyWorkingHourReferences?: boolean | null;
  /** Whether any registered attendances are referencing this schedule entry */
  hasAnyAttendanceReferences: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetCoursesAndNewDocumentsDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  courseSupervisorName?: string | null;
  /** @format int32 */
  courseSupervisorId: number;
  courseLecturerName?: string | null;
  /** @format int32 */
  courseLecturerId: number;
  /** @format date-time */
  courseStartDate?: string | null;
  courseLocation?: string | null;
  newDocumentParticipants?: ApiParticipantNewDocumentDto[] | null;
}

export interface ApiGetCurrentEmployeeDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  organizationId: number;
  organizationName?: string | null;
  /** @format int32 */
  currentOrganizationId: number;
  currentOrganizationName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export interface ApiGetCurriculumDefaultOptionDto {
  departments?: ApiMetadataDtoInfoType[] | null;
  memberOrganisations?: ApiMetadataDtoInfoType[] | null;
  courseAreas?: ApiHierarchicalAreaDtoType[] | null;
  courseLevels?: ApiMetadataDtoInfoType[] | null;
  courseEvaluations?: ApiMetadataDtoInfoType[] | null;
  courseStatuses?: ApiMetadataDtoInfoType[] | null;
  ssbCourses?: ApiMetadataDtoInfoType[] | null;
  teachingMethods?: string[] | null;
  cefrLevels?: string[] | null;
}

export interface ApiGetCurriculumDto {
  /** @format int32 */
  id: number;
  /** Point 1 */
  name?: string | null;
  ownerDepartmentId?: number[] | null;
  /** @format int32 */
  orgId: number;
  /** @format int32 */
  memberOrgId?: number | null;
  /** @format int32 */
  mainCourseId: number;
  /** @format int32 */
  subCourseId: number;
  ssbCode?: string | null;
  cefrLevel?: string | null;
  /** @format double */
  hoursMin: number;
  /** @format double */
  hoursMax: number;
  /** @format double */
  hoursOnline?: number | null;
  /** @format double */
  hoursStudyLoad?: number | null;
  /** @format int32 */
  levelId: number;
  /** @format int32 */
  points?: number | null;
  /** @format int32 */
  evaluationId: number;
  /** @format int32 */
  statusId: number;
  nusCode?: string | null;
  nusCodeName?: string | null;
  /** Point 2 */
  teacherRequired: boolean;
  teacherRequirements?: string | null;
  /** Point 3 */
  foreknowledgeRequired: boolean;
  foreknowledgeRequirements?: string | null;
  targetGroup?: string | null;
  /** Point 4 */
  externalCurriculumUrl?: string | null;
  learningObjectives?: string | null;
  course?: ApiGetCourseDraftDtoType;
  /** Point 6 */
  courseCertificateRequired: boolean;
  /** Point 7 */
  webContent?: string | null;
  teachingMethod?: string | null;
  vocationalSchool?: ApiVocationalSchoolDtoType;
  partners?: ApiCustomerOrgDto[] | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  subjectCodes?: ApiGetRankedCurriculumSubjectCodeDto[] | null;
}

export interface ApiGetCurriculumFileDto {
  fileOwners?: ApiFileOwnerDto[] | null;
  /** @format int64 */
  id?: number | null;
  originalFileName?: string | null;
  description?: string | null;
  mimeType?: string | null;
  category?: string | null;
  isAdminRead?: boolean | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUserFullName?: string | null;
}

export interface ApiGetCurriculumSubjectCodeDto {
  /**
   * Id'en til emnekoden
   * @format int32
   */
  id: number;
  educationalOfferCode?: string | null;
  /** @format int32 */
  subjectNumber: number;
  subjectCode?: string | null;
  subjectName?: string | null;
  /** @format int32 */
  credits: number;
  /** @format int32 */
  subjectHours: number;
  isActive: boolean;
}

export interface ApiGetCustomerDto {
  /** @format int32 */
  id: number;
  /** @format uuid */
  adUserId?: string | null;
  postAddress1?: string | null;
  postAddress2?: string | null;
  postAddress3?: string | null;
  postCity?: string | null;
  postZip?: string | null;
  postCountry?: string | null;
  invoiceAddress1?: string | null;
  invoiceAddress2?: string | null;
  invoiceAddress3?: string | null;
  invoiceCity?: string | null;
  invoiceZip?: string | null;
  invoiceCountry?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  bankAccountNo?: string | null;
  email?: string | null;
  isActive: boolean;
  /** @format date-time */
  insertDate: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUser?: string | null;
  /** @format date-time */
  updateDate: string;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: string | null;
  /**
   * The date the user last verified their contact info (Email, MobileNumber, PhoneNumber)
   * @format date-time
   */
  lastVerifiedContactInfoDate: string;
  customer?: any;
}

export interface ApiGetCustomerOrganizationAddressListDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  orgNumber?: string | null;
  email?: string | null;
  mobileNumber?: string | null;
  isActive: boolean;
}

export interface ApiGetCustomerOrganizationAddressListDtoPagedResult {
  items?: ApiGetCustomerOrganizationAddressListDto[] | null;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface ApiGetCustomerPersonAddressListDto {
  /** @format int32 */
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  mobileNumber?: string | null;
  customerPersonType?: string | null;
  isActive: boolean;
}

export interface ApiGetCustomerPersonAddressListDtoPagedResult {
  items?: ApiGetCustomerPersonAddressListDto[] | null;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface ApiGetCustomerPersonDto {
  /** @format int32 */
  id: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /**
   * The date the user last verified their contact info (Email, MobileNumber, PhoneNumber)
   * @format date-time
   */
  lastVerifiedContactInfoDate: string;
  type?: string | null;
  /** @format uuid */
  adUserId?: string | null;
  isActive: boolean;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  /** @format date-time */
  birthDate?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  postAddress1?: string | null;
  postZip?: string | null;
  postCity?: string | null;
  postCountry?: string | null;
  gender?: string | null;
  bankAccountNo?: string | null;
  /** The social security number (personnummer) for the user */
  ssno?: string | null;
  lastApprovedTermsOfUsage?: string | null;
  receiveNewsletter: boolean;
  /** @format int32 */
  birthYear?: number | null;
}

export interface ApiGetDashboardContractDto {
  /**
   * Antall Usignerte kontrakter
   * @format int32
   */
  unsignedContracts: number;
  /**
   * Antall feilede kontrakter og kontrakter med utløpte signaturer
   * Disse statusene er
   * SYSCO.Universum.Api.Dtos.ContractStatusesDtoTypes.FAILED_PDF_GENERATIONVALUESYSCO.Universum.Api.Dtos.ContractStatusesDtoTypes.FAILED_SENDING_TO_MIDDLEWARESYSCO.Universum.Api.Dtos.ContractStatusesDtoTypes.FAILED_SENDING_TO_SIGNICATSYSCO.Universum.Api.Dtos.ContractStatusesDtoTypes.DOWNLOAD_FAILED
   * @format int32
   */
  failedAndExpiredContracts: number;
}

export interface ApiGetDashboardCourseAttendanceDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  /** BrukerId til deltakere for dette kurset med oppmøte under den angitte grensen (standard 80%). */
  courseParticipantsWithLowAttendance?: number[] | null;
}

export interface ApiGetDashboardCourseContractDto {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format int32 */
  courseContractId: number;
  courseName?: string | null;
  userFullName?: string | null;
  courseContractStatus?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
}

export interface ApiGetDashboardDto {
  /**
   * Antallet søkere for kurs som brukeren er kursadministrator for.
   * Definisjonen av en søker er at deltakeren har status SYSCO.Universum.Common.Constants.CourseParticipantStatus.APPLIED, SYSCO.Universum.Common.Constants.CourseParticipantStatus.SUBSCRIBED eller SYSCO.Universum.Common.Constants.CourseParticipantStatus.WAITINGLIST
   * @format int32
   */
  courseParticipantAppliedCount: number;
  /**
   * Antall studieplaner som ikke er godkjent enda.
   * Dette er studieplaner med status SYSCO.Universum.Common.Constants.CurriculumStatusDescriptionId.FOR_APPROVAL
   * @format int32
   */
  curriculumForApprovalCount: number;
  /**
   * Antall reise/stipend - som ikke er godkjent enda.
   * Dette er reise/stipend med status SYSCO.Universum.Api.Dtos.CourseParticipantTravelAndExpense.CourseParticipantTravelAndExpenseStatusDtoType.FORAPPROVAL
   * @format int32
   */
  courseParticipantExpenseForApprovalCount: number;
  /**
   * Antall arbeidstimeoppføringer (en oppføring kan ha flere timer) som ikke er godkjent enda.
   * Dette er arbeidstimeoppføringer med status SYSCO.Universum.Common.Constants.WorkingHourStatus.NOT_APPROVED_VALUE
   * @format int32
   */
  workingHourEntriesForApprovalCount: number;
  /**
   * Antallet kurs som brukeren er kursadministrator for med en eller flere deltakere med oppmøte under den angitte grensen (standard 80%).
   * @format int32
   */
  courseWithLowAttendanceCount: number;
  coursesWithParticipantsWithLowAttendance?: ApiGetDashboardCourseAttendanceDto[] | null;
  /**
   * Antallet påmeldinger som ikke er merket som lest
   * @format int32
   */
  unreadCourseApplicationsCount: number;
  /**
   * Antallet nye dokumenter lastet opp av deltakere som ikke er merket som "sett"
   * @format int32
   */
  unreadNewDocumentsCount: number;
  /**
   * Antallet deltakere som er endret av gjestebruker
   * @format int32
   */
  changedCourseParticipantApplicationsCount: number;
  /**
   * Antall 20/25 kurs som er avslutten som enten ikke har et fakturagrunnlag eller
   * @format int32
   */
  nonJournaledOuFondClosedCourses: number;
  unsigedAndFailedExpiredContractsContracts?: ApiGetDashboardContractDto;
}

export interface ApiGetDepartmentDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  parentId?: number | null;
  isActive: boolean;
  isEconomyActive: boolean;
  name?: string | null;
  email?: string | null;
  isWebVisible: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetEfactorStudentDto {
  CUC_CRS_ID?: string | null;
  CUC_CRS_NAME?: string | null;
  CUC_ID?: string | null;
  CUC_CUCP_GIVENNAME?: string | null;
  CUC_CUCP_FAMILYNAME?: string | null;
  CUC_PHONE?: string | null;
  CUC_EMAIL?: string | null;
  CUC_CCP_STATUS_DESC?: string | null;
  CUC_CRS_STATUS_DESC?: string | null;
  CUC_CUA_ADR1?: string | null;
  CUC_CUA_ADR2?: string | null;
  CUC_CUA_ADR3?: string | null;
  CUC_CUA_ZIP?: string | null;
  CUC_CUA_ZIPPLACE?: string | null;
}

export interface ApiGetEmployeeContractLineDtoType {
  /**
   * Id'en til aof ansatt kontraktlinjen
   * @format int32
   */
  id: number;
  /**
   * Id'en til kontrakten alltid null for en aof ansatt
   * @format int32
   */
  courseContractId?: number | null;
  /**
   * Id'en til kostnadsgruppen for aof-ansatt kontraktlinjen
   * @format int32
   */
  costId: number;
  /** Navnet og gruppen på kostnadstypen (type timer) for kostnadsgruppen til aof-ansatt kontraktlinjen */
  costTypeName?: string | null;
  /** Hvilken type antallet er (vanligvis bare timer) */
  unit?: string | null;
  /**
   * Maks antall (vanligvis bare timer) for denne aof-ansatt kontraktlinjen
   * @format double
   */
  amount: number;
  /**
   * Dette er antall timer minus alle registrerte arbeidstimer for denne aof-ansatt kontraktlinjen uansett status på timene
   * @format double
   */
  remainingAmount: number;
  /**
   * Dette er antall timer minus alle registrerte arbeidstimer for denne aof-ansatt kontraktlinjen som har status godkjent eller til utbetaling
   * @format double
   */
  remainingApprovedAmount: number;
  /**
   * Dette er antall arbeidstimer for denne aof-ansatt kontraktlinjen som har status godkjent eller til utbetaling
   * @format double
   */
  approvedWorkingHours: number;
  /**
   * Dette er antall arbeidstimer for denne aof-ansatt kontraktlinjen uansett status på timene
   * @format double
   */
  workingHours: number;
  /**
   * Prisen vil alltid være null for en aof-ansatt kontraktlinje
   * @format double
   */
  price?: number | null;
  /** Beskrivelsen på kontraktlinjen */
  description?: string | null;
  /** Sammendrag av registrerte arbeidstimer for hver timeplanoppføring */
  scheduleWorkingHours?: ApiScheduleWorkingHourDto[] | null;
  /** @format date-time */
  inserted: string;
  insertedBy?: string | null;
  /** @format date-time */
  updated: string;
  updatedBy?: string | null;
}

export interface ApiGetEmployeeDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  organizationId: number;
  email?: string | null;
  phoneNumber?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUser?: string | null;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export interface ApiGetEmployeeWorkingHourDto {
  /**
   * Id'en til arbeidstimen
   * @format int64
   */
  id: number;
  /**
   * Id'en til kurset som kontraktlinjen for arbeidstimen er skrevet for
   * @format int32
   */
  courseId: number;
  /** Navnet til kurset som kontraktlinjen for arbeidstimen er skrevet for */
  courseName?: string | null;
  /**
   * Id'en til Visma Global for kurset sin eier-avdeling
   * @format int32
   */
  courseOwnerOrganizationErpClientId?: number | null;
  /**
   * Id'en til kurset sin regnskapsavdeling
   * @format int32
   */
  courseEconomyAccountingDepartment?: number | null;
  /**
   * Produktnummeret til kursområdet til kurset sin studieplan
   * @format int32
   */
  courseAreaErpProductNo?: number | null;
  /**
   * Id'en til brukeren som timen (og kontrakten) gjelder for
   * @format int32
   */
  userId: number;
  /** Status for arbeidstimen */
  status?: string | null;
  /** Status for arbeidstimen på norsk */
  statusText?: string | null;
  /** Brukerens fulle navn */
  userFullName?: string | null;
  /**
   * Ansattnummeret til brukeren for denne avdelingen i Visma Lønn
   * @format int32
   */
  erpPayrollEmployeeId?: number | null;
  /** Beskrivelsen på kontraktlinjen */
  courseContractLineDescription?: string | null;
  /** Navnet på kostnadstypen (type timer) for kostnaden til kontraktlinjen */
  costTypeName?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /**
   * Id til ansatt kontraktslinjer som det skal skrives timer for
   * @format int32
   */
  employeeContractLineId: number;
  /**
   * Antall timer som skal registreres. Kan bli satt til nærmeste halvtime (i.e. 2.5)
   * @format double
   */
  amount: number;
  /**
   * <br>
   *              Hvis kostnadstypen for ansattskontraktlinjen er 'Undervisningstimer' så må denne datoen/tidspunktet tilsvare en dato/tidspunkt i timeplanen.
   *
   *              hvis kostnadstypen for ansattskontraktlinjen er ikk 'Unervisningstimer' så må denne datoen tilsvar en dag hvor UTC tidspunktet er
   *              00:00:00 på den angitte dagen og være for idag eller en dag i fortiden
   *
   * @format date-time
   */
  date: string;
  /** kommentaren til de registrerte timene. Kan være opptil 500 karakterer lang */
  comment?: string | null;
  /** Indikerer om arbeidstimen er en sykemelding. Kan kun registreres av en kursadministrator */
  isSickLeave?: boolean | null;
  /** Indikerer om arbeidstimen er kanselert ifh til slettet timeplanoppføring fra tidligere. Kan kun registreres av en kursadministrator */
  isCancelled?: boolean | null;
}

export interface ApiGetExecutionPlanDto {
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  info: ApiExecutionPlanInfoDtoType;
  plan: boolean;
  economyRequired: boolean;
  certificatesRequired: boolean;
  certificate: ApiExecutionPlanCertificateDtoType;
  teachingMaterialsRequired: boolean;
  testsRequired: boolean;
  testDescriptionRequired: boolean;
}

export interface ApiGetExpenseLineDto {
  /**
   * Quantity is used on costs with a set price
   * @format double
   */
  quantity?: number | null;
  /**
   * Amount is used on costs with no set price
   * @format double
   */
  amount?: number | null;
  origin?: ApiCourseParticipantTravelAndExpenseAddressDto;
  destination?: ApiCourseParticipantTravelAndExpenseAddressDto;
  /** @format int32 */
  providedBreakfastQuantity?: number | null;
  /** @format int32 */
  providedLunchQuantity?: number | null;
  /** @format int32 */
  providedDinnerQuantity?: number | null;
  /** @format double */
  subtractedDietAmount?: number | null;
  /** @format date-time */
  departure?: string | null;
  /** @format date-time */
  arrival?: string | null;
  /** @format int32 */
  costId: number;
  costName?: string | null;
  attachments?: ApiGetFileDto[] | null;
  description?: string | null;
  /**
   * The calculated total amount of the cost line
   * @format double
   */
  totalAmount: number;
}

export interface ApiGetFacilitationGrantApplicationDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  courseSupervisorName?: string | null;
  applicantName?: string | null;
  applicationStatus?: string | null;
  applicationDescription?: string | null;
  descriptionOfAdditionalCost?: string | null;
  targetGroup?: string | null;
  location?: string | null;
  /** @format double */
  numberOfHours: number;
  /** @format int32 */
  numberOfParticipants: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  commentToGrant?: string | null;
  commentToActualCost?: string | null;
  /** @format int32 */
  departmentId: number;
  departmentName?: string | null;
  budgetLines?: ApiFacilitationCostsDto[] | null;
}

export interface ApiGetFileDefaultOptionDto {
  categories?: string[] | null;
}

export interface ApiGetFileDto {
  /** @format int64 */
  id: number;
  fileName?: string | null;
  description?: string | null;
}

export interface ApiGetGrantDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  governmentFunded: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetGrantRateDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  year: number;
  /** @format int32 */
  version: number;
  /** @format double */
  teachingGrantAmount: number;
  /** @format double */
  extraProfessionalCourseAmount: number;
  /** @format date-time */
  inserted?: string | null;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated?: string | null;
  /** @format int32 */
  updatedBy: number;
  updatedByUserFullName?: string | null;
  insertedByUserFullName?: string | null;
}

export interface ApiGetGuestEmployeeDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  organizationId: number;
  email?: string | null;
  phoneNumber?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUser?: string | null;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export interface ApiGetGuestEmployeewithMemberOrganizationDto {
  /** @format int32 */
  id: number;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  insertedByUser?: string | null;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  updatedByUser?: string | null;
  memberOrganization?: ApiGetMemberOrganizationDto;
}

export interface ApiGetGuestUserDashboardDto {
  /** @format int32 */
  participantApplicationCount: number;
  /** @format int32 */
  preApprovedParticipantCount: number;
}

export interface ApiGetHierarchicalMemberOrganizationDto {
  children?: ApiGetHierarchicalMemberOrganizationDto[] | null;
  /** @format int32 */
  id: number;
  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  orgNo?: string | null;
  ssbId?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible: boolean;
  isActive: boolean;
  /** @format int32 */
  clientAccountNumber?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  inheritOrgNoFromParent?: boolean | null;
}

export interface ApiGetHolidayDetailDto {
  /** @format int32 */
  id: number;
  periodName?: string | null;
  /** @format date-time */
  fromDate: string;
  /** @format date-time */
  toDate: string;
}

export interface ApiGetHolidayDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  isActive: boolean;
  details?: ApiGetHolidayDetailDto[] | null;
}

export interface ApiGetKursAdminCourseDto {
  /**
   * Antall arbeidstimer som ikke er godkjent enda
   * @format double
   */
  unapprovedWorkingHours: number;
  facilitationGrantApplication?: ApiGetFacilitationGrantApplicationDto;
  isCommissionedCourse: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  externalTitle?: string | null;
  reasonForStatusChange?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;
  /** @format int32 */
  studyLocationId?: number | null;
  /** @format date-time */
  cancellationDeadline?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  isTimeWebVisible: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /** @format int32 */
  hoursWithoutInstructor?: number | null;
  /** @format int32 */
  hoursOnline?: number | null;
  /** @format int32 */
  hoursOfDigitalEducation?: number | null;
  /** @format int32 */
  hoursSelfStudy?: number | null;
  /**
   * Antall (timeplan)timer gjennomført (timeplan-timer som er i fortiden)
   * @format double
   */
  completedScheduledHours: number;
  /**
   * Antall (timeplan)timer gjenværende
   * @format double
   */
  remainingScheduledHours: number;
  /**
   * Totalt antall (timeplan)timer
   * @format double
   */
  totalScheduledHours: number;
  /** @format int32 */
  courseSupervisorId: number;
  /** @format int32 */
  organizerOrganizationId: number;
  /** @format int32 */
  ownerOrganizationId: number;
  /** @format int32 */
  lecturerId: number;
  /** @format int32 */
  contactId: number;
  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format date-time */
  unenrollmentDeadline?: string | null;
  /** @format int32 */
  minimumParticipants: number;
  /** @format int32 */
  maxParticipants: number;
  /**
   * Vises kun hvis bruker-context er en ressurs
   * @format int32
   */
  participantSubscribedCount?: number | null;
  /** @format int32 */
  participantCapacity: number;
  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiGetPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;
  /** Id'ene til læremidlene for studieplanen for dette kurset */
  curriculumTeachingMaterialIds?: number[] | null;
  tests?: ApiGetTestDtoType[] | null;
  comments?: string | null;
  economy?: ApiGetCourseEconomyDtoType;
  webDescription?: ApiGetCourseDescriptionBaseDto;
  teachingMethod?: string | null;
  /** @format int32 */
  mainCourseId?: number | null;
  /** @format int32 */
  facilitationCourseId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format int32 */
  subOrganizerOrganizationId?: number | null;
}

export interface ApiGetLocationDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  areaId: number;
  street?: string | null;
  postalCode?: string | null;
  postalArea?: string | null;
  isActive: boolean;
  details?: string | null;
  rooms?: ApiGetRoomDto[] | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetMemberOrganizationDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  orgNo?: string | null;
  ssbId?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible: boolean;
  isActive: boolean;
  /** @format int32 */
  clientAccountNumber?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  inheritOrgNoFromParent?: boolean | null;
}

/**
* These settings are mostly made for Fellesforbundet but can be used for other organizations.
Using FF as an example.
    * HasMultiLevelMemberOrganization = true, FF have 3 levels (the last one being avdelinger)
    * CanUseSubOrganizer = true, FF can organize courses on lower member organization levels like lvl 2 or 3.
    * UsePreApprovalForCourses = true, FF participants have to be pre-approved even outside of FF courses.
    * BypassEnrollmentDeadlineInGuestPortal = true, FF want to have the opportunity to change data without caring about enrollment deadlines.
*/
export interface ApiGetMemberOrganizationFeatureSettingDto {
  /** @format int32 */
  organizationId: number;
  /** Setting to control if a member-organization can have more than the standard 2 levels. */
  hasMultiLevelMemberOrganization: boolean;
  /**
   * Setting to control if a course can use sub-organizer. When using sub-organizer this will be the approving
   * member organization. If used in conjunction with pre-approval the participant first needs to be pre-approved
   * before being approved.
   */
  canUseSubOrganizer: boolean;
  /**
   * Setting to control if a member-organization's participants have to be pre-approved for all courses.
   * The pre-approval organization will always be the lowest one in the hierarchy, in Fellesforbundet
   * this is level 3 (avdelinger).
   */
  usePreApprovalForCourses: boolean;
  /** Frontend setting to see if member organization should skip the check for enrollment deadline. */
  bypassEnrollmentDeadlineInGuestPortal: boolean;
}

export interface ApiGetMemberOrganizationUserDto {
  /** @format int32 */
  id: number;
  email?: string | null;
}

export interface ApiGetMessageResultDto {
  /** @format int64 */
  id: number;
  /** @format int64 */
  threadId: number;
  /** @format int64 */
  replyToId?: number | null;
  subject?: string | null;
  text?: string | null;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  senderMsgUserId: number;
  sender?: string | null;
  recipientMsgUserIds?: number[] | null;
  recipients?: string[] | null;
  /** @format date-time */
  sent: string;
  isRead: boolean;
  sentFromKursAdmin: boolean;
  attachments?: ApiAttachmentDto[] | null;
}

export interface ApiGetMessageResultDtoPagedResult {
  items?: ApiGetMessageResultDto[] | null;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface ApiGetMessagingDefaultOptionDto {
  variables?: string[] | null;
}

export interface ApiGetMinSideCourseDto {
  mainCourseArea?: string | null;
  coursePractice: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  externalTitle?: string | null;
  reasonForStatusChange?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;
  /** @format int32 */
  studyLocationId?: number | null;
  /** @format date-time */
  cancellationDeadline?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  isTimeWebVisible: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /** @format int32 */
  hoursWithoutInstructor?: number | null;
  /** @format int32 */
  hoursOnline?: number | null;
  /** @format int32 */
  hoursOfDigitalEducation?: number | null;
  /** @format int32 */
  hoursSelfStudy?: number | null;
  /**
   * Antall (timeplan)timer gjennomført (timeplan-timer som er i fortiden)
   * @format double
   */
  completedScheduledHours: number;
  /**
   * Antall (timeplan)timer gjenværende
   * @format double
   */
  remainingScheduledHours: number;
  /**
   * Totalt antall (timeplan)timer
   * @format double
   */
  totalScheduledHours: number;
  /** @format int32 */
  courseSupervisorId: number;
  /** @format int32 */
  organizerOrganizationId: number;
  /** @format int32 */
  ownerOrganizationId: number;
  /** @format int32 */
  lecturerId: number;
  /** @format int32 */
  contactId: number;
  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format date-time */
  unenrollmentDeadline?: string | null;
  /** @format int32 */
  minimumParticipants: number;
  /** @format int32 */
  maxParticipants: number;
  /**
   * Vises kun hvis bruker-context er en ressurs
   * @format int32
   */
  participantSubscribedCount?: number | null;
  /** @format int32 */
  participantCapacity: number;
  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiGetPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;
  /** Id'ene til læremidlene for studieplanen for dette kurset */
  curriculumTeachingMaterialIds?: number[] | null;
  tests?: ApiGetTestDtoType[] | null;
  comments?: string | null;
  economy?: ApiGetCourseEconomyDtoType;
  webDescription?: ApiGetCourseDescriptionBaseDto;
  teachingMethod?: string | null;
  /** @format int32 */
  mainCourseId?: number | null;
  /** @format int32 */
  facilitationCourseId?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format int32 */
  subOrganizerOrganizationId?: number | null;
}

export interface ApiGetNonJournaledCoursesDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  organizerOrganizationName?: string | null;
  /** @format date-time */
  endDate: string;
}

export interface ApiGetNonJournaledCoursesDtoPagedResult {
  items?: ApiGetNonJournaledCoursesDto[] | null;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export interface ApiGetNusCodeDto {
  code?: string | null;
  /** @format int32 */
  ownerOrganizationId: number;
  name?: string | null;
  shortName?: string | null;
  educationalOfferCode?: string | null;
  isActive: boolean;
}

export interface ApiGetOfferletterWithParticipantsDto {
  /** @format int32 */
  courseId: number;
  courseParticipants?: ApiGetCourseParticipantOfferletterDto[] | null;
}

export interface ApiGetOrderDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  courseParticipantExpenseId?: number | null;
  /**
   * Kursdato start
   * @format date-time
   */
  courseStartDate?: string | null;
  /**
   * Kursdato slutt
   * @format date-time
   */
  courseEndDate?: string | null;
  status?: ApiIdNameDtoType;
  participant?: ApiOrderParticipantDto;
  invoiceRecipient?: ApiOrderInvoiceRecipientDto;
  orderLines?: ApiOrderLineDto[] | null;
  /** Avdragslinjer (en liste, bare ved type 2) */
  orderPaymentLines?: ApiOrderPaymentLineDto[] | null;
  /** @format date-time */
  queued?: string | null;
  /** @format int32 */
  retryNumber: number;
  /** @format int32 */
  erpClientId: number;
  /** @format int32 */
  erpOrderNo?: number | null;
  erpVgOrderNo?: string | null;
  /** @format date-time */
  erpSendt?: string | null;
  /** @format date-time */
  erpLastUpdated?: string | null;
  erpReceipt?: string | null;
  /** @format int32 */
  erpResponseCode?: number | null;
  /** @format date-time */
  inserted?: string | null;
  erpStatus?: string | null;
  /** @format int32 */
  erpInvoiceNo?: number | null;
  /** @format int32 */
  numberOfReminders?: number | null;
  /** @format date-time */
  lastReminderDate?: string | null;
  /** Internfakturering */
  isInternal: boolean;
  /** Kundens bestillingsnummer – (manuelt felt, PH nummer, kan være tom) */
  customerOrderNo?: string | null;
  /** Reise/utleggsreferanse – (Refusjon kurs ____)(max 30 tegn) */
  expenseRef?: string | null;
  /** Deres referanse – (manuelt) */
  theirRef?: string | null;
  /**
   * Motbilag – (ved kreditering sende PK fra originale ordrenummer)
   * @format int32
   */
  contraEntryOrderId?: number | null;
  /**
   * Avdragstype – (type 0, 1 eller 2)
   * Type 0: Ingen avdrag
   * Type 1: Fast intervall
   *     Antall avdrag
   *     Intervall(eks.antall mnd mellom hvert avdrag)
   * Type 2: Liste med avdrag(må gå opp i 100%)
   *     Dato
   *     Prosent
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;
  /**
   * Antall avdrag – (bare fylt ut dersom type 1)
   * @format int32
   */
  noOfPayments?: number | null;
  /**
   * Avdragsintervall – (bare fylt ut dersom type 1)
   * @format int32
   */
  paymentInverval?: number | null;
  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  contraEntryOrdersId?: number[] | null;
}

export interface ApiGetPlanDtoType {
  /** @format int32 */
  schoolRouteId?: number | null;
  registerHours: boolean;
  schedules?: ApiGetCourseScheduleDtoType[] | null;
}

export interface ApiGetPoliceCertificateDto {
  /** @format int32 */
  userId: number;
  userFullName?: string | null;
  participantStatus?: string | null;
  /** @format int64 */
  fileId?: number | null;
  certificateStatus?: string | null;
  message?: string | null;
  fileName?: string | null;
  /** @format date-time */
  fileInserted: string;
}

export interface ApiGetRankedCurriculumSubjectCodeDto {
  /** @format int32 */
  rank: number;
  /**
   * Id'en til emnekoden
   * @format int32
   */
  id: number;
  educationalOfferCode?: string | null;
  /** @format int32 */
  subjectNumber: number;
  subjectCode?: string | null;
  subjectName?: string | null;
  /** @format int32 */
  credits: number;
  /** @format int32 */
  subjectHours: number;
  isActive: boolean;
}

export interface ApiGetRoleDto {
  name?: string | null;
  isActive: boolean;
  isResource: boolean;
  isForRegularCourse: boolean;
  isForVocationalSchool: boolean;
  cannotBeCombined: boolean;
  requiresCertification: boolean;
  description?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetRoomDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  /** @format int32 */
  capacity: number;
  /** @format int32 */
  recommendedCapacity: number;
  /** @format int32 */
  floorNumber?: number | null;
  isActive: boolean;
  description?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetShortContractLineDtoType {
  /**
   * Id'en til kontraktlinjen
   * @format int32
   */
  id: number;
  /**
   * Id'en til kontrakten
   * @format int32
   */
  courseContractId: number;
  /**
   * Id'en til kostnaden for kontraktlinjen
   * @format int32
   */
  costId: number;
  /** Kostandsypenavn */
  costTypeName?: string | null;
  /**
   * Maks antall (vanligvis bare timer) for denne kontraktlinjen
   * @format double
   */
  amount: number;
  /**
   * maks antall timer minus timer som er sendt til betaling
   * @format double
   */
  remainingAmount: number;
  /**
   * summen av alle timer som er registrert utenom status avslått
   * @format double
   */
  registeredAmount: number;
  /**
   * Prisen
   * @format double
   */
  price?: number | null;
  /** Beskrivelsen på kontraktlinjen */
  description?: string | null;
}

export interface ApiGetShortCourseDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  curriculumId: number;
  status?: string | null;
  courseName?: string | null;
  courseLocation?: ApiCourseLocationDtoType;
  /** @format int32 */
  studyLocationId?: number | null;
  areaName?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format date-time */
  cancellationDeadline?: string | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format int32 */
  courseSupervisorId: number;
  courseSupervisorName?: string | null;
  /** @format int32 */
  organizerOrganizationId: number;
  organizerOrganizationName?: string | null;
  /** @format int32 */
  ownerOrganizationId: number;
  ownerOrganizationName?: string | null;
  teachingMethod?: string | null;
  /** @format int32 */
  lecturerId: number;
  lecturerName?: string | null;
  /** @format int32 */
  kasId?: number | null;
  /** @format double */
  price: number;
  /** @format int32 */
  erpProductNo?: number | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  participants?: ApiGetCourseParticipantDtoType[] | null;
}

export interface ApiGetShortCurriculumDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  /** @format int32 */
  orgId: number;
  orgName?: string | null;
  /** @format int32 */
  mainCourseId?: number | null;
  mainCourseName?: string | null;
  /** @format int32 */
  subCourseId?: number | null;
  ssbCode?: string | null;
  subCourseName?: string | null;
  /** @format int32 */
  curriculumStatusId: number;
  curriculumStatusName?: string | null;
  teachingMethod?: string | null;
  /** @format double */
  hoursMin: number;
  /** @format double */
  hoursMax: number;
  /** @format int32 */
  insertedById: number;
  insertedByUsername?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
}

export interface ApiGetSignatureRequestsDto {
  type?: string | null;
  status?: string | null;
  /** @format date-time */
  sent: string;
}

export interface ApiGetSigningFileDto {
  /** @format int32 */
  id: number;
  signStatus?: string | null;
  /** @format int64 */
  unsignedFileId: number;
  unsignedFileName?: string | null;
  /** @format int64 */
  signedFileId?: number | null;
  signedFileName?: string | null;
  /** @format date-time */
  signatureDeadline?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  isSigned: boolean;
  isStatusChangeable: boolean;
  isDeletable: boolean;
  isResendable: boolean;
  isCancellable: boolean;
  courseExternalTitle?: string | null;
  /** @format int32 */
  courseId: number;
  signeeUserFullName?: string | null;
  signeeUserId?: string | null;
  signatureRequests?: ApiGetSignatureRequestsDto[] | null;
}

export interface ApiGetSmsResultDto {
  messageText?: string | null;
  /** @format date-time */
  inserted: string;
  recipients?: ApiRecipientDto[] | null;
}

export interface ApiGetStipendDto {
  /** @format double */
  hours: number;
  /** @format date-time */
  fromDate: string;
  /** @format date-time */
  toDate: string;
  /** @format int32 */
  costId: number;
  costName?: string | null;
  attachments?: ApiGetFileDto[] | null;
  description?: string | null;
  /**
   * The calculated total amount of the cost line
   * @format double
   */
  totalAmount: number;
}

export interface ApiGetStudyLocationDto {
  /** @format int32 */
  id: number;
  code?: string | null;
  name?: string | null;
  isActive: boolean;
}

export interface ApiGetTeachingGrantAmountDto {
  /** @format int32 */
  courseId: number;
  /** @format double */
  amount: number;
}

export interface ApiGetTeachingGrantAmountDtoIdDto {
  id?: ApiGetTeachingGrantAmountDto;
}

export interface ApiGetTeachingMaterialCategoryDto {
  name?: string | null;
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetTeachingMaterialDto {
  /** @format int32 */
  id: number;
  name?: string | null;
  description?: string | null;
  isActive: boolean;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  category?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetTestDtoType {
  /** @format int32 */
  id: number;
  /** @format int32 */
  testTypeId: number;
  name?: string | null;
  description?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetTestTypeDto {
  /** @format int32 */
  id: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  name?: string | null;
  description?: string | null;
}

export interface ApiGetUnreadCourseApplicationsDto {
  /** @format int32 */
  courseId: number;
  courseName?: string | null;
  externalTitle?: string | null;
  areaName?: string | null;
  /** @format int32 */
  courseSupervisorId: number;
  courseSupervisorName?: string | null;
  /** @format int32 */
  lecturerId: number;
  lecturerName?: string | null;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format date-time */
  enrollmentDeadline: string;
  /** @format int32 */
  applicantUserId: number;
  applicantName?: string | null;
  /** @format date-time */
  applicationSentDate: string;
  applicationStatus?: string | null;
  appliedViaForm: boolean;
  isRead: boolean;
}

export interface ApiGetUserDto {
  /** @format int32 */
  id: number;
  type?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
}

export interface ApiGetWorkingHourDto {
  /**
   * Id'en til arbeidstimen
   * @format int64
   */
  id: number;
  /** Statusen til arbeidstimen */
  status?: string | null;
  /** Brukervennlig status til arbeidstimen */
  statusText?: string | null;
  /**
   * Id'en til kurset som kontraktlinjen for arbeidstimen er skrevet for
   * @format int32
   */
  courseId: number;
  /** Navnet til kurset som kontraktlinjen for arbeidstimen er skrevet for */
  courseName?: string | null;
  /**
   * Id'en til Visma Global for kurset sin eier-avdeling
   * @format int32
   */
  courseOwnerOrganizationErpClientId?: number | null;
  /**
   * Id'en til kurset sin regnskapsavdeling
   * @format int32
   */
  courseEconomyAccountingDepartment?: number | null;
  /**
   * Produktnummeret til kursområdet til kurset sin studieplan
   * @format int32
   */
  courseAreaErpProductNo?: number | null;
  /**
   * Id'en til kontrakten som timen gjelder for
   * @format int32
   */
  courseContractId?: number | null;
  /**
   * Id'en til brukeren som timen (og kontrakten) gjelder for
   * @format int32
   */
  userId: number;
  /** Brukerens fulle navn */
  userFullName?: string | null;
  /**
   * Ansattnummeret til brukeren for denne avdelingen i Visma Lønn
   * @format int32
   */
  erpPayrollEmployeeId?: number | null;
  /** Beskrivelsen på kontraktlinjen */
  courseContractLineDescription?: string | null;
  /**
   * Prisen til kontraktlinjen
   * @format double
   */
  courseContractLinePrice: number;
  /** Navnet på kostnadstypen (type timer) for kostnaden til kontraktlinjen */
  costTypeName?: string | null;
  /**
   * Lønnsarten til kostnaden til kontraktlinjen
   * @format int32
   */
  costPayType?: number | null;
  /**
   * Id'en til brukeren som godkjente arbeidstimene
   * @format int32
   */
  approvedByUserId?: number | null;
  /** Brukeren som godkjente arbeidstimene sitt fulle navn */
  approvedByUserFullName?: string | null;
  /**
   * Datoen arbeidstimene ble godkjent
   * @format date-time
   */
  approvedByDate?: string | null;
  /**
   * Id'en til brukeren som avviste arbeidstimene
   * @format int32
   */
  declinedByUserId?: number | null;
  /** Brukeren som avviste arbeidstimene sitt fulle navn */
  declinedByUserFullName?: string | null;
  /**
   * Datoen arbeidstimene ble avvist
   * @format date-time
   */
  declinedByDate?: string | null;
  /**
   * Id'en til filen knyttet til denne arbeidstimen (f.eks. en visma eksport eller fakturagrunnlag)
   * @format int64
   */
  fileId?: number | null;
  /** Filnavnet til filen knyttet til denne arbeidstimen */
  fileName?: string | null;
  /** Statusen til filen knyttet til denne arbeidstimen */
  fileStatus?: string | null;
  /** Brukervennlige statusen til filen knyttet til denne arbeidstimen */
  fileStatusText?: string | null;
  /**
   * Id'en til brukeren som genererte filen for arbeidstimene
   * @format int32
   */
  fileGeneratedByUserId?: number | null;
  /** Brukeren som genererte filen for arbeidstimene sitt fulle navn */
  fileGeneratedByUserFullName?: string | null;
  /**
   * Datoen filen ble generert
   * @format date-time
   */
  fileGeneratedByDate?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /**
   * The id'en til kurskontraktlinjen som det skal skrives timer for
   * @format int32
   */
  courseContractLineId: number;
  /**
   * Antall timer som skal registreres. Kan bli satt ned til nærmeste halvtime (e.g. 2.5)
   * @format double
   */
  amount: number;
  /**
   * <br>
   *             Hvis kostnadstypen for kontraktlinjen er 'Undervisningstimer' så må denne datoen/tidspunktet tilsvare en dato/tidspunkt i timeplanen.
   *
   *             Hvis kostnadstypen for kontraktlinjen ikke er 'Undervisningstimer' så må denne datoen tilsvare en dag hvor
   *             UTC tidspunktet er 00:00:00 på den angitte dagen og være for i dag eller en dag i fortiden.
   *
   * @format date-time
   */
  date: string;
  /** Kommentaren til de registrerte timene. Kan være opptil 500 karakterer lang. */
  comment?: string | null;
  /** Indikerer om arbeidstimen er en sykemelding. Kan kun registreres av en kursadministrator */
  isSickLeave?: boolean | null;
  /** Indikerer om arbeidstimen er kanselert ifh til slettet timeplanoppføring fra tidligere. Kan kun registreres av en kursadministrator */
  isCancelled?: boolean | null;
}

export interface ApiGrant {
  /** @format int32 */
  id: number;
  name?: string | null;
  governmentFunded: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseEconomy?: ApiCourseEconomy[] | null;
}

export interface ApiGrantDto {
  /** @format int32 */
  id: number;
  name?: string | null;
}

export interface ApiGrantRate {
  /** @format int32 */
  id: number;
  /** @format int32 */
  year: number;
  /** @format double */
  teachingGrantAmount: number;
  /** @format double */
  extraProfessionalCourseAmount: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseEconomy?: ApiCourseEconomy[] | null;
}

export interface ApiGuestEmployee {
  /** @format int32 */
  userId: number;
  /** @format int32 */
  organizationId: number;
  email?: string | null;
  /** @format uuid */
  adUserId?: string | null;
  memberOrganization?: ApiMemberOrganization;
  user?: ApiUser;
}

export interface ApiGuestEmployeeCourseParticipantStatusDto {
  participantStatus?: string | null;
  /** @format int32 */
  memberOrganizationId: number;
  statusComment?: string | null;
}

export interface ApiGuidIdDto {
  /** @format uuid */
  id: string;
}

export interface ApiHierarchicalAreaDtoType {
  /** @format int32 */
  parentId?: number | null;
  children?: ApiHierarchicalAreaDtoType[] | null;
  ssbCode?: string | null;
  code?: string | null;
  isActive: boolean;
  /** @format int32 */
  id: number;
  description?: string | null;
}

export interface ApiHoliday {
  /** @format int32 */
  id: number;
  name?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse[] | null;
  holidayDetail?: ApiHolidayDetail[] | null;
}

export interface ApiHolidayDetail {
  /** @format int32 */
  id: number;
  /** @format int32 */
  holidayId: number;
  periodName?: string | null;
  /** @format date-time */
  fromDate: string;
  /** @format date-time */
  toDate: string;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  holiday?: ApiHoliday;
}

export interface ApiIdNameDtoType {
  /** @format int32 */
  id: number;
  name?: string | null;
}

export interface ApiIdRankDto {
  /** @format int32 */
  userId: number;
  /** @format int32 */
  rank: number;
}

export interface ApiInsertNusCodeDto {
  code?: string | null;
  name?: string | null;
  shortName?: string | null;
  educationalOfferCode?: string | null;
  isActive: boolean;
}

export interface ApiInt32IdDto {
  /** @format int32 */
  id: number;
}

export interface ApiInt32ValidationEntry {
  /**
   * The item that was validated
   * @format int32
   */
  validatedItem: number;
  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiInt32ValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiInt32ValidationEntry[] | null;
}

export interface ApiInt64IdDto {
  /** @format int64 */
  id: number;
}

export interface ApiInvitationDto {
  field?: string | null;
  value?: string | null;
}

export interface ApiLocation {
  /** @format int32 */
  id: number;
  /** @format int32 */
  areaId: number;
  street?: string | null;
  postalCode?: string | null;
  postalArea?: string | null;
  details?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  area?: ApiArea;
  course?: ApiCourse[] | null;
  room?: ApiRoom[] | null;
}

export interface ApiMarkCourseApplicationsAsReadDto {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
}

export interface ApiMarkCourseParticipantGuestUserChangeDto {
  changeIds?: number[] | null;
}

export interface ApiMemberOrganization {
  /** @format int32 */
  organizationId: number;
  /** @format int32 */
  parentId?: number | null;
  name?: string | null;
  orgNo?: string | null;
  ssbId?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  clientAccountNumber?: number | null;
  organization?: ApiOrganization;
  courseParticipant?: ApiCourseParticipant[] | null;
  memberOrganizationUsers?: ApiMemberOrganizationUser[] | null;
  guestEmployee?: ApiGuestEmployee[] | null;
  appFormResponse?: ApiAppFormResponse[] | null;
  memberOrganizationFeatureSetting?: ApiMemberOrganizationFeatureSetting;
}

export interface ApiMemberOrganizationFeatureSetting {
  /** @format int32 */
  organizationId: number;
  hasMultiLevelMemberOrganization: boolean;
  /** @format int32 */
  maxNumberOfLevels: number;
  canUseSubOrganizer: boolean;
  usePreApprovalForCourses: boolean;
  bypassEnrollmentDeadlineInGuestPortal: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  memberOrganization?: ApiMemberOrganization;
  isMemberOrganization: boolean;
}

export interface ApiMemberOrganizationUser {
  /** @format int32 */
  id: number;
  email?: string | null;
  /** @format int32 */
  organizationId: number;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  inserted: string;
  memberOrganization?: ApiMemberOrganization;
}

export interface ApiMetadataDtoInfoType {
  /** @format int32 */
  id: number;
  description?: string | null;
}

export interface ApiNewDocumentsDto {
  documentName?: string | null;
  /** @format int64 */
  fileId: number;
  documentCategory?: string | null;
  /** @format date-time */
  inserted: string;
  isCourseAdminRead: boolean;
}

export interface ApiNusCode {
  code?: string | null;
  name?: string | null;
  shortName?: string | null;
  educationalOfferCode?: string | null;
  isActive: boolean;
  /** @format int32 */
  ownerOrganizationId: number;
  department?: ApiDepartment;
  curriculum?: ApiCurriculum[] | null;
}

export interface ApiOfferletterCustomVariables {
  decisionText?: string | null;
  ourReferance?: string | null;
  /** @format date-time */
  offerDeadLine: string;
  /** @format int32 */
  practicalHourAmount?: number | null;
  showPoliceCertificate: boolean;
  showReference: boolean;
}

export interface ApiOrder {
  /** @format int32 */
  id: number;
  /** @format int32 */
  courseId?: number | null;
  /** @format int32 */
  courseParticipantTravelAndExpenseId?: number | null;
  /** @format int32 */
  orderStatusId: number;
  /** @format date-time */
  courseStartDate?: string | null;
  /** @format date-time */
  courseEndDate?: string | null;
  isInternal: boolean;
  customerOrderNo?: string | null;
  expenseRef?: string | null;
  theirRef?: string | null;
  /** @format int32 */
  contraEntryOrderId?: number | null;
  /**
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;
  /** @format int32 */
  noOfPayments?: number | null;
  /** @format int32 */
  paymentInverval?: number | null;
  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format int32 */
  retryNumber: number;
  /** @format int32 */
  erpClientId: number;
  /** @format int32 */
  erpOrderNo?: number | null;
  erpVgOrderNo?: string | null;
  /** @format date-time */
  erpLastUpdated?: string | null;
  erpReceipt?: string | null;
  /** @format int32 */
  erpResponseCode?: number | null;
  erpStatus?: string | null;
  /** @format int32 */
  erpInvoiceNo?: number | null;
  /** @format int32 */
  numberOfReminders?: number | null;
  /** @format date-time */
  lastReminderDate?: string | null;
  course?: ApiCourse;
  contraEntryOrder?: ApiOrder;
  participant?: ApiOrderParticipant;
  invoiceRecipient?: ApiOrderInvoiceRecipient;
  orderQueued?: ApiOrderQueued;
  orderSendt?: ApiOrderSendt;
  orderLines?: ApiOrderLine[] | null;
  orderPaymentLines?: ApiOrderPaymentLine[] | null;
  contraEntryOrders?: ApiOrder[] | null;
  courseParticipantTravelAndExpense?: ApiCourseParticipantTravelAndExpense;
}

export interface ApiOrderInvoiceRecipient {
  /** @format int32 */
  orderId: number;
  /** @format int32 */
  userId?: number | null;
  /** @format int32 */
  customerNo: number;
  name: string;
  address?: string | null;
  postNo: string;
  postArea: string;
  email: string;
  telephone?: string | null;
  orgNo?: string | null;
  bankAccountNo?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  user?: ApiUser;
  order?: ApiOrder;
}

export interface ApiOrderInvoiceRecipientDto {
  /** @format int32 */
  userId?: number | null;
  /** Orgnummer */
  orgNo?: string | null;
  /** Kontonummer */
  bankAccountNo?: string | null;
  /**
   * ID
   * @format int32
   */
  customerNo: number;
  /** Navn */
  name: string;
  /** Gateadresse */
  address?: string | null;
  /** Postnummer */
  postNo: string;
  /** Poststed */
  postArea: string;
  /** E-post-adresse */
  email: string;
  /** Telefonnummer */
  telephone?: string | null;
}

export interface ApiOrderInvoiceRecipientTemplateDto {
  /** @format int32 */
  userId: number;
  /** Orgnummer */
  orgNo?: string | null;
  accountNumber?: string | null;
  /**
   * ID
   * @format int32
   */
  customerNo: number;
  /** Navn */
  name: string;
  /** Gateadresse */
  address?: string | null;
  /** Postnummer */
  postNo: string;
  /** Poststed */
  postArea: string;
  /** E-post-adresse */
  email: string;
  /** Telefonnummer */
  telephone?: string | null;
}

export interface ApiOrderLine {
  /** @format int32 */
  id: number;
  /** @format int32 */
  orderId: number;
  description?: string | null;
  articleNo: string;
  /** @format int32 */
  courseId?: number | null;
  /** @format int32 */
  userId?: number | null;
  userName?: string | null;
  /** @format int32 */
  projectNo: number;
  /** @format int32 */
  productNo: number;
  /** @format int32 */
  courseAreaId?: number | null;
  /** @format int32 */
  accountingDepartment: number;
  /** @format int32 */
  teachingMethod: number;
  /** @format int32 */
  employeeNo: number;
  /** @format int32 */
  employeeId?: number | null;
  /** @format double */
  amount: number;
  /** @format double */
  discount: number;
  /** @format double */
  quantity: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  order?: ApiOrder;
  courseArea?: ApiCourseArea;
  employee?: ApiEmployee;
  user?: ApiUser;
}

export interface ApiOrderLineDto {
  /** @format int32 */
  courseAreaId?: number | null;
  /** @format int32 */
  employeeId?: number | null;
  /**
   * Foreslås hentes fra kurs
   * @format int32
   */
  projectNo: number;
  /**
   * Foreslås hentes fra CourseArea
   * @format int32
   */
  productNo: number;
  /** @format int32 */
  accountingDepartment: number;
  /** @format int32 */
  teachingMethod: number;
  /** @format int32 */
  employeeNo: number;
  /** @format double */
  previouslyInvoicedAmount?: number | null;
  /** @format double */
  previouslyCreditedAmount?: number | null;
  /** Beskrivelse. Hvis oppgitt, overstyrer artikkelnavn */
  description?: string | null;
  /** Kan foreslås hentes fra CourseArticle */
  articleNo?: string | null;
  /**
   * @format double
   * @min 0
   * @max 10000000000000000
   */
  amount: number;
  /**
   * Rabatt
   * @format double
   * @min 0
   * @max 100
   */
  discount: number;
  /**
   * Antall – (positivt ved debitering, negativt ved kreditering)
   * @format double
   */
  quantity: number;
  user?: ApiOrderLineUserDto;
}

export interface ApiOrderLineUserDto {
  /** @format int32 */
  id?: number | null;
  name?: string | null;
}

export interface ApiOrderParticipant {
  /** @format int32 */
  orderId: number;
  /** @format int32 */
  courseId?: number | null;
  /** @format int32 */
  userId?: number | null;
  /** @format int32 */
  customerNo: number;
  name: string;
  address?: string | null;
  postNo: string;
  postArea: string;
  email: string;
  telephone?: string | null;
  orgNo?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  order?: ApiOrder;
  user?: ApiUser;
}

export interface ApiOrderParticipantDto {
  /** @format int32 */
  userId?: number | null;
  /** Orgnummer */
  orgNo?: string | null;
  /**
   * ID
   * @format int32
   */
  customerNo: number;
  /** Navn */
  name: string;
  /** Gateadresse */
  address?: string | null;
  /** Postnummer */
  postNo: string;
  /** Poststed */
  postArea: string;
  /** E-post-adresse */
  email: string;
  /** Telefonnummer */
  telephone?: string | null;
}

export interface ApiOrderParticipantTemplateDto {
  /** @format int32 */
  userId: number;
  orgNo?: string | null;
  /**
   * ID
   * @format int32
   */
  customerNo: number;
  /** Navn */
  name: string;
  /** Gateadresse */
  address?: string | null;
  /** Postnummer */
  postNo: string;
  /** Poststed */
  postArea: string;
  /** E-post-adresse */
  email: string;
  /** Telefonnummer */
  telephone?: string | null;
}

export interface ApiOrderPaymentLine {
  /** @format int32 */
  id: number;
  /** @format int32 */
  orderId: number;
  /** @format date-time */
  invoiceDate: string;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  percentage: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  order?: ApiOrder;
}

export interface ApiOrderPaymentLineDto {
  /**
   * Fakturadato
   * @format date-time
   */
  invoiceDate: string;
  /**
   * Prosent
   * @format double
   * @min 0
   * @max 100
   */
  percentage: number;
}

export interface ApiOrderQueued {
  /** @format int32 */
  orderId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  order?: ApiOrder;
}

export interface ApiOrderSendt {
  /** @format int32 */
  orderId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  order?: ApiOrder;
}

export interface ApiOrderTemplateDto {
  /**
   * Kursdato start
   * @format date-time
   */
  courseStartDate?: string | null;
  /**
   * Kursdato slutt
   * @format date-time
   */
  courseEndDate?: string | null;
  orderLines?: ApiOrderLineDto[] | null;
  /** @format int32 */
  courseId: number;
  participant?: ApiOrderParticipantTemplateDto;
  invoiceRecipient?: ApiOrderInvoiceRecipientTemplateDto;
  /** Avdragslinjer (en liste, bare ved type 2) */
  orderPaymentLines?: ApiOrderPaymentLineDto[] | null;
  /** Internfakturering */
  isInternal: boolean;
  /** Kundens bestillingsnummer – (manuelt felt, PH nummer, kan være tom) */
  customerOrderNo?: string | null;
  /** Reise/utleggsreferanse – (Refusjon kurs ____)(max 30 tegn) */
  expenseRef?: string | null;
  /** Deres referanse – (manuelt) */
  theirRef?: string | null;
  /**
   * Motbilag – (ved kreditering sende PK fra originale ordrenummer)
   * @format int32
   */
  contraEntryOrderId?: number | null;
  /**
   * Avdragstype – (type 0, 1 eller 2)
   * Type 0: Ingen avdrag
   * Type 1: Fast intervall
   *     Antall avdrag
   *     Intervall(eks.antall mnd mellom hvert avdrag)
   * Type 2: Liste med avdrag(må gå opp i 100%)
   *     Dato
   *     Prosent
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;
  /**
   * Antall avdrag – (bare fylt ut dersom type 1)
   * @format int32
   */
  noOfPayments?: number | null;
  /**
   * Avdragsintervall – (bare fylt ut dersom type 1)
   * @format int32
   */
  paymentInverval?: number | null;
  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  contraEntryOrdersId?: number[] | null;
}

export interface ApiOrganization {
  /** @format int32 */
  id: number;
  isActive: boolean;
  isEconomyActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  department?: ApiDepartment;
  memberOrganization?: ApiMemberOrganization;
  accountingDepartment?: ApiAccountingDepartment;
  cost?: ApiCost[] | null;
  communicationTemplateGroup?: ApiCommunicationTemplateGroup[] | null;
}

export interface ApiOrganizationDto {
  /** @format int32 */
  organizationId: number;
  name?: string | null;
  /** @format int32 */
  erpOrganizationId?: number | null;
}

export interface ApiOrganizationDtoType {
  type?: string | null;
  isActive: boolean;
  /** @format int32 */
  id: number;
  name?: string | null;
}

export interface ApiPaginationInput {
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
}

export interface ApiParticipantNewDocumentDto {
  /** @format int32 */
  userId: number;
  courseParticipantName?: string | null;
  courseParticipantStatus?: string | null;
  newDocuments?: ApiNewDocumentsDto[] | null;
}

export interface ApiPostNewDocumentsReadStatusDto {
  /** @format int32 */
  courseId: number;
  /** @format int32 */
  userId: number;
  /** @format int64 */
  fileId: number;
}

export interface ApiProductContributionDto {
  /** @format double */
  commonActions?: number | null;
  /** @format double */
  organizationCourses?: number | null;
}

export interface ApiPurchaserOrganizationDto {
  organizationNumber?: string | null;
  name?: string | null;
  homePage?: string | null;
  address?: string | null;
  city?: string | null;
  zip?: string | null;
  country?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  isBrregSubCompany?: boolean | null;
}

export interface ApiPutAppFormTemplateDto {
  name?: string | null;
  isActive: boolean;
}

export interface ApiRecipientDto {
  mobileNumber?: string | null;
  name?: string | null;
}

export interface ApiRegulationFile {
  /** @format int64 */
  fileId: number;
  /** @format int32 */
  organizationId: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  file?: ApiFile;
  department?: ApiDepartment;
  appFormTemplate?: ApiAppFormTemplate[] | null;
  appFormResponse?: ApiAppFormResponse[] | null;
}

export interface ApiRejectCourseParticipantTravelAndExpenseDto {
  comment?: string | null;
}

export interface ApiRejectFacilitationGrantApplicationDto {
  commentToGrant?: string | null;
  commentToActualCost?: string | null;
}

export interface ApiReopenCourseStatusDto {
  status?: string | null;
}

export interface ApiRole {
  name?: string | null;
  description?: string | null;
  isActive: boolean;
  isResource: boolean;
  isForRegularCourse: boolean;
  isForVocationalSchool: boolean;
  cannotBeCombined: boolean;
  requiresCertification: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseParticipantRole?: ApiCourseParticipantRole[] | null;
  courseContract?: ApiCourseContract[] | null;
}

export interface ApiRoom {
  /** @format int32 */
  id: number;
  /** @format int32 */
  locationId: number;
  name?: string | null;
  /** @format int32 */
  capacity: number;
  /** @format int32 */
  recommendedCapacity: number;
  /** @format int32 */
  floorNumber?: number | null;
  description?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  location?: ApiLocation;
  course?: ApiCourse[] | null;
}

export interface ApiSchedule {
  /** @format int32 */
  courseId: number;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end: string;
  title?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  course?: ApiCourse;
  courseParticipantAttendance?: ApiCourseParticipantAttendance[] | null;
}

export interface ApiScheduleBaseDto {
  title?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
}

export interface ApiScheduleBaseDtoValidationEntry {
  validatedItem?: ApiScheduleBaseDto;
  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiScheduleBaseDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiScheduleBaseDtoValidationEntry[] | null;
}

export interface ApiScheduleBatchDto {
  /** The schedules to change/validate */
  schedules?: ApiScheduleBaseDto[] | null;
}

export interface ApiScheduleWorkingHourDto {
  /**
   * Start-tidspunktet i timeplanoppføringen
   * @format date-time
   */
  start?: string | null;
  /**
   * Slutt-tidspunktet i timeplanoppføringen
   * @format date-time
   */
  end?: string | null;
  /**
   * Antall arbeidstimer som er godkjent
   * @format double
   */
  approvedWorkingHours: number;
  /**
   * antall arbeidstimer som er registrert uansett status
   * @format double
   */
  totalRegisteredWorkingHours: number;
  /**
   * antall arbeidstimer som er avslått eller ikke godkjent
   * @format double
   */
  declinedWorkingHours: number;
  /**
   * Antall arbeidstimner som er lagt for rette i timeplanen
   * @format double
   */
  scheduledWorkingHours: number;
}

export interface ApiSendCourseParticipantCertificateDto {
  status: ApiCertificateStatus;
  userIds?: number[] | null;
}

export interface ApiSendSmsMessageDto {
  /** @format int32 */
  courseId: number;
  recipientIds?: number[] | null;
  messageText?: string | null;
}

export interface ApiSetMessageReadStatusDto {
  isRead: boolean;
}

export interface ApiSignatureRequest {
  /** @format int32 */
  id: number;
  /** @format int32 */
  signingFileId: number;
  type?: string | null;
  status?: string | null;
  /** @format date-time */
  sent: string;
  message?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  signingFile?: ApiSigningFile;
}

export interface ApiSignatureStatusDto {
  /** @format int32 */
  signicatDocumentId?: number;
  status?: string | null;
  fileName?: string | null;
  content?: string | null;
}

export interface ApiSigningFile {
  /** @format int32 */
  id: number;
  /** @format int32 */
  signicatDocumentId?: number | null;
  signStatus?: string | null;
  /** @format int64 */
  unsignedFileId: number;
  /** @format int64 */
  signedFileId?: number | null;
  /** @format date-time */
  deadline?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  signedFile?: ApiFile;
  unsignedFile?: ApiFile;
  courseParticipantFile?: ApiCourseParticipantFile;
  signatureRequests?: ApiSignatureRequest[] | null;
}

export interface ApiSigningFileCancellationCourseAdminDto {
  /** @format int32 */
  signingFileId: number;
  isValidated: boolean;
}

export interface ApiSigningFileIdsDto {
  signingFileIds?: ApiSigningFileCancellationCourseAdminDto[] | null;
}

export interface ApiSigningFileStatusDescriptionDto {
  signingFileStatus?: string | null;
  signingFileDescription?: string | null;
  signingFileCourseAdminText?: string | null;
  signingFileMinSideText?: string | null;
  /** @format int32 */
  signingFileCourseAdminValue: number;
  /** @format int32 */
  signingFileMinSideValue: number;
}

export enum ApiSortOrder {
  ApiAscending = "Ascending",
  ApiDescending = "Descending",
}

export interface ApiSsbCountryDto {
  name?: string | null;
  ssbCode?: string | null;
}

export interface ApiSsbSubjectDto {
  ssbCode?: string | null;
  description?: string | null;
  parentSsbCode?: string | null;
}

export interface ApiStipendDto {
  /** @format int32 */
  costId: number;
  /** @format date-time */
  fromDate: string;
  /** @format date-time */
  toDate: string;
  /** @format double */
  hours: number;
  attachmentIds?: number[] | null;
  description?: string | null;
}

export interface ApiStringStringKeyValuePair {
  key?: string | null;
  value?: string | null;
}

export interface ApiStudyLocation {
  /** @format int32 */
  id: number;
  /** @format int32 */
  organizationId: number;
  code?: string | null;
  name?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  department?: ApiDepartment;
  course?: ApiCourse[] | null;
}

export interface ApiTeachingGrantAmount {
  /** @format int32 */
  courseId: number;
  /** @format double */
  amount: number;
  /** @format date-time */
  inserted: string;
  /** @format date-time */
  updated: string;
  course?: ApiCourse;
}

export interface ApiTeachingMaterial {
  /** @format int32 */
  id: number;
  name?: string | null;
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  categoryName?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  categoryNameNavigation?: ApiTeachingMaterialCategory;
  courseTeachingMaterial?: ApiCourseTeachingMaterial[] | null;
  curriculumTeachingMaterial?: ApiCurriculumTeachingMaterial[] | null;
}

export interface ApiTeachingMaterialCategory {
  name?: string | null;
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  teachingMaterial?: ApiTeachingMaterial[] | null;
}

export interface ApiTestType {
  /** @format int32 */
  id: number;
  name?: string | null;
  description?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  courseTest?: ApiCourseTest[] | null;
}

export interface ApiUpdateClientAccountInvoiceBasisDto {
  createClientAccountInvoicePartBasis?: ApiCreateClientAccountInvoicePartBasisDto[] | null;
}

export interface ApiUpdateClientAccountRateDto {
  centralCourseRates?: ApiAccountRatesDto;
  localCourseRates?: ApiAccountRatesDto;
  productContributions?: ApiProductContributionDto;
  /** @format double */
  governmentContribution?: number | null;
  /** @format double */
  grantedAmount?: number | null;
  /** @format double */
  balance?: number | null;
  isActive: boolean;
}

export interface ApiUpdateClientAccountRateDtoValidationEntry {
  validatedItem?: ApiUpdateClientAccountRateDto;
  /** The validation errors for the SYSCO.Universum.Api.Dtos.Validation.ValidationEntry`1.ValidatedItem */
  validationErrors?: string[] | null;
}

export interface ApiUpdateClientAccountRateDtoValidationResponseDto {
  /**
   * The general validation errors for the entire list of validated entries.
   * E.g. if the sum of entries makes it invalid, but the items themselves are valid,
   * the errors are more general.
   */
  generalValidationErrors?: string[] | null;
  validationList?: ApiUpdateClientAccountRateDtoValidationEntry[] | null;
}

export interface ApiUpdateCommunicationTemplateDto {
  /**
   * Id'en malgruppen denne kommunikasjonsmalen gjelder for
   * @format int32
   */
  communicationTemplateGroupId: number;
  /** Navnet til kommunikasjonsmalen */
  name?: string | null;
  /** Emnet til kommunikasjonsmalen */
  subject?: string | null;
  /** Innholdet til kommunikasjonsmalen */
  content?: string | null;
  /** Om kommunikasjonsmalen er aktiv eller ikke */
  isActive: boolean;
}

export interface ApiUpdateContractDto {
  contractLines?: ApiUpdateContractLineDto[] | null;
  accountNo?: string | null;
  isSelfEmployed?: boolean | null;
  /** @format int32 */
  customerOrganizationId?: number | null;
  note?: string | null;
  isPrimaryEmployer?: boolean | null;
  isMission?: boolean | null;
  taxMunicipalityNo?: string | null;
  taxMunicipalityName?: string | null;
  /** @format date-time */
  signatureDeadline?: string | null;
  /**
   * The role per contract can be different than the course participant role.
   * E.g. a resource can have a contract for a role that is different than his usual role.
   */
  roleName?: string | null;
  isActive?: boolean | null;
}

export interface ApiUpdateContractLineDto {
  /** @format int32 */
  id: number;
  /** @format int32 */
  costId: number;
  unit?: string | null;
  /** @format double */
  amount: number;
  /**
   * If the SYSCO.Universum.Api.Dtos.CreateContractLineDto.CostId is a cost where the price is not null,
   * the cost price will be saved for the contract line instead of the value of this input field.
   * @format double
   */
  price?: number | null;
  description?: string | null;
}

export interface ApiUpdateCostDto {
  /** Kostnadstype / Navn */
  name?: string | null;
  /** Beskrivelse (kun brukt for utgifter) */
  description?: string | null;
  /**
   * Sats
   * @format double
   */
  price?: number | null;
  /**
   * Lønnsart (kun brukt for lønnskostnader)
   * @format int32
   */
  payType?: number | null;
  /** Arikkelnummer (kun brukt for utgifter) */
  articleNo?: string | null;
  /**
   * Trekkode (kun brukt for lønnskostnader)
   * @format int32
   */
  deductionCode?: number | null;
  /** Undervisningsform */
  teachingMethod?: string | null;
  /** Status */
  isActive?: boolean | null;
  /**
   * If expense, specify expense type
   * Valid values:
   * generelle reiseutgifter
   * reise eget kjøretøy
   * reise eget kjøretøy med avtale om forhøyet sats
   * passasjergodtgjørelse
   * diettsats uten overnatting 6 til 12 timer
   * diettsats uten overnatting over 12 timer
   * diettsats overnatting
   * stipend
   */
  travelAndExpenseType?: string | null;
}

export interface ApiUpdateCourseAreaDto {
  name?: string | null;
  ssbCode?: string | null;
  code?: string | null;
  isActive?: boolean | null;
}

export interface ApiUpdateCourseDto {
  /** Gyldige statuser: planlagt, åpnet, utsatt, innstilt, avsluttet, slettet */
  status?: string | null;
  reasonForStatusChange?: string | null;
  isCommissionedCourse: boolean;
  courseName?: string | null;
  externalTitle?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;
  /** @format int32 */
  studyLocationId?: number | null;
  /** @format date-time */
  cancellationDeadline?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  isTimeWebVisible: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /** @format int32 */
  hoursWithoutInstructor?: number | null;
  /** @format int32 */
  hoursOnline?: number | null;
  /** @format int32 */
  hoursSelfStudy?: number | null;
  /**
   * Kursansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  courseSupervisorId: number;
  /** @format int32 */
  organizerOrganizationId: number;
  /**
   * Fagansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  lecturerId: number;
  /**
   * Kontaktperson - kursadministratorbruker, ansatt.
   * @format int32
   */
  contactId: number;
  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format date-time */
  unenrollmentDeadline?: string | null;
  /** @format int32 */
  minimumParticipants: number;
  /** @format int32 */
  maxParticipants: number;
  /** @format int32 */
  participantCapacity: number;
  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiUpsertPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;
  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  tests?: ApiUpsertTestDtoType[] | null;
  comments?: string | null;
  webDescription?: ApiUpsertCourseDescriptionDto;
  economy?: ApiUpsertCourseEconomyDtoType;
  /** @format int32 */
  subOrganizerOrganizationId?: number | null;
  /** @format int32 */
  hoursOfDigitalEducation?: number | null;
}

export interface ApiUpdateCourseExtendedDto {
  /**
   * Id til regnskapsklient
   * @format int32
   */
  ownerOrganizationId: number;
  /** Gyldige statuser: planlagt, åpnet, utsatt, innstilt, avsluttet, slettet */
  status?: string | null;
  reasonForStatusChange?: string | null;
  isCommissionedCourse: boolean;
  courseName?: string | null;
  externalTitle?: string | null;
  useLearningPlatform: boolean;
  courseLocation?: ApiCourseLocationDtoType;
  /** @format int32 */
  studyLocationId?: number | null;
  /** @format date-time */
  cancellationDeadline?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  isTimeWebVisible: boolean;
  /** @format int32 */
  hoursWithInstructor: number;
  /** @format int32 */
  hoursWithoutInstructor?: number | null;
  /** @format int32 */
  hoursOnline?: number | null;
  /** @format int32 */
  hoursSelfStudy?: number | null;
  /**
   * Kursansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  courseSupervisorId: number;
  /** @format int32 */
  organizerOrganizationId: number;
  /**
   * Fagansvarlig - kursadministratorbruker, ansatt.
   * @format int32
   */
  lecturerId: number;
  /**
   * Kontaktperson - kursadministratorbruker, ansatt.
   * @format int32
   */
  contactId: number;
  /**
   * KasId er id fra gamle kursadmin system (KAS) som skal avvikles
   * @format int32
   */
  kasId?: number | null;
  /** @format date-time */
  enrollmentDeadline?: string | null;
  /** @format date-time */
  unenrollmentDeadline?: string | null;
  /** @format int32 */
  minimumParticipants: number;
  /** @format int32 */
  maxParticipants: number;
  /** @format int32 */
  participantCapacity: number;
  /** Bedriftsinternt kurs (het fra gammelt av "Bestillingskurs"/"Kurset er bestilt") */
  isOrderable: boolean;
  plan?: ApiUpsertPlanDtoType;
  additionalTeachingMaterialIds?: number[] | null;
  /** Id'ene til organisasjoner som finansierer kurset */
  financedByOrganizationIds?: number[] | null;
  tests?: ApiUpsertTestDtoType[] | null;
  comments?: string | null;
  webDescription?: ApiUpsertCourseDescriptionDto;
  economy?: ApiUpsertCourseEconomyDtoType;
  /** @format int32 */
  subOrganizerOrganizationId?: number | null;
  /** @format int32 */
  hoursOfDigitalEducation?: number | null;
}

export interface ApiUpdateCourseParticipanOrganizationResultDto {
  success: boolean;
  /** @format int32 */
  numberToCreate: number;
  /** @format int32 */
  numberToUpdate: number;
  /** @format int32 */
  numberCreated: number;
  /** @format int32 */
  numberUpdated: number;
  messages?: string[] | null;
}

export interface ApiUpdateCourseParticipantCertificateDto {
  variables?: ApiCourseCertificateParticipantVariablesDto;
}

export interface ApiUpdateCourseParticipantTravelAndExpenseDto {
  /**
   * Course Id
   * @format int32
   */
  courseId: number;
  /** General description */
  description?: string | null;
  /**
   * Date
   * @format date-time
   */
  date: string;
  /** Bank account number */
  bankAccountNo?: string | null;
  /** Expenses and diet for travel to course */
  expenseLinesTo?: ApiExpenseLineDto[] | null;
  /** Expenses and diet for travel from course */
  expenseLinesFrom?: ApiExpenseLineDto[] | null;
  stipend?: ApiStipendDto;
}

export interface ApiUpdateCourseParticipatCertificateBulkDto {
  variables?: ApiCourseCertificateBulkVariablesDto;
}

export interface ApiUpdateCustomerPersonDto {
  firstName?: string | null;
  lastName?: string | null;
  /** @format date-time */
  birthDate?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  postAddress1?: string | null;
  postZip?: string | null;
  postCity?: string | null;
  postCountry?: string | null;
  gender?: string | null;
  bankAccountNo?: string | null;
  /** The social security number (personnummer) for the user */
  ssno?: string | null;
  lastApprovedTermsOfUsage?: string | null;
  receiveNewsletter: boolean;
  /** @format int32 */
  birthYear?: number | null;
}

export interface ApiUpdateCustomerPersonEmailDto {
  /** The new email address for the user */
  emailAddress?: string | null;
  /** The verification code sent to the new email address */
  emailVerificationCode?: string | null;
}

export interface ApiUpdateDraftDto {
  orderLines?: ApiCreateOrderLineDto[] | null;
  /** @format int32 */
  courseId: number;
  participant?: ApiOrderParticipantTemplateDto;
  invoiceRecipient?: ApiOrderInvoiceRecipientTemplateDto;
  /** Avdragslinjer (en liste, bare ved type 2) */
  orderPaymentLines?: ApiOrderPaymentLineDto[] | null;
  /** Internfakturering */
  isInternal: boolean;
  /** Kundens bestillingsnummer – (manuelt felt, PH nummer, kan være tom) */
  customerOrderNo?: string | null;
  /** Reise/utleggsreferanse – (Refusjon kurs ____)(max 30 tegn) */
  expenseRef?: string | null;
  /** Deres referanse – (manuelt) */
  theirRef?: string | null;
  /**
   * Motbilag – (ved kreditering sende PK fra originale ordrenummer)
   * @format int32
   */
  contraEntryOrderId?: number | null;
  /**
   * Avdragstype – (type 0, 1 eller 2)
   * Type 0: Ingen avdrag
   * Type 1: Fast intervall
   *     Antall avdrag
   *     Intervall(eks.antall mnd mellom hvert avdrag)
   * Type 2: Liste med avdrag(må gå opp i 100%)
   *     Dato
   *     Prosent
   * @format int32
   * @min 0
   * @max 2
   */
  paymentType: number;
  /**
   * Antall avdrag – (bare fylt ut dersom type 1)
   * @format int32
   */
  noOfPayments?: number | null;
  /**
   * Avdragsintervall – (bare fylt ut dersom type 1)
   * @format int32
   */
  paymentInverval?: number | null;
  /** @format int32 */
  cardTransactionId?: number | null;
  cardOrderRef?: string | null;
  contraEntryOrdersId?: number[] | null;
}

export interface ApiUpdateFileCategoryDto {
  category?: string | null;
}

export interface ApiUpdateHolidayDetailDto {
  /** @format int32 */
  id: number;
  periodName?: string | null;
  /** @format date-time */
  fromDate: string;
  /** @format date-time */
  toDate: string;
}

export interface ApiUpdateHolidayDto {
  name?: string | null;
  isActive: boolean;
  details?: ApiUpdateHolidayDetailDto[] | null;
}

export interface ApiUpdateMemberOrganizationDto {
  name?: string | null;
  orgNo?: string | null;
  phone?: string | null;
  email?: string | null;
  url?: string | null;
  isWebVisible?: boolean | null;
  /** @format int32 */
  clientAccountNumber?: number | null;
  inheritOrgNoFromParent?: boolean | null;
}

export interface ApiUpdateNusCodeDto {
  name?: string | null;
  shortName?: string | null;
  educationalOfferCode?: string | null;
  isActive: boolean;
}

export interface ApiUpdateRoleDto {
  description?: string | null;
  isActive?: boolean | null;
  isResource?: boolean | null;
  isForRegularCourse?: boolean | null;
  isForVocationalSchool?: boolean | null;
  cannotBeCombined?: boolean | null;
  requiresCertification?: boolean | null;
}

export interface ApiUpdateTeachingMaterialCategoryDto {
  description?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
}

export interface ApiUpdateWorkingHourDto {
  /**
   * The id'en til kurskontraktlinjen som det skal skrives timer for
   * @format int32
   */
  courseContractLineId: number;
  /**
   * Antall timer som skal registreres. Kan bli satt ned til nærmeste halvtime (e.g. 2.5)
   * @format double
   */
  amount: number;
  /**
   * <br>
   *             Hvis kostnadstypen for kontraktlinjen er 'Undervisningstimer' så må denne datoen/tidspunktet tilsvare en dato/tidspunkt i timeplanen.
   *
   *             Hvis kostnadstypen for kontraktlinjen ikke er 'Undervisningstimer' så må denne datoen tilsvare en dag hvor
   *             UTC tidspunktet er 00:00:00 på den angitte dagen og være for i dag eller en dag i fortiden.
   *
   * @format date-time
   */
  date: string;
  /** Kommentaren til de registrerte timene. Kan være opptil 500 karakterer lang. */
  comment?: string | null;
  /** Indikerer om arbeidstimen er en sykemelding. Kan kun registreres av en kursadministrator */
  isSickLeave?: boolean | null;
  /** Indikerer om arbeidstimen er kanselert ifh til slettet timeplanoppføring fra tidligere. Kan kun registreres av en kursadministrator */
  isCancelled?: boolean | null;
}

export interface ApiUpdatedCustomerDto {
  name?: string | null;
  address?: string | null;
  postNo?: string | null;
  postArea?: string | null;
  email?: string | null;
  telephone?: string | null;
}

export interface ApiUpsertAppFormResponseAdditionalQuestionDto {
  /** Can be boolean or text */
  response?: string | null;
  /** @format uuid */
  questionId: string;
}

export interface ApiUpsertApplicationFormResponseDto {
  additionalQuestions?: ApiUpsertAppFormResponseAdditionalQuestionDto[] | null;
  costBearerPrivate?: ApiUpsertCostBearerPrivateDto;
  /** @format uuid */
  templateId: string;
  employer?: ApiEmployerDto;
  isCostBearerPrivate?: boolean | null;
  costBearerOrganization?: ApiCostBearerOrganizationDto;
  purchaser?: ApiPurchaserOrganizationDto;
  isFormalCompetence?: boolean | null;
  isNorwegianCitizen?: boolean | null;
  citizenship?: string | null;
  isNorwegianMotherTongue?: boolean | null;
  norwegianSkillsLevel?: string | null;
  norwegianSkillsCerfLevel?: string | null;
  personalNumber?: string | null;
  isNewsletter?: boolean | null;
  isUnionized?: boolean | null;
  /** @format int32 */
  memberOrganizationId?: number | null;
  courseRegulationsAccepted?: boolean | null;
  /** @format int32 */
  regulationsFileId?: number | null;
}

export interface ApiUpsertAreaDto {
  country?: string | null;
  countryCode?: string | null;
  county?: string | null;
  municipality?: string | null;
  place?: string | null;
  studyLocationCode?: string | null;
}

export interface ApiUpsertCostBearerPrivateDto {
  hasVerifiedContactInfo?: boolean | null;
  /**
   * Ønsket avdrag; mndlig(1), kvartalsvis(3), halvårlig(6)
   * @format int32
   */
  installmentInterval?: number | null;
  /** @format int32 */
  installmentType?: number | null;
}

export interface ApiUpsertCourseDescriptionDto {
  /** @format uuid */
  templateId: string;
  title?: string | null;
  webCategories?: string[] | null;
  intro?: string | null;
  targetGroup?: string | null;
  foreknowledgeRequirements?: string | null;
  documentationRequired?: boolean | null;
  learningObjectives?: string | null;
  courseContent?: string | null;
  method?: string | null;
  financing?: string | null;
  exam?: string | null;
  /** @format date-time */
  publishDate: string;
  /** @format date-time */
  unpublishDate?: string | null;
  enrollmentStatus?: string | null;
  defaultEnrollmentStudentStatus?: string | null;
}

export interface ApiUpsertCourseDtoType {
  content?: string | null;
  method?: string | null;
  teachingMaterials?: number[] | null;
}

export interface ApiUpsertCourseEconomyDtoType {
  /** @format int32 */
  accountingDepartment?: number | null;
  /** @format double */
  cancellationFee?: number | null;
  /** @format double */
  price?: number | null;
  /**
   * Id til opplæringstilskudd
   * @format int32
   */
  grantId?: number | null;
  allowExpenses?: boolean | null;
  articleNos?: string[] | null;
}

export interface ApiUpsertCourseNoteDto {
  title?: string | null;
  text?: string | null;
}

export interface ApiUpsertCourseParticipantDto {
  /** @format int32 */
  userId: number;
  /** @deprecated */
  roleName?: string | null;
  roleNames?: string[] | null;
  status?: string | null;
  requiresElectronicInvoice: boolean;
  /** @format int32 */
  memberOrganizationId?: number | null;
  /** @format int32 */
  purchaserId?: number | null;
  /** @format int32 */
  invoiceRecipientId?: number | null;
}

export interface ApiUpsertCourseParticipantEmploymentDto {
  employeerName?: string | null;
  employeerEmail?: string | null;
  employeerMobilePhone?: string | null;
  employeerAddress?: string | null;
  employeerPostalCode?: string | null;
  employeerCity?: string | null;
  /** @format double */
  employmentFraction: number;
  isCurrentEmployment: boolean;
}

export interface ApiUpsertCourseParticipantOfferletterCourseVariablesDto {
  /** Vedtakteksten, get a stored test user can change */
  decisionText?: string | null;
  /** Reference Person */
  ourReference?: string | null;
  /**
   * antall timer praksis
   * @format int32
   */
  practicalHourAmount: number;
  /** whever to show policecertificatetext or not */
  showPoliceCertificate: boolean;
  /** whever to show reference or not */
  showReference: boolean;
  /**
   * Deadline for accepting offer letter
   * @format date-time
   */
  offerDeadline: string;
}

export interface ApiUpsertCurriculumDto {
  /** @format uuid */
  draftId: string;
  /** Point 1 */
  name?: string | null;
  /** @format int32 */
  memberOrgId?: number | null;
  /** @format int32 */
  subCourseId: number;
  cefrLevel?: string | null;
  /** @format double */
  hoursMin: number;
  /** @format double */
  hoursMax: number;
  /** @format double */
  hoursOnline?: number | null;
  /** @format double */
  hoursStudyLoad?: number | null;
  /** @format int32 */
  levelId: number;
  /** @format int32 */
  points?: number | null;
  /** @format int32 */
  evaluationId: number;
  nusCode?: string | null;
  /** Point 2 */
  teacherRequired: boolean;
  teacherRequirements?: string | null;
  /** Point 3 */
  foreknowledgeRequired: boolean;
  foreknowledgeRequirements?: string | null;
  targetGroup?: string | null;
  /** Point 4 */
  externalCurriculumUrl?: string | null;
  learningObjectives?: string | null;
  course?: ApiUpsertCourseDtoType;
  /** Point 6 */
  courseCertificateRequired: boolean;
  /** Point 7 */
  webContent?: string | null;
  teachingMethod?: string | null;
  vocationalSchool?: ApiVocationalSchoolDtoType;
  partners?: number[] | null;
  subjectCodes?: ApiUpsertRankedSubjectCode[] | null;
}

export interface ApiUpsertCurriculumStatusDto {
  /** @format int32 */
  statusId: number;
}

export interface ApiUpsertCurriculumSubjectCodeDto {
  educationalOfferCode?: string | null;
  /** @format int32 */
  subjectNumber: number;
  subjectCode?: string | null;
  subjectName?: string | null;
  /** @format int32 */
  credits: number;
  /** @format int32 */
  subjectHours: number;
  isActive: boolean;
}

export interface ApiUpsertCustomerOrgDto {
  /** @format int32 */
  id: number;
  postAddress1?: string | null;
  postAddress2?: string | null;
  postAddress3?: string | null;
  postCity?: string | null;
  postZip?: string | null;
  postCountry?: string | null;
  invoiceAddress1?: string | null;
  invoiceAddress2?: string | null;
  invoiceAddress3?: string | null;
  invoiceCity?: string | null;
  invoiceZip?: string | null;
  invoiceCountry?: string | null;
  phoneNumber?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
  isActive: boolean;
  customer?: ApiCustomerOrgDto;
}

export interface ApiUpsertEmployeeContractLineDto {
  /** @format double */
  amount: number;
  description?: string | null;
  /** @format int32 */
  costTypeId: number;
}

export interface ApiUpsertGrantRateDto {
  /** @format double */
  teachingGrantAmount: number;
  /** @format double */
  extraProfessionalCourseAmount: number;
}

export interface ApiUpsertLocationDto {
  street?: string | null;
  postalCode?: string | null;
  postalArea?: string | null;
  isActive?: boolean | null;
  details?: string | null;
}

export interface ApiUpsertMemberOrganizationUserDto {
  email?: string | null;
}

export interface ApiUpsertPlanDtoType {
  /** @format int32 */
  schoolRouteId: number;
  registerHours: boolean;
  schedules?: ApiScheduleBaseDto[] | null;
}

export interface ApiUpsertRankedSubjectCode {
  /** @format int32 */
  id: number;
  /** @format int32 */
  rank: number;
}

export interface ApiUpsertRoomDto {
  name?: string | null;
  /** @format int32 */
  capacity?: number | null;
  /** @format int32 */
  recommendedCapacity?: number | null;
  /** @format int32 */
  floorNumber?: number | null;
  isActive?: boolean | null;
  description?: string | null;
}

export interface ApiUpsertStudyLocationDto {
  code?: string | null;
  name?: string | null;
  isActive?: boolean | null;
}

export interface ApiUpsertTeachingMaterialDto {
  name?: string | null;
  category?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
}

export interface ApiUpsertTestDtoType {
  /** @format int32 */
  testTypeId: number;
  name?: string | null;
  description?: string | null;
}

export interface ApiUpsertTestTypeDto {
  name?: string | null;
  description?: string | null;
}

export interface ApiUser {
  /** @format int32 */
  id: number;
  type?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  isActive: boolean;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  updated: string;
  /** @format int32 */
  updatedBy: number;
  /** @format int32 */
  msgUserId?: number | null;
  customerOrganization?: ApiCustomerOrganization;
  customerPerson?: ApiCustomerPerson;
  employee?: ApiEmployee;
  guestEmployee?: ApiGuestEmployee;
  courseParticipant?: ApiCourseParticipant[] | null;
  orderInvoiceRecipient?: ApiOrderInvoiceRecipient[] | null;
  orderParticipant?: ApiOrderParticipant[] | null;
  orderLine?: ApiOrderLine[] | null;
  userPayrollEmployee?: ApiUserPayrollEmployee[] | null;
  userEmailVerificationCode?: ApiUserEmailVerificationCode[] | null;
}

export interface ApiUserDefaultVariablesDto {
  userFullName?: string | null;
  /** @format double */
  userAttendanceHours: number;
}

export interface ApiUserEmailVerificationCode {
  /** @format int32 */
  userEmailVerificationCodeId: number;
  /** @format int32 */
  userId: number;
  email?: string | null;
  verificationCode?: string | null;
  /** @format date-time */
  expirationDate: string;
  user?: ApiUser;
}

export interface ApiUserIdsDto {
  userIds?: number[] | null;
}

export interface ApiUserPayrollEmployee {
  /** @format int32 */
  userId: number;
  /** @format int32 */
  erpClientId: number;
  /** @format int32 */
  erpPayrollEmployeeId: number;
  user?: ApiUser;
}

export interface ApiUsersIdNameDto {
  /** @format int32 */
  id: number;
  name?: string | null;
}

/**
* The following usage of the dto is suggested for each use.
Creating a course:
```
POST schedule/validate
{
    schoolRouteId: {value}, (because the value can be changed while creating)
    curriculumId: {value}, (because the value can be changed while creating)
    hoursWithInstructor: {value}, (because the value can be changed while creating)
    schedules: {values} (because the value can be changed while creating)
}
```

Updating a course:
```
POST course/{courseId}/schedule/validate
{
    schoolRouteId: {value}, (because the value can be changed while editing)
    curriculumId: {value}, (because the value can be changed while editing)
    hoursWithInstructor: {value}, (because the value can be changed while editing)
    schedules: {values} (because the value can be changed while editing)
}
```

Changing a schedule in the calendar:
```
POST course/{courseId}/schedule/validate
{
    schoolRouteId: null, (because the value can't change while only changing schedules for a course)
    curriculumId: null, (because the value can't change while only changing schedules for a course)
    hoursWithInstructor: null, (because the value can't change while only changing schedules for a course)
    schedules: {values} (because the value can be changed while changing schedules)
}
```
*/
export interface ApiValidateSchedulesDto {
  /**
   * The id of the school route (holiday-entry) to check for intersections.
   * Should be set if the CourseId is not used in the url or if the SYSCO.Universum.Api.Dtos.Schedule.ValidateSchedulesDto.SchoolRouteId for the given CourseId can be different (e.g. when editing the course).
   * @format int32
   */
  schoolRouteId?: number | null;
  /**
   * The id of the curriculum to check for min and max hours allowed.
   * Should be set if the CourseId is not used in the url or if the SYSCO.Universum.Api.Dtos.Schedule.ValidateSchedulesDto.CurriculumId for the given CourseId can be different (e.g. when editing the course).
   * @format int32
   */
  curriculumId?: number | null;
  /**
   * The number of hours with instructor, will be used to validate against the schedules.
   * Should be set if the CourseId is not used in the url or if the SYSCO.Universum.Api.Dtos.Schedule.ValidateSchedulesDto.HoursWithInstructor for the given CourseId can be different (e.g. when editing the course).
   * @format double
   */
  hoursWithInstructor?: number | null;
  /** The schedules to change/validate */
  schedules?: ApiScheduleBaseDto[] | null;
}

export interface ApiVocationalSchoolDtoType {
  /** @format int32 */
  subjectCount: number;
  practice: boolean;
}

export interface ApiWorkingHour {
  /** @format int64 */
  id: number;
  /** @format int32 */
  courseContractLineId: number;
  /** @format date-time */
  date: string;
  /** @format double */
  amount: number;
  comment?: string | null;
  status?: string | null;
  /** @format int32 */
  approvedBy?: number | null;
  /** @format int32 */
  declinedBy?: number | null;
  /** @format int64 */
  workingHourFileId?: number | null;
  /** @format date-time */
  lastApprovedDate?: string | null;
  /** @format date-time */
  declinedDate?: string | null;
  isSickLeave?: boolean | null;
  isCancelled?: boolean | null;
  /** @format int32 */
  insertedBy: number;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  updatedBy: number;
  /** @format date-time */
  updated: string;
  approvedByEmployee?: ApiEmployee;
  declinedByEmployee?: ApiEmployee;
  courseContractLine?: ApiCourseContractLine;
  workingHourFile?: ApiWorkingHourFile;
}

export interface ApiWorkingHourFile {
  /** @format int64 */
  id: number;
  /** @format int64 */
  fileId?: number | null;
  serviceBusMessageId?: string | null;
  status?: string | null;
  /** @format date-time */
  inserted: string;
  /** @format int32 */
  insertedBy: number;
  workingHour?: ApiWorkingHour[] | null;
  file?: ApiFile;
}

export enum ApiWorkingHourUserIdFilter {
  RESOURCE_USER = "RESOURCE_USER",
  COURSE_SUPERVISOR_ONLY = "COURSE_SUPERVISOR_ONLY",
  COURSE_LECTURER_ONLY = "COURSE_LECTURER_ONLY",
  COURSE_SUPERVISOR_AND_LECTURER = "COURSE_SUPERVISOR_AND_LECTURER",
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "http://aof-it-dev-universumsh-noe-apim.azure-api.net/development/v2/kursadmin",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: Iterable<any> = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Development KursAdmin API v2
 * @version 1.0
 * @baseUrl http://aof-it-dev-universumsh-noe-apim.azure-api.net/development/v2/kursadmin
 *
 * Secure KursAdmin backend API. You need JWT(AD) to access all the operations.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  course = {
    /**
     * No description
     *
     * @tags AppFormResponse, kursadmin
     * @name GetAppFormResponseAsync
     * @request GET:/course/{courseid}/participant/{userId}/appformresponse
     */
    getAppFormResponseAsync: (courseid: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormResponseDto, any>({
        path: `/course/${courseid}/participant/${userId}/appformresponse`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin, minside
     * @name GetCourseDefaultOptions
     * @request GET:/course/defaultoptions
     */
    getCourseDefaultOptions: (params: RequestParams = {}) =>
      this.request<ApiGetCourseDefaultOptionDto, any>({
        path: `/course/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseList
     * @request GET:/course/list
     */
    getCourseList: (
      query?: {
        /**
         *  0 will filter on  all courses
         * <br />  1 will filter on SUPERVISOR_ONLY
         * <br /> 2 will filter on LECTURER_ONLY
         * <br />  3 will filter on SUPERVISOR_AND_LECTURER
         */
        CourseResponsibleFilter?: ApiCourseResponsibleFilter;
        Statuses?: string[];
        /** @format int32 */
        UserId?: number;
        /** @format int32 */
        OrganizerOrganizationId?: number;
        /** @format int32 */
        LecturerId?: number;
        /** @format int32 */
        CourseSupervisorId?: number;
        TeachingMethod?: string;
        /** Checks if query contains part of the CourseName phrase. */
        CourseName?: string;
        /**
         * Checks if query contains the CourseId as part of its ID.
         * @format int32
         */
        CourseId?: number;
        SortBy?: string;
        SortOrder?: ApiSortOrder;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseListDtoPagedResult, any>({
        path: `/course/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseByIdFilteredByDepartmentAsync
     * @request GET:/course/{id}
     */
    getCourseByIdFilteredByDepartmentAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetKursAdminCourseDto, any>({
        path: `/course/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpdateCourseAsync
     * @request PUT:/course/{id}
     */
    updateCourseAsync: (id: number, data: ApiUpdateCourseDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin, minside
     * @name GetCourseDescriptionByIdAsync
     * @request GET:/course/{id}/webdescription
     */
    getCourseDescriptionByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseDescriptionDto, any>({
        path: `/course/${id}/webdescription`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseParticipantsByCourseIdAsync
     * @request GET:/course/{id}/participant
     */
    getCourseParticipantsByCourseIdAsync: (
      id: number,
      query?: {
        RoleNames?: string[];
        Statuses?: string[];
        IsActive?: boolean;
        isResource?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantKursAdminDto[], any>({
        path: `/course/${id}/participant`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpsertCourseParticipantAsync
     * @request POST:/course/{id}/participant
     */
    upsertCourseParticipantAsync: (id: number, data: ApiUpsertCourseParticipantDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/participant`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseResourcesByCourseIdAsync
     * @request GET:/course/{id}/resource
     */
    getCourseResourcesByCourseIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseResourceDto[], any>({
        path: `/course/${id}/resource`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name InsertEmployeeContractLine
     * @request POST:/course/{courseId}/user/{userId}/employeecontractline
     */
    insertEmployeeContractLine: (
      courseId: number,
      userId: number,
      data: ApiUpsertEmployeeContractLineDto,
      params: RequestParams = {}
    ) =>
      this.request<ApiInt32IdDto, string[] | void>({
        path: `/course/${courseId}/user/${userId}/employeecontractline`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name DeleteEmployeeContractLine
     * @request DELETE:/course/employeecontractline/{id}
     */
    deleteEmployeeContractLine: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/course/employeecontractline/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpdateEmployeeContractLine
     * @request PUT:/course/employeecontractline/{id}
     */
    updateEmployeeContractLine: (id: number, data: ApiUpsertEmployeeContractLineDto, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/course/employeecontractline/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetEmployeeContractLines
     * @request GET:/course/{id}/user/{userId}/employeecontractline
     */
    getEmployeeContractLines: (id: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetEmployeeContractLineDtoType[], void>({
        path: `/course/${id}/user/${userId}/employeecontractline`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseContractLines
     * @request GET:/course/{id}/user/{userId}/contractline
     */
    getCourseContractLines: (id: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetContractLineDtoType[], any>({
        path: `/course/${id}/user/${userId}/contractline`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name DeleteCourseResourcesByCourseIdAsync
     * @request DELETE:/course/{id}/resource/{userId}
     */
    deleteCourseResourcesByCourseIdAsync: (id: number, userId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/resource/${userId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name DeleteCourseParticipantByCourseIdAsync
     * @request DELETE:/course/{id}/participant/{userId}
     */
    deleteCourseParticipantByCourseIdAsync: (id: number, userId: number, params: RequestParams = {}) =>
      this.request<void, ApiErrorDtoWithPointers | void>({
        path: `/course/${id}/participant/${userId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CreateCourseAsync
     * @request POST:/course
     */
    createCourseAsync: (data: ApiCreateCourseDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/course`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CreateFacilitationCourseAsync
     * @request POST:/course/{mainCourseId}/facilitationcourse
     */
    createFacilitationCourseAsync: (mainCourseId: number, data: ApiCreateCourseDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/course/${mainCourseId}/facilitationcourse`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reopen a closed course, can only be done by 'Kursadminstrator utvidet'
     *
     * @tags Course, kursadmin
     * @name ReopenCourseAsync
     * @request POST:/course/{id}/reopen
     */
    reopenCourseAsync: (id: number, data: ApiReopenCourseStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/reopen`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name ValidateCloseCourseAsync
     * @request POST:/course/{id}/close/validate
     */
    validateCloseCourseAsync: (
      id: number,
      query?: {
        skipWorkingHours?: boolean;
        skipTeacherHours?: boolean;
        skipAmountOfParticipantsOlderThan14?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiCloseCourseValidationDto, any>({
        path: `/course/${id}/close/validate`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CloseCourseAsync
     * @request POST:/course/{id}/close
     */
    closeCourseAsync: (
      id: number,
      query?: {
        skipWorkingHours?: boolean;
        skipTeacherHours?: boolean;
        skipAmountOfParticipantsOlderThan14?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiCloseCourseValidationDto, any>({
        path: `/course/${id}/close`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CalulateTeachingGrantAmountAsync
     * @request POST:/course/{id}/close/teachinggrant
     */
    calulateTeachingGrantAmountAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, void>({
        path: `/course/${id}/close/teachinggrant`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetTeachingGrantAmountAsync
     * @request GET:/course/{id}/teachinggrant
     */
    getTeachingGrantAmountAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetTeachingGrantAmountDtoIdDto, void>({
        path: `/course/${id}/teachinggrant`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CreateCourseParticipantCommentAsync
     * @request POST:/course/{courseId}/user/{userId}/courseparticipantcomment
     */
    createCourseParticipantCommentAsync: (
      courseId: number,
      userId: number,
      data: ApiCreateCourseParticipantCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/user/${userId}/courseparticipantcomment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseParticipantCommentsAsync
     * @request GET:/course/{courseId}/user/{userId}/courseparticipantcomment
     */
    getCourseParticipantCommentsAsync: (courseId: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantCommentDto[], void>({
        path: `/course/${courseId}/user/${userId}/courseparticipantcomment`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name ChangeCourseParticipantsCommentGuestUserVisibilityAsync
     * @request POST:/course/{courseId}/courseparticipantcomment
     */
    changeCourseParticipantsCommentGuestUserVisibilityAsync: (
      courseId: number,
      data: ApiInt32IdDto[],
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/courseparticipantcomment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpdateCourseParticipantRanksAsync
     * @request PUT:/course/{id}/participant/rank
     */
    updateCourseParticipantRanksAsync: (id: number, data: ApiIdRankDto[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/participant/rank`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseNotesAsync
     * @request GET:/course/{id}/note
     */
    getCourseNotesAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseNoteDto[], any>({
        path: `/course/${id}/note`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name CreateCourseNoteAsync
     * @request POST:/course/{id}/note
     */
    createCourseNoteAsync: (id: number, data: ApiUpsertCourseNoteDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/course/${id}/note`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetCourseNoteAsync
     * @request GET:/course/{id}/note/{courseNoteId}
     */
    getCourseNoteAsync: (id: number, courseNoteId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseNoteDto, any>({
        path: `/course/${id}/note/${courseNoteId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name DeleteCourseNoteAsync
     * @request DELETE:/course/{id}/note/{courseNoteId}
     */
    deleteCourseNoteAsync: (id: number, courseNoteId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/course/${id}/note/${courseNoteId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetOrdersAsync
     * @request GET:/course/{id}/order
     */
    getOrdersAsync: (
      id: number,
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetOrderDto[], any>({
        path: `/course/${id}/order`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name GetPoliceCertificateDtoList
     * @request GET:/course/{id}/police-certificate
     */
    getPoliceCertificateDtoList: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetPoliceCertificateDto[], any>({
        path: `/course/${id}/police-certificate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name ApprovePoliceCertificate
     * @request POST:/course/{id}/police-certificate/approve
     */
    approvePoliceCertificate: (id: number, data: ApiInt32IdDto[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/course/${id}/police-certificate/approve`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name RejectPoliceCertificate
     * @request POST:/course/{id}/police-certificate/decline
     */
    rejectPoliceCertificate: (id: number, data: ApiInt32IdDto[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/course/${id}/police-certificate/decline`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseCertificate, kursadmin
     * @name GetCourseParticipantCertificateList
     * @request GET:/course/{courseId}/certificate
     */
    getCourseParticipantCertificateList: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseCertificateDto, void>({
        path: `/course/${courseId}/certificate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseCertificate, kursadmin
     * @name UpdateCourseParticipantCertificates
     * @request POST:/course/{courseId}/certificate
     */
    updateCourseParticipantCertificates: (
      courseId: number,
      data: ApiUpdateCourseParticipatCertificateBulkDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/certificate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseCertificate, kursadmin
     * @name UpdateCourseParticipantCertificate
     * @request POST:/course/{courseId}/certificate/{userId}
     */
    updateCourseParticipantCertificate: (
      courseId: number,
      userId: number,
      data: ApiUpdateCourseParticipantCertificateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/certificate/${userId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseCertificate, kursadmin
     * @name DeleteCourseParticipantCertificates
     * @request POST:/course/{courseId}/certificate/delete
     */
    deleteCourseParticipantCertificates: (
      courseId: number,
      data: ApiDeleteCourseParticipantCertificateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/certificate/delete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseCertificate, kursadmin
     * @name GenerateCourseParticipantCertificates
     * @request POST:/course/{courseId}/certificate/generate
     */
    generateCourseParticipantCertificates: (
      courseId: number,
      data: ApiGenerateCourseParticipantCertificateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/certificate/generate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseCertificate, kursadmin
     * @name SendCourseParticipantCertificates
     * @request POST:/course/{courseId}/certificate/send
     */
    sendCourseParticipantCertificates: (
      courseId: number,
      data: ApiSendCourseParticipantCertificateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/certificate/send`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseInvitation, kursadmin
     * @name GetCourseInvitations
     * @request GET:/course/{id}/invitation
     */
    getCourseInvitations: (
      id: number,
      query?: {
        /** @format int32 */
        CourseId?: number;
        Statuses?: string[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseInvitationDto[], void>({
        path: `/course/${id}/invitation`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseInvitation, kursadmin
     * @name CreateInvitationsAsync
     * @request POST:/course/{id}/invitation
     */
    createInvitationsAsync: (
      id: number,
      data: ApiCreateCourseInvitationDto[],
      query?: {
        bypassWarnings?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, ApiCourseInvitationValidationDto[] | void>({
        path: `/course/${id}/invitation`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseInvitation, kursadmin
     * @name DeleteInvitationAsync
     * @request DELETE:/course/{courseId}/invitation/{invitationId}
     */
    deleteInvitationAsync: (courseId: number, invitationId: number, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/course/${courseId}/invitation/${invitationId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseParticipantAttendance, kursadmin, minside
     * @name GetCourseParticipantAttendancesByCourseIdAsync
     * @request GET:/course/{courseId}/attendance
     */
    getCourseParticipantAttendancesByCourseIdAsync: (
      courseId: number,
      query?: {
        /**
         * Startdatoen og tidspunktet til timeplanen som fremmøtet gjelder for
         * @format date-time
         */
        ScheduleStart?: string;
        /**
         * Id'en til brukeren som fremmøtet gjelder for
         * @format int32
         */
        UserId?: number;
        /** Brukerens fulle navn */
        UserFullName?: string;
        /** Om fremmøtet er registrert av en bruker */
        IsRegistered?: boolean;
        /** Om fremmøtet er registrert av en KursAdmin-bruker */
        IsSetByKursAdmin?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantAttendanceDto[], string[] | void>({
        path: `/course/${courseId}/attendance`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Merges (creates/updates) the list of attendances for the given course
     *
     * @tags CourseParticipantAttendance, kursadmin, minside
     * @name BatchMergeCourseParticipantAttendancesByCourseIdAsync
     * @request POST:/course/{courseId}/attendance/batchmerge
     */
    batchMergeCourseParticipantAttendancesByCourseIdAsync: (
      courseId: number,
      data: ApiBatchMergeCourseParticipantAttendancesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiCourseParticipantAttendanceBaseDtoValidationResponseDto | void>({
        path: `/course/${courseId}/attendance/batchmerge`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseParticipantAttendance, kursadmin
     * @name DeleteCourseParticipantAttendanceAsync
     * @request DELETE:/course/{courseId}/attendance/{userId}
     */
    deleteCourseParticipantAttendanceAsync: (
      courseId: number,
      userId: number,
      query?: {
        /** @format date-time */
        ScheduleStart?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/course/${courseId}/attendance/${userId}`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * @description Get employment information on user
     *
     * @tags CourseParticipantEmployment, kursadmin
     * @name GetCourseParticipantEmploymentByUser
     * @request GET:/course/{courseId}/employment/user/{userId}
     */
    getCourseParticipantEmploymentByUser: (courseId: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantEmploymentDto[], any>({
        path: `/course/${courseId}/employment/user/${userId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get course participants employment information for course
     *
     * @tags CourseParticipantEmployment, kursadmin
     * @name GetCourseParticipantEmploymentOnCourse
     * @request GET:/course/{courseId}/employment/participants
     */
    getCourseParticipantEmploymentOnCourse: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantEmploymentAggregatedDataDto[], void>({
        path: `/course/${courseId}/employment/participants`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name GetGrantApplicationAsync
     * @request GET:/course/{courseId}/facilitationgrantapplication
     */
    getGrantApplicationAsync: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetFacilitationGrantApplicationDto, any>({
        path: `/course/${courseId}/facilitationgrantapplication`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Creates new facilitation grant application
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name CreateGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication
     */
    createGrantApplication: (
      courseId: number,
      data: ApiCreateFacilitationGrantApplicationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiCreateFacilitationGrantApplicationDtoValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name GetFilteredGrantApplicationsAsync
     * @request GET:/course/facilitationgrantapplication
     */
    getFilteredGrantApplicationsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetFacilitationGrantApplicationDto[], any>({
        path: `/course/facilitationgrantapplication`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Rejects a facilitation grant application
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name RejectFacilitationGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication/reject
     */
    rejectFacilitationGrantApplication: (
      courseId: number,
      data: ApiRejectFacilitationGrantApplicationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiInt32ValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication/reject`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Approves facilitation grant application
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name ApproveFacilitationGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication/approve
     */
    approveFacilitationGrantApplication: (
      courseId: number,
      data: ApiApproveFacilitationGrantApplicationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiApproveFacilitationGrantApplicationDtoValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication/approve`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Approves facilitation grant application costs
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name ApproveActualCostsForFacilitationGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication/approvecosts
     */
    approveActualCostsForFacilitationGrantApplication: (
      courseId: number,
      data: ApiApproveFacilitationGrantApplicationCostsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiApproveFacilitationGrantApplicationCostsDtoValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication/approvecosts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add actual costs a facilitation grant application
     *
     * @tags FacilitationGrantApplication, kursadmin
     * @name AddActualCostsToGrantApplication
     * @request POST:/course/{courseId}/facilitationgrantapplication/addcosts
     */
    addActualCostsToGrantApplication: (
      courseId: number,
      data: ApiAddActualCostsToFacilitationGrantApplicationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiAddActualCostsToFacilitationGrantApplicationDtoValidationResponseDto>({
        path: `/course/${courseId}/facilitationgrantapplication/addcosts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Validates the schedules based on the input data
     *
     * @tags Schedule, kursadmin
     * @name ValidateSchedulesForCourseAsync
     * @request POST:/course/{courseId}/schedule/validate
     */
    validateSchedulesForCourseAsync: (courseId: number, data: ApiValidateSchedulesDto, params: RequestParams = {}) =>
      this.request<void, ApiScheduleBaseDtoValidationResponseDto | void>({
        path: `/course/${courseId}/schedule/validate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Replaces the schedules for the course with the given list
     *
     * @tags Schedule, kursadmin
     * @name BatchUpdateSchedule
     * @request PUT:/course/{courseId}/schedule/batch
     */
    batchUpdateSchedule: (courseId: number, data: ApiScheduleBatchDto, params: RequestParams = {}) =>
      this.request<void, ApiScheduleBaseDtoValidationResponseDto | void>({
        path: `/course/${courseId}/schedule/batch`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  appformtemplate = {
    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin, minside
     * @name GetAppFormTemplatesAsync
     * @request GET:/appformtemplate
     */
    getAppFormTemplatesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormTemplateDto[], any>({
        path: `/appformtemplate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin
     * @name CreateAppFormTemplateAsync
     * @request POST:/appformtemplate
     */
    createAppFormTemplateAsync: (data: ApiCreateApplicationFormTemplateDto, params: RequestParams = {}) =>
      this.request<ApiGuidIdDto, any>({
        path: `/appformtemplate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin, minside
     * @name GetAppFormTemplateAsync
     * @request GET:/appformtemplate/{id}
     */
    getAppFormTemplateAsync: (id: string, params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormTemplateDto, any>({
        path: `/appformtemplate/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin
     * @name UpdateAppFormTemplate
     * @request PUT:/appformtemplate/{id}
     */
    updateAppFormTemplate: (id: string, data: ApiPutAppFormTemplateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/appformtemplate/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppFormTemplate, kursadmin
     * @name DeleteAppFormTemplateAsync
     * @request DELETE:/appformtemplate/{id}
     */
    deleteAppFormTemplateAsync: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/appformtemplate/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  area = {
    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetAreasAsync
     * @request GET:/area
     */
    getAreasAsync: (params: RequestParams = {}) =>
      this.request<ApiGetAreaDto[], any>({
        path: `/area`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name CreateAreaAsync
     * @request POST:/area
     */
    createAreaAsync: (data: ApiUpsertAreaDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/area`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetAreaByIdAsync
     * @request GET:/area/{id}
     */
    getAreaByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetAreaDto, any>({
        path: `/area/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name UpdateAreaAsync
     * @request PUT:/area/{id}
     */
    updateAreaAsync: (id: number, data: ApiUpsertAreaDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/area/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetLocationsAsync
     * @request GET:/area/{id}/location
     */
    getLocationsAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetLocationDto[], any>({
        path: `/area/${id}/location`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name CreateLocationAsync
     * @request POST:/area/{id}/location
     */
    createLocationAsync: (id: number, data: ApiUpsertLocationDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/area/${id}/location`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetLocationAsync
     * @request GET:/area/{id}/location/{locationId}
     */
    getLocationAsync: (id: number, locationId: number, params: RequestParams = {}) =>
      this.request<ApiGetLocationDto, any>({
        path: `/area/${id}/location/${locationId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name UpdateLocationAsync
     * @request PUT:/area/{id}/location/{locationId}
     */
    updateLocationAsync: (id: number, locationId: number, data: ApiUpsertLocationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/area/${id}/location/${locationId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name GetRoomAsync
     * @request GET:/area/{id}/location/{locationId}/room/{roomId}
     */
    getRoomAsync: (id: number, locationId: number, roomId: number, params: RequestParams = {}) =>
      this.request<ApiGetRoomDto, any>({
        path: `/area/${id}/location/${locationId}/room/${roomId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin
     * @name UpdateRoomAsync
     * @request PUT:/area/{id}/location/{locationId}/room/{roomId}
     */
    updateRoomAsync: (
      id: number,
      locationId: number,
      roomId: number,
      data: ApiUpsertRoomDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/area/${id}/location/${locationId}/room/${roomId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Area, kursadmin, minside
     * @name CreateRoomAsync
     * @request POST:/area/{id}/location/{locationId}/room
     */
    createRoomAsync: (id: number, locationId: number, data: ApiUpsertRoomDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/area/${id}/location/${locationId}/room`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  clientaccount = {
    /**
     * No description
     *
     * @tags ClientAccount, kursadmin
     * @name GetClientAccountCourses
     * @summary Get client account courses
     * @request GET:/clientaccount/courses
     */
    getClientAccountCourses: (params: RequestParams = {}) =>
      this.request<ApiGetClientAccountCourseDto[], string[]>({
        path: `/clientaccount/courses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientAccount, kursadmin
     * @name GetClientAccountInvoiceBasis
     * @summary Get client account invoice basis for a course
     * @request GET:/clientaccount/{courseId}/invoicebasis
     */
    getClientAccountInvoiceBasis: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetClientAccountInvoiceBasisDto[], void>({
        path: `/clientaccount/${courseId}/invoicebasis`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new client account invoice basis draft
     *
     * @tags ClientAccount, kursadmin
     * @name CreateClientAccountInvoiceBasis
     * @summary Creates invoice basis draft
     * @request POST:/clientaccount/{courseId}/invoicebasis
     */
    createClientAccountInvoiceBasis: (
      courseId: number,
      data: ApiCreateClientAccountInvoiceBasisDto,
      params: RequestParams = {}
    ) =>
      this.request<ApiClientAccountInvoiceBasisNumberDto, ApiInt32ValidationResponseDto>({
        path: `/clientaccount/${courseId}/invoicebasis`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientAccount, kursadmin
     * @name GetClientAccountInvoiceBasisForApproval
     * @summary Get client account invoice basis for approval
     * @request GET:/clientaccount/invoicebasis/forapproval
     */
    getClientAccountInvoiceBasisForApproval: (params: RequestParams = {}) =>
      this.request<ApiGetClientAccountInvoiceBasisDto[], any>({
        path: `/clientaccount/invoicebasis/forapproval`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientAccount, kursadmin
     * @name GetClientAccountInvoiceBasisTemplate
     * @summary Get client account invoice basis template for a course
     * @request GET:/clientaccount/{courseId}/invoicebasis/template
     */
    getClientAccountInvoiceBasisTemplate: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetClientAccountInvoiceBasisDto, ApiInt32ValidationResponseDto>({
        path: `/clientaccount/${courseId}/invoicebasis/template`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update a client account invoice basis draft
     *
     * @tags ClientAccount, kursadmin
     * @name UpdateClientAccountInvoiceBasis
     * @summary Update invoice basis draft
     * @request PUT:/clientaccount/{courseId}/invoicebasis/{invoiceBasisNumber}
     */
    updateClientAccountInvoiceBasis: (
      courseId: number,
      invoiceBasisNumber: number,
      data: ApiUpdateClientAccountInvoiceBasisDto,
      params: RequestParams = {}
    ) =>
      this.request<void, string[]>({
        path: `/clientaccount/${courseId}/invoicebasis/${invoiceBasisNumber}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes a client account invoice basis that has a status DRAFT
     *
     * @tags ClientAccount, kursadmin
     * @name DeleteClientAccountInvoiceBasis
     * @summary Deletes invoice basis draft
     * @request DELETE:/clientaccount/{courseId}/invoicebasis/{invoiceBasisNumber}
     */
    deleteClientAccountInvoiceBasis: (courseId: number, invoiceBasisNumber: number, params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/clientaccount/${courseId}/invoicebasis/${invoiceBasisNumber}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * @description Send a client account invoice basis draft for approval
     *
     * @tags ClientAccount, kursadmin
     * @name SendClientAccountInvoiceBasisForApproval
     * @summary Send invoice basis draft for approval
     * @request PUT:/clientaccount/{courseId}/invoicebasis/{invoiceBasisNumber}/sendforapproval
     */
    sendClientAccountInvoiceBasisForApproval: (
      courseId: number,
      invoiceBasisNumber: number,
      params: RequestParams = {}
    ) =>
      this.request<void, string[]>({
        path: `/clientaccount/${courseId}/invoicebasis/${invoiceBasisNumber}/sendforapproval`,
        method: "PUT",
        ...params,
      }),

    /**
     * @description Approve a client account invoice basis
     *
     * @tags ClientAccount, kursadmin
     * @name ApproveClientAccountInvoiceBasis
     * @summary Approve invoice basis draft
     * @request PUT:/clientaccount/{courseId}/invoicebasis/{invoiceBasisNumber}/approve
     */
    approveClientAccountInvoiceBasis: (courseId: number, invoiceBasisNumber: number, params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/clientaccount/${courseId}/invoicebasis/${invoiceBasisNumber}/approve`,
        method: "PUT",
        ...params,
      }),

    /**
     * @description Generate invoice basis pdf
     *
     * @tags ClientAccount, kursadmin
     * @name GenerateClientAccountInvoiceBasis
     * @summary Generate invoicebasis pdf
     * @request PUT:/clientaccount/{courseId}/invoicebasis/{invoiceBasisNumber}/generateInvoiceBasisPdf
     */
    generateClientAccountInvoiceBasis: (courseId: number, invoiceBasisNumber: number, params: RequestParams = {}) =>
      this.request<void, string[] | string>({
        path: `/clientaccount/${courseId}/invoicebasis/${invoiceBasisNumber}/generateInvoiceBasisPdf`,
        method: "PUT",
        ...params,
      }),

    /**
     * @description Reject a client account invoice basis
     *
     * @tags ClientAccount, kursadmin
     * @name RejectClientAccountInvoiceBasis
     * @summary Reject invoice basis draft
     * @request PUT:/clientaccount/{courseId}/invoicebasis/{invoiceBasisNumber}/reject
     */
    rejectClientAccountInvoiceBasis: (courseId: number, invoiceBasisNumber: number, params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/clientaccount/${courseId}/invoicebasis/${invoiceBasisNumber}/reject`,
        method: "PUT",
        ...params,
      }),

    /**
     * @description Check if course is considered to be Internal AOF for client account invoice basis.
     *
     * @tags ClientAccount, kursadmin
     * @name DoCourseUseInternalAofAsClientAccountInvoiceBasis
     * @summary Update invoice basis draft
     * @request GET:/clientaccount/{courseId}/invoicebasis/isAOFClientAccountInvoiceBasis
     */
    doCourseUseInternalAofAsClientAccountInvoiceBasis: (courseId: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/clientaccount/${courseId}/invoicebasis/isAOFClientAccountInvoiceBasis`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  clientaccountrate = {
    /**
     * No description
     *
     * @tags ClientAccountRate, kursadmin
     * @name GetClientAccountRateOverviewAsync
     * @summary Get list of years with client account rates and whether the year is active or not
     * @request GET:/clientaccountrate
     */
    getClientAccountRateOverviewAsync: (params: RequestParams = {}) =>
      this.request<ApiGetClientAccountRateOverviewDto[], any>({
        path: `/clientaccountrate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Creates client account rates for a new year as a copy of a previous year
     *
     * @tags ClientAccountRate, kursadmin
     * @name CreateClientAccountRatesAsync
     * @summary Creates client account rates for a new year as a copy of a previous year
     * @request POST:/clientaccountrate
     */
    createClientAccountRatesAsync: (
      query?: {
        /**
         * Year to create client account rates for
         * @format int32
         */
        year?: number;
        /**
         * Optional, copy from latest year if not specified
         * @format int32
         */
        copyFromYear?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, string[]>({
        path: `/clientaccountrate`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientAccountRate, kursadmin
     * @name GetClientAccountRatesAsync
     * @summary Get client account rates for a specific year
     * @request GET:/clientaccountrate/{year}
     */
    getClientAccountRatesAsync: (year: number, params: RequestParams = {}) =>
      this.request<ApiGetClientAccountRateDto[], void>({
        path: `/clientaccountrate/${year}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update client account rates
     *
     * @tags ClientAccountRate, kursadmin
     * @name UpdateClientAccountRatesAsync
     * @summary Update client account rates for a specific year and member organization
     * @request PUT:/clientaccountrate/{year}/{memberOrganizationId}
     */
    updateClientAccountRatesAsync: (
      year: number,
      memberOrganizationId: number,
      data: ApiUpdateClientAccountRateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, ApiUpdateClientAccountRateDtoValidationResponseDto | void>({
        path: `/clientaccountrate/${year}/${memberOrganizationId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  communicationtemplate = {
    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name GetCommunicationTemplatesAsync
     * @request GET:/communicationtemplate
     */
    getCommunicationTemplatesAsync: (
      query?: {
        Search?: string;
        CommunicationTemplateGroupIds?: number[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCommunicationTemplateDto[], any>({
        path: `/communicationtemplate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name CreateCommunicationTemplateAsync
     * @request POST:/communicationtemplate
     */
    createCommunicationTemplateAsync: (data: ApiCreateCommunicationTemplateDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, string[]>({
        path: `/communicationtemplate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name GetCommunicationTemplateByIdAsync
     * @request GET:/communicationtemplate/{id}
     */
    getCommunicationTemplateByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCommunicationTemplateDto, void>({
        path: `/communicationtemplate/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name UpdateCommunicationTemplateAsync
     * @request PUT:/communicationtemplate/{id}
     */
    updateCommunicationTemplateAsync: (
      id: number,
      data: ApiUpdateCommunicationTemplateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, string[] | void>({
        path: `/communicationtemplate/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplate, kursadmin
     * @name DeleteCommunicationTemplateAsync
     * @request DELETE:/communicationtemplate/{id}
     */
    deleteCommunicationTemplateAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/communicationtemplate/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
  communicationtemplategroup = {
    /**
     * No description
     *
     * @tags CommunicationTemplateGroup, kursadmin
     * @name GetCommunicationTemplateGroupsAsync
     * @request GET:/communicationtemplategroup
     */
    getCommunicationTemplateGroupsAsync: (
      query?: {
        Name?: string;
        Types?: string[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCommunicationTemplateGroupDto[], any>({
        path: `/communicationtemplategroup`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommunicationTemplateGroup, kursadmin
     * @name GetCommunicationTemplateGroupByIdAsync
     * @request GET:/communicationtemplategroup/{id}
     */
    getCommunicationTemplateGroupByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCommunicationTemplateGroupDto, void>({
        path: `/communicationtemplategroup/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  economy = {
    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetCourseEconomyBaseAsync
     * @request GET:/economy/defaultoption
     */
    getCourseEconomyBaseAsync: (params: RequestParams = {}) =>
      this.request<ApiCourseEconomyBaseDataDto, any>({
        path: `/economy/defaultoption`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetCostsPerOrganizationAsync
     * @request GET:/economy/cost
     */
    getCostsPerOrganizationAsync: (
      query?: {
        CostTypeCostGroup?: string;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCostDto[], any>({
        path: `/economy/cost`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name CreateCostAsync
     * @request POST:/economy/cost
     */
    createCostAsync: (data: ApiCreateCostDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/economy/cost`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetCostAsync
     * @request GET:/economy/cost/{id}
     */
    getCostAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCostDto, any>({
        path: `/economy/cost/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name UpdateCostAsync
     * @request PUT:/economy/cost/{id}
     */
    updateCostAsync: (id: number, data: ApiUpdateCostDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/economy/cost/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin, minside
     * @name GetCostTypesAsync
     * @request GET:/economy/costtype
     */
    getCostTypesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetCostTypeDto[], any>({
        path: `/economy/costtype`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetContractsAsync
     * @request GET:/economy/course/{courseId}/contract
     */
    getContractsAsync: (
      courseId: number,
      query?: {
        Statuses?: string[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetContractSimpleDto[], any>({
        path: `/economy/course/${courseId}/contract`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetContractsForCourseAndUserAsync
     * @request GET:/economy/course/{courseId}/user/{userId}/contract
     */
    getContractsForCourseAndUserAsync: (
      courseId: number,
      userId: number,
      query?: {
        Statuses?: string[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetContractWithFileDto[], any>({
        path: `/economy/course/${courseId}/user/${userId}/contract`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name CreateContractAsync
     * @request POST:/economy/course/{courseId}/user/{userId}/contract
     */
    createContractAsync: (courseId: number, userId: number, data: ApiCreateContractDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/economy/course/${courseId}/user/${userId}/contract`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin, minside
     * @name GetContractAsync
     * @request GET:/economy/contract/{id}
     */
    getContractAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetContractWithFileDto, any>({
        path: `/economy/contract/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name DeleteContractAsync
     * @request DELETE:/economy/contract/{id}
     */
    deleteContractAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name UpdateContractAsync
     * @request PUT:/economy/contract/{id}
     */
    updateContractAsync: (id: number, data: ApiUpdateContractDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name SendContractForSigningAsync
     * @request POST:/economy/contract/{id}/send-for-signing
     */
    sendContractForSigningAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}/send-for-signing`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name ResendContractForSigningAsync
     * @request POST:/economy/contract/{id}/resend
     */
    resendContractForSigningAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}/resend`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name SendContractNotificationAsync
     * @request POST:/economy/contract/{id}/send-notification
     */
    sendContractNotificationAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}/send-notification`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name SendContractCancellationRequestAsync
     * @request POST:/economy/contract/{id}/cancel
     */
    sendContractCancellationRequestAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}/cancel`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name ChangeContractStatusAsync
     * @request POST:/economy/contract/{id}/status
     */
    changeContractStatusAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/economy/contract/${id}/status`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetWorkingHourAsync
     * @request GET:/economy/workinghour/{id}
     */
    getWorkingHourAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetWorkingHourDto, void>({
        path: `/economy/workinghour/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetEmployeeWorkingHourAsync
     * @request GET:/economy/workinghour/employee/{id}
     */
    getEmployeeWorkingHourAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetEmployeeWorkingHourDto, void>({
        path: `/economy/workinghour/employee/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name GetWorkingHoursAsync
     * @request GET:/economy/workinghour
     */
    getWorkingHoursAsync: (
      query?: {
        OnlySupervisedCourses?: boolean;
        /** @format int32 */
        UserId?: number;
        IncludeEmployeeHours?: boolean;
        WorkingHourUserIdFilter?: ApiWorkingHourUserIdFilter;
        /** @format int32 */
        CourseId?: number;
        Statuses?: string[];
        /** @format date-time */
        FromDate?: string;
        /** @format date-time */
        ToDate?: string;
        IsSelfEmployed?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetWorkingHourDto[], any>({
        path: `/economy/workinghour`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name CreateWorkingHourAdminAsync
     * @request POST:/economy/workinghour/admin
     */
    createWorkingHourAdminAsync: (data: ApiCreateWorkingHourDto, params: RequestParams = {}) =>
      this.request<ApiInt64IdDto, string[]>({
        path: `/economy/workinghour/admin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name CreateEmployeeWorkingHourAsync
     * @request POST:/economy/workinghour/employee/admin
     */
    createEmployeeWorkingHourAsync: (data: ApiCreateEmployeeWorkingHourDto, params: RequestParams = {}) =>
      this.request<ApiInt64IdDto, string[]>({
        path: `/economy/workinghour/employee/admin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name UpdateWorkingHourAdminAsync
     * @request PUT:/economy/workinghour/{id}/admin
     */
    updateWorkingHourAdminAsync: (id: number, data: ApiUpdateWorkingHourDto, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/economy/workinghour/${id}/admin`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name DeleteWorkingHourAdminAsync
     * @request DELETE:/economy/workinghour/{id}/admin
     */
    deleteWorkingHourAdminAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/economy/workinghour/${id}/admin`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name UpdateEmployeeWorkingHourAsync
     * @request PUT:/economy/workinghour/employee/{id}/admin
     */
    updateEmployeeWorkingHourAsync: (id: number, data: ApiCreateEmployeeWorkingHourDto, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/economy/workinghour/employee/${id}/admin`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name DeleteWorkingHourEmployeeAsync
     * @request DELETE:/economy/workinghour/employee/{id}/admin
     */
    deleteWorkingHourEmployeeAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/economy/workinghour/employee/${id}/admin`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name ApproveWorkingHourAsync
     * @request POST:/economy/workinghour/approve
     */
    approveWorkingHourAsync: (data: number[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/economy/workinghour/approve`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name UnapproveWorkingHourAsync
     * @request POST:/economy/workinghour/unapprove
     */
    unapproveWorkingHourAsync: (data: number[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/economy/workinghour/unapprove`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name DeclineWorkingHourAsync
     * @request POST:/economy/workinghour/decline
     */
    declineWorkingHourAsync: (data: number[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/economy/workinghour/decline`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cost, kursadmin
     * @name SetPaymentPendingWorkingHourAsync
     * @request POST:/economy/workinghour/setpaymentpending
     */
    setPaymentPendingWorkingHourAsync: (data: number[], params: RequestParams = {}) =>
      this.request<void, string[]>({
        path: `/economy/workinghour/setpaymentpending`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grant, kursadmin
     * @name GetGrantRates
     * @request GET:/economy/grant/grantrate
     */
    getGrantRates: (params: RequestParams = {}) =>
      this.request<ApiGetGrantRateDto[], any>({
        path: `/economy/grant/grantrate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grant, kursadmin
     * @name CreateGrantRate
     * @request POST:/economy/grant/grantrate/{year}
     */
    createGrantRate: (year: number, data: ApiUpsertGrantRateDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/economy/grant/grantrate/${year}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Grant, kursadmin
     * @name UpdateGrantRate
     * @request PUT:/economy/grant/grantrate/{year}
     */
    updateGrantRate: (year: number, data: ApiUpsertGrantRateDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/economy/grant/grantrate/${year}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  extended = {
    /**
     * No description
     *
     * @tags Course, kursadmin
     * @name UpdateCourseExtendedAsync
     * @request PUT:/extended/course/{id}
     */
    updateCourseExtendedAsync: (id: number, data: ApiUpdateCourseExtendedDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/extended/course/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  coursearea = {
    /**
     * No description
     *
     * @tags CourseArea, kursadmin, minside
     * @name GetCourseAreasAsync
     * @request GET:/coursearea
     */
    getCourseAreasAsync: (params: RequestParams = {}) =>
      this.request<ApiHierarchicalAreaDtoType[], any>({
        path: `/coursearea`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseArea, kursadmin
     * @name CreateCourseAreaAsync
     * @request POST:/coursearea
     */
    createCourseAreaAsync: (data: ApiCreateCourseAreaDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/coursearea`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseArea, kursadmin, minside
     * @name GetCourseAreaAsync
     * @request GET:/coursearea/{id}
     */
    getCourseAreaAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseAreaDto, any>({
        path: `/coursearea/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseArea, kursadmin
     * @name UpdateCourseAreaAsync
     * @request PUT:/coursearea/{id}
     */
    updateCourseAreaAsync: (id: number, data: ApiUpdateCourseAreaDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coursearea/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  offerletter = {
    /**
     * No description
     *
     * @tags CourseParticipantOfferletter, kursadmin
     * @name GetOfferLetterParticipants
     * @request GET:/offerletter/{courseId}
     */
    getOfferLetterParticipants: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetOfferletterWithParticipantsDto, string[] | void>({
        path: `/offerletter/${courseId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseParticipantOfferletter, kursadmin
     * @name UpsertAndGenerateOfferLetter
     * @request POST:/offerletter/{courseId}/{userId}
     */
    upsertAndGenerateOfferLetter: (
      courseId: number,
      userId: number,
      data: ApiUpsertCourseParticipantOfferletterCourseVariablesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/offerletter/${courseId}/${userId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CourseParticipantOfferletter, kursadmin
     * @name SendOfferlettersToSigning
     * @request POST:/offerletter/{courseId}/send
     */
    sendOfferlettersToSigning: (courseId: number, data: ApiUserIdsDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/offerletter/${courseId}/send`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  courseparticipantstatus = {
    /**
     * @description Retrieve a list of user's CourseParticipantStatus.
     *
     * @tags CourseParticipantStatus, kursadmin
     * @name GetCourseParticipantStatusesByUserIdAsync
     * @request GET:/courseparticipantstatus/{userId}
     */
    getCourseParticipantStatusesByUserIdAsync: (userId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantStatusDto[], void>({
        path: `/courseparticipantstatus/${userId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  travelAndExpense = {
    /**
     * @description Get expense by Id
     *
     * @tags CourseParticipantTravelAndExpense, minside, kursadmin
     * @name GetCourseParticipantTravelAndExpense
     * @request GET:/travel-and-expense/{expenseId}
     */
    getCourseParticipantTravelAndExpense: (expenseId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantTravelAndExpenseDto, void>({
        path: `/travel-and-expense/${expenseId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update course participant expense
     *
     * @tags CourseParticipantTravelAndExpense, minside, kursadmin
     * @name UpdateCourseParticipantTravelAndExpense
     * @request PUT:/travel-and-expense/{expenseId}
     */
    updateCourseParticipantTravelAndExpense: (
      expenseId: number,
      data: ApiUpdateCourseParticipantTravelAndExpenseDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/travel-and-expense/${expenseId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get expense list
     *
     * @tags CourseParticipantTravelAndExpense, minside, kursadmin
     * @name GetCourseParticipantTravelAndExpenseList
     * @request GET:/travel-and-expense
     */
    getCourseParticipantTravelAndExpenseList: (
      query?: {
        /** @format int32 */
        CourseId?: number;
        Statuses?: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantTravelAndExpenseListDto[], void>({
        path: `/travel-and-expense`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Approve expense
     *
     * @tags CourseParticipantTravelAndExpense, kursadmin
     * @name ApproveCourseParticipantTravelAndExpense
     * @request PUT:/travel-and-expense/{expenseId}/approve
     */
    approveCourseParticipantTravelAndExpense: (expenseId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/travel-and-expense/${expenseId}/approve`,
        method: "PUT",
        ...params,
      }),

    /**
     * @description Reject expense
     *
     * @tags CourseParticipantTravelAndExpense, kursadmin
     * @name RejectCourseParticipantTravelAndExpense
     * @request PUT:/travel-and-expense/{expenseId}/reject
     */
    rejectCourseParticipantTravelAndExpense: (
      expenseId: number,
      data: ApiRejectCourseParticipantTravelAndExpenseDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/travel-and-expense/${expenseId}/reject`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Creates an order from an expense
     *
     * @tags CourseParticipantTravelAndExpense, kursadmin
     * @name CreateOrderFromCourseParticipantTravelAndExpense
     * @request POST:/travel-and-expense/{expenseId}/create-order
     */
    createOrderFromCourseParticipantTravelAndExpense: (expenseId: number, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, void>({
        path: `/travel-and-expense/${expenseId}/create-order`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  curriculum = {
    /**
     * No description
     *
     * @tags Curriculum, minside, kursadmin
     * @name GetCurriculumDefaultoptionsAsync
     * @request GET:/curriculum/defaultoptions
     */
    getCurriculumDefaultoptionsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetCurriculumDefaultOptionDto, any>({
        path: `/curriculum/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetCurriculumAsync
     * @request GET:/curriculum/{id}
     */
    getCurriculumAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCurriculumDto, any>({
        path: `/curriculum/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name UpdateCurriculumAsync
     * @request PUT:/curriculum/{id}
     */
    updateCurriculumAsync: (id: number, data: ApiUpsertCurriculumDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name DeleteCurriculumAsync
     * @request DELETE:/curriculum/{id}
     */
    deleteCurriculumAsync: (
      id: number,
      query?: {
        /** @format int32 */
        statusId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/curriculum/${id}`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetCoursesByCurriculumIdAsync
     * @request GET:/curriculum/{id}/course
     */
    getCoursesByCurriculumIdAsync: (
      id: number,
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetShortCourseDto[], any>({
        path: `/curriculum/${id}/course`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetCurriculaAsync
     * @request GET:/curriculum
     */
    getCurriculaAsync: (
      query?: {
        /** @format int32 */
        MainCourseId?: number;
        /** @format int32 */
        SubCourseId?: number;
        /** @format int32 */
        InsertedBy?: number;
        OnlyActiveMainAndSubCourses?: boolean;
        TeachingMethod?: string;
        StatusIds?: number[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetShortCurriculumDto[], any>({
        path: `/curriculum`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name CreateCurriculumAsync
     * @request POST:/curriculum
     */
    createCurriculumAsync: (data: ApiUpsertCurriculumDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/curriculum`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name ApproveCurriculumAsync
     * @request PUT:/curriculum/{id}/status
     */
    approveCurriculumAsync: (id: number, data: ApiUpsertCurriculumStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}/status`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetExecutionPlanAsync
     * @request GET:/curriculum/{id}/executionplan
     */
    getExecutionPlanAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetExecutionPlanDto, any>({
        path: `/curriculum/${id}/executionplan`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name UpsertExecutionPlanAsync
     * @request POST:/curriculum/{id}/executionplan
     */
    upsertExecutionPlanAsync: (id: number, data: ApiExecutionPlanDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}/executionplan`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name DeleteExecutionPlanAsync
     * @request DELETE:/curriculum/{id}/executionplan
     */
    deleteExecutionPlanAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/${id}/executionplan`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetDraftsPerUserAsync
     * @request GET:/curriculum/draft
     */
    getDraftsPerUserAsync: (params: RequestParams = {}) =>
      this.request<ApiUpsertCurriculumDto[], any>({
        path: `/curriculum/draft`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name CreateDraftAsync
     * @request POST:/curriculum/draft
     */
    createDraftAsync: (data: ApiUpsertCurriculumDto, params: RequestParams = {}) =>
      this.request<ApiGuidIdDto, any>({
        path: `/curriculum/draft`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetDraftPerIdAndUserId
     * @request GET:/curriculum/draft/{id}
     */
    getDraftPerIdAndUserId: (id: string, params: RequestParams = {}) =>
      this.request<ApiUpsertCurriculumDto, any>({
        path: `/curriculum/draft/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name UpdateDraftAsync
     * @request PUT:/curriculum/draft/{id}
     */
    updateDraftAsync: (id: string, data: ApiUpsertCurriculumDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/draft/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name DeleteDraftAsync
     * @request DELETE:/curriculum/draft/{id}
     */
    deleteDraftAsync: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/curriculum/draft/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name CreateNusCodeAsync
     * @request POST:/curriculum/nuscode
     */
    createNusCodeAsync: (data: ApiInsertNusCodeDto, params: RequestParams = {}) =>
      this.request<ApiGuidIdDto, string[]>({
        path: `/curriculum/nuscode`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name GetNusCodes
     * @request GET:/curriculum/nuscode
     */
    getNusCodes: (
      query?: {
        /** @format int32 */
        orgId?: number;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetNusCodeDto[], any>({
        path: `/curriculum/nuscode`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Curriculum, kursadmin
     * @name UpdateNusCodeAsync
     * @request PUT:/curriculum/nuscode/{code}
     */
    updateNusCodeAsync: (code: string, data: ApiUpdateNusCodeDto, params: RequestParams = {}) =>
      this.request<ApiGuidIdDto, string[] | void>({
        path: `/curriculum/nuscode/${code}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CurriculumSubjectCode, kursadmin
     * @name GetCurriculumSubjectCode
     * @request GET:/curriculum/subject-code/{id}
     */
    getCurriculumSubjectCode: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCurriculumSubjectCodeDto, void>({
        path: `/curriculum/subject-code/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CurriculumSubjectCode, kursadmin
     * @name UpdateCurriculumSubjectCode
     * @request PUT:/curriculum/subject-code/{id}
     */
    updateCurriculumSubjectCode: (id: number, data: ApiUpsertCurriculumSubjectCodeDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/curriculum/subject-code/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CurriculumSubjectCode, kursadmin
     * @name DeleteCurriculumSubjectCode
     * @request DELETE:/curriculum/subject-code/{id}
     */
    deleteCurriculumSubjectCode: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/curriculum/subject-code/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CurriculumSubjectCode, kursadmin
     * @name GetCurriculumSubjectCodes
     * @request GET:/curriculum/subject-code
     */
    getCurriculumSubjectCodes: (
      query?: {
        subjectCodeState?: ApiCurriculumSubjectCodeStateFilter;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCurriculumSubjectCodeDto[], any>({
        path: `/curriculum/subject-code`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CurriculumSubjectCode, kursadmin
     * @name CreateCurriculumSubjectCode
     * @request POST:/curriculum/subject-code
     */
    createCurriculumSubjectCode: (data: ApiUpsertCurriculumSubjectCodeDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, void>({
        path: `/curriculum/subject-code`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  customer = {
    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerPersonAddressList
     * @request GET:/customer/addresslist/person
     */
    getCustomerPersonAddressList: (
      query?: {
        FirstName?: string;
        LastName?: string;
        Email?: string;
        MobileNumber?: string;
        IncludeInactive?: boolean;
        /**
         * The customer person types to include. By default it only includes SYSCO.Universum.Common.Constants.CustomerPersonType.B2C.
         * Specify other types (e.g. SYSCO.Universum.Common.Constants.CustomerPersonType.RESTRICTED) to vary the results.
         */
        Types?: string[];
        SortBy?: string;
        SortOrder?: ApiSortOrder;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCustomerPersonAddressListDtoPagedResult, any>({
        path: `/customer/addresslist/person`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerPersonsAsync
     * @request GET:/customer/person
     */
    getCustomerPersonsAsync: (
      query?: {
        FirstName?: string;
        LastName?: string;
        Email?: string;
        MobileNumber?: string;
        /**
         * The customer person types to include. By default it only includes SYSCO.Universum.Common.Constants.CustomerPersonType.B2C.
         * Specify other types (e.g. SYSCO.Universum.Common.Constants.CustomerPersonType.RESTRICTED) to vary the results.
         */
        Types?: string[];
        IsCourseParticipant?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCustomerDto[], any>({
        path: `/customer/person`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerOrganizationAddressList
     * @request GET:/customer/addresslist/organization
     */
    getCustomerOrganizationAddressList: (
      query?: {
        Name?: string;
        OrgNumber?: string;
        Email?: string;
        MobileNumber?: string;
        IncludeInactive?: boolean;
        SortBy?: string;
        SortOrder?: ApiSortOrder;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCustomerOrganizationAddressListDtoPagedResult, any>({
        path: `/customer/addresslist/organization`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerOrganizationsAsync
     * @request GET:/customer/organization
     */
    getCustomerOrganizationsAsync: (
      query?: {
        Name?: string;
        Orgno?: string;
        Email?: string;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCustomerDto[], any>({
        path: `/customer/organization`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name CreateCustomerOrganizationAsync
     * @request POST:/customer/organization
     */
    createCustomerOrganizationAsync: (data: ApiUpsertCustomerOrgDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/customer/organization`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerOrgByIdAsync
     * @request GET:/customer/organization/{id}
     */
    getCustomerOrgByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCustomerDto, any>({
        path: `/customer/organization/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name UpdateOrganizationAsync
     * @request PUT:/customer/organization/{id}
     */
    updateOrganizationAsync: (id: number, data: ApiUpsertCustomerOrgDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/customer/organization/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name GetCustomerPersonByIdAsync
     * @request GET:/customer/person/{id}
     */
    getCustomerPersonByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCustomerDto, void>({
        path: `/customer/person/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name UpdateCustomerPersonEmailAsync
     * @request POST:/customer/customer/person/{id}/email
     */
    updateCustomerPersonEmailAsync: (
      id: number,
      query?: {
        emailAddress?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, string[]>({
        path: `/customer/customer/person/${id}/email`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, kursadmin, minside
     * @name VerifyContactInfoForPersonAsync
     * @request POST:/customer/person/{id}/verify-contact-info
     */
    verifyContactInfoForPersonAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/customer/person/${id}/verify-contact-info`,
        method: "POST",
        ...params,
      }),
  };
  courseadmin = {
    /**
     * No description
     *
     * @tags Customer, kursadmin
     * @name UpdateCustomerPersonAdminAsync
     * @request PUT:/courseadmin/customer/person/{id}
     */
    updateCustomerPersonAdminAsync: (id: number, data: ApiUpdateCustomerPersonDto, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/courseadmin/customer/person/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  dashboard = {
    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetDashboardSelfAsync
     * @request GET:/dashboard/self
     */
    getDashboardSelfAsync: (
      query?: {
        getCurriculumForApprovalCount?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetDashboardDto, any>({
        path: `/dashboard/self`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetDashboardAsync
     * @summary Get dashboard for a user without department access control
     * @request GET:/dashboard
     */
    getDashboardAsync: (
      query?: {
        /**
         * UserID for a user to get dashboard for
         * @format int32
         */
        userId?: number;
        /**
         * 0 will filter on  all courses
         *             <br />  1 will filter on SUPERVISOR_ONLY
         *             <br /> 2 will filter on LECTURER_ONLY
         */
        courseResponsibleFilter?: ApiCourseResponsibleFilter;
        getCurriculumForApprovalCount?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetDashboardDto, any>({
        path: `/dashboard`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetUnreadCourseApplications
     * @request GET:/dashboard/participant/unread
     */
    getUnreadCourseApplications: (
      query?: {
        /** @format int32 */
        userId?: number;
        /** @format int32 */
        courseId?: number;
        courseName?: string;
        /** @format int32 */
        areaId?: number;
        areaName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetUnreadCourseApplicationsDto[], any>({
        path: `/dashboard/participant/unread`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name MarkCourseApplicationsAsReadAsync
     * @request POST:/dashboard/participant/markasread
     */
    markCourseApplicationsAsReadAsync: (data: ApiMarkCourseApplicationsAsReadDto[], params: RequestParams = {}) =>
      this.request<ApiInt64IdDto, string[]>({
        path: `/dashboard/participant/markasread`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetChangedCourseParticipantApplications
     * @request GET:/dashboard/changedapplications/unread
     */
    getChangedCourseParticipantApplications: (
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetChangedCourseParticipantApplicationDto[], any>({
        path: `/dashboard/changedapplications/unread`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name MarkChangedCourseParticipantApplicationsRead
     * @request PUT:/dashboard/changedapplications/markasread
     */
    markChangedCourseParticipantApplicationsRead: (
      data: ApiMarkCourseParticipantGuestUserChangeDto,
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, string[]>({
        path: `/dashboard/changedapplications/markasread`,
        method: "PUT",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetCoursesWithNewDocuments
     * @request GET:/dashboard/newdocument
     */
    getCoursesWithNewDocuments: (
      query?: {
        CourseIds?: number[];
        CourseNames?: string[];
        AreaLocations?: string[];
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCoursesAndNewDocumentsDto[], any>({
        path: `/dashboard/newdocument`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name MarkNewDocumentsAsRead
     * @request PUT:/dashboard/newdocument/markasread
     */
    markNewDocumentsAsRead: (data: ApiPostNewDocumentsReadStatusDto[], params: RequestParams = {}) =>
      this.request<ApiInt64IdDto, string[]>({
        path: `/dashboard/newdocument/markasread`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Dashboard, kursadmin
 * @name GetNonJournaledClosedCoursesEconomyResponsilbe
 * @summary Overview over grantId 20/25 closed courses with none or at least one client account invoice basi with status 'draft' or "sent-for-aproval'
this endpoint is only available for economy responsible ad group
 * @request GET:/dashboard/nonjournaledcourses/economyresponsible
 */
    getNonJournaledClosedCoursesEconomyResponsilbe: (
      query?: {
        /**
         * Sorteringsparameter
         * 'courseId' for kurs id
         * 'courseName' for kursnavn
         * 'organizerOrganizationName' for arrangør
         * 'endDate' for endDate
         */
        SortBy?: string;
        SortOrder?: ApiSortOrder;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetNonJournaledCoursesDtoPagedResult, any>({
        path: `/dashboard/nonjournaledcourses/economyresponsible`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Dashboard, kursadmin
 * @name GetNonJournaledClosedCourses
 * @summary Overview over grantId 20/25 closed courses with none or at least one client account invoice basi with status 'draft' or "sent-for-aproval'
 available for non economy responsible
 * @request GET:/dashboard/nonjournaledcourses
 */
    getNonJournaledClosedCourses: (
      query?: {
        /**
         * Sorteringsparameter
         * 'courseId' for kurs id
         * 'courseName' for kursnavn
         * 'organizerOrganizationName' for arrangør
         * 'endDate' for endDate
         */
        SortBy?: string;
        SortOrder?: ApiSortOrder;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
        courseResponsibleFilter?: ApiCourseResponsibleFilter;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetNonJournaledCoursesDtoPagedResult, any>({
        path: `/dashboard/nonjournaledcourses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard, kursadmin
     * @name GetUnsignedFailedAndExpiredCourseContracts
     * @summary Overview over unsigned and failed coursecontracts
     * @request GET:/dashboard/coursecontracts
     */
    getUnsignedFailedAndExpiredCourseContracts: (
      query?: {
        courseResponsibleFilter?: ApiCourseResponsibleFilter;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetDashboardCourseContractDto[], any>({
        path: `/dashboard/coursecontracts`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  erp = {
    /**
     * No description
     *
     * @tags Erp, kursadmin
     * @name GetErpEmployees
     * @request GET:/erp/employee
     */
    getErpEmployees: (params: RequestParams = {}) =>
      this.request<ApiErpEmployeeDtoType[], any>({
        path: `/erp/employee`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Erp, kursadmin
     * @name GetPayrollEmployees
     * @request GET:/erp/employee/payroll
     */
    getPayrollEmployees: (params: RequestParams = {}) =>
      this.request<ApiErpEmployeeDtoType[], any>({
        path: `/erp/employee/payroll`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Erp, kursadmin
     * @name ImportErpEmployee
     * @request POST:/erp/employee/{employeeNo}
     */
    importErpEmployee: (employeeNo: number, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/erp/employee/${employeeNo}`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags Erp, kursadmin
     * @name SyncErpPayrollEmployee
     * @request POST:/user/{userId}/erp/employee/payroll/sync
     */
    syncErpPayrollEmployee: (userId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/${userId}/erp/employee/payroll/sync`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetUserAsync
     * @request GET:/user/{id}
     */
    getUserAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetUserDto, any>({
        path: `/user/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetEmployeeAsync
     * @request GET:/user/employee/{id}
     */
    getEmployeeAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCurrentEmployeeDto, void>({
        path: `/user/employee/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetEmployeesAsync
     * @request GET:/user/employee
     */
    getEmployeesAsync: (
      query?: {
        FirstName?: string;
        LastName?: string;
        Email?: string;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetEmployeeDto[], any>({
        path: `/user/employee`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetGuestEmployeesAsync
     * @request GET:/user/employee/guest
     */
    getGuestEmployeesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetGuestEmployeeDto[], any>({
        path: `/user/employee/guest`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User, kursadmin
     * @name GetCurrentEmployeeAsync
     * @request GET:/user/employee/self
     */
    getCurrentEmployeeAsync: (params: RequestParams = {}) =>
      this.request<ApiGetCurrentEmployeeDto, any>({
        path: `/user/employee/self`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  file = {
    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetFileDefaultOption
     * @request GET:/file/defaultoptions
     */
    getFileDefaultOption: (params: RequestParams = {}) =>
      this.request<ApiGetFileDefaultOptionDto, any>({
        path: `/file/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, minside, kursadmin
     * @name GetMinSideFileDefaultOption
     * @request GET:/file/minside/defaultoptions
     */
    getMinSideFileDefaultOption: (params: RequestParams = {}) =>
      this.request<ApiGetFileDefaultOptionDto, any>({
        path: `/file/minside/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, minside, kursadmin
     * @name DownloadFileAsync
     * @request GET:/file/{id}
     */
    downloadFileAsync: (id: number, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/file/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name DeleteFileAsync
     * @request DELETE:/file/{id}
     */
    deleteFileAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/file/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name UpdateFileCategoryAsync
     * @request PUT:/file/{id}/category
     */
    updateFileCategoryAsync: (id: number, data: ApiUpdateFileCategoryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/file/${id}/category`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, minside, kursadmin
     * @name GetCurriculumFilesAsync
     * @request GET:/file/curriculum/{curriculumId}
     */
    getCurriculumFilesAsync: (curriculumId: number, params: RequestParams = {}) =>
      this.request<ApiGetCurriculumFileDto[], any>({
        path: `/file/curriculum/${curriculumId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateCurriculumFileAsync
     * @request POST:/file/curriculum/{curriculumId}
     */
    createCurriculumFileAsync: (
      curriculumId: number,
      data: {
        Category?: string;
        /** @format binary */
        File?: File;
        Description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/curriculum/${curriculumId}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetCourseFilesAsync
     * @request GET:/file/course/{courseId}
     */
    getCourseFilesAsync: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseRelatedFileDto[], any>({
        path: `/file/course/${courseId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateCourseFileAsync
     * @request POST:/file/course/{courseId}
     */
    createCourseFileAsync: (
      courseId: number,
      data: {
        Category?: string;
        /** @format binary */
        File?: File;
        Description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/course/${courseId}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateFacilitationGrantApplicationFileAsync
     * @request POST:/file/course/{courseId}/facilitationgrantapplication
     */
    createFacilitationGrantApplicationFileAsync: (
      courseId: number,
      data: {
        Category?: string;
        /** @format binary */
        File?: File;
        Description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/course/${courseId}/facilitationgrantapplication`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetFacilitationGrantApplicationFilesAsync
     * @request GET:/file/course/{courseId}/facilitationgrantapplication
     */
    getFacilitationGrantApplicationFilesAsync: (courseId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseRelatedFileDto[], any>({
        path: `/file/course/${courseId}/facilitationgrantapplication`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetParticipantFilesCourseAdminAsync
     * @request GET:/file/participant/{userId}
     */
    getParticipantFilesCourseAdminAsync: (userId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseRelatedFileDto[], any>({
        path: `/file/participant/${userId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetParticipantSignatureFilesCourseAdminAsync
     * @request GET:/file/signing_file/{userId}
     */
    getParticipantSignatureFilesCourseAdminAsync: (
      userId: number,
      query?: {
        /** @format int32 */
        courseId?: number;
        /** @format int32 */
        status?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetSigningFileDto[], any>({
        path: `/file/signing_file/${userId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateCourseParticipantFileByIdAsync
     * @request POST:/file/course/{courseId}/participant/{userId}
     */
    createCourseParticipantFileByIdAsync: (
      courseId: number,
      userId: number,
      data: {
        Category?: string;
        /** @format binary */
        File?: File;
        Description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/course/${courseId}/participant/${userId}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name GetCourseRegulationsFilesAsync
     * @request GET:/file/courseregulation
     */
    getCourseRegulationsFilesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetCourseRegulationFileDto[], any>({
        path: `/file/courseregulation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File, kursadmin
     * @name CreateCourseRegulationsFileAsync
     * @request POST:/file/courseregulation
     */
    createCourseRegulationsFileAsync: (
      data: {
        /** @format binary */
        File?: File;
        Description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/file/courseregulation`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  holiday = {
    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name GetHolidayAsync
     * @request GET:/holiday/{id}
     */
    getHolidayAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetHolidayDto, any>({
        path: `/holiday/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name UpdateHolidayAsync
     * @request PUT:/holiday/{id}
     */
    updateHolidayAsync: (id: number, data: ApiUpdateHolidayDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/holiday/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name DeleteHolidayAsync
     * @request DELETE:/holiday/{id}
     */
    deleteHolidayAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/holiday/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name GetHolidaysAsync
     * @request GET:/holiday
     */
    getHolidaysAsync: (
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetHolidayDto[], any>({
        path: `/holiday`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holiday, kursadmin
     * @name CreateHolidayAsync
     * @request POST:/holiday
     */
    createHolidayAsync: (data: ApiCreateHolidayDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/holiday`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags Info, kursadmin, minside
     * @name Info
     * @request GET:/health/info
     */
    info: (params: RequestParams = {}) =>
      this.request<ApiBuildinfo, any>({
        path: `/health/info`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ping, kursadmin, minside
     * @name Ping
     * @request GET:/health/ping
     */
    ping: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/health/ping`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  messaging = {
    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name GetMessagingDefaultOptions
     * @request GET:/messaging/defaultoptions
     */
    getMessagingDefaultOptions: (params: RequestParams = {}) =>
      this.request<ApiGetMessagingDefaultOptionDto, any>({
        path: `/messaging/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name SendMessageFromKursAdminAsync
     * @request POST:/messaging/kursadmin
     */
    sendMessageFromKursAdminAsync: (
      data: {
        /** @format int32 */
        CourseId?: number;
        RecipientIds?: number[];
        Subject?: string;
        Text?: string;
        Attachments?: File[];
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/messaging/kursadmin`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, minside, kursadmin
     * @name ReplyTo
     * @request POST:/messaging/reply
     */
    replyTo: (
      data: {
        /** @format int64 */
        ReplyTo?: number;
        Subject?: string;
        Text?: string;
        Attachments?: File[];
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiInt64IdDto, any>({
        path: `/messaging/reply`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name SearchMessagesKursadmin
     * @request GET:/messaging/kursadmin/search
     */
    searchMessagesKursadmin: (
      query?: {
        OnlySupervisedCourses?: boolean;
        CourseResponsibleFilter?: ApiCourseResponsibleFilter;
        /** @format int32 */
        UserId?: number;
        Sender?: boolean;
        IsRead?: boolean;
        /** @format date-time */
        SentFromDate?: string;
        /** @format date-time */
        SentToDate?: string;
        /** @format int32 */
        CourseId?: number;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetMessageResultDtoPagedResult, any>({
        path: `/messaging/kursadmin/search`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name GetNumberOfUnreadMessagesCourseAdmin
     * @request GET:/messaging/kursadmin/unreadcount
     */
    getNumberOfUnreadMessagesCourseAdmin: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/messaging/kursadmin/unreadcount`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name SetReadKursadmin
     * @request PUT:/messaging/kursadmin/{id}/read
     */
    setReadKursadmin: (id: number, data: ApiSetMessageReadStatusDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/messaging/kursadmin/${id}/read`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name GetMessageByIdKursAdmin
     * @request GET:/messaging/kursadmin/{id}
     */
    getMessageByIdKursAdmin: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetMessageResultDto, any>({
        path: `/messaging/kursadmin/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name SendSmsMessageFromKursAdminAsync
     * @request POST:/messaging/kursadmin/sms
     */
    sendSmsMessageFromKursAdminAsync: (data: ApiSendSmsMessageDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/messaging/kursadmin/sms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging, kursadmin
     * @name GetSmsMessagesKursadmin
     * @request GET:/messaging/kursadmin/sms/list
     */
    getSmsMessagesKursadmin: (
      query?: {
        /** @format int32 */
        CourseId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetSmsResultDto[], any>({
        path: `/messaging/kursadmin/sms/list`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  order = {
    /**
     * @description For participants. Generates one or more order templates that can later be inserted via POST. Does not insert anything to the database.
     *
     * @tags Order, kursadmin
     * @name GetTemplates
     * @request POST:/order/generate-templates
     */
    getTemplates: (data: ApiGenerateTemplateDto[], params: RequestParams = {}) =>
      this.request<ApiOrderTemplateDto[], any>({
        path: `/order/generate-templates`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description For participants. Generates one or more order templates that can later be inserted via POST. Does not insert anything to the database.
     *
     * @tags Order, kursadmin
     * @name GetTemplatesParticipant
     * @request POST:/order/generate-templates/participant
     */
    getTemplatesParticipant: (data: ApiGenerateParticipantOrderTemplateDto[], params: RequestParams = {}) =>
      this.request<ApiOrderTemplateDto[], any>({
        path: `/order/generate-templates/participant`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description For organizations. Generates an order template that can later be inserted via POST. Does not insert anything to the database.
     *
     * @tags Order, kursadmin
     * @name GetTemplatesOrganization
     * @request POST:/order/generate-templates/organization
     */
    getTemplatesOrganization: (data: ApiGenerateOrganizationOrderTemplateDto, params: RequestParams = {}) =>
      this.request<ApiOrderTemplateDto, any>({
        path: `/order/generate-templates/organization`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a single stored order.
     *
     * @tags Order, kursadmin
     * @name GetOrderAsync
     * @request GET:/order/{id}
     */
    getOrderAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetOrderDto, any>({
        path: `/order/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update one order, the order must have status DRAFT.
     *
     * @tags Order, kursadmin
     * @name UpdateDraft
     * @request PUT:/order/{id}
     */
    updateDraft: (id: number, data: ApiUpdateDraftDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/order/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes one order, the order must have status DRAFT.
     *
     * @tags Order, kursadmin
     * @name Delete
     * @request DELETE:/order/{id}
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/order/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * @description Insert one order into the database, with status DRAFT.
     *
     * @tags Order, kursadmin
     * @name CreateDraft
     * @request POST:/order
     */
    createDraft: (data: ApiCreateDraftDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/order`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Queues one order for sending, the order must have status DRAFT or FAILEDQUEUEING. If queued successfully, the status of the order is then set to QUEUED. Normally, the order is then sent by the function app, and the order status is SENT.
     *
     * @tags Order, kursadmin
     * @name Queue
     * @request POST:/order/{id}/queue
     */
    queue: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/order/${id}/queue`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Cancels one order, the order must not have status SENT. The status of the order is then set to DRAFT.
     *
     * @tags Order, kursadmin
     * @name Cancel
     * @request POST:/order/{id}/cancel
     */
    cancel: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/order/${id}/cancel`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Creates a contra entry order of the given order. A contra entry order is an order negating the order given. It is equal to the given order, except with opposite quantity. The given order must have status SENT or FAILEDORDER (otherwise, one could simply cancel it).
     *
     * @tags Order, kursadmin
     * @name CreateContraEntry
     * @request POST:/order/{id}/contra
     */
    createContraEntry: (id: number, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/order/${id}/contra`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  organization = {
    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetDepartmentsAsync
     * @request GET:/organization/department
     */
    getDepartmentsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetDepartmentDto[], any>({
        path: `/organization/department`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetMemberOrganizationsAsync
     * @request GET:/organization/member
     */
    getMemberOrganizationsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto[], any>({
        path: `/organization/member`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name PostMemberOrganizationsAsync
     * @request POST:/organization/member
     */
    postMemberOrganizationsAsync: (data: ApiCreateMemberOrganizationDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/organization/member`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetMainOfficesMemberOrganizationAsync
     * @request GET:/organization/member/mainoffice
     */
    getMainOfficesMemberOrganizationAsync: (params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto[], any>({
        path: `/organization/member/mainoffice`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name GetMemberOrganizationAsync
     * @request GET:/organization/{id}/member
     */
    getMemberOrganizationAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto, void>({
        path: `/organization/${id}/member`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name UpdateMemberOrganizationsAsync
     * @request PUT:/organization/{id}/member
     */
    updateMemberOrganizationsAsync: (id: number, data: ApiUpdateMemberOrganizationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/${id}/member`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetMemberOrganizationChilOfficesAsync
     * @request GET:/organization/{id}/member/regionaloffice
     */
    getMemberOrganizationChilOfficesAsync: (
      id: number,
      query?: {
        includeInactive?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetMemberOrganizationDto[], void>({
        path: `/organization/${id}/member/regionaloffice`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetLocalOfficeMembershipOrganizationsForRegion
     * @request GET:/organization/regional/{id}/member/localoffice
     */
    getLocalOfficeMembershipOrganizationsForRegion: (
      id: number,
      query?: {
        includeInactive?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetMemberOrganizationDto[], void>({
        path: `/organization/regional/${id}/member/localoffice`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetMemberOrganizationLocalOfficesAsync
     * @request GET:/organization/{id}/member/localoffice
     */
    getMemberOrganizationLocalOfficesAsync: (
      id: number,
      query?: {
        includeInactive?: boolean;
        /** @format int32 */
        returnLevel?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetMemberOrganizationDto[], void>({
        path: `/organization/${id}/member/localoffice`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name DeactivateMemberOrganizationsAsync
     * @request POST:/organization/{id}/member/deactivate
     */
    deactivateMemberOrganizationsAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/${id}/member/deactivate`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name ActivateMemberOrganizationsAsync
     * @request POST:/organization/{id}/member/activate
     */
    activateMemberOrganizationsAsync: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/${id}/member/activate`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name PostMemberOrganizationUserAsync
     * @request POST:/organization/{id}/member/user
     */
    postMemberOrganizationUserAsync: (
      id: number,
      data: ApiUpsertMemberOrganizationUserDto,
      params: RequestParams = {}
    ) =>
      this.request<ApiInt32IdDto, any>({
        path: `/organization/${id}/member/user`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name DeleteMemberOrganizationUser
     * @request DELETE:/organization/{orgId}/member/user/{userId}
     */
    deleteMemberOrganizationUser: (orgId: number, userId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/${orgId}/member/user/${userId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name GetMemberOrganizationUsers
     * @request GET:/organization/{orgId}/member/user
     */
    getMemberOrganizationUsers: (orgId: number, params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationUserDto[], any>({
        path: `/organization/${orgId}/member/user`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetActivatedMemberOrganizationFeatureSettings
     * @request GET:/organization/member/active-feature-settings
     */
    getActivatedMemberOrganizationFeatureSettings: (
      query?: {
        HasMultiLevelMemberOrganization?: boolean;
        CanUseSubOrganizer?: boolean;
        UsePreApprovalForCourses?: boolean;
        BypassEnrollmentDeadlineInGuestPortal?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetMemberOrganizationFeatureSettingDto[], void>({
        path: `/organization/member/active-feature-settings`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name GetMemberOrganizationFeatureSettings
     * @request GET:/organization/{orgId}/member/feature-settings
     */
    getMemberOrganizationFeatureSettings: (orgId: number, params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationFeatureSettingDto, void>({
        path: `/organization/${orgId}/member/feature-settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name CreateMemberOrganizationFeatureSettings
     * @request POST:/organization/{orgId}/member/feature-settings
     */
    createMemberOrganizationFeatureSettings: (
      orgId: number,
      data: ApiCreateMemberOrganizationFeatureSettingDto,
      params: RequestParams = {}
    ) =>
      this.request<void, string[]>({
        path: `/organization/${orgId}/member/feature-settings`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name UpdateMemberOrganizationFeatureSettings
     * @request PUT:/organization/{orgId}/member/feature-settings
     */
    updateMemberOrganizationFeatureSettings: (
      orgId: number,
      data: ApiCreateMemberOrganizationFeatureSettingDto,
      params: RequestParams = {}
    ) =>
      this.request<void, string[]>({
        path: `/organization/${orgId}/member/feature-settings`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name GetPotentialOrganizerOrganizations
     * @request GET:/organization
     */
    getPotentialOrganizerOrganizations: (
      query?: {
        isOrganizer?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiOrganizationDtoType[], any>({
        path: `/organization`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin
     * @name GetHierarchyOfOrganization
     * @request GET:/organization/member/{id}/hierarchical
     */
    getHierarchyOfOrganization: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetHierarchicalMemberOrganizationDto, any>({
        path: `/organization/member/${id}/hierarchical`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization, kursadmin, minside
     * @name GetHierarchyOfAllOrganizations
     * @request GET:/organization/member/hierarchical
     */
    getHierarchyOfAllOrganizations: (
      query?: {
        getInactive?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetHierarchicalMemberOrganizationDto[], any>({
        path: `/organization/member/hierarchical`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  role = {
    /**
     * No description
     *
     * @tags Role, kursadmin, minside
     * @name GetRolesAsync
     * @request GET:/role
     */
    getRolesAsync: (params: RequestParams = {}) =>
      this.request<ApiRole[], any>({
        path: `/role`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role, kursadmin
     * @name CreateRoleAsync
     * @request POST:/role
     */
    createRoleAsync: (data: ApiCreateRoleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/role`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role, kursadmin, minside
     * @name GetRoleByNameAsync
     * @request GET:/role/{name}
     */
    getRoleByNameAsync: (name: string, params: RequestParams = {}) =>
      this.request<ApiGetRoleDto, any>({
        path: `/role/${name}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role, kursadmin
     * @name UpdateRoleAsync
     * @request PUT:/role/{name}
     */
    updateRoleAsync: (name: string, data: ApiUpdateRoleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/role/${name}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  schedule = {
    /**
     * @description Validates the schedules based on the input data
     *
     * @tags Schedule, kursadmin
     * @name ValidateSchedulesAsync
     * @request POST:/schedule/validate
     */
    validateSchedulesAsync: (data: ApiValidateSchedulesDto, params: RequestParams = {}) =>
      this.request<void, ApiScheduleBaseDtoValidationResponseDto>({
        path: `/schedule/validate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  signature = {
    /**
     * No description
     *
     * @tags Signature, kursadmin
     * @name GetSignatureDefaultOptions
     * @request GET:/signature/defaultoptions
     */
    getSignatureDefaultOptions: (params: RequestParams = {}) =>
      this.request<ApiSigningFileStatusDescriptionDto[], any>({
        path: `/signature/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Signature, kursadmin
     * @name SendFileForSigning
     * @request POST:/signature
     */
    sendFileForSigning: (data: ApiCreateSigningFileDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, string[] | void>({
        path: `/signature`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Signature, kursadmin
     * @name ResendFile
     * @request POST:/signature/{id}/resend
     */
    resendFile: (id: number, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/signature/${id}/resend`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Signature, kursadmin
     * @name DeleteSigningFile
     * @request DELETE:/signature/{id}
     */
    deleteSigningFile: (id: number, params: RequestParams = {}) =>
      this.request<void, string[] | void>({
        path: `/signature/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Signature, kursadmin
     * @name CancelSigningCourseAdminBulk
     * @request POST:/signature/cancel
     */
    cancelSigningCourseAdminBulk: (data: ApiSigningFileIdsDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/signature/cancel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Signature, kursadmin
     * @name SendNotification
     * @request POST:/signature/{id}/notification
     */
    sendNotification: (id: number, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/signature/${id}/notification`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Signature, kursadmin
     * @name ChangeToSignedStatus
     * @request POST:/signature/{id}/status
     */
    changeToSignedStatus: (id: number, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/signature/${id}/status`,
        method: "POST",
        ...params,
      }),
  };
  ssb = {
    /**
     * No description
     *
     * @tags Ssb, kursadmin, minside
     * @name GetCountriesAsync
     * @request GET:/ssb/country
     */
    getCountriesAsync: (
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiSsbCountryDto[], any>({
        path: `/ssb/country`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ssb, kursadmin, minside
     * @name GetSubjectsAsync
     * @request GET:/ssb/subject
     */
    getSubjectsAsync: (
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiSsbSubjectDto[], any>({
        path: `/ssb/subject`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  studylocation = {
    /**
     * No description
     *
     * @tags StudyLocation, kursadmin
     * @name GetStudyLocationsPerOrganizationAsync
     * @request GET:/studylocation
     */
    getStudyLocationsPerOrganizationAsync: (params: RequestParams = {}) =>
      this.request<ApiGetStudyLocationDto[], any>({
        path: `/studylocation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StudyLocation, kursadmin
     * @name CreateStudyLocationAsync
     * @request POST:/studylocation
     */
    createStudyLocationAsync: (data: ApiUpsertStudyLocationDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/studylocation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StudyLocation, kursadmin
     * @name UpdateStudyLocationAsync
     * @request PUT:/studylocation/{id}
     */
    updateStudyLocationAsync: (id: number, data: ApiUpsertStudyLocationDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/studylocation/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  teachingmaterial = {
    /**
     * No description
     *
     * @tags TeachingMaterial, kursadmin, minside
     * @name GetTeachingMaterialsAsync
     * @request GET:/teachingmaterial
     */
    getTeachingMaterialsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetTeachingMaterialDto[], any>({
        path: `/teachingmaterial`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterial, kursadmin
     * @name CreateTeachingMaterialAsync
     * @request POST:/teachingmaterial
     */
    createTeachingMaterialAsync: (data: ApiUpsertTeachingMaterialDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/teachingmaterial`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterial, kursadmin, minside
     * @name GetTeachingMaterialAsync
     * @request GET:/teachingmaterial/{id}
     */
    getTeachingMaterialAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetTeachingMaterialDto, any>({
        path: `/teachingmaterial/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterial, kursadmin
     * @name UpdateTeachingMaterialAsync
     * @request PUT:/teachingmaterial/{id}
     */
    updateTeachingMaterialAsync: (id: number, data: ApiUpsertTeachingMaterialDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/teachingmaterial/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  teachingmaterialcategory = {
    /**
     * No description
     *
     * @tags TeachingMaterialCategory, kursadmin, minside
     * @name GetTeachingMaterialCategoryAsync
     * @request GET:/teachingmaterialcategory
     */
    getTeachingMaterialCategoryAsync: (params: RequestParams = {}) =>
      this.request<ApiGetTeachingMaterialCategoryDto[], any>({
        path: `/teachingmaterialcategory`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterialCategory, kursadmin
     * @name CreateTeachingMaterialCategoryAsync
     * @request POST:/teachingmaterialcategory
     */
    createTeachingMaterialCategoryAsync: (data: ApiCreateTeachingMaterialCategoryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/teachingmaterialcategory`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TeachingMaterialCategory, kursadmin
     * @name UpdateTeachingMaterialCategoryAsync
     * @request PUT:/teachingmaterialcategory/{name}
     */
    updateTeachingMaterialCategoryAsync: (
      name: string,
      data: ApiUpdateTeachingMaterialCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/teachingmaterialcategory/${name}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  testtype = {
    /**
     * No description
     *
     * @tags TestType, kursadmin, minside
     * @name GetTestTypesAsync
     * @request GET:/testtype
     */
    getTestTypesAsync: (params: RequestParams = {}) =>
      this.request<ApiGetTestTypeDto[], any>({
        path: `/testtype`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TestType, kursadmin
     * @name CreateTestTypeAsync
     * @request POST:/testtype
     */
    createTestTypeAsync: (data: ApiUpsertTestTypeDto, params: RequestParams = {}) =>
      this.request<ApiInt32IdDto, any>({
        path: `/testtype`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TestType, kursadmin, minside
     * @name GetTestTypeIdAsync
     * @request GET:/testtype/{id}
     */
    getTestTypeIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetTestTypeDto, any>({
        path: `/testtype/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TestType, kursadmin
     * @name UpdateTestType
     * @request PUT:/testtype/{id}
     */
    updateTestType: (id: number, data: ApiUpsertTestTypeDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testtype/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  guestside = {
    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseDefaultOptions
     * @request GET:/guestside/defaultoptions
     */
    getGuestUserCourseDefaultOptions: (params: RequestParams = {}) =>
      this.request<ApiGetCourseDefaultOptionDto, any>({
        path: `/guestside/defaultoptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCoursList
     * @request GET:/guestside/course/list
     */
    getGuestUserCoursList: (params: RequestParams = {}) =>
      this.request<ApiGetCourseListDto[], any>({
        path: `/guestside/course/list`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseByIdFilteredByOrganizationAsync
     * @request GET:/guestside/course/{id}
     */
    getGuestUserCourseByIdFilteredByOrganizationAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetKursAdminCourseDto, any>({
        path: `/guestside/course/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseParticipantsByCourseIdAsync
     * @request GET:/guestside/course/{id}/participant
     */
    getGuestUserCourseParticipantsByCourseIdAsync: (
      id: number,
      query?: {
        RoleNames?: string[];
        Statuses?: string[];
        IsActive?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantKursAdminDto[], any>({
        path: `/guestside/course/${id}/participant`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseParticipantCommentsAsync
     * @request GET:/guestside/course/{courseId}/user/{userId}/courseparticipantcomment
     */
    getGuestUserCourseParticipantCommentsAsync: (courseId: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantCommentDto[], void>({
        path: `/guestside/course/${courseId}/user/${userId}/courseparticipantcomment`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name CreateGuestUserCourseParticipantCommentAsync
     * @request POST:/guestside/course/{courseId}/user/{userId}/courseparticipantcomment
     */
    createGuestUserCourseParticipantCommentAsync: (
      courseId: number,
      userId: number,
      data: ApiCreateCourseParticipantCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/guestside/course/${courseId}/user/${userId}/courseparticipantcomment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCourseParticipantHistory
     * @request GET:/guestside/participant/{userId}/history
     */
    getGuestUserCourseParticipantHistory: (userId: number, params: RequestParams = {}) =>
      this.request<ApiGetCourseParticipantDtoType[], any>({
        path: `/guestside/participant/${userId}/history`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestCourseParticipantAppFormResponse
     * @request GET:/guestside/course/{courseId}/participant/{userId}/application
     */
    getGuestCourseParticipantAppFormResponse: (courseId: number, userId: number, params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormResponseDto, any>({
        path: `/guestside/course/${courseId}/participant/${userId}/application`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name ChangeGuestUserCourseParticipantApplicationStatus
     * @request POST:/guestside/course/{courseId}/participant/{userId}/status
     */
    changeGuestUserCourseParticipantApplicationStatus: (
      courseId: number,
      userId: number,
      data: ApiGuestEmployeeCourseParticipantStatusDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/guestside/course/${courseId}/participant/${userId}/status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetCourseParticipantAttendances
     * @request GET:/guestside/course/{courseId}/attendance
     */
    getCourseParticipantAttendances: (
      courseId: number,
      query?: {
        /**
         * Startdatoen og tidspunktet til timeplanen som fremmøtet gjelder for
         * @format date-time
         */
        ScheduleStart?: string;
        /**
         * Id'en til brukeren som fremmøtet gjelder for
         * @format int32
         */
        UserId?: number;
        /** Brukerens fulle navn */
        UserFullName?: string;
        /** Om fremmøtet er registrert av en bruker */
        IsRegistered?: boolean;
        /** Om fremmøtet er registrert av en KursAdmin-bruker */
        IsSetByKursAdmin?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCourseParticipantAttendanceDto[], string[] | void>({
        path: `/guestside/course/${courseId}/attendance`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCustomerPersonsAsync
     * @request GET:/guestside/customer/person
     */
    getGuestUserCustomerPersonsAsync: (
      query?: {
        FirstName?: string;
        LastName?: string;
        Email?: string;
        MobileNumber?: string;
        /**
         * The customer person types to include. By default it only includes SYSCO.Universum.Common.Constants.CustomerPersonType.B2C.
         * Specify other types (e.g. SYSCO.Universum.Common.Constants.CustomerPersonType.RESTRICTED) to vary the results.
         */
        Types?: string[];
        IsCourseParticipant?: boolean;
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetCustomerPersonDto[], any>({
        path: `/guestside/customer/person`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCustomerPersonByIdAsync
     * @request GET:/guestside/customer/person/{id}
     */
    getGuestUserCustomerPersonByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCustomerDto, any>({
        path: `/guestside/customer/person/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserDepartmentsAsync
     * @request GET:/guestside/organization/department
     */
    getGuestUserDepartmentsAsync: (params: RequestParams = {}) =>
      this.request<ApiGetDepartmentDto[], any>({
        path: `/guestside/organization/department`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserMemberOrganizations
     * @request GET:/guestside/organization/member
     */
    getGuestUserMemberOrganizations: (params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto[], any>({
        path: `/guestside/organization/member`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetMemberOrganizationAsync1
     * @request GET:/guestside/{id}/member
     */
    getMemberOrganizationAsync1: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationDto, void>({
        path: `/guestside/${id}/member`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetHierarchyOfAllOrganizationsGuestUser
     * @request GET:/guestside/organization/member/hierarchical
     */
    getHierarchyOfAllOrganizationsGuestUser: (
      query?: {
        getInactive?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetHierarchicalMemberOrganizationDto[], any>({
        path: `/guestside/organization/member/hierarchical`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetActivatedMemberOrganizationFeatureSettings1
     * @request GET:/guestside/member/active-feature-settings
     */
    getActivatedMemberOrganizationFeatureSettings1: (
      query?: {
        HasMultiLevelMemberOrganization?: boolean;
        CanUseSubOrganizer?: boolean;
        UsePreApprovalForCourses?: boolean;
        BypassEnrollmentDeadlineInGuestPortal?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiGetMemberOrganizationFeatureSettingDto[], void>({
        path: `/guestside/member/active-feature-settings`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetMemberOrganizationFeatureSettings1
     * @request GET:/guestside/{orgId}/member/feature-settings
     */
    getMemberOrganizationFeatureSettings1: (orgId: number, params: RequestParams = {}) =>
      this.request<ApiGetMemberOrganizationFeatureSettingDto, void>({
        path: `/guestside/${orgId}/member/feature-settings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserAreaByIdAsync
     * @request GET:/guestside/area/{id}
     */
    getGuestUserAreaByIdAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetAreaDto, any>({
        path: `/guestside/area/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCurriculumAsync
     * @request GET:/guestside/curriculum/{id}
     */
    getGuestUserCurriculumAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetCurriculumDto, any>({
        path: `/guestside/curriculum/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserExecutionPlanAsync
     * @request GET:/guestside/curriculum/{id}/executionplan
     */
    getGuestUserExecutionPlanAsync: (id: number, params: RequestParams = {}) =>
      this.request<ApiGetExecutionPlanDto, any>({
        path: `/guestside/curriculum/${id}/executionplan`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserAppFormTemplateAsync
     * @request GET:/guestside/appformtemplate/{id}
     */
    getGuestUserAppFormTemplateAsync: (id: string, params: RequestParams = {}) =>
      this.request<ApiGetApplicationFormTemplateDto, any>({
        path: `/guestside/appformtemplate/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetGuestUserCurrentGuestEmployeeAsync
     * @request GET:/guestside/employee/guest/self
     */
    getGuestUserCurrentGuestEmployeeAsync: (params: RequestParams = {}) =>
      this.request<ApiGetGuestEmployeewithMemberOrganizationDto, any>({
        path: `/guestside/employee/guest/self`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetDashboardSelfAsync1
     * @request GET:/guestside/dashboard
     */
    getDashboardSelfAsync1: (params: RequestParams = {}) =>
      this.request<ApiGetGuestUserDashboardDto, any>({
        path: `/guestside/dashboard`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetCourseApplications
     * @request GET:/guestside/dashboard/participant/applications
     */
    getCourseApplications: (params: RequestParams = {}) =>
      this.request<ApiGetUnreadCourseApplicationsDto[], any>({
        path: `/guestside/dashboard/participant/applications`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GuestUserCourse, guestuser
     * @name GetPreApprovedParticipants
     * @request GET:/guestside/dashboard/participant/preApproved
     */
    getPreApprovedParticipants: (params: RequestParams = {}) =>
      this.request<ApiGetUnreadCourseApplicationsDto[], any>({
        path: `/guestside/dashboard/participant/preApproved`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
