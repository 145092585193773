import { api } from "./api";
import { httpClient } from "./httpClient";

export const getPerson = (id: number) =>
  httpClient.get(`/customer/person/${id}`, {
    params: {},
  });

export const getOrganization = (id: number) => api.customer.getCustomerOrgByIdAsync(id);

export const searchOrganizationsByName = (payload: any) =>
  api.customer.getCustomerOrganizationsAsync({ Name: payload.name, PageSize: payload.pageSize });

export const createCustomer = (customer: any) => httpClient.post(`/customer/person`, customer);

export const putOrganization = (org: any) => httpClient.put(`/customer/organization/${org.id}`, org);

export const putPerson = (person: any) => httpClient.put(`/customer/person/${person.id}`, person);

export const createOrganization = (org: any) => httpClient.post(`/customer/organization`, org);

export const getUserById = (id: number) => httpClient.get(`/user/${id}`);

export const getAllEmployees = (params?: any) =>
  httpClient.get(`/user/employee/`, {
    params: {
      firstName: params?.firstName,
      lastName: params?.lastName,
      email: params?.email,
      pageSize: params?.pageSize,
    },
  });
