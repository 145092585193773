import { addParticipant, getStudent, getStudents } from "@/api/courseParticipants.api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { CourseParticipantsActions } from "@/store/modules/courseParticipants/courseParticipants.actions.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { CourseParticipantsMutations } from "./courseParticipants.mutations.enum";
import { CourseParticipantsStoreState } from "./courseParticipants.store.interface";

const initialState: () => CourseParticipantsStoreState = () => ({
  students: [],
  person: {},
});

const state = initialState();

const getters = <GetterTree<CourseParticipantsStoreState, RootStoreState>>{
  getField,
  getStudents(state) {
    return state.students;
  },
  getPerson(state) {
    return state.person;
  },
};

const actions = <ActionTree<CourseParticipantsStoreState, RootStoreState>>{
  [CourseParticipantsActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [CourseParticipantsActions.FetchStudents]({ commit }, courseId) {
    const response = await getStudents(courseId);
    commit("SET_STUDENTS", response.data);
  },
  async [CourseParticipantsActions.FetchPerson]({ commit }, userId) {
    const response = await getStudent(userId);
    commit("SET_PERSON", response.data);
  },
  async [CourseParticipantsActions.PostStudent]({ commit }, courseId) {
    const person = { ...state.person };
    person.roleName = "Student";

    await addParticipant(person, courseId);
    commit("ADDED", person);
    if (person.status == "påmeldt") {
      openNotification(this as any, NotificationItemType.Success, "Studenten er lagt til kurset");
    } else {
      openNotification(this as any, NotificationItemType.Success, `Studentenes status: ${person.status}`);
    }
  },
};

const mutations = <MutationTree<CourseParticipantsStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the global reset.
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof CourseParticipantsStoreState] = newState[key as keyof CourseParticipantsStoreState];
    });
  },
  [CourseParticipantsMutations.SET_PERSON](state, data) {
    state.person = data;
  },
  [CourseParticipantsMutations.SET_STUDENTS](state, data) {
    state.students = data;
  },
  [CourseParticipantsMutations.ADDED](state, data) {
    state.students.push(data);
  },
};

export const CourseParticipantsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
