import * as Msal from "msal";
import { UserConstants } from "./../shared/constants/userConstants";

import { AuthRole, DEFAULT_DEPARTMENT_ID } from "@/auth/auth.constants";
import { loginRequest, loginTenantId, msalConfig, reduceUserAuthorizations } from "@/auth/auth.utils";
import { EnvironmentVariables } from "@/environment";
import router from "@/router/router";
import { runStoreMutation } from "@/shared/helpers/store.helpers";
import store from "@/store/root.store";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreMutations } from "@/store/store.mutations";
import LogRocket from "logrocket";

// TODO fix this (to v2)
class AuthService {
  msalConfig: any;
  loginRequest;
  auth;
  myRights = [];
  roles: AuthRole[] = [];
  tenantId: string | null;

  constructor() {
    this.tenantId = localStorage.getItem(loginTenantId);
    this.msalConfig = msalConfig;
    this.loginRequest = loginRequest;

    this.auth = new Msal.UserAgentApplication(this.msalConfig);

    this.auth.handleRedirectCallback(this.authRedirectCallBack);

    this.roles = reduceUserAuthorizations(this.user?.roles, AuthRole);
  }

  async authRedirectCallBack(error: Msal.AuthError, response?: Msal.AuthResponse) {
    if (error) {
      console.warn("authRedirectCallBack error", error);
      return;
    }
    if (!response) {
      console.warn("authRedirectCallBack no response");
      return;
    }
    const { tokenType, idToken } = response;

    sessionStorage.setItem("user-token-expires-in", idToken.expiration);

    if (EnvironmentVariables.IN_PRODUCTION_ENV) {
      LogRocket.identify(idToken.claims.oid, {
        name: idToken.name,
        email: idToken.preferredName,
      });
    }

    // TODO fix this
    // console.log("this.auth", this.auth);
    // TEMP fix for msal bug
    if (!this.msalConfig) {
      this.msalConfig = msalConfig;
      this.auth = new Msal.UserAgentApplication(this.msalConfig);
    }
    if (tokenType === "id_token") {
      runStoreMutation(store as any, StoreModules.Hoc, StoreMutations.HocMutations.SET_SKELETON_LOADING, "table");
      if (!this.auth) {
        return;
      }
      const tokenResponse = await this.auth.acquireTokenSilent(loginRequest);
      if (tokenResponse.accessToken) {
        runStoreMutation(store as any, StoreModules.Hoc, StoreMutations.HocMutations.REMOVE_SKELETON_LOADING, "table");
      }

      const redirectUrl = sessionStorage.getItem("redirectPostLoginUrl");
      // TODO find out why all post login auto redirect to home
      if (redirectUrl && redirectUrl !== "/home") {
        sessionStorage.removeItem("redirectPostLoginUrl");
        router.push(redirectUrl);
      }
    }
  }

  // this fails, TODO fix
  async acquireTokenSilent() {
    // acquireTokenSilent should handle access token renewal
    try {
      const response = await this.auth.acquireTokenSilent(loginRequest);
      return response.accessToken;
    } catch (err: any) {
      // signIn if acquireTokenSilent failed due to consent or interaction required
      if (
        err.errorCode === "consent_required" ||
        err.errorCode === "interaction_required" ||
        err.errorCode === "login_required"
      ) {
        this.signIn();
      }
    }
  }

  get user() {
    return this.auth.getAccount()?.idToken;
  }

  hasRoles(...roles: AuthRole[]) {
    return this.roles.some((userRole) => roles.includes(userRole));
  }

  canImpersonateDepartment() {
    return this.roles.includes(AuthRole.ImpersonateDepartment);
  }

  fromMemberOrganization() {
    return this.hasRoles(AuthRole.ApproveMemberOrgApplication);
  }

  canModifyClosedCourse() {
    return this.roles.includes(AuthRole.ModifyClosedCourse);
  }

  get isAuthenticated() {
    return !!this.user;
  }

  signIn() {
    this.auth.loginRedirect(loginRequest);
    window.sessionStorage.setItem(UserConstants.USER_DEPARTMENT_ID, DEFAULT_DEPARTMENT_ID.toString());
  }

  signOut() {
    this.auth.logout();
  }
}

// TODO fix this
export const authService = new AuthService();
