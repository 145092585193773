import { CurriculumRouteNames } from "@/shared/enums/RouteNames/curriculumRouteNamesEnum";

export const StudieplanerRouteList = [
  {
    path: "studieplaner",
    name: CurriculumRouteNames.CurriculumList,
    component: () => import(/* webpackChunkName: "TableStudieplanPage" */ "@/pages/studieplan/TableStudieplanPage.vue"),
  },
  {
    path: "studieplaner/utkast",
    name: CurriculumRouteNames.CurriculumDraftList,
    component: () =>
      import(/* webpackChunkName: "StudieplanDraftsPage" */ "@/pages/studieplan/StudieplanDraftsPage.vue"),
  },
  {
    path: "studieplaner/ny",
    name: CurriculumRouteNames.CreateCurriculumPrerequisites,
    component: () =>
      import(
        /* webpackChunkName: "NewStudieplanPrerequisitesPage" */ "@/pages/studieplan/new/NewStudieplanPrerequisitesPage.vue"
      ),
  },
  {
    path: "studieplaner/ny/steppers",
    name: CurriculumRouteNames.CreateCurriculum,
    component: () =>
      import(/* webpackChunkName: "StudieplanUpsertPage" */ "@/pages/studieplan/StudieplanUpsertPage.vue"),
  },
  {
    path: "studieplaner/utkast/:id",
    name: CurriculumRouteNames.EditCurriculumDraft,
    component: () =>
      import(/* webpackChunkName: "StudieplanUpsertPage" */ "@/pages/studieplan/StudieplanUpsertPage.vue"),
    props: {
      isEditing: true,
      isDraft: true,
    },
  },

  {
    path: "studieplaner/details/:id",
    name: CurriculumRouteNames.CurriculumDetails,
    component: () =>
      import(/* webpackChunkName: "StudieplanDetailsPage" */ "@/pages/studieplan/StudieplanDetailsPage.vue"),
  },
  {
    path: "studieplaner/studieplan/:id",
    name: CurriculumRouteNames.EditCurriculum,
    component: () => import(/* webpackChunkName: "StudieplanEditPage" */ "@/pages/studieplan/StudieplanUpsertPage.vue"),
    props: {
      isEditing: true,
    },
  },
  {
    path: "studieplaner/details/gjennomforing/:id",
    name: CurriculumRouteNames.CreateExecutionPlanPrerequisites,
    component: () =>
      import(
        /* webpackChunkName: "CreateExecutionPlanPrerequisitesPage" */ "@/pages/studieplan/edit/CreateExecutionPlanPrerequisitesPage.vue"
      ),
  },
];
