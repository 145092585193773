import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";
import { AreaActions } from "@/store/modules/area/area.actions.enum";

import {
  getAreas,
  getArea,
  putArea,
  postArea,
  getLocations,
  getLocation,
  putLocation,
  postLocation,
  getRoom,
  putRoom,
  postRoom,
} from "@/api/area.api";
import { AreaStoreState } from "./area.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { AreaMutations } from "./area.mutations.enum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

const initialState: () => AreaStoreState = () => ({
  areas: [],
  locations: [],
  rooms: [],
  areaSingle: {
    place: "",
  },
  locationSingle: {
    id: "",
    street: "",
    details: "",
    postalCode: "",
    postalArea: "",
    isActive: false,
    rooms: [],
  },
  roomsQueue: [],
  roomSingle: {
    id: null,
    name: "",
    capacity: "",
    description: "",
    recommendedCapacity: "",
    floorNumber: "",
    isActive: false,
  },
});

const state = initialState();

const getters = <GetterTree<AreaStoreState, RootStoreState>>{
  getField,
  areas(state) {
    return state.areas;
  },
  area(state) {
    return state.areaSingle;
  },
  locations(state) {
    return state.locations;
  },
  location(state) {
    return state.locationSingle;
  },
  rooms(state) {
    return state.locationSingle.rooms;
  },
  roomsQueue(state) {
    return state.roomsQueue;
  },
  room(state) {
    return state.roomSingle;
  },
};

const actions = <ActionTree<AreaStoreState, RootStoreState>>{
  [AreaActions.Reset]({ commit }) {
    commit("RESET");
  },

  async [AreaActions.FetchAreas]({ commit }) {
    const response = await getAreas();
    if (response.status == 200) {
      await commit("SET_AREAS", response.data);
    }
  },
  async [AreaActions.FetchArea]({ commit }, id) {
    const response = await getArea(id);
    if (response.status == 200) {
      await commit("SET_AREA", response.data);
    }
  },
  async [AreaActions.AddArea]({ dispatch }) {
    await postArea(state.areaSingle);
    openNotification(this as any, NotificationItemType.Success, "Opprettelse av sted vellykket.");
    dispatch("area/fetchAreas", null, { root: true });
  },
  async [AreaActions.EditArea]({ dispatch }) {
    await putArea(state.areaSingle);
    openNotification(this as any, NotificationItemType.Success, "Redigering av sted vellykket.");
    dispatch("area/fetchAreas", null, { root: true });
  },
  async [AreaActions.FetchLocations]({ commit }, areaId) {
    const response = await getLocations(areaId || state.areaSingle.id);
    await commit("SET_LOCATIONS", response.data);
  },
  async [AreaActions.FetchLocation]({ commit }, id) {
    if (!state.areaSingle.id) {
      return;
    }
    const response = await getLocation(state.areaSingle.id, id);
    await commit("SET_LOCATION", response.data);
    // await commit("SET_ROOMS", response.data.rooms);
  },
  async [AreaActions.RefreshRooms]({ commit }) {
    if (!state.areaSingle.id) {
      return;
    }
    const response = await getLocation(state.areaSingle.id, state.locationSingle.id);
    await commit("SET_ROOMS", response.data.rooms);
  },
  async [AreaActions.AddLocation]({ dispatch }) {
    if (!state.areaSingle.id) {
      return;
    }
    const response = await postLocation(state.areaSingle.id, state.locationSingle);
    dispatch("addRoomsFromQueue", response.data.id);
    openNotification(this as any, NotificationItemType.Success, "Opprettelse av lokasjon vellykket.");
    await dispatch("area/fetchLocations", null, { root: true });
  },
  async [AreaActions.EditLocation]({ dispatch }) {
    if (!state.areaSingle.id) {
      return;
    }
    await putLocation(state.areaSingle.id, state.locationSingle);
    openNotification(this as any, NotificationItemType.Success, "Redigering av lokasjon vellykket.");
    await dispatch("area/fetchLocations", null, { root: true });
  },
  async [AreaActions.FetchRoom]({ commit }, id) {
    if (!state.areaSingle.id) {
      return;
    }
    const response = await getRoom(state.areaSingle.id, state.locationSingle.id, id);
    commit("SET_ROOM", response.data);
  },
  async [AreaActions.AddRoom]({ dispatch }) {
    if (state.locationSingle.id) {
      dispatch("postRoom");
    } else {
      dispatch("delegateRoom");
    }
  },
  async [AreaActions.PostRoom]({ dispatch }) {
    if (!state.areaSingle.id) {
      return;
    }
    await postRoom(state.areaSingle.id, state.locationSingle.id, {
      ...state.roomSingle,
    });
    openNotification(this as any, NotificationItemType.Success, "Opprettelse av rom vellykket.");
    await dispatch("area/fetchLocation", state.locationSingle.id, {
      root: true,
    });
  },
  async [AreaActions.EditRoom]({ dispatch }, id) {
    if (!state.areaSingle.id) {
      return;
    }
    await putRoom(state.areaSingle.id, state.locationSingle.id, id, {
      ...state.roomSingle,
    });
    openNotification(this as any, NotificationItemType.Success, "Redigering av rom vellykket.");
    await dispatch("area/fetchLocation", state.locationSingle.id, {
      root: true,
    });
  },
  async [AreaActions.AddRoomsFromQueue]({ state, commit }, locationId) {
    state.roomsQueue.forEach((room) => {
      if (!state.areaSingle.id) {
        return;
      }
      postRoom(state.areaSingle.id, locationId, room).catch((err) => {
        openNotification(this as any, NotificationItemType.Error, err.response.data);
      });
    });
    commit("RESET_ROOMS_QUEUE");
  },
  [AreaActions.DelegateRoom]({ commit }) {
    commit("PUSH_ROOM_IN_QUEUE", { ...state.roomSingle });
    commit("SET_ROOM", {});
  },
};

const mutations = <MutationTree<AreaStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the gloabal reset.
  [AreaMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      const value: any = newState[key as keyof AreaStoreState];
      if (typeof value === "object" && value !== null) {
        for (const property in value) {
          if (property in state[key as keyof AreaStoreState]) {
            // @ts-ignore TODO fix this
            state[key as keyof AreaStoreState][property] = value[property];
          } else {
            // Make sure that new properties are reactive
            Vue.set(state[key as keyof AreaStoreState], property, value[property]);
          }
        }
      } else {
        state[key as keyof AreaStoreState] = value;
      }
    });
  },
  [AreaMutations.SET_COUNTRY_CODE](state, data) {
    state.areaSingle.countryCode = data;
  },
  [AreaMutations.SET_AREAS](state, response) {
    state.areas = response;
  },
  [AreaMutations.SET_AREA](state, response) {
    state.areaSingle = response;
  },
  [AreaMutations.SET_LOCATIONS](state, response) {
    state.locations = response;
  },
  [AreaMutations.SET_LOCATION](state, response) {
    const { locationSingle } = initialState();
    for (const key in state.locationSingle) {
      // @ts-ignore TODO fix this
      state.locationSingle[key] = response[key] ?? locationSingle[key];
    }
  },

  [AreaMutations.SET_ROOMS](state, response) {
    state.locationSingle.rooms = response;
  },
  [AreaMutations.SET_ROOM](state, payload = {}) {
    const { roomSingle } = initialState();
    for (const key in state.roomSingle) {
      // @ts-ignore TODO fix this
      state.roomSingle[key] = payload[key] ?? roomSingle[key];
    }
  },
  RESET_ROOMS_QUEUE(state) {
    state.roomsQueue = [];
  },
  [AreaMutations.PUSH_ROOM_IN_QUEUE](state, room) {
    state.roomsQueue.push(room);
  },
  [AreaMutations.REMOVE_INDEX_FROM_QUEUE](state, index) {
    state.roomsQueue.splice(index, 1);
  },
};

export const AreaModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
