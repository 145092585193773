var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.departments.length ? _c('v-select', {
    staticClass: "ml-2",
    attrs: {
      "dense": "",
      "hide-details": "",
      "hide-no-data": "",
      "solo": "",
      "no-filter": "",
      "value": _vm.selectedDepartment,
      "items": _vm.departments
    },
    on: {
      "change": _vm.setDepartment
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }