import { httpClient } from "./httpClient";

export const getAreas = () => httpClient.get(`/area`);

export const getArea = (id: string) => httpClient.get(`/area/${id}`);

export const putArea = (areaBody: any) => httpClient.put(`/area/${areaBody.id}`, areaBody);

export const postArea = (areaBody: any) => httpClient.post(`/area`, areaBody);

export const getLocations = (areaId: number) => httpClient.get(`/area/${areaId}/location`);

export const getLocation = (areaId: number, id: string) => httpClient.get(`/area/${areaId}/location/${id}`);

export const putLocation = (areaId: number, location: any) => {
  const { id, ...locationBody } = location;
  return httpClient.put(`/area/${areaId}/location/${id}`, locationBody);
};

export const postLocation = (areaId: number, locationBody: any) =>
  httpClient.post(`/area/${areaId}/location`, locationBody);

export const getRoom = (areaId: number, locationId: string, id: string) =>
  httpClient.get(`/area/${areaId}/location/${locationId}/room/${id}`);

export const putRoom = (areaId: number, locationId: string, id: string, roomBody: any) =>
  httpClient.put(`/area/${areaId}/location/${locationId}/room/${id}`, roomBody);

export const postRoom = (areaId: number, locationId: string, roomBody: any) =>
  httpClient.post(`/area/${areaId}/location/${locationId}/room`, roomBody);
