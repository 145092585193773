export enum HocMutations {
  RESET = "RESET",
  SET_LANGUAGE = "SET_LANGUAGE",
  SET_SKELETON_LOADING = "SET_SKELETON_LOADING",
  REMOVE_SKELETON_LOADING = "REMOVE_SKELETON_LOADING",
  APPEND_NOTIFICATION_ITEM = "APPEND_NOTIFICATION_ITEM",
  REMOVE_NOTIFICATION_ITEM = "REMOVE_NOTIFICATION_ITEM",
  SET_SHOW_LOADING_SPINNER = "SET_SHOW_LOADING_SPINNER",
  CHANGE_BLOCKING_REQUESTS = "CHANGE_BLOCKING_REQUESTS",
  CHANGE_NON_BLOCKING_REQUESTS = "CHANGE_NON_BLOCKING_REQUESTS",
  CHANGE_TABLE_SKELETON_REQUESTS = "CHANGE_TABLE_SKELETON_REQUESTS",
  SET_DEPARTMENT = "SET_DEPARTMENT",
  SET_PRE_FILLED_VALUE = "SET_PRE_FILLED_VALUE",
}
