import { getField, updateField } from "vuex-map-fields";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ContractsMutations } from "./contracts.mutations.enum";
import { ContractsStoreState } from "./contracts.store.interface";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { ContractsActions } from "./contracts.actions.enum";
import { api } from "@/api/api";
import { openNotification } from "@/shared/helpers/store.helpers";

const initialState: () => ContractsStoreState = () => ({
  contracts: [],
});

const state = initialState();

const getters = <GetterTree<ContractsStoreState, RootStoreState>>{
  getField,
};

const actions = <ActionTree<ContractsStoreState, RootStoreState>>{
  [ContractsActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [ContractsActions.FetchContracts]({ commit }, payload) {
    try {
      const response = await api.economy.getContractsForCourseAndUserAsync(payload.courseId, payload.userId);
      commit("FETCH_CONTRACTS", response.data);
    } catch (err) {
      openNotification(this as any, NotificationItemType.Error, "En feil oppsto under henting av kontrakter.");
    }
  },
};

const mutations = <MutationTree<ContractsStoreState>>{
  updateField,
  [ContractsMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof ContractsStoreState] = newState[key as keyof ContractsStoreState];
    });
  },
  [ContractsMutations.FETCH_CONTRACTS](state, response) {
    state.contracts = response;
  },
};

export const ContractsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
