import { api } from "@/api/api";
import { postExecutionPlan } from "@/api/gjennomforing.api";
import { authService } from "@/auth/authService";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { ExecutionActions } from "@/store/modules/execution/execution.actions.enum";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { ExecutionMutations } from "./execution.mutations.enum";
import { ExecutionStoreState } from "./execution.store.interface";

const initialState: () => ExecutionStoreState = () => ({
  executionPlan: {
    info: {
      reasonForStatusChangeRequired: false,
      cancellationDeadlineRequired: false,
      hoursWithoutInstructorRequired: false,
      hoursSelfStudyRequired: false,
      enrollmentDeadlineRequired: false,
      unenrollmentDeadlineRequired: false,
      minimumParticipantsRequired: false,
      maximumParticipantsRequired: false,
      financiersRequired: false,
      priceRequired: false,
      additionalFilesRequired: false,
      groupIdRequired: false,
      //  Må sjekkes
      status: "Sendt til godkjenning",
      learningPlatform: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      // cancellationDeadlineRequired: new Date().toISOString().substr(0, 10),
      startsAt: null,
      endsAt: null,
    },
    plan: {
      plan: true,
      scheduleRequired: false,
      //  Må sjekkes
      registerHours: false,
      mondayStartTime: null,
      mondayEndTime: null,
      tuesdayStartTime: null,
      tuesdayEndTime: null,
      wednesdayStartTime: null,
      wednesdayEndTime: null,
      thursdayStartTime: null,
      thursdayEndTime: null,
      fridayStartTime: null,
      fridayEndTime: null,
    },
    economyRequired: true,
    certificatesRequired: false,
    certificate: {
      courseCertificateRequired: false,
      gradeCertificateRequired: false,
      diplomaRequired: false,
    },
    teachingMaterialsRequired: false,
    testsRequired: false,
    descriptionRequired: false,
  },
  studyplan: {},
  message: {},
  selectedCurriculum: {},
});

const state = initialState();

const getters = <GetterTree<ExecutionStoreState, RootStoreState>>{
  getField,
  getExec() {
    return state.executionPlan;
  },
  executionPlan: (state) => state.executionPlan,
};

const actions = <ActionTree<ExecutionStoreState, RootStoreState>>{
  [ExecutionActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [ExecutionActions.PostExecutionPlan]({ dispatch }, id) {
    const response = await postExecutionPlan(state.executionPlan, id);
    if (response.status == 204) {
      await dispatch("plans/updateStudyplan", null, { root: true });
      openNotification(this as any, NotificationItemType.Success, "Opprettelse av gjennomføringsplan vellykket.");
    }
  },
  async [ExecutionActions.GetExecutionPlan]({ commit }, id) {
    const response = !authService.fromMemberOrganization()
      ? await api.curriculum.getExecutionPlanAsync(id)
      : await api.guestside.getGuestUserExecutionPlanAsync(id);
    commit("SET_EXECUTION_PLAN", response.data);
  },
  [ExecutionActions.CertificateRequired]({ commit }) {
    commit("CERTIFICATE_REQUIRED");
  },
};

const mutations = <MutationTree<ExecutionStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the gloabal reset.
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof ExecutionStoreState] = newState[key as keyof ExecutionStoreState];
    });
  },
  [ExecutionMutations.SET_EXECUTION_PLAN](state, data) {
    state.executionPlan = data;
  },
  [ExecutionMutations.POST_EXECUTION_PLAN_MESSAGE](state, data) {
    state.message = data;
  },
  [ExecutionMutations.CERTIFICATE_REQUIRED](state) {
    state.executionPlan.certificatesRequired = true;
  },
};

export const ExecutionModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
