import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { PartnerStoreState } from "./partner.store.interface";
import { PartnerActions } from "@/store/modules/partner/partner.actions.enum";

const initialState: () => PartnerStoreState = () => ({
  partner: {},
});

const state = initialState();

const getters = <GetterTree<PartnerStoreState, RootStoreState>>{
  getField,
  getPartner(state) {
    return state.partner;
  },
};

const actions = <ActionTree<PartnerStoreState, RootStoreState>>{
  [PartnerActions.Reset]({ commit }) {
    commit("RESET");
  },
};

const mutations = <MutationTree<PartnerStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the global reset.
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof PartnerStoreState] = newState[key as keyof PartnerStoreState];
    });
  },
  /* SET_CUSTOMER(state, data) {
    state.customer = data;
  } */
};

export const PartnerModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
