import Vue from "vue";
import { VDialog } from "vuetify/lib";

/*
Usage in a component: 

this.$dialog({
  component: SomeDialogComponent,                  //Desired dialog component
  parent: this,
  style: 'some-style-property: some-style-value;', //(optional) CSS styles
  ...otherProps                                    //(SomeDialogComponent props)
}).onOk(data => {
  console.log(`Received: ${data}`)
})
.onCancel(() => {
  console.log(`Dialog canceled`);
})
.onClose(() => {
  console.log(`Dialog closed`);
});

Example SomeDialogComponent:
<template>
  <v-card>
    <v-card-title> Dialog title </v-card-title>
    <v-card-text>
      <!-- Dialog body -->
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="onOk">Ok</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  methods: {
    onOk() {
      this.$emit("ok", "some data"); //some data is optional ;)
      this.hide();
    },
    onCancel() {
      this.hide();
    },
    hide() {
      this.$emit("hide");
    }
  }
};
</script>

The dialog (component and the element from dom) is destroyed when closed/hidden
*/

const dialog = ({ style, component, parent, ...props }) => {
  const okFns = [],
    cancelFns = [],
    API = {
      onOk(fn) {
        okFns.push(fn);
        return API;
      },
      onCancel(fn) {
        cancelFns.push(fn);
        return API;
      },
      onClose(fn) {
        okFns.push(fn);
        cancelFns.push(fn);
        return API;
      },
      hide() {
        vm.hide();
        return API;
      },
    };

  const el = document.createElement("div");
  document.body.appendChild(el);

  const cleanup = () => {
    vm.$destroy();
    vm.$el.remove();
    vm = null;
  };

  let emittedOk = false;

  Vue.observable(props);

  let vm = new Vue({
    el,
    name: "Dialog",
    parent,
    data() {
      return { show: false };
    },
    mounted() {
      console.warn("Dialog component is deprecated");
      this.show = true;
    },
    methods: {
      hide() {
        this.onInput(false);
      },
      onInput(show) {
        this.show = show;
        if (!show) {
          this.onHide();
        }
      },
      onOk(data) {
        emittedOk = true;
        okFns.forEach((f) => f(data));
      },
      onHide() {
        if (!emittedOk) {
          cancelFns.forEach((f) => f());
        }
        cleanup();
      },
    },
    render(h) {
      const content = h(component, {
        props,
        on: {
          ok: this.onOk,
          hide: this.hide,
        },
      });
      return h(
        VDialog,
        {
          style,
          props: {
            width: "fit-content",
            value: this.show,
          },
          on: {
            input: this.onInput,
          },
        },
        [content]
      );
    },
  });
  return API;
};

export default {
  install(Vue) {
    Vue.prototype.$dialog = dialog;
  },
};
