import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { getCurrentInstance, watch } from "@vue/composition-api";
import VueRouter from "vue-router";
import { Store } from "vuex";
import { hasMemberOrgAccess } from "./accessLevelApiAdapter";
import { canCreateInvoiceBasis, isGrantApplicationEnabled } from "./courseHelpers";
import { isVocationalSchool } from "./curriculumHelpers";
import { globalLoadingWrapper } from "./loadingHelpers";
import { runStoreAction } from "./store.helpers";

export interface MenuItem {
  title: string;
  link: string;
  icon: string;
  requireAuthorization?: (auth: typeof authService) => boolean;
  isEnabledForMemberOrganizations?: boolean;
  extraProperties?: {
    [key: string]: string;
  };
  featureFlag?: boolean;
}

const filterAuthorizedMenuItems = (router: VueRouter, items: MenuItem[]) =>
  items
    .filter((item) => {
      if ("featureFlag" in item) {
        return item.featureFlag;
      }
      return true;
    })
    .filter((item) => {
      const route = router.resolve(item.link).resolved;
      if (route.name === "NotFound") {
        console.warn(`Could not resolve route '${item.link}' (not found)`);
        return false;
      }

      if (item.requireAuthorization) {
        return item.requireAuthorization(authService);
      }

      return true;
    })
    .filter((item) => {
      if (!hasMemberOrgAccess) {
        return true;
      }
      return item.isEnabledForMemberOrganizations;
    })
    .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

export const watchRoutePathImmediate = (watchFunction: (routePath?: string) => void) => {
  const vm = getCurrentInstance();
  watch(() => vm?.proxy.$route.path, watchFunction, { immediate: true });
};

export const adminItems = (router: VueRouter) =>
  filterAuthorizedMenuItems(router, [
    {
      title: "Læremidler",
      link: `/admin/teachingmaterial`,
      icon: "mdi-pencil-ruler",
    },
    {
      title: "Læremiddel-kategorier",
      link: `/admin/teachingmaterial/categories`,
      icon: "mdi-view-grid-plus-outline",
    },
    {
      title: "Ressursroller",
      link: `/admin/resourceRoles`,
      icon: "mdi-account-cog",
    },
    {
      title: "Lokasjoner",
      link: `/admin/areas`,
      icon: "mdi-map-search-outline",
    },
    {
      title: "Medlemsorganisasjoner",
      link: `/admin/memberOrganizations`,
      icon: "mdi-map-search-outline",
    },
    {
      title: "Testtyper",
      link: `/admin/testtypes`,
      icon: "mdi-book-check-outline",
    },
    {
      title: "Skolerute",
      link: `/admin/vacationSchedule`,
      icon: "mdi-timeline-clock-outline",
    },
    {
      title: "Påmeldingsskjema",
      link: `/admin/applicationform`,
      icon: "mdi-pencil-box-multiple-outline",
      extraProperties: {
        "data-cy": "applicationform-link",
      },
    },
    {
      title: "Godkjente arbeidstimer",
      link: "/admin/approvedWorkingHours",
      icon: "mdi-clock-check-outline",
      extraProperties: {
        "data-cy": "approvedWorkingHours-link",
      },
    },
    {
      title: "Hoved- og kursområder",
      link: `/admin/subjectRegister`,
      icon: "mdi-form-select",
    },
    {
      title: "Utdanningstilbuds- og emnekoder",
      link: `/admin/subjectCodes`,
      icon: "mdi-form-select",
    },
    {
      title: "Utgifter",
      link: `/admin/expenses`,
      icon: "mdi-cash-register",
    },
    {
      title: "Lønnskostnader",
      link: `/admin/salaryCosts`,
      icon: "mdi-cash-register",
      extraProperties: {
        "data-cy": "salaryCosts-link",
      },
    },
    {
      title: "Opplæringstilskudd",
      link: `/admin/trainingGrants`,
      icon: "mdi-account-cash-outline",
    },
    {
      title: "Kommunikasjonsmaler",
      link: `/admin/communicationTemplates`,
      icon: "mdi-format-list-bulleted",
      extraProperties: {
        "data-cy": "communicationTemplates-link",
      },
    },
    {
      title: "Studiestedsregister",
      link: `/admin/studyLocationRegister`,
      icon: "mdi-city-variant",
      extraProperties: {
        "data-cy": "studyLocationRegister-link",
      },
    },
    {
      title: "NUS-koderegister",
      link: `/admin/nusCodeRegister`,
      icon: "mdi-list-box-outline",
      extraProperties: {
        "data-cy": "nusCodeRegister-link",
      },
    },
    {
      title: "Satsregister",
      link: `/admin/rateRegisterList`,
      icon: "mdi-cash-multiple",
      requireAuthorization: (authService) => authService.hasRoles(AuthRole.ModifyExtendedCourse), // TODO add check for "Økonomiansvarlig sentralt"
      extraProperties: {
        "data-cy": "rateRegisterList-link",
      },
    },
    // The need for this is not warranted as of now, the customer wants to use the clientaccount course overview as an entrypoint for this functionality instead.
    // {
    //   title: "Fakturagrunnlag",
    //   link: "/admin/invoiceBasisList",
    //   icon: "mdi-receipt-text-outline",
    //   requireAuthorization: (authService) => authService.hasRoles(AuthRole.ModifyExtendedCourse), // TODO add check for "Økonomiansvarlig sentralt",
    //   extraProperties: {
    //     "data-cy": "invoiceBasisList-link",
    //   },
    //   featureFlag: featureFlags.not_in_prod_or_staging,
    // },
  ]);

export const contactItems = (router: VueRouter) =>
  filterAuthorizedMenuItems(router, [
    {
      title: "Alle personer",
      link: `/adressebok`,
      icon: "mdi-account-group-outline",
    },
    {
      title: "Alle firma",
      link: `/adressebok/firma`,
      icon: "mdi-office-building-outline",
    },
  ]);

export const courseItems = async (store: Store<StoreState>, path: string, courseId: number, router: VueRouter) => {
  // store: Store<StoreState>
  if (!path.includes("/kurset/")) {
    return filterAuthorizedMenuItems(router, [
      {
        title: "Søk",
        link: `/kurs`,
        icon: "mdi-table",
        isEnabledForMemberOrganizations: true,
      },
      {
        title: "Opprett",
        link: `/kurs/ny`,
        icon: "mdi-table-plus",
      },
    ]);
  }

  // TODO improve this mess
  await globalLoadingWrapper({ blocking: false }, async () => {
    if (store.state.courses.course.id !== courseId) {
      await runStoreAction(store, StoreModules.Courses, StoreActions.CoursesActions.FetchCourse, courseId);
      await Promise.allSettled([
        await runStoreAction(
          store,
          StoreModules.Execution,
          StoreActions.ExecutionActions.GetExecutionPlan,
          store.state.courses.course.curriculumId
        ),
        await runStoreAction(
          store,
          StoreModules.Plans,
          StoreActions.PlansActions.FetchCurriculum,
          store.state.courses.course.curriculumId
        ),
      ]);
    }
  });

  const items: MenuItem[] = [
    {
      title: isVocationalSchool(store.state.plans.studyplan.mainCourseId) ? "Alle utdanninger" : "Alle kurs",
      link: `/kurs`,
      icon: "mdi-arrow-left",
      isEnabledForMemberOrganizations: true,
      extraProperties: {
        class: "highlightItem",
      },
    },
    {
      title: isVocationalSchool(store.state.plans.studyplan.mainCourseId) ? "Dashboard utdanninger" : "Dashboard",
      link: `/kurset/${courseId}`,
      icon: "mdi-view-dashboard-outline",
      isEnabledForMemberOrganizations: true,
    },
    {
      title: isVocationalSchool(store.state.plans.studyplan.mainCourseId) ? "Studenter" : "Deltakere",
      link: `/kurset/${courseId}/participants`,
      icon: "mdi-account-group-outline",
      isEnabledForMemberOrganizations: true,
      extraProperties: {
        "data-cy": "participants-link",
      },
    },
  ];
  // Hide menu item until functionality is implemented
  /* if (store.state.courses.course.useLearningPlatform) {
    items.push({
      title: "Læringsplattform",
      link: `/kurset/${courseId}/platform`,
      icon: "mdi-cellphone-link",
    });
  } */
  items.push(
    {
      title: "Ressurser",
      link: `/kurset/${courseId}/resources`,
      icon: "mdi-account-cog-outline",
      extraProperties: {
        "data-cy": "resources-link",
      },
    }
    // Hide menu item until functionality is implemented
    /* {
      title: "Historikk", // Skal endres til if fagskole = ,
      link: `/kurset/${courseId}/history`,
      icon: "mdi-history",
    } */
  );

  if (store.state.courses.course.plan?.registerHours) {
    items.push({
      title: "Fremmøte",
      link: `/kurset/${courseId}/attendance`,
      icon: "mdi-account-check-outline",
    });
  }
  if (
    store.state.plans.studyplan.courseCertificateRequired &&
    !isVocationalSchool(store.state.plans.studyplan.mainCourseId)
  ) {
    items.push({
      title: "Kursbevis",
      link: `/kurset/${courseId}/certificate`,
      icon: "mdi-certificate-outline",
    });
  }

  items.push({
    title: "Økonomi",
    link: `/kurset/${courseId}/economy`,
    icon: "mdi-cash-register",
  });

  if (store.state.courses.course.economy?.allowExpenses) {
    items.push({
      title: "Refusjonskrav",
      link: `/kurset/${courseId}/expenses`,
      icon: "mdi-airplane",
      extraProperties: {
        "data-cy": "courseParticipantExpenses-link",
      },
    });
  }

  if (isVocationalSchool(store.state.plans.studyplan.mainCourseId)) {
    items.push({
      title: "Arbeidsgivere",
      link: `/kurset/${courseId}/employer`,
      icon: "mdi-account-multiple",
    });
  }

  if (store.state.courses.course.plan?.schedules?.length) {
    items.push({
      title: "Arbeidstimer",
      link: `/kurset/${courseId}/workingHours`,
      icon: "mdi-clock",
    });
  }

  if (isGrantApplicationEnabled(store.state.courses.course.economy?.grantId)) {
    items.push({
      title: "Tilretteleggingstilskudd",
      link: `/kurset/${courseId}/facilitationGrants`,
      icon: "mdi-cash-multiple",
    });
  }

  if (canCreateInvoiceBasis(store.state.courses.course.economy?.grantId)) {
    items.push({
      title: "Fakturagrunnlag",
      link: `/kurset/${courseId}/invoiceBasis`,
      icon: "mdi-account-cash",
    });
  }

  items.push({
    title: "Dokumenter", // Skal endres til if fagskole = ,
    link: `/kurset/${courseId}/files`,
    icon: "mdi-file-document-multiple-outline",
  });

  items.push({
    title: "Notater",
    link: `/kurset/${courseId}/notes`,
    icon: "mdi-notebook",
    extraProperties: {
      "data-cy": "notes-link",
    },
  });

  items.push({
    title: "Invitasjoner",
    link: `/kurset/${courseId}/invites`,
    icon: "mdi-account-multiple-plus",
    extraProperties: {
      "data-cy": "invites-link",
    },
  });

  items.push({
    title: "Meldinger",
    link: `/kurset/${courseId}/messages`,
    icon: "mdi-forum",
    extraProperties: {
      "data-cy": "messages-link",
    },
  });
  return filterAuthorizedMenuItems(router, items);
};

export const studyplanItems = (router: VueRouter) =>
  filterAuthorizedMenuItems(router, [
    {
      title: "Studieplaner",
      link: `/studieplaner`,
      icon: "mdi-table",
    },
    {
      title: "Mine utkast",
      link: `/studieplaner/utkast`,
      icon: "mdi-table-edit",
    },
    {
      title: "Ny studieplan",
      link: `/studieplaner/ny`,
      icon: "mdi-table-plus",
    },
  ]);

export const dashboardItems = (router: VueRouter) =>
  filterAuthorizedMenuItems(router, [
    {
      title: "Dashboard",
      link: `/home`,
      icon: "mdi-view-dashboard",
      isEnabledForMemberOrganizations: true,
    },
    {
      title: "Studieplaner til godkjenning",
      link: `/home/plansforApproval`,
      icon: "mdi-format-list-checks",
      requireAuthorization: (authService) => authService.hasRoles(AuthRole.ApproveCurriculum),
    },
    // Not implemented/ready yet
    // {
    //   title: "Timer til godkjenning",
    //   link: `/home/hoursForApproval`,
    //   icon: "mdi-account-clock-outline",
    // },
    // Not implemented/ready yet
    // {
    //   title: "Kommende kurs/klasser",
    //   link: `/home/pendingCourses`,
    //   icon: "mdi-account-group",
    // },
    // Not implemented/ready yet
    // {
    //   title: "Mine ordre",
    //   link: `/home/pendingOrders`,
    //   icon: "mdi-account-cash",
    // },
    {
      title: hasMemberOrgAccess ? "Nye søknader" : "Nye påmeldinger og søknader",
      link: `/home/newApplications`,
      icon: "mdi-account-details",
      isEnabledForMemberOrganizations: true,
    },
    {
      title: "Forhåndsgodkjente søkere",
      link: `/home/preApproved`,
      icon: "mdi-account-details",
      isEnabledForMemberOrganizations: true,
    },
    {
      title: "Lavt fremmøte",
      link: "/home/coursesWithLowAttendance",
      icon: "mdi-account-alert",
    },
    {
      title: "Uleste meldinger",
      link: `/home/unreadCourseMessages`,
      icon: "mdi-forum",
    },
    {
      title: "Nye dokumenter",
      link: "/home/newDocuments",
      icon: "mdi-file-document",
    },
    {
      title: "Endrede deltakere",
      link: "/home/changedParticipants",
      icon: "mdi-account-box-outline",
    },
  ]);
