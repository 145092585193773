export enum SingleCourseRouteNames {
  CourseDashboard = "courseDashboard",
  CourseParticipants = "courseParticipants",
  CourseParticipantDetails = "courseParticipantDetails",
  CourseTeachingPlatform = "courseTeachingPlatform",
  CourseResources = "courseResources",
  CourseResourceDetails = "courseResourceDetails",
  CourseCompanyPage = "courseCompanyPage",
  CourseHistory = "courseHistory",
  CourseAttendance = "courseAttendance",
  CourseAttendanceParticipant = "courseAttendanceParticipant",
  CourseCertificates = "courseCertificates",
  CourseEconomy = "courseEconomy",
  CourseEconomyNew = "courseEconomyNew",
  CourseEconomyDetails = "courseEconomyDetails",
  CourseEconomyContra = "courseEconomyContra",
  CourseExpenses = "courseExpenses",
  CourseExpenseDetails = "courseSingleExpenses",
  CourseFiles = "courseFiles",
  CourseNotes = "courseNotes",
  CourseInvites = "courseInvites",
  CourseInvitesNew = "courseInvitesNew",
  CourseEvaluation = "courseEvaluation",
  CourseMessages = "courseMessages",
  CourseSingleMessage = "courseSingleMessage",
  CourseWorkingHours = "courseWorkingHours",
  CourseFacilitationGrants = "CourseFacilitationGrants",
  CourseWorkingHourDetails = "courseSingleWorkingHour",
  CourseInvoiceBasis = "courseInvoiceBasis",
  CourseInvoiceBasisDetails = "courseInvoiceBasisDetails",
  EndCourse = "endCourse",
  Employer = "employer",
}
