import { getField, updateField } from "vuex-map-fields";
import { getOrganization, putOrganization } from "@/api/organization.api";
import { OrganizationStoreState } from "./organization.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { OrganizationMutations } from "./organization.mutations.enum";
import { OrganizationActions } from "@/store/modules/organization/organization.actions.enum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { api } from "@/api/api";

const initialState: () => OrganizationStoreState = () => ({
  organizationInfo: {},
  organizations: [],
});

const state = initialState();

const getters = <GetterTree<OrganizationStoreState, RootStoreState>>{
  getField,
  getOrganizationInfo(state) {
    return state.organizationInfo;
  },
};

const actions = <ActionTree<OrganizationStoreState, RootStoreState>>{
  [OrganizationActions.Reset]({ commit }) {
    commit("RESET");
  },
  /* async [OrganizationActions.FetchSchedules]({ commit, dispatch }) {
    const response = await getSchedules();
    if (response.length > 0) {
      await commit("SET_ORG_INFOS", response);
    }
  }, */
  async [OrganizationActions.FetchOrganizations]({ commit }) {
    const response = await api.organization.getMemberOrganizationsAsync();
    await commit("SET_ORGANIZATIONS", response.data);
  },
  async [OrganizationActions.FetchOrganizationInfo]({ commit }) {
    const response = await getOrganization();
    await commit("SET_ORG_INFO", response);
  },
  /* async [OrganizationActions.AddSchedule]({ dispatch }) {
    await postSchedule(state.vacationScheduleSingle);
    openNotification(this as any, NotificationItemType.Success, "Opprettelse av test type vellykket.");
  }, */
  async [OrganizationActions.EditOrganizationInfo]() {
    await putOrganization(state.organizationInfo);
    openNotification(this as any, NotificationItemType.Success, "Redigering av test type vellykket.");
  },
};

const mutations = <MutationTree<OrganizationStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the gloabal reset.
  [OrganizationMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof OrganizationStoreState] = newState[key as keyof OrganizationStoreState];
    });
  },
  [OrganizationMutations.SET_ORG_INFO](state, data) {
    state.organizationInfo = data;
  },
  [OrganizationMutations.SET_ORGANIZATIONS](state, data) {
    state.organizations = data;
  },
};

export const OrganizationModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
