export enum CoursesActions {
  Reset = "reset",
  FetchCourse = "fetchCourse",
  FetchCourseDefaultOptions = "fetchCourseDefaultOptions",
  FetchCourses = "fetchCourses",
  GetCourses = "getCourses",
  GetCoursesByStudId = "getCoursesByStudId",
  FetchCurriculum = "fetchCurriculum",
  FetchLocations = "fetchLocations",
  UpdateCourse = "updateCourse",
  FetchCourseCategories = "fetchCourseCategories",
}
