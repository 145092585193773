var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.filters.length ? _c('div', _vm._l(_vm.filters, function (_ref, i) {
    var filterComponent = _ref.filterComponent,
        props = _ref.props,
        on = _ref.on,
        handlers = _ref.handlers;
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-content', {
      staticClass: "pa-2"
    }, [_c(filterComponent, _vm._g(_vm._b({
      tag: "component"
    }, 'component', props, false), Object.assign({}, on, handlers)))], 1)], 1);
  }), 1) : _vm._e(), _c('portal-target', {
    attrs: {
      "name": "tableFilter"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }