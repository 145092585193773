export enum DashboardRouteNames {
  Home = "home",
  PendingCourses = "pendingCourses",
  PlansForApproval = "plansForApproval",
  PendingWorkingHours = "pendingWorkingHours",
  PendingOrders = "pendingOrders",
  UnreadCourseMessages = "unreadCourseMessages",
  PreRequisitionsExecutionPlan = "preRequisitionsExecutionPlan",
  NewExecutionPlan = "newExecutionPlan",
  FacilitationGrants = "facilitationGrants",
  CoursesWithLowAttendance = "coursesWithLowAttendance",
  NewApplications = "newApplications",
  NewDocuments = "newDocuments",
  ChangedParticipants = "changedParticipants",
  Contracts = "contracts",
  PreApproved = "preApproved",
}
