import { getEconomyDefaultOptions } from "./../../../api/cost.api";
import { getField, updateField } from "vuex-map-fields";
import { getAllCost, getCost, postCost, putCost } from "@/api/cost.api";
import { CostStoreState } from "./cost.store.interface";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootStoreState } from "@/store/root-store.state.interface";
import { CostMutations } from "./cost.mutations.enum";
import { CostActions } from "@/store/modules/cost/cost.actions.enum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

const initialState: () => CostStoreState = () => ({
  costs: [],
  cost: {},
  economyDefaultOptions: [],
});

const state = initialState();

const getters = <GetterTree<CostStoreState, RootStoreState>>{
  getField,
  getCosts(state) {
    return state.costs;
  },
  getCost(state) {
    return state.cost;
  },
};

const actions = <ActionTree<CostStoreState, RootStoreState>>{
  [CostActions.Reset]({ commit }) {
    commit("RESET");
  },
  async [CostActions.Fetchcosts]({ commit }) {
    const response = await getAllCost();
    if (response.status === 200) {
      await commit("SET_COSTS", response.data);
    }
  },
  async [CostActions.FetchEconomyDefaultOptions]({ commit }) {
    const response = await getEconomyDefaultOptions();
    await commit("SET_ECONOMY_DEFAULT_OPTIONS", response.data);
  },
  async [CostActions.FetchCost]({ commit }, id) {
    const response = await getCost(id);
    if (response.status === 200) {
      await commit("SET_COST", response.data);
    }
  },
  async [CostActions.AddCost]({ commit }) {
    const response = await postCost(state.cost);
    commit("ADD", response.data.id);
    openNotification(this as any, NotificationItemType.Success, "Opprettelse av test type vellykket.");
  },
  async [CostActions.EditCost]() {
    await putCost(state.cost);
    openNotification(this as any, NotificationItemType.Success, "Redigering av test type vellykket.");
  },
};

const mutations = <MutationTree<CostStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the gloabal reset.
  [CostMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof CostStoreState] = newState[key as keyof CostStoreState];
    });
  },
  [CostMutations.SET_COST](state, data) {
    state.cost = data;
  },
  [CostMutations.SET_COSTS](state, data) {
    state.costs = data;
  },
  [CostMutations.SET_ECONOMY_DEFAULT_OPTIONS](state, data) {
    state.economyDefaultOptions = data;
  },
  [CostMutations.ADD](state, data) {
    state.cost.id = data;
    state.costs.push(state.cost);
  },
};

export const CostModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
