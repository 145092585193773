// import { httpClient } from "./httpClient";

export const getOrganizations = () => mockOrg;

/* httpClient.get(`/Organization`); */

export const getOrganization = () => mockOrg;
/* httpClient.get(`/Organization/${id}`); */

export const putOrganization = (body: any) => {
  mockOrg = body;
  /*   httpClient.put(
        `/Organization/${OrganizationBody.id}`,
        OrganizationBody
      );
    }; */
};

export const postOrganization = () => {
  // mockOrg.push(body);
  /*   httpClient.post(`/Organization`, OrganizationBody);
   */
};

/* HELPERS */

let mockOrg = {
  invoiceDetails: {
    companyName: "Sysco AS",
    contactName: "Hung Huynh",
    referenceNumber: 12341,
    numberOfParticipants: 7,
    allowExternalParticipants: true,
    invoiceTo: "Kjetil Langeset",
    invoiceAddress: "Stølsmyr 2",
    invoiceZipCode: 1111,
    invoiceZipName: "Karmøy",
  },
  onlineCourseOrder: {
    optionalText: null,
    courseDate: "2021-03-17",
    courseTitle: "Nettkurs",
    timeframe: "Usikker på hva dette vil være ... ?",
    lecturerId: 476,
    price: 100000.0,
    organizationNumber: 987129360,
    signatur: "BankId - module",
    info: "Her kan det stå mye nyttig info om kurset.",
    other: "Her står det annen info knyttet til kurset.",
    specialTerms: "Dette kurset skal holdes av ... Med deltakere fra ...",
    standardText: "Denne teksten skal hentes fra kurset...",
  },
};
